import {SafeClamp} from './math'

/**
 * Função padrão que aplica um valor em uma variavel de estado direto na chave informada.
 * @param func Função despachante para aplicar o valor no estado
 * @param key Chave do objeto presente na variavel de estado
 * @param value Valor a ser aplicado na chave do objeto
 * @returns void
 */
export const handleApplyValueToState = (func: React.Dispatch<any>, key: string, value: any) => {
  func((prev: any) => {
    return {...prev, [key]: value}
  })
}

/**
 * Função padrão que aplica um valor em uma variavel de estado direto na chave informada de um objeto dentro de um array. NOTA: Perceba que esta função só funciona com objetos do tipo Array.
 * @param func Função despachante para aplicar o valor no estado
 * @param index Index do array onde se encontra o objeto para ter a propriedade alterada
 * @param key Chave do objeto presente na variavel de estado
 * @param value Valor a ser aplicado na chave do objeto
 * @returns void
 */
export const handleApplyValueToArrayedState = (func: React.Dispatch<any>, index: number, key: string, value: any) => {
  func((prev: any) => {
    prev[index][key] = value
    return [...prev]
  })
}

/**
 * Função padrão que aplica um valor de ponto flutuante em uma variavel de estado direto na chave informada.
 * @param func Função despachante para aplicar o valor no estado
 * @param key Chave do objeto presente na variavel de estado
 * @param value Valor a ser aplicado na chave do objeto
 * @param min (Opcional) Número mínimo para travar o valor informado
 * @param max (Opcional) Número máximo para travar o valor informado
 * @param fractionDigits (Opcional) Número de dígitos após a virgula. NOTA: Ao setar esse parâmetro o valor final será transformado em string!!!
 * @returns void
 */
export const handleApplyClampedValueToState = (
  func: React.Dispatch<any>,
  key: string,
  value: number,
  min?: number,
  max?: number,
  fractionDigits?: number
) => {
  let _val = SafeClamp(value, min, max)
  handleApplyValueToState(func, key, fractionDigits != null ? _val.toFixed(fractionDigits) : _val)
}

/**
 * Função padrão que aplica um valor de ponto flutuante em uma variavel de estado direto na chave informada.
 * @param func Função despachante para aplicar o valor no estado
 * @param index Index do array onde se encontra o objeto para ter a propriedade alterada
 * @param key Chave do objeto presente na variavel de estado
 * @param value Valor a ser aplicado na chave do objeto
 * @param min (Opcional) Número mínimo para travar o valor informado
 * @param max (Opcional) Número máximo para travar o valor informado
 * @param fractionDigits (Opcional) Número de dígitos após a virgula. NOTA: Ao setar esse parâmetro o valor final será transformado em string!!!
 * @returns void
 */
export const handleApplyClampedValueToArrayedState = (
  func: React.Dispatch<any>,
  index: number,
  key: string,
  value: number,
  min?: number,
  max?: number,
  fractionDigits?: number
) => {
  let _val = SafeClamp(value, min, max)
  handleApplyValueToArrayedState(func, index, key, fractionDigits != null ? _val.toFixed(fractionDigits) : _val)
}
