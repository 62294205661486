import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import TabsOptions from '../pages/_dynamic/components/TabsOptions'
import Dashboard from '../pages/_dynamic/components/dashboard/dashboard'
import PrivateRead from '../pages/_dynamic/components/PrivateRead'
import CustomPageRenderer from '../pages/_dynamic/components/CustomPageRenderer'

interface IPrivateRoutes {
  routeChooser(originalRoute?: string): string
}

const PrivateRoutes = ({routeChooser}: IPrivateRoutes) => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={routeChooser('/dashboard')} />} />
        {/* Pages */}
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='pages/_dynamic/*'
          element={
            <SuspensedView>
              <TabsOptions defaultOpenedTab={0} showTabs={false} reset={true} />
            </SuspensedView>
          }
        />
        <Route
          path='pages/_dynamicread/:accesscode/:idrouteitem'
          element={
            <SuspensedView>
              <PrivateRead />
            </SuspensedView>
          }
        />
        <Route
          path='pages/custom/:accesscode'
          element={
            <SuspensedView>
              <CustomPageRenderer />
            </SuspensedView>
          }
        />
        <Route
          path='pages/_dynamictabs/*'
          element={
            <SuspensedView>
              <TabsOptions defaultOpenedTab={0} showTabs={true} reset={false} />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/dashboard' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
