import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../app/modules/api/ApiWrapper'
import {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import {ElapsedFollowUpTimerLinkResult} from '../../../../app/modules/api/models/Result/ElapsedFollowUpTimerLinkResult'
import Timer from '../../../../app/pages/_dynamic/components/utility/Timer'

interface IHeaderFollowUps {}

const HeaderFollowUps = ({}: IHeaderFollowUps) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [loading, setLoading] = useState<boolean>(false)
  const [followUps, setFollowUps] = useState<Array<ElapsedFollowUpTimerLinkResult>>([])

  const handleInit = async () => {
    setLoading(true)
    await handleLoadFollowUps()
    setLoading(false)
  }

  const handleLoadFollowUps = () => {
    apiWrapper
      .get('api/v1/ElapsedFollowUpTimer/listarLink')
      .then((response) => {
        setFollowUps((response.data.data as Array<ElapsedFollowUpTimerLinkResult>).filter((x) => x.termino == null))
      })
      .catch((error) => {
        //console.log(error)
      })
  }

  useEffect(() => {
    handleInit()

    const intervalId = setInterval(() => {
      handleLoadFollowUps()
    }, 5000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <>
      <div
        data-kt-menu-trigger="{default: 'click'}"
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
        className='btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px position-relative'
      >
        <KTIcon iconName='timer' className='fs-1' />
        {followUps.length > 0 && (
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        )}
      </div>

      <div className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px' data-kt-menu='true'>
        <div
          className='d-flex flex-column bgi-no-repeat rounded-top'
          style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
        >
          <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
            Acompanhamentos Ativos
            <br />
            <span className='fs-8 opacity-75 ps-3'>{followUps.length} acompanhamento(s)</span>
          </h3>

          <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
            <li className='nav-item'>
              <a className='nav-link text-white opacity-75 opacity-state-100 pb-4 active' data-bs-toggle='tab' href='#kt_topbar_followup_all'>
                Todos
              </a>
            </li>
          </ul>
        </div>

        <div className='tab-content'>
          {followUps.length > 0 && (
            <div className='tab-pane fade show active' id='kt_topbar_followup_all' role='tabpanel'>
              <div className='scroll-y mh-325px my-5 px-8'>
                {followUps.map((followUp, index) => (
                  <div key={`log${index}`} className='d-flex flex-stack py-4'>
                    <div className='d-flex align-items-center me-2'>
                      <span className={clsx('badge', `badge-light-success`, 'me-4')}>
                        <Timer initialDate={new Date(followUp.inicio)} />
                      </span>

                      <span
                        style={{cursor: 'pointer', maxWidth: '235px'}}
                        className='text-gray-800 text-hover-primary fw-bold text-truncate'
                        onClick={() => {
                          switch (followUp.accessCode) {
                            case 'SYSTASK':
                              window.open(`/pages/_dynamicTabs/${followUp.accessCode}/${followUp.idmenuAppGenericValue}`, '_blank')
                              break
                            default:
                              window.open(`/pages/_dynamicread/${followUp.accessCode}/${followUp.idmenuAppGenericValue}`, '_blank')
                              break
                          }

                          //navigate(`/pages/_dynamicread/${followUp.accessCode}/${followUp.idmenuAppGenericValue}`, {replace: true})
                        }}
                      >
                        {followUp.nome}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {loading && (
          <Box component='div' sx={{py: 1, textAlign: 'center'}}>
            <CircularProgress />
          </Box>
        )}
        {!loading && followUps.length == 0 && (
          <Box component='div' sx={{px: 1, py: 3, textAlign: 'center'}}>
            <span className='text-gray-800 fw-bold'>Não há acompanhamentos ativos</span>
          </Box>
        )}
      </div>
    </>
  )
}

export {HeaderFollowUps}
