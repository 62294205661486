import React, { useState, useRef, useEffect } from 'react'
import StyledDialog from '../styled/StyledDialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import DialogActions from '@mui/material/DialogActions'
import StyledButton from '../styled/StyledButton'
import CancelIcon from '@mui/icons-material/Cancel'
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = '/library/pdf.worker.min.mjs';

interface IModalPdf {
  pdfUrl: string
  callback(data: any): void
}

const ModalPdf = ({ pdfUrl, callback }: IModalPdf) => {
  const [numPages, setNumPages] = useState<number | null>(null)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageWidth, setPageWidth] = useState<number>(0)
  const contentRef = useRef<HTMLDivElement>(null)

  const handleClose = () => {
    callback({ closed: true })
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
    callback({ loaded: true })
    updatePageWidth()
  }

  const updatePageWidth = () => {
    if (contentRef.current) {
      const newWidth = contentRef.current.offsetWidth - 32 // Subtracting padding
      setPageWidth(newWidth)
    }
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(updatePageWidth)
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current)
    }

    updatePageWidth() // Initial call

    return () => {
      if (contentRef.current) {
        resizeObserver.unobserve(contentRef.current)
      }
    }
  }, [])

  return (
    <StyledDialog open fullWidth maxWidth='md'>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>Visualização de PDF</Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <StyledButton variant='contained' color='inherit' disabled={pageNumber <= 1} onClick={() => setPageNumber(pageNumber - 1)}>
            Anterior
          </StyledButton>
          <StyledButton variant='contained' color='inherit' disabled={pageNumber >= (numPages || 1)} onClick={() => setPageNumber(pageNumber + 1)}>
            Próximo
          </StyledButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers ref={contentRef} sx={{ height: 'calc(100vh - 200px)', padding: '16px', overflow: 'auto' }}>
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.error('Error loading PDF:', error)}
          loading={
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
          }
        >
          {pageWidth > 0 && (
            <Page
              key={`page_${pageNumber}`}
              pageNumber={pageNumber}
              width={pageWidth}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          )}
        </Document>
      </DialogContent>
      <DialogActions>
        <StyledButton variant='contained' color='inherit' startIcon={<CancelIcon />} onClick={handleClose}>
          Fechar
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  )
}

export default ModalPdf