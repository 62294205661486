import React, {useEffect, useState} from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import DeleteIcon from '@mui/icons-material/Delete'

import BoxSVGMessage from '../utility/BoxSVGMessage'
import {CCalcFieldRule} from '../../../../models/classes/CCalcFieldRule'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'

interface IFieldRuleOperationParams {
  accessCode: string
  operations: Array<CCalcFieldRule>
  setOperations: React.Dispatch<React.SetStateAction<CCalcFieldRule[]>>
  callBack: (response: any) => void
}

const FieldRuleOperation = ({accessCode, operations, setOperations, callBack}: IFieldRuleOperationParams) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [Opened, setOpened] = useState<boolean>(false)
  const [Fields, setFields] = useState<Array<any>>([])

  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  const handleInit = () => {
    setOpened(true)

    apiWrapper.get('api/v1/MenuAppFields/listarPorMenuApp?item.AccessCode=' + accessCode).then((response) => {
      let fields: Array<any> = response.data.data.filter((item: any) => {
        return item.tipo == 'number' || item.tipo == 'decimal'
      })

      setFields(fields)
    })
  }

  const handleClose = (applyOperations: boolean) => {
    let obj: any = {}

    if (applyOperations) {
      obj.apply = true
    }

    setOpened(false)
    handleCallBack(obj)
  }

  const handleAddOperation = () => {
    let newOperation = new CCalcFieldRule()
    newOperation.operador = operations.length == 0 ? '#' : '+'
    setOperations([...operations, newOperation])
  }

  const handleRemoveOperation = (index: number) => {
    setOperations(operations.filter((item, i) => i != index))
  }

  const handleClearOperations = () => {
    setOperations([])
  }

  const handleOnChangeEvent = (index: number, key: string, event: any) => {
    setOperations((prev: Array<CCalcFieldRule>) => {
      prev[index][key] = event.target.value
      return [...prev]
    })
  }

  const handleOnChangeAutoComplete = (index: number, key: string, value: any) => {
    setOperations((prev: Array<CCalcFieldRule>) => {
      prev[index][key] = value?.id ?? 0
      return [...prev]
    })
  }

  useEffect(() => {
    handleInit()
  }, [])

  return (
    <Dialog open={Opened} fullWidth={true} maxWidth='sm'>
      <DialogTitle>Filtros Ativos</DialogTitle>
      <DialogContent dividers={true} sx={{minHeight: 300}}>
        <Box component='div' sx={{p: 1}}>
          <Button variant='text' size='small' sx={{px: 1}} onClick={() => handleAddOperation()}>
            + Adicionar operação
          </Button>
          <Button variant='text' size='small' color='error' sx={{px: 1}} onClick={() => handleClearOperations()}>
            - Limpar operações
          </Button>
        </Box>
        {operations.length == 0 && (
          <Box component='div' sx={{p: 4}}>
            <BoxSVGMessage message='Nenhum registro encontrado' />
          </Box>
        )}
        {operations.map((item: CCalcFieldRule, index: number) => {
          return (
            <Box key={`item-${index}`} component='div' className='d-flex' sx={{p: 1}}>
              <Box component='div'>
                <IconButton aria-label='delete' color='error' onClick={() => handleRemoveOperation(index)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box component='div' sx={{px: 0.5, width: 85}}>
                <FormControl fullWidth disabled={index == 0}>
                  <InputLabel id='operadorLogico-label'>Op. Matemático</InputLabel>
                  <Select
                    labelId='operadorLogico-label'
                    id='operadorLogico-label'
                    value={item.operador}
                    label='Operador Lógico'
                    size='small'
                    onChange={(event) => handleOnChangeEvent(index, 'operador', event)}
                  >
                    {index == 0 && <MenuItem value='#'> </MenuItem>}
                    <MenuItem value='+'>+</MenuItem>
                    <MenuItem value='-'>-</MenuItem>
                    <MenuItem value='*'>*</MenuItem>
                    <MenuItem value='/'>/</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box component='div' sx={{px: 0.5, width: 225}}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  options={Fields}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Campo' />}
                  value={item.campo != null && item.campo != 0 ? Fields.find((op: any) => op.id == item.campo) ?? null : null}
                  onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'campo', value)}
                />
              </Box>
            </Box>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='inherit' onClick={() => handleClose(true)}>
          Fechar
        </Button>
        {/*
        <Button variant='contained' color='primary' onClick={() => handleClose(true)}>
          Aplicar
        </Button>
        */}
      </DialogActions>
    </Dialog>
  )
}

export default FieldRuleOperation
