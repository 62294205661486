import React, {useEffect, useState} from 'react'
import {Api} from '../../../../modules/api/Api'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'

interface IFontAwesomeSelect {
  id: string
  label: string
  labelId: string
  type: string
  value: any
  onChange(event: any): void
}

export const MenuAppResourceSelect = ({id, label, labelId, type, value, onChange}: IFontAwesomeSelect) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [icons, setIcons] = useState<Array<any>>([])

  const handleInit = () => {
    apiWrapper.get('api/v1/MenuAppResource/listar?item.Tipo=' + type).then((response: any) => {
      setIcons(response.data.data)
    })
  }

  useEffect(() => {
    handleInit()
  }, [])

  return (
    <>
      <FormControl fullWidth size='small'>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select labelId={labelId} id={id} value={value} label={label} onChange={(event) => onChange(event)}>
          {icons.map((item: any, index: number) => {
            return (
              <MenuItem key={`resource-${index}`} value={item.resource}>
                {type == 'fontawesome' && (
                  <>
                    <i className={item.resource} aria-hidden='true'></i>
                    <span className='px-1'></span>
                    {item.nome}
                  </>
                )}
                {type == 'duotune' && (
                  <>
                    <img src={toAbsoluteUrl('/media/icons/duotune' + item.resource)} className='h-15px' />
                    <span className='px-1'></span>
                    {item.nome}
                  </>
                )}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </>
  )
}

export default MenuAppResourceSelect
