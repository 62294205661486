/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {AuthenticationPage} from '../modules/authentication/AuthenticationPage'
import {Logout} from '../modules/authentication/components/Logout'
import {App} from '../App'
import {useGlobalAuthentication} from '../modules/authentication/Authentication'
import PublicAccess from '../pages/_dynamic/components/PublicAccess'
import {getUrlParams} from '../models/wrappers/helpers'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  //* Importamos a variavel de usuario logado do contexto de autenticação global para verificar se o usuário está logado e tem acesso as rotas privadas
  const {loggedUser, auth} = useGlobalAuthentication()

  const getCurrentURL = () => {
    let params = getUrlParams()

    if (
      loggedUser != null ||
      auth != null ||
      params?.ReturnRoute != null ||
      window.location.href == `${window.location.origin}/` ||
      window.location.href.endsWith('/auth')
    ) {
      return ''
    }

    return `?ReturnRoute=${window.location.href.replace(window.location.origin, '')}`
  }

  const getReturnRoute = (originalRoute?: string) => {
    let params = getUrlParams()
    let ReturnRoute = (params?.ReturnRoute ?? originalRoute ?? '').replaceAll(`${window.location.origin}`, '')

    return ReturnRoute
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {loggedUser ?? auth ? (
            <>
              <Route path='/*' element={<PrivateRoutes routeChooser={getReturnRoute} />} />
              {/*<Route index element={<Navigate to={getReturnRoute('/dashboard')} />} />*/}
            </>
          ) : (
            <>
              <Route path='public/app/:accesscode' element={<PublicAccess />} />
              <Route path='public/app/:accesscode/:idrouteitem' element={<PublicAccess />} />
              <Route path='auth/*' element={<AuthenticationPage />} />
              <Route path='public/library/*' />
              <Route path='/*' element={<Navigate to={`/auth${getCurrentURL()}`} />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
