import {styled, SxProps, Theme} from '@mui/material/styles'
import Paper, {PaperClasses} from '@mui/material/Paper'
import {CommonProps} from '@mui/material/OverridableComponent'
import {MUIStyledCommonProps} from '@mui/system'
import {ReactNode, DetailedHTMLProps, HTMLAttributes, RefObject} from 'react'

const _Paper = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1e1e2d' : '#f2f2f2',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const Item = (
  props: JSX.IntrinsicAttributes & {
    children?: ReactNode
    classes?: Partial<PaperClasses> | undefined
    elevation?: number | undefined
    square?: boolean | undefined
    sx?: SxProps<Theme> | undefined
    variant?: 'elevation' | 'outlined' | undefined
  } & CommonProps &
    Omit<
      Pick<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'key' | keyof HTMLAttributes<HTMLDivElement>> & {
        ref?: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined
      },
      keyof CommonProps | 'children' | 'elevation' | 'square' | 'sx' | 'variant'
    > &
    MUIStyledCommonProps<Theme> & {children?: ReactNode}
) => {
  return <_Paper {...props} />
}

export default Item
