import {useState, useEffect} from 'react'
import {Api} from '../../../../modules/api/Api'
import {Box} from '@mui/material'

import KanbanCardFiles from '../modals/modalKanbanCardFiles'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'

interface IKanbanCard {
  onClick?(id: number): void
  cardId: number
  accessCode?: string
  headerLeft?: any
  headerLeftValue?: any
  headerRight?: any
  headerRightValue?: any
  title?: any
  titleTooltip?: any
  content?: any
  contentTooltip?: any
  footer?: any
  footerBadge?: any
  footerBadgePopup?: any
  commentOnClick?: any
}

//TODO: hookar eventos do card padrão

export const KanbanCard = ({
  onClick,
  cardId,
  accessCode,
  headerLeft,
  headerLeftValue,
  headerRight,
  headerRightValue,
  title,
  titleTooltip,
  content,
  contentTooltip,
  footer,
  footerBadge,
  footerBadgePopup,
  commentOnClick,
}: IKanbanCard) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [arquivos, setArquivos] = useState<Array<any>>([])
  const [modalKanbanCardFiles, setModalKanbanCardFiles] = useState(false)
  const [Tags, setTags] = useState<string>('')
  const [mensagens, setMensagens] = useState<Array<any>>([])

  const handleInit = () => {
    if (accessCode == null || accessCode == '') {
      return
    }

    apiWrapper.get(`api/v1/Arquivo/listarPorMenuAppItem?item.AccessCode=${accessCode}&item.IDMenuAppGenericValue=${cardId}`).then((response) => {
      setArquivos(response.data.data)
    })

    apiWrapper.get(`api/v1/MenuAppFieldTag/carregar?item.AccessCode=${accessCode}&item.IDMenuAppGenericValue=${cardId}`).then((response) => {
      let Tags = JSON.parse(response.data.data.tag)
      let TagString = ''

      Tags.forEach((item: string, index: number) => {
        TagString += item + (index == Tags.length - 1 ? '' : ', ')
      })

      setTags(TagString)
    })

    apiWrapper
      .get(`api/v1/MenuAppFieldComment/ListarPorMenuAppItem?item.AccessCode=${accessCode}&item.IDMenuAppGenericValue=${cardId}`)
      .then(async (response) => {
        setMensagens(response.data.data)
      })
  }

  const handleOnClick = (event: any) => {
    if (onClick != null) {
      onClick(cardId)
    }
  }

  const handleOnClickFiles = (event: any) => {
    setModalKanbanCardFiles(true)
  }

  const handleFilesModalCallback = (data: any = null) => {
    setModalKanbanCardFiles(false)
  }

  useEffect(() => {
    handleInit()
  }, [])

  let overflow: any = {
    whiteSpace: 'nowrap !important',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
  }

  return (
    <>
      <Box component='div' className='card my-1' sx={{minWidth: '275px', maxWidth: '275px', minHeight: '130px'}} title=''>
        <Box component='div' className='card-body p-3'>
          {/*CARD HEADER*/}
          <Box component='div' className='row' onClick={handleOnClick} sx={{cursor: 'pointer !important'}}>
            <Box component='div' className='col-sm-7' sx={{...overflow}}>
              <Box component='span' className='text-muted fs-8'>
                {headerLeft ?? ''}
              </Box>
              <Box component='p' sx={{...overflow}}>
                <Box component='span' className='fs-7 fw-bold'>
                  {headerLeftValue ?? ''}
                </Box>
              </Box>
            </Box>
            <Box component='div' className='col-sm-5'>
              <Box component='span' className='float-end text-muted fs-8' sx={{...overflow}}>
                {headerRight ?? ''}
              </Box>
              <br></br>
              <Box component='span' className='float-end float-end fs-7 fw-bold' sx={{...overflow}}>
                {headerRightValue ?? ''}
              </Box>
            </Box>
          </Box>

          {/*CARD BODY*/}
          <Box component='div' className='row' onClick={handleOnClick} sx={{cursor: 'pointer !important'}}>
            <Box component='div' className='col-sm-12'>
              <Box component='h5' sx={{...overflow}} className='mt-2 mb-2' title={titleTooltip ?? undefined}>
                {title ?? ''}
              </Box>
            </Box>
          </Box>

          <Box component='div' className='row' onClick={handleOnClick} sx={{cursor: 'pointer !important'}}>
            <Box
              component='div'
              sx={{
                display: '-webkit-box !important',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden !important',
                textOverflow: 'ellipsis !important',
                whiteSpace: 'normal',
              }}
              className='mb-0'
              title={contentTooltip ?? undefined}
            >
              {content ?? ''}
            </Box>
          </Box>

          {/*CARD FOOTER*/}
          <Box component='div' className='row position-relative bottom-0 start-0' sx={{top: '6px !important'}}>
            {footer != null && (
              <Box component='div' className='col-sm-6' onClick={handleOnClick} sx={{cursor: 'pointer !important'}}>
                <Box component='h5' sx={{...overflow}} className='mt-2 mb-2'>
                  {footer ?? ''}
                </Box>
              </Box>
            )}
            {footerBadge != null && (
              <Box component='div' className='col-sm-6' sx={{...overflow}}>
                <span className='badge bg-primary' title={footerBadgePopup ?? undefined}>
                  {footerBadge ?? ''}
                </span>
              </Box>
            )}
            <Box component='div' className='col-sm-6' sx={{alignSelf: 'center !important', direction: 'rtl !important'}}>
              <Box component='span' sx={{cursor: 'pointer !important'}} className='px-1' title='Comentários'>
                <Box
                  component='i'
                  sx={mensagens.length > 0 ? {color: '#27c6db !important'} : undefined}
                  onClick={commentOnClick}
                  className='fa fa-comments'
                  aria-hidden='true'
                >
                  {' '}
                  {mensagens.length}
                </Box>
              </Box>
              <Box component='span' sx={{cursor: 'pointer !important'}} className='px-1' title='Arquivos'>
                <Box
                  component='i'
                  sx={arquivos.length > 0 ? {color: '#34d12c !important'} : undefined}
                  onClick={handleOnClickFiles}
                  className='fa fa-paperclip'
                  aria-hidden='true'
                >
                  {' '}
                  {arquivos.length}
                </Box>
              </Box>
              <Box component='span' sx={{cursor: 'pointer !important'}} className='px-1'>
                <Box
                  component='i'
                  title={Tags != '' ? Tags : undefined}
                  className='fa fa-tag'
                  aria-hidden='true'
                  style={Tags != '' ? {color: 'orange'} : undefined}
                ></Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {modalKanbanCardFiles && <KanbanCardFiles files={arquivos} callBack={handleFilesModalCallback} />}
    </>
  )
}

export default KanbanCard
