import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Item from '../utility/CardItem'

import {GetAccessToken} from '../../../../modules/api/Api'

interface IUploadProps {
  readonly?: boolean
  customPage?: boolean
  idMenuApp?: number
  toUploadFile?: any
  existentAzFile?: any
  noUpload?: boolean
  onDelete: (azFile: any) => void
  onUpload: (newAzFile: any, toUploadFile: any) => void
}

const Upload = ({readonly, customPage, idMenuApp, toUploadFile, existentAzFile, noUpload, onDelete, onUpload}: IUploadProps) => {
  const [azFile, setAzFile] = useState<any>(existentAzFile)
  const [file, setFile] = useState<any>(toUploadFile)
  const [progress, setProgress] = useState<number>(0)

  async function Upload(toUpload: File) {
    if (!noUpload) {
      const newAzFile = await UploadFile(toUpload, setProgress, customPage, idMenuApp)
      setAzFile(newAzFile)
      onUpload(newAzFile, toUpload)
      return
    }

    setFile(undefined)
    onUpload(null, toUpload)
  }

  useEffect(() => {
    if (file != null) {
      Upload(file)
    }
  }, [])

  return (
    <Item>
      <Box sx={{flexGrow: 1}}>
        <Grid container direction='column' spacing={2}>
          {azFile != null && (
            <Grid item xs container direction='row' spacing={2}>
              <Grid item xs='auto'>
                <IconButton aria-label='baixar' onClick={() => OpenInNewTab(azFile.urlDownload)}>
                  <DownloadIcon fontSize='small' />
                </IconButton>
                <IconButton disabled={readonly} aria-label='apagar' onClick={() => onDelete(azFile)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item xs='auto'>
                <Typography sx={{pt: 0.8}} component='div'>
                  {azFile.nomeOriginal}
                </Typography>
              </Grid>
            </Grid>
          )}
          {file != null && (
            <Grid item>
              <LinearProgress variant='determinate' value={progress} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Item>
  )
}

async function UploadFile(file: File, onProgress: (percentage: number) => void, customPage?: boolean, idMenuApp?: number) {
  const url = process.env.REACT_APP_BST_API_URL + '/api/v1/Storage/EnviarArquivoAz'
  const token = await GetAccessToken()

  return new Promise<any>((res, rej) => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', url)
    xhr.setRequestHeader('Authorization', ('Bearer ' + token) as string)

    xhr.onload = () => {
      const resp = JSON.parse(xhr.responseText)
      res(resp)
    }
    xhr.onerror = (evt) => rej(evt)
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100
        onProgress(Math.round(percentage))
      }
    }

    const formData = new FormData()
    formData.append('file', file)
    formData.append('CustomPage', customPage ? 'true' : 'false')
    formData.append('IDMenuApp', idMenuApp != null ? idMenuApp.toString() : '0')

    xhr.send(formData)
  })
}

function OpenInNewTab(url: string) {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export default Upload
