import {useMsal} from '@azure/msal-react'
import {MenuAppDesktopsResult} from '../../../../modules/api/models/Result/MenuAppDesktopsResult'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import {useEffect, useState} from 'react'

import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import DeleteIcon from '@mui/icons-material/Delete'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import DownloadIcon from '@mui/icons-material/Download'

import StyledDialog from '../styled/StyledDialog'
import StyledButton from '../styled/StyledButton'
import BoxSVGMessage from '../utility/BoxSVGMessage'
import ModalExclusao from '../modals/modalExclusao'
import ModalPerformingAction from '../modals/modalPerformingAction'
import {useIntl} from 'react-intl'
import {StyledTabs} from '../styled/StyledTab'
import DesktopPermissionModal from './desktopPermissionModal'
import StyledCard from '../styled/StyledCard'
import StyledCardContent from '../styled/StyledCardContent'
import Typography from '@mui/material/Typography'
import StyledCardActions from '../styled/StyledCardActions'
import {toast} from 'react-toastify'
import {useGlobalAuthentication} from '../../../../modules/authentication/Authentication'

interface IDesktopManagerModal {
  accessCode: string
  desktops: Array<MenuAppDesktopsResult>
  sharedDesktops: Array<MenuAppDesktopsResult>
  tab?: number
  setDesktops: React.Dispatch<React.SetStateAction<MenuAppDesktopsResult[]>>
  setSharedDesktops: React.Dispatch<React.SetStateAction<MenuAppDesktopsResult[]>>
  callBack(data: any): void
}

const DesktopManagerModal = ({accessCode, desktops, sharedDesktops, tab, setDesktops, setSharedDesktops, callBack}: IDesktopManagerModal) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const {loggedUser} = useGlobalAuthentication()

  //* VARIAVEIS DO COMPONENTE
  const [desktopParaExclusao, setDesktopParaExclusao] = useState<MenuAppDesktopsResult | undefined>(undefined)
  const [tabIndex, setTabIndex] = useState<number>(0)

  //* FUNÇÃO DE CALLBACK QUE É CHAMADO APÓS FECHAR O COMPONENTE
  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  //* FUNÇÃO DE INICIALIZAÇÃO DO COMPONENTE
  const handleOpen = () => {
    if (tab != null) {
      setTabIndex(tab)
    }
  }

  //* FUNÇÃO DE FECHAMENTO DO COMPONENTE
  const handleClose = async () => {
    handleOpenPerformingAction('Salvando registros, aguarde...')

    for (const desktop of desktops) {
      if (desktop.idmenuAppDesktopsPai != null || desktop.idpessoaCreated != loggedUser?.id) {
        continue
      }

      await handleSaveDesktop(desktop)
    }

    handleClosePerformingAction()
    handleCallBack()
  }

  //* FUNÇÃO QUE MUDA PROPRIEDADES DE DETERMINADO DESKTOP
  const handleOnChange = (index: number, key: string, value: any) => {
    setDesktops((prev: Array<MenuAppDesktopsResult>) => {
      prev[index][key] = value
      return [...prev]
    })
  }

  //* FUNÇÃO DE ADIÇÃO DE DESKTOP
  const handleAddDesktop = async () => {
    handleOpenPerformingAction('Salvando novo desktop, aguarde...')

    let newDesktop = new MenuAppDesktopsResult()
    let saveRequest = await handleSaveDesktop(newDesktop)
    newDesktop = saveRequest.data.data as MenuAppDesktopsResult

    setDesktops((prev: Array<MenuAppDesktopsResult>) => {
      return [...prev, newDesktop]
    })

    handleClosePerformingAction()
  }

  //* FUNÇÃO DE SALVAMENTO DE DESKTOP
  const handleSaveDesktop = (desktop: MenuAppDesktopsResult) => {
    let obj = {
      item: {
        AccessCode: accessCode,
        MenuAppDesktops: desktop,
      },
    }

    return apiWrapper.put('api/v1/MenuAppDesktops/salvar', obj)
  }

  //* FUNÇÃO DE EXCLUSÃO DE DESKTOP
  const handleDeleteDesktop = (desktop: MenuAppDesktopsResult) => {
    let obj = {
      item: {
        ID: desktop.id,
      },
    }

    return apiWrapper.delete('api/v1/MenuAppDesktops/excluir', {data: obj, headers: {}})
  }

  //* FUNÇÃO QUE GERENCIA AS MUDANÇAS DE SELEÇÃO DAS TABS
  const handleOnTabChange = (index: number) => {
    setTabIndex(index)
  }

  //* FUNÇÃO QUE IMPORTA O DESKTOP COMPARTILHADO
  const handleImportSharedDesktop = async (desktop: MenuAppDesktopsResult) => {
    handleOpenPerformingAction('Importando registros, aguarde...')

    let desktopCopy = JSON.parse(JSON.stringify(desktop)) as MenuAppDesktopsResult
    desktopCopy.id = 0
    desktopCopy.idmenuAppDesktopsPai = desktop.id
    desktopCopy.idpessoaCreated = loggedUser?.id
    desktopCopy.dtCreated = new Date()
    desktopCopy.idpessoaEdited = undefined
    desktopCopy.dtEdited = undefined

    let desktopCopySaveRequest = await handleSaveDesktop(desktopCopy)
    desktopCopy = desktopCopySaveRequest.data.data as MenuAppDesktopsResult

    if (desktopCopy.id == 0) {
      toast.warning('Ops! Este desktop não está mais disponível.')

      setSharedDesktops((prev: Array<MenuAppDesktopsResult>) => {
        return [...prev.filter((x) => x != desktop)]
      })
    } else {
      setDesktops((prev: Array<MenuAppDesktopsResult>) => {
        return [...prev, desktopCopy]
      })
    }

    handleClosePerformingAction()
  }

  //* FUNCÃO QUE CHECA SE O ITEM JA ESTÁ COMPARTILHADO
  const handleCheckIfIsShared = (desktop: MenuAppDesktopsResult) => {
    let alreadyShared = desktops.find((x) => x.idmenuAppDesktopsPai == desktop.id)
    return alreadyShared != null
  }

  //* VARIAVEIS DO MODAL DE EXCLUSÃO
  const [modalExclusao, setModalExclusao] = useState<boolean>(false)

  //* FUNÇÃO DE ABERTURA DO MODAL DE EXCLUSÃO
  const handleOpenExclusao = (desktop: MenuAppDesktopsResult) => {
    setModalExclusao(true)
    setDesktopParaExclusao(desktop)
  }

  //* FUNÇÃO DE FECHAMENTO E CONFIRMAÇÃO DO MODAL DE EXCLUSÃO
  const handleCloseExclusao = async (excluir: boolean) => {
    setModalExclusao(false)

    if (!excluir || desktopParaExclusao == null) {
      return
    }

    handleOpenPerformingAction('Excluindo registro, aguarde...')

    await handleDeleteDesktop(desktopParaExclusao)
    setDesktops((prev: Array<MenuAppDesktopsResult>) => {
      return [...prev.filter((x) => x.id != desktopParaExclusao.id)]
    })

    handleClosePerformingAction()
  }

  //* VARIÁVEIS DO MODAL DE PERMISSÕES
  const [modalDesktopPermission, setModalDesktopPermission] = useState<boolean>(false)
  const [modalDesktopPermissionDesktopID, setModalDesktopPermissionDesktopID] = useState<number>(0)

  //* FUNÇÃO DE ABERTURA DO MODAL DE PERMISSÕES
  const handleOpenModalDesktopPermission = (desktopId: number) => {
    setModalDesktopPermission(true)
    setModalDesktopPermissionDesktopID(desktopId)
  }

  //* FUNÇÃO DE CALLBACK DO MODAL DE PERMISSÕES
  const modalDesktopPermissionCallBack = (data?: any) => {
    setModalDesktopPermission(false)
    setModalDesktopPermissionDesktopID(0)
  }

  //* VARIAVEIS DO MODAL DE AGUARDE
  const [modalPerformingAction, setModalPerformingAction] = useState<boolean>(false)
  const [modalPerformingActionMessage, setModalPerformingActionMessage] = useState<string>('')

  //* FUNÇÃO DE ABERTURA DO MODAL DE AGUARDE
  const handleOpenPerformingAction = (message: string) => {
    setModalPerformingAction(true)
    setModalPerformingActionMessage(message)
  }

  //* FUNÇÃO DE FECHAMENTO DO MODAL DE AGUARDE
  const handleClosePerformingAction = () => {
    setModalPerformingAction(false)
    setModalPerformingActionMessage('')
  }

  //* USEEFFECTS
  useEffect(() => {
    handleOpen()
  }, [])

  return (
    <>
      <StyledDialog open={true} fullWidth={true} maxWidth='md'>
        <DialogTitle>Gerenciar Desktops</DialogTitle>
        <StyledTabs value={tabIndex} onChange={(event, value) => handleOnTabChange(value)} sx={{mb: 1}}>
          <Tab label='Meus desktops' value={0} />
          <Tab label='Compartilhados' value={1} />
        </StyledTabs>
        <DialogContent dividers={true} sx={{minHeight: '300px'}}>
          {tabIndex == 0 && desktops.length == 0 && <BoxSVGMessage message='Nenhum item encontrado' />}
          {tabIndex == 1 && sharedDesktops.length == 0 && <BoxSVGMessage message='Nenhum item encontrado' />}
          {tabIndex == 0 &&
            desktops.map((item: MenuAppDesktopsResult, index: number) => {
              return (
                <Box key={`desktop-${index}`} component='div' className='d-flex' sx={{py: 1}}>
                  <Box component='div' sx={{px: 0.5, width: '35%'}}>
                    <TextField
                      fullWidth
                      disabled={item.idmenuAppDesktopsPai != null || item.idpessoaCreated != loggedUser?.id}
                      size='small'
                      label='Nome'
                      value={item.nome}
                      onChange={(event) => handleOnChange(index, 'nome', event.target.value)}
                    />
                  </Box>
                  <Box component='div' sx={{px: 0.5, width: '65%'}}>
                    <TextField
                      fullWidth
                      disabled={item.idmenuAppDesktopsPai != null || item.idpessoaCreated != loggedUser?.id}
                      size='small'
                      label='Descrição'
                      value={item.descricao}
                      onChange={(event) => handleOnChange(index, 'descricao', event.target.value)}
                    />
                  </Box>
                  <Box component='div'>
                    <IconButton
                      disabled={item.idmenuAppDesktopsPai != null || item.idpessoaCreated != loggedUser?.id}
                      aria-label='config'
                      title='Permissões'
                      color='inherit'
                      onClick={() => handleOpenModalDesktopPermission(item.id)}
                    >
                      <LockPersonIcon />
                    </IconButton>
                  </Box>
                  <Box component='div'>
                    <IconButton
                      disabled={item.idpessoaCreated != loggedUser?.id}
                      aria-label='delete'
                      title='Apagar'
                      color='error'
                      onClick={() => handleOpenExclusao(item)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              )
            })}
          {tabIndex == 1 && (
            <>
              <Box component='div' className='container'>
                <Box component='div' className='row'>
                  {sharedDesktops.map((item: MenuAppDesktopsResult, index: number) => {
                    return (
                      <Box
                        component='div'
                        className='col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4'
                        sx={{marginBottom: '20px'}}
                        title={handleCheckIfIsShared(item) ? 'Item já importado!' : 'Importar'}
                      >
                        <StyledCard>
                          <StyledCardContent>
                            <Typography variant='body1' color='textSecondary' component='p' sx={{mt: 0.6}} className='word-wrap text-dark'>
                              <strong>{item.nome}</strong>
                            </Typography>
                            <Typography variant='body1' color='textSecondary' component='p' className='h-50px word-wrap text-dark py-2'>
                              {item.descricao}
                            </Typography>
                          </StyledCardContent>
                          <StyledCardActions sx={{justifyContent: 'flex-end'}}>
                            <StyledButton
                              disabled={handleCheckIfIsShared(item)}
                              startIcon={<DownloadIcon fontSize='small' />}
                              size='small'
                              color='primary'
                              onClick={() => handleImportSharedDesktop(item)}
                            >
                              Importar
                            </StyledButton>
                          </StyledCardActions>
                        </StyledCard>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton variant='contained' sx={{px: 1}} onClick={() => handleAddDesktop()}>
            Adicionar Desktop
          </StyledButton>
          <StyledButton variant='contained' color='inherit' onClick={() => handleClose()}>
            Fechar
          </StyledButton>
        </DialogActions>
      </StyledDialog>

      {/* MODAL EXCLUSÃO */}
      <ModalExclusao open={modalExclusao} title='Excluir' message='Esta ação não pode ser desfeita, confirma?' callBack={handleCloseExclusao} />

      {/* MODAL DE PERMISSÕES */}
      {modalDesktopPermission && (
        <DesktopPermissionModal idMenuAppDesktop={modalDesktopPermissionDesktopID} callBack={modalDesktopPermissionCallBack} />
      )}

      {/* MODAL AGUARDE */}
      {modalPerformingAction && <ModalPerformingAction message={modalPerformingActionMessage} />}
    </>
  )
}

export default DesktopManagerModal
