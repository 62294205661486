// Cores e classes padrões usadas pelo sistema
export const badgeColors = [
  {id: 'badge badge-primary', label: 'Azul'},
  {id: 'badge badge-secondary', label: 'Cinza'},
  {id: 'badge badge-success', label: 'Verde'},
  {id: 'badge badge-danger', label: 'Vermelha'},
  {id: 'badge badge-warning', label: 'Amarelo'},
  {id: 'badge badge-info', label: 'Roxo'},
  {id: 'badge badge-light', label: 'Branco'},
  {id: 'badge badge-dark', label: 'Preto'},
]
