import {useState, useEffect, useContext, useRef} from 'react'
import {useParams, useNavigate} from 'react-router-dom'

import {toast} from 'react-toastify'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SettingsIcon from '@mui/icons-material/Settings'

import CardItem from '../../utility/CardItem'
import DynamicModal from '../../modals/modalDynamicV2'
import LinearProgress from '@mui/material/LinearProgress'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import {Responsive, WidthProvider} from 'react-grid-layout'
import {KTSVG} from '../../../../../../_metronic/helpers/components/KTSVG'
import FileGrid from '../../grid/FileGrid'
import {Tab, Tabs} from '@mui/material'
import {GlobalContext} from '../../../../../App'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import StyledButton from '../../styled/StyledButton'
import StyledFormControl from '../../styled/StyledFormControl'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../../modules/api/ApiWrapper'
import {MenuAppDesktopsResult} from '../../../../../modules/api/models/Result/MenuAppDesktopsResult'
import DesktopManagerModal from '../../desktops/desktopManagerModal'
import {MenuAppRoleSimpleResult} from '../../../../../modules/api/models/Result/MenuAppRoleSimpleResult'
import ChartModal from '../../chart/chartModal'
import {NoRowsOverlay} from '../../utility/NoRowsOverlay'
import ChartWrapper from '../../chart/chartWrapper'
import {useGlobalAuthentication} from '../../../../../modules/authentication/Authentication'

const GridLayout = WidthProvider(Responsive)

const RGBTable: Array<string> = [
  'rgba(0, 66, 157,',
  'rgba(50, 93, 169,',
  'rgba(78, 120, 181,',
  'rgba(102, 148, 193,',
  'rgba(128, 177, 204,',
  'rgba(157, 206, 214,',
  'rgba(192, 234, 222,',
  'rgba(255, 255, 224,',
  'rgba(255, 220, 198,',
  'rgba(254, 184, 171,',
  'rgba(240, 152, 149,',
  'rgba(216, 125, 129,',
  'rgba(181, 106, 114,',
  'rgba(129, 95, 103,',
  'rgba(0, 91, 100,',
]

interface IDynamicChartsV2 {
  isDisplayed?: boolean
  isPrimary?: boolean
  accessCode?: string
}

const DynamicChartsV2 = ({isDisplayed, isPrimary, accessCode}: IDynamicChartsV2) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)
  const navigate = useNavigate()

  const {loggedUser} = useGlobalAuthentication()

  //* CONTEXTO GLOBAL PARA APLICAR TITULO E BOTÕES AO TOOLBAR
  const globalContext: any = useContext(GlobalContext)

  //* PARAMETROS DE ROTA
  const RouteParams = useParams()

  //* FUNÇÃO QUE DECIDE SE USAREMOS OS PARAMETROS DE ROTA OU OS PARAMETROS DO CONSTRUTOR DO COMPONENTE
  const BuildParams = () => {
    if (accessCode != null) {
      return {AccessCode: accessCode}
    } else {
      return {AccessCode: RouteParams.accesscode}
    }
  }

  //* PARAMETROS MONTADOS
  const {AccessCode} = BuildParams()

  //* VARIAVEIS DO COMPONENTE
  const [permissions, setPermissions] = useState<MenuAppRoleSimpleResult | undefined>(undefined)
  const [desktops, setDesktops] = useState<Array<MenuAppDesktopsResult>>([])
  const [sharedDesktops, setSharedDesktops] = useState<Array<MenuAppDesktopsResult>>([])
  const [selectedDesktop, setSelectedDesktop] = useState<MenuAppDesktopsResult | undefined>(undefined)
  const [charts, setCharts] = useState<Array<any>>([])
  const [layout, setLayout] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [showNoRowsOverlay, setShowNoRowsOverlay] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [enableDailyMessage, setEnableDailyMessage] = useState<boolean>(false)

  const handleCheckDailyMessage = () => {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    let today = `${year}-${month}-${day}-${loggedUser?.id}`

    let lastMessage = localStorage.getItem(`chart-daily-${loggedUser?.id}`)
    if (lastMessage == null || lastMessage != today) {
      localStorage.setItem(`chart-daily-${loggedUser?.id}`, today)
      setEnableDailyMessage(true)
    }
  }

  //* FUNÇÃO DE INICIALIZAÇÃO DO COMPONENTE
  const handleInit = async () => {
    handleCheckDailyMessage()

    setLoading(true)

    /* PASSO A PASSO:
    .1 Carregar os desktops e os desktops compartilhados
    .2 Setar o primeiro desktop como o ativo pra renderização
    .3 Baseado no desktop que está ativo trazer os charts daquele desktop e renderizar
    .4 Aplicar o layout salvo direto no desktop após carregar os charts
    */

    //* Carregamos as permissões do app
    let permissionRequest = await apiWrapper.get(`/api/v1/MenuAppRole/verificarPermissoesPorCodigoDeAcesso?item.AccessCode=${AccessCode}`)
    let permissions = permissionRequest.data.data as MenuAppRoleSimpleResult
    setPermissions(permissions)

    //* Carregamos e aplicamos os desktops para gerenciamento e renderização
    let desktopRequest = await apiWrapper.get(`api/v1/MenuAppDesktops/listarPorMenuApp?item.AccessCode=${AccessCode}`)
    let desktops = desktopRequest.data.data as Array<MenuAppDesktopsResult>
    setDesktops(desktops)
    setSelectedDesktop(desktops.at(0))

    //* Carregamos os desktops compartilhados para importação
    let sharedDesktopRequest = await apiWrapper.get(`api/v1/MenuAppDesktops/listarCompartilhadosPorMenuApp?item.AccessCode=${AccessCode}`)
    let sharedDesktops = sharedDesktopRequest.data.data as Array<MenuAppDesktopsResult>
    setSharedDesktops(sharedDesktops)

    if (desktops.length == 0) {
      if (sharedDesktops.length == 0) {
        setLoading(false)
      }

      setShowNoRowsOverlay(true)
    }
  }

  //* FUNÇÃO QUE CARREGA OS CHARTS BASEADO NO DESKTOP SELECIONADO
  const handleLoadCharts = async () => {
    setShowNoRowsOverlay(false)
    setLoading(true)
    setCharts([])

    if (selectedDesktop == null) {
      setLayout(null)
      setLoading(false)
      return
    } else {
      //* Validamos se o desktop ainda existe, pois caso ele seja compartilhado e o dono o apagou então ocorreria erros ao tentar recuperar os charts relacionados
      let exists = await handleValidateDesktop(selectedDesktop)
      if (!exists) {
        toast.warning('Ops! Este desktop não está mais disponível.')
        setLoading(false)
        return
      }
    }

    let elements: Array<any> = []
    let chartsRequest = await apiWrapper.get(
      `api/v1/Element/carregarElements?item.AccessCode=${AccessCode}&item.IDMenuAppDesktop=${selectedDesktop.id}`
    )

    let charts = chartsRequest.data.data as Array<any>

    for (const item of charts) {
      let obj: any = {
        id: item.id,
        guid: item.guid,
        nome: item.nome,
        tipo: item.tipo,
        layout: item.layout,
        showTitle: item.showTitle,
      }

      if (item.tipo == 'chart') {
        obj.chart = {
          tipo: item.chart.tipo,
          accessCode: item.chart.accessCode,
          dimensao: item.chart.dimensao,
          labels: item.chart.labels,
          datasets: item.chart.datasets.map((chart, index) => {
            return {
              label: chart.label,
              campo: chart.campo,
              data: chart.data,
              backgroundColor: chart.cor != '' ? chart.cor : RGBTable[index] + ' 1)',
              operacao: chart.operacao,
              formatacao: chart.formatacao != '' ? JSON.parse(chart.formatacao) : {formatacao: '', exibicao: []},
            }
          }),
        }

        if (item.chart.tipo == 'Bar' || item.chart.tipo == 'Number' || item.chart.tipo == 'Table') {
          for (let i = 0; i < obj.chart.datasets.length; i++) {
            //obj.chart.datasets[i].backgroundColor = RGBTable[i] + ' 1)'
          }
        } else if (item.chart.tipo == 'Pie' || item.chart.tipo == 'PolarArea' || item.chart.tipo == 'Radar') {
          let colors: any = item.chart.labels.map((label: any, index: number) => {
            return {bg: RGBTable[index] + ' 0.5)', border: RGBTable[index] + ' 1)'}
          })

          for (let i = 0; i < obj.chart.datasets.length; i++) {
            //obj.chart.datasets[i].backgroundColor = colors.map((color: any) => color.bg)
            obj.chart.datasets[i].borderColor = colors.map((color: any) => color.border)
            obj.chart.datasets[i].borderWidth = 1
          }
        }
      } else if (item.tipo == 'link') {
        obj.link = item.link
      } else if (item.tipo == 'file') {
        obj.file = item.file
      } else if (item.tipo == 'image') {
        obj.image = item.image
      }

      elements.push(obj)
    }

    if (elements.length == 0) {
      setShowNoRowsOverlay(true)
    }

    setCharts(elements)
    setLoading(false)
  }

  //* FUNÇÃO QUE VALIDA SE O DESKTOP AINDA EXISTE
  const handleValidateDesktop = async (desktop: MenuAppDesktopsResult) => {
    let loadRequest = await apiWrapper.get(`api/v1/MenuAppDesktops/carregar?item.ID=${desktop.id}`)
    let exists = loadRequest.data.data != null

    if (!exists) {
      setDesktops((prev: Array<MenuAppDesktopsResult>) => {
        return [...prev.filter((x) => x != desktop)]
      })

      setSelectedDesktop(desktops.at(0))
    }

    return exists
  }

  //* FUNÇÃO QUE MUDA O DESKTOP SELECIONADO
  const handleOnChangeSelectedDesktop = (id: number) => {
    if (id == -1) {
      return
    }

    let desktop = desktops.find((x) => x.id == id)
    if (desktop != null) {
      setSelectedDesktop(desktop)
      setEditMode(false)
    }
  }

  //* FUNÇÃO DE MUDANÇA DE MODO DE EDIÇÃO
  const handleOnChangeEditMode = () => {
    setEditMode((prev: boolean) => {
      return !prev
    })
  }

  //* FUNÇÃO DE SALVAMENTO DE LAYOUT
  const handleSaveLayout = (layout: any) => {
    if (!isDisplayed && AccessCode != 'SYSDASHBOARD') {
      return
    }

    if (selectedDesktop == null || selectedDesktop.idmenuAppDesktopsPai != null) {
      return
    }

    let obj: any = {
      item: {AccessCode: AccessCode, IDMenuAppDesktop: selectedDesktop.id, Layout: layout},
    }

    apiWrapper.put('api/v1/Element/salvarLayout', obj) //.then((response) => handleLoadCharts())
  }

  //* FUNÇÃO QUE DEFINE O QUE ACONTECE QUANDO O LAYOUT MUDA
  const handleOnChangeLayout = (newLayout: any, layouts: any) => {
    if (newLayout.length == 0 || !editMode) {
      return
    }

    handleSaveLayout(newLayout)
  }

  //* FUNÇÃO QUE DEFINE O QUE ACONTECE QUANDO O WIDTH MUDA
  const handleOnWidthChange = (containerWidth: number, margin: [number, number], cols: number, containerPadding: [number, number]) => {
    handleLoadCharts()
  }

  //* FUNÇÃO DE GERENCIAMENTO DE CONTROLES
  const handleBuildControls = () => {
    //* Caso o modo de edição for verdadeiro então setamos para falso, isso por si só vai dar trigger no useEffect do editMode reconstruindo os botões
    //* Caso contrario chamamos a reconstrução de botões manualmente
    if (editMode) {
      setEditMode(false)
    } else {
      BuildControls()
    }
  }

  //* FUNÇÃO DE CLICK NO CHART
  const handleOnChartClick = (dataset: Array<any>, options: any, data: any) => {
    if (dataset.length == 0) {
      return
    }

    let accessCode = data.accessCode

    let index = dataset[0].index
    let campo = data.dimensao
    let label = data.labels[index]

    let filter = {
      columnField: campo,
      operatorValue: 'equals',
      value: [label],
    }

    let base64 = btoa(JSON.stringify(filter))
    window.open(`/pages/_dynamicTabs/${accessCode}?f=${base64}`, '_blank')

    //window.open(`/pages/_dynamicTabs/${accessCode}`, '_blank')
  }

  /* MODAL CHARTS */

  //* VARIAVEIS DO MODAL CHARTS
  const [modalCharts, setModalCharts] = useState<boolean>(false)
  const [modalChartsItemID, setModalChartsItemID] = useState<number>(0)

  //* FUNÇÃO DE ABERTURA DO MODAL CHARTS
  const handleOpenModalCharts = (id: number) => {
    setModalCharts(true)
    setModalChartsItemID(id)
  }

  //* FUNÇÃO DE CALLBACK DO MODAL CHARTS
  const modalChartsCallBack = (data?: any) => {
    if (data != null) {
      if (data.refresh != null) {
        handleLoadCharts()
      }
    }

    setModalCharts(false)
    setModalChartsItemID(0)
  }

  //* FUNÇÃO DE ESCOLHA DE ABERTURA DE MODAL CASO NÃO TENHA REGISTROS
  const handleChooseModalToOpen = () => {
    if (desktops.length == 0) {
      handleOpenModalDesktopManager()
    } else {
      handleOpenModalCharts(0)
    }
  }

  /* MODAL DYNAMIC */

  //* VARIAVEIS DO MODAL DYNAMIC
  const [modalDynamic, setModalDynamic] = useState<boolean>(false)
  const [modalDynamicItemID, setModalDynamicItemID] = useState<number>(0)

  //* FUNÇÃO DE ABERTURA DO MODAL DYNAMIC
  const handleOpenModalDynamic = () => {
    setModalDynamic(true)
    setModalDynamicItemID(0)
  }

  //* FUNÇÃO DE CALLBACK DO MODAL DYNAMIC
  const modalDynamicCallBack = (response: any = null) => {
    if (response != null) {
      if (response.id != null) {
        handleInit()
      }

      if (response.keepOpen != null && response.keepOpen && response.id != null) {
        setModalDynamicItemID(response.id)
        return
      }
    }

    setModalDynamic(false)
    setModalDynamicItemID(0)
  }

  /* MODAL GERENCIAMENTO DE DESKTOP */

  //* VARIAVEIS DO MODAL DE GERENCIAMENTO DE DESKTOP
  const [modalDesktopManager, setModalDesktopManager] = useState<boolean>(false)

  //* FUNÇÃO DE ABERTURA DO MODAL DE GERENCIAMENTO DE DESKTOP
  const handleOpenModalDesktopManager = () => {
    setModalDesktopManager(true)
  }

  //* FUNÇÃO DE CALLBACK DO MODAL DE GERENCIAMENTO DE DESKTOP
  const modalDesktopManagerCallBack = (data?: any) => {
    setModalDesktopManager(false)
    setEnableDailyMessage(false)
  }

  function formatDataset(format: string, value: any) {
    switch (format) {
      case 'Moeda (R$)':
        return parseFloat(value).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      case 'Moeda ($)':
        return parseFloat(value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      default:
        return value
    }
  }

  function GetChartHeader(item: any) {
    if (item.tipo == 'link') {
      return <></>
    } else if (item.tipo == 'image') {
      return (
        <>
          {editMode && (
            <Box component='div' sx={{display: 'inline-grid', alignSelf: 'self-end', position: 'absolute'}}>
              <IconButton aria-label='actions' size='small' onClick={() => handleOpenModalCharts(item.id)}>
                <MoreVertIcon />
              </IconButton>
            </Box>
          )}
        </>
      )
    }

    return (
      <>
        {(item.showTitle || editMode) && (
          <Box component='div' className='d-flex flex-stack' sx={{p: 1, height: '15%'}}>
            <Box component='div' className='page-title d-flex'>
              {item.showTitle && (
                <>
                  <Box component='h1' className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
                    {item.nome}
                  </Box>
                </>
              )}
            </Box>
            <Box component='div' className='d-flex align-items-center py-1'>
              {editMode && (
                <IconButton
                  sx={item.tipo == 'image' ? {position: 'absolute', left: '92%', top: '55%'} : undefined}
                  aria-label='actions'
                  size='small'
                  onClick={() => handleOpenModalCharts(item.id)}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        )}
      </>
    )
  }

  function GetChartComponent(item: any) {
    if (item.tipo == 'chart') {
      let formatacao = ''
      let tooltip: any = {
        callbacks: {
          afterBody: function (context) {
            return 'Clique aqui para mais detalhes'
          },
        },
      }

      if (item.chart.formatacao != null) {
        formatacao = item.chart.formatacao.formatacao

        switch (item.chart.formatacao.formatacao) {
          case 'Moeda (R$)':
            tooltip = {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || ''
                  if (label) {
                    label += ': '
                  }

                  if (context.raw !== null) {
                    let formatted_brl = formatDataset('Moeda (R$)', context.raw)
                    label += `${formatted_brl}`
                  }

                  return label
                },
                afterBody: function (context) {
                  return 'Clique aqui para mais detalhes'
                },
              },
            }

            break
          case 'Moeda ($)':
            tooltip = {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || ''
                  if (label) {
                    label += ': '
                  }

                  if (context.raw !== null) {
                    let formatted_brl = formatDataset('Moeda ($)', context.raw)
                    label += `${formatted_brl}`
                  }

                  return label
                },
                afterBody: function (context) {
                  return 'Clique para mais detalhes'
                },
              },
            }

            break
        }
      }

      let options: any = {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right',
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
            },
          },
          title: {
            display: false,
          },
          tooltip: tooltip,
        },
        onHover: (event, chartElement) => {
          if (chartElement.length) {
            ;(event.native.target as HTMLElement).style.cursor = 'pointer'
          } else {
            ;(event.native.target as HTMLElement).style.cursor = 'default'
          }
        },
      }

      switch (item.chart.tipo) {
        case 'Bar':
        case 'Pie':
        case 'Line':
        case 'Radar':
        case 'PolarArea':
          return <ChartWrapper type={item.chart.tipo} options={options} data={item.chart} onClick={handleOnChartClick} />
        case 'Number':
          return (
            <Box
              component='div'
              onClick={() => {
                window.open(`/pages/_dynamicTabs/${item.chart.accessCode}`)
              }}
            >
              <Typography
                variant='h2'
                sx={{color: item.chart.datasets[0].backgroundColor, pt: 1, height: '100%', cursor: 'pointer'}}
                title='Clique para mais detalhes'
              >
                {item.chart.datasets[0].data[0]}
              </Typography>
            </Box>
          )
        case 'Table':
          return (
            <TableContainer>
              <Table size='small' aria-label='chart table'>
                <TableHead>
                  <TableRow>
                    <TableCell>{item.chart.datasets[0].label}</TableCell>
                    <TableCell>{GetOperationName(item.chart.datasets[0].operacao)}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.chart.labels.map((label: any, index: number) => {
                    return (
                      <TableRow key={`label-chart-${item.guid}-${index}`}>
                        <TableCell>{label}</TableCell>
                        <TableCell>{formatDataset(formatacao, item.chart.datasets[0].data[index])}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )
      }
    } else if (item.tipo == 'link') {
      return (
        <Box component='div' className='border d-flex align-items-center' sx={{height: '100%'}}>
          <Box component='div' className='d-flex flex-stack' sx={{p: 1, width: '100%'}}>
            <Box component='div' className='page-title d-flex'>
              <Box component='div' className='symbol symbol-40px me-4'>
                <Box component='span' className='symbol-label bg-light'>
                  <KTSVG path='/media/icons/duotune/general/gen017.svg' className='svg-icon-2 svg-icon-primary' />
                </Box>
              </Box>
              <Box component='div' className='d-flex flex-column'>
                <Box component='div' className='d-flex flex-column'>
                  <Box
                    component='span'
                    className='text-start fs-4 text-gray-800 fw-semibold'
                    sx={{cursor: 'pointer'}}
                    onClick={() => {
                      if (editMode) {
                        return
                      }

                      if (item.link.tipo == 'menuapp') {
                        navigate(item.link.valor, {replace: true})
                      } else if (item.link.tipo == 'externo') {
                        let link = item.link.valor.replace('http://', '').replace('https://', '')
                        window.open('https://' + link, '_blank')
                      }
                    }}
                  >
                    {item.nome}
                  </Box>
                  <Box component='span' className='text-start fs-7 text-muted fw-bold'>
                    {item.link.tipo == 'menuapp' ? 'App Interno' : 'Link Externo'}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box component='div' className='d-flex align-items-center py-1'>
              {editMode && (
                <IconButton aria-label='actions' size='small' onClick={() => handleOpenModalCharts(item.id)}>
                  <MoreVertIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
      )
    } else if (item.tipo == 'file') {
      return <FileGrid idMenuApp={item.file.menuapp} filters={item.file.filtro} />
    } else if (item.tipo == 'image') {
      return (
        <Box component='div' sx={{width: '100%', height: '100%'}}>
          <img title={item.nome} src={item.image.url} style={{width: '100%', height: '100%'}} />
        </Box>
      )
    } else {
      return <></>
    }
  }

  /* FUNÇÕES HELPERS */

  //* FUNÇÃO QUE CONSTROI OS CONTROLES DO TOOLBAR
  function BuildControls(isEditMode?: boolean) {
    if (!isDisplayed) {
      return
    }

    let isDashboard = AccessCode == 'SYSDASHBOARD'
    let controls: Array<any> = []

    if (selectedDesktop != null && selectedDesktop?.idmenuAppDesktopsPai == null && selectedDesktop.idpessoaCreated == loggedUser?.id) {
      controls.push(
        <StyledButton
          disableElevation
          variant='contained'
          startIcon={isEditMode ? <CloseIcon /> : <EditIcon />}
          color='inherit'
          onClick={() => handleOnChangeEditMode()}
        >
          {isEditMode ? 'Encerrar Edição' : 'Editar Layout'}
        </StyledButton>
      )
    }

    if (selectedDesktop != null && selectedDesktop.idmenuAppDesktopsPai == null) {
      if (isDashboard) {
        controls.push(
          <Button disableElevation variant='contained' startIcon={<AddIcon />} color='info' onClick={() => handleOpenModalCharts(0)}>
            Item
          </Button>
        )
      } else {
        controls.push(
          <StyledButton disableElevation variant='contained' startIcon={<AddIcon />} color='inherit' onClick={() => handleOpenModalCharts(0)}>
            Gráfico
          </StyledButton>
        )
      }
    }

    if (!isDashboard && permissions != null && permissions.biInsert) {
      controls.push(
        <Button disableElevation variant='contained' startIcon={<AddIcon />} color='info' onClick={() => handleOpenModalDynamic()}>
          Adicionar
        </Button>
      )
    }

    globalContext.ReactState.Toolbar2.setControls(controls)
  }

  function GetOperationName(operacao: string) {
    switch (operacao) {
      case 'count':
        return 'Contagem'
      case 'sum':
        return 'Soma'
      case 'average':
        return 'Média'
      default:
        return 'Desconhecido'
    }
  }

  //* USEEFFECTS
  useEffect(() => {
    handleInit()
  }, [])

  useEffect(() => {
    //* Reconstruímos os controles devido aos mesmos estarem em outro componente não conectado a esse os botões não recebem os re-renders das mudanças de estado
    BuildControls(editMode)
  }, [editMode])

  useEffect(() => {
    if (desktops.length == 0 && selectedDesktop != null) {
      setSelectedDesktop(undefined)
    } else if (desktops.length == 1 && selectedDesktop == null) {
      setSelectedDesktop(desktops.at(0))
    }

    handleBuildControls()
  }, [desktops])

  useEffect(() => {
    handleLoadCharts()
    handleBuildControls()
  }, [selectedDesktop])

  useEffect(() => {
    toast.dismiss()

    if (!isDisplayed) {
      return
    }

    handleInit()
    handleBuildControls()
  }, [isDisplayed, AccessCode])

  return (
    <>
      <StyledFormControl sx={{height: '100%', width: '100%'}} component='fieldset' variant='standard'>
        {showNoRowsOverlay && (
          <NoRowsOverlay
            message={
              enableDailyMessage && sharedDesktops.length > 0
                ? 'Existem gráficos compartilhados com você! Clique no botão abaixo para vizualizá-los.'
                : undefined
            }
            openModalMessage={enableDailyMessage && sharedDesktops.length > 0 ? 'Vizualizar gráficos compartilhados' : undefined}
            openModal={permissions?.biInsert ? handleChooseModalToOpen : undefined}
          />
        )}
        {loading && (
          <Box component='div' sx={{alignItems: 'center', justifyContent: 'center'}}>
            <LinearProgress />
          </Box>
        )}
        <GridLayout
          className='layout'
          breakpoints={{lg: 1920, md: 1594, sm: 960, xs: 640, xxs: 0}}
          cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
          rowHeight={50}
          onLayoutChange={handleOnChangeLayout}
          onWidthChange={handleOnWidthChange}
          isDraggable={editMode}
          isResizable={editMode}
        >
          {charts.length > 0 &&
            charts.map((item: any, index: number) => {
              let dimension: any = {}

              if (item.tipo == 'chart') {
                switch (item.chart.tipo) {
                  case 'Bar':
                    dimension = {x: 0, y: 999, w: 3, h: 6, minW: 3, minH: 5}
                    break
                  case 'Pie':
                    dimension = {x: 0, y: 999, w: 2, h: 7, minW: 2, minH: 5}
                    break
                  case 'Line':
                    dimension = {x: 0, y: 999, w: 3, h: 6, minW: 3, minH: 5}
                    break
                  case 'Radar':
                    dimension = {x: 0, y: 999, w: 2, h: 7, minW: 1, minH: 5}
                    break
                  case 'PolarArea':
                    dimension = {x: 0, y: 999, w: 2, h: 7, minW: 1, minH: 5}
                    break
                  case 'Number':
                    dimension = {x: 0, y: 999, w: 1, h: 2, minW: 1, minH: 2}
                    break
                  case 'Table':
                    dimension = {x: 0, y: 999, w: 3, h: 4, minW: 2, minH: 3}
                    break
                }
              } else if (item.tipo == 'link') {
                dimension = {x: 0, y: 999, w: 2, h: 2, minW: 2, minH: 2, maxH: 2}
              } else if (item.tipo == 'file') {
                dimension = {x: 0, y: 999, w: 5, h: 7, minW: 3, minH: 5, maxW: 5, maxH: 10}
              } else if (item.tipo == 'image') {
                dimension = {x: 0, y: 999, w: 5, h: 7, minW: 2, minH: 2, maxW: 12, maxH: 8}
              }

              if (item.layout != null) {
                /* 
                cleanup do objeto de layout pois se o as propriedades estiverem vazias 
                o backend manda como 'null' porem o componente espera que se for o caso
                que venham 'undefined' e não 'null', isso quebra o resize do componente

                erro classico de programador de javascript 🥱
              */
                item.layout.isBounded = dimension.isBounded ?? undefined
                item.layout.isDraggable = dimension.isDraggable ?? undefined
                item.layout.isResizable = dimension.isResizable ?? undefined
                item.layout.resizeHandles = dimension.resizeHandles ?? undefined
                item.layout.minW = dimension.minW ?? undefined
                item.layout.minH = dimension.minH ?? undefined
                item.layout.maxH = dimension.maxH ?? undefined
                item.layout.maxW = dimension.maxW ?? undefined
                dimension = item.layout
              }

              return (
                <Box key={item.guid} id={item.guid} component='div' data-grid={dimension}>
                  <CardItem elevation={0} sx={{width: '100%', height: '100%'}}>
                    <Box component='div' className='d-flex flex-column' sx={{width: '100%', height: '100%', overflow: 'auto'}}>
                      {GetChartHeader(item)}
                      {!loading && (
                        <>
                          <Box component='div' sx={{height: '100%'}}>
                            {GetChartComponent(item)}
                          </Box>
                        </>
                      )}
                      {loading && <LinearProgress />}
                    </Box>
                  </CardItem>
                </Box>
              )
            })}
        </GridLayout>
        <Box component='footer' className='footer mt-auto' sx={{width: '100%'}}>
          <Tabs value={selectedDesktop?.id ?? -1} onChange={(event, value) => handleOnChangeSelectedDesktop(value)} centered>
            {desktops.length > 0 &&
              desktops.map((item: any, index: number) => {
                return <Tab color='primary' key={`desktop-option-${index}`} value={item.id} label={item.nome} />
              })}
            <Tab
              disabled={editMode}
              onClick={(event) => handleOpenModalDesktopManager()}
              sx={{minWidth: '50px'}}
              icon={<SettingsIcon color='primary' fontSize='large' />}
              value={-1}
              title='Gerenciar Desktops'
            />
          </Tabs>
        </Box>
      </StyledFormControl>

      {/* MODAL CHARTS */}
      {modalCharts && (
        <ChartModal
          accessCode={AccessCode as string}
          idMenuAppDesktop={selectedDesktop?.id ?? 0}
          idMenuAppDesktopChart={modalChartsItemID}
          callBack={modalChartsCallBack}
        />
      )}

      {/* MODAL DYNAMIC */}
      {modalDynamic && <DynamicModal accessCode={AccessCode} id={modalDynamicItemID} callBack={modalDynamicCallBack} />}

      {/* MODAL GERENCIAMENTO DE DESKTOP */}
      {modalDesktopManager && (
        <DesktopManagerModal
          tab={enableDailyMessage ? 1 : undefined}
          accessCode={AccessCode as string}
          desktops={desktops}
          sharedDesktops={sharedDesktops}
          setDesktops={setDesktops}
          setSharedDesktops={setSharedDesktops}
          callBack={modalDesktopManagerCallBack}
        />
      )}
    </>
  )
}

export default DynamicChartsV2
