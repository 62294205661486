import React, {useEffect, useState} from 'react'
import moment from 'moment'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import DeleteIcon from '@mui/icons-material/Delete'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider, DesktopDatePicker, DateTimePicker} from '@mui/x-date-pickers'
import ptBRLocale from 'date-fns/locale/pt-BR'

import {CGlobalFilterStructure} from '../../../../models/classes/CMenuAppHistoryStructure'
import BoxSVGMessage from '../utility/BoxSVGMessage'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'

interface IFieldRuleFilterParams {
  accessCode: string
  accessCodeExtra: string
  filters: Array<CGlobalFilterStructure>
  setFilters: React.Dispatch<React.SetStateAction<CGlobalFilterStructure[]>>
  callBack: (response: any) => void
}

const FieldRuleFilter = ({accessCode, accessCodeExtra, filters, setFilters, callBack}: IFieldRuleFilterParams) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [Opened, setOpened] = useState<boolean>(false)
  const [Fields, setFields] = useState<Array<any>>([])
  const [FieldsExtra, setFieldsExtra] = useState<Array<any>>([])

  const [Operators, setOperators] = useState<Array<any>>([])
  const [SingleSelectOptions, setSingleSelectOptions] = useState<Array<any>>([])

  const Ops: any = {
    boolean: ['Igual', 'Diferente'],
    string: ['Igual', 'Diferente', 'Contém', 'Não contém'],
    singleSelect: ['Igual', 'Diferente'],
    number: ['Igual', 'Diferente', 'Maior que', 'Menor que', 'Maior ou igual a', 'Menor ou igual a'],
    decimal: ['Igual', 'Diferente', 'Maior que', 'Menor que', 'Maior ou igual a', 'Menor ou igual a'],
    geocode: ['Igual', 'Diferente', 'Maior que', 'Menor que', 'Maior ou igual a', 'Menor ou igual a'],
    date: ['Igual', 'Diferente', 'Maior que', 'Menor que', 'Maior ou igual a', 'Menor ou igual a'],
    dateTime: ['Igual', 'Diferente', 'Maior que', 'Menor que', 'Maior ou igual a', 'Menor ou igual a'],
  }

  const TipoValores: Array<string> = ['Específico', 'Campo da Tela']

  const Values: any = {
    boolean: ['Verdadeiro', 'Falso', 'Nenhum'],
  }

  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  const handleInit = () => {
    setOpened(true)

    apiWrapper.get(`api/v1/MenuAppFields/listarPorMenuApp?item.AccessCode=${accessCode ?? accessCodeExtra}`).then((response) => {
      let fields: Array<any> = response.data.data.filter((item: any) => {
        return item.tipo != 'upload' && item.tipo != 'geocode'
      })

      let _singleSelectOptions: any = []

      filters.forEach((filter, index) => {
        _singleSelectOptions[index] = []
      })

      setSingleSelectOptions(_singleSelectOptions)

      filters.forEach((filter, index) => {
        let filterField = fields.find((field: any) => field.id == filter.campo)

        if (filterField == null) {
          return
        }

        setOperators(Ops[filterField.tipo])

        if (filterField.tipo == 'singleSelect') {
          handleOnInputChangeAutoComplete(filter, index, '', filter.valor)
        }
      })

      setFields(fields)
    })

    apiWrapper.get('api/v1/MenuAppFields/listarPorMenuApp?item.AccessCode=' + accessCodeExtra).then((response) => {
      let fields: Array<any> = response.data.data.filter((item: any) => {
        return item.tipo != 'upload'
      })

      setFieldsExtra(fields)
    })
  }

  const handleClose = (applyFilters: boolean) => {
    let obj: any = {}

    if (applyFilters) {
      obj.apply = true
    }

    setOpened(false)
    handleCallBack(obj)
  }

  const handleAddFilter = () => {
    let newFilter = new CGlobalFilterStructure()
    newFilter.operadorLogico = filters.length == 1 ? 'E' : filters.length > 0 ? filters[1].operadorLogico : '-'
    setFilters([...filters, newFilter])
    setSingleSelectOptions([...SingleSelectOptions, []])
  }

  const handleRemoveFilter = (index: number) => {
    setFilters(filters.filter((item, i) => i != index))
    setSingleSelectOptions(SingleSelectOptions.filter((item, i) => i != index))
  }

  const handleClearFilters = () => {
    setFilters([])
    setSingleSelectOptions([])
  }

  const handleOnChangeDate = (index: number, key: string, value: any) => {
    setFilters((prev: Array<CGlobalFilterStructure>) => {
      prev[index][key] = moment(value).format('YYYY-MM-DD').toString()
      return [...prev]
    })
  }

  const handleOnChangeEvent = (index: number, key: string, event: any) => {
    setFilters((prev: Array<CGlobalFilterStructure>) => {
      if (key == 'operadorLogico' && index == 1) {
        prev.forEach((item: CGlobalFilterStructure, index: number) => {
          if (index <= 1) {
            return
          }

          item.operadorLogico = event.target.value
        })
      }

      prev[index][key] = event.target.value
      return [...prev]
    })
  }

  const handleOnOpenAutoComplete = (item: CGlobalFilterStructure, index: number, event: any) => {
    if (event.target.value == '' || event.target.value == null) {
      handleOnInputChangeAutoComplete(item, index, '')
    }
  }

  const handleOnInputChangeAutoComplete = (item: CGlobalFilterStructure, index: number, value: any, id: any = null) => {
    if (item.campo == null || item.campo == 0) {
      return
    }

    let url: string = ''

    if (id == null) {
      url = `/api/v1/Dynamic/listarSingleSelect?item.AccessCode=${accessCode}&item.IDMenuAppField=${item.campo}&item.Query=${value}&RegistroPorPagina=15`
    } else {
      url = `/api/v1/Dynamic/listarSingleSelect?item.AccessCode=${accessCode}&item.IDMenuAppField=${item.campo}&item.IDs=${id}&RegistroPorPagina=15`
    }

    apiWrapper.get(url).then((response) => {
      setSingleSelectOptions((prev: any) => {
        prev[index] = response.data.data
        return [...prev]
      })

      if (id != null) {
        setFilters((prev: Array<CGlobalFilterStructure>) => {
          prev[index]['valor'] = id.toString()
          return [...prev]
        })
      }
    })
  }

  const handleOnChangeAutoComplete = (index: number, key: string, value: any) => {
    setFilters((prev: Array<CGlobalFilterStructure>) => {
      if (key == 'campo') {
        prev[index]['tipoValor'] = ''
        prev[index]['valor'] = ''
      }

      if (key == 'tipoValor') {
        prev[index]['valor'] = ''
      }

      if (key == 'valor') {
        prev[index][key] = value?.id?.toString() ?? ''
        return [...prev]
      }

      if (key == 'campo') {
        if (value != null) {
          let operadores = Ops[value.tipo]

          if (operadores != null) {
            setOperators(operadores)
            prev[index]['operador'] = operadores[0]
          } else {
            setOperators([])
          }
        } else {
          setOperators([])
          setSingleSelectOptions((prev: any) => {
            prev[index] = []
            return [...prev]
          })
          prev[index]['operador'] = ''
        }

        prev[index][key] = value?.id ?? 0
        return [...prev]
      }

      prev[index][key] = value
      return [...prev]
    })
  }

  function GetComponenteValor(item: CGlobalFilterStructure, index: number) {
    if (Fields.length == 0) {
      return <></>
    }

    let field: any = Fields.find((field: any) => field.id == item.campo)

    if (field == null) {
      return <></>
    }

    switch (field.tipo) {
      case 'boolean':
        return (
          <Autocomplete
            fullWidth
            disablePortal
            options={Values.boolean}
            size='small'
            renderInput={(params) => <TextField {...params} label='Valor' />}
            value={item.valor}
            onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'valor', value)}
          />
        )
      case 'string':
        return <TextField fullWidth size='small' label='Valor' value={item.valor} onChange={(event) => handleOnChangeEvent(index, 'valor', event)} />
      case 'number':
        return (
          <TextField
            fullWidth
            size='small'
            label='Valor'
            type='number'
            InputLabelProps={{shrink: true}}
            value={item.valor}
            onChange={(event) => handleOnChangeEvent(index, 'valor', event)}
          />
        )
      case 'date':
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
            <DesktopDatePicker
              slotProps={{textField: {size: 'small'}}}
              label='Valor'
              value={item.valor != '' ? new Date(item.valor as string) : null}
              onChange={(date) => handleOnChangeDate(index, 'valor', date)}
              sx={{width: 300}}
            />
          </LocalizationProvider>
        )
      case 'dateTime':
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
            <DateTimePicker
              slotProps={{textField: {size: 'small'}}}
              label='Valor'
              value={item.valor != '' ? new Date(item.valor as string) : null}
              onChange={(date) => handleOnChangeDate(index, 'valor', date)}
              sx={{width: 300}}
            />
          </LocalizationProvider>
        )
      case 'singleSelect':
        return (
          <Autocomplete
            fullWidth
            disablePortal
            options={SingleSelectOptions[index]}
            size='small'
            renderInput={(params) => <TextField {...params} label='Valor' />}
            value={item.valor != null && item.valor != '' ? SingleSelectOptions[index]?.find((op: any) => op.id == item.valor) ?? null : null}
            onOpen={(event) => handleOnOpenAutoComplete(item, index, event)}
            onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'valor', value)}
            onInputChange={(event, value) => handleOnInputChangeAutoComplete(item, index, value)}
            filterOptions={(x) => x}
          />
        )
      default:
        return <></>
    }
  }

  useEffect(() => {
    handleInit()
  }, [])

  return (
    <Dialog open={Opened} fullWidth={true} maxWidth='lg'>
      <DialogTitle>Filtros Ativos</DialogTitle>
      <DialogContent dividers={true} sx={{minHeight: 300}}>
        <Box component='div' sx={{p: 1}}>
          <Button variant='text' size='small' sx={{px: 1}} onClick={() => handleAddFilter()}>
            + Adicionar filtro
          </Button>
          <Button variant='text' size='small' color='error' sx={{px: 1}} onClick={() => handleClearFilters()}>
            - Limpar filtros
          </Button>
        </Box>
        {filters.length == 0 && (
          <Box component='div' sx={{p: 4}}>
            <BoxSVGMessage message='Nenhum registro encontrado' />
          </Box>
        )}
        {filters.map((item: CGlobalFilterStructure, index: number) => {
          return (
            <Box key={`item-${index}`} component='div' className='d-flex' sx={{p: 1}}>
              <Box component='div'>
                <IconButton aria-label='delete' color='error' onClick={() => handleRemoveFilter(index)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box component='div' sx={{px: 0.5, width: 85}}>
                <FormControl fullWidth disabled={index != 1}>
                  <InputLabel id='operadorLogico-label'>Op. Lógico</InputLabel>
                  <Select
                    labelId='operadorLogico-label'
                    id='operadorLogico-label'
                    value={item.operadorLogico}
                    label='Operador Lógico'
                    size='small'
                    onChange={(event) => handleOnChangeEvent(index, 'operadorLogico', event)}
                  >
                    {index == 0 && <MenuItem value='-'>-</MenuItem>}
                    <MenuItem value='E'>E</MenuItem>
                    <MenuItem value='Ou'>Ou</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box component='div' sx={{px: 0.5, width: 225}}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  options={Fields}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Campo' />}
                  value={item.campo != null && item.campo != 0 ? Fields.find((op: any) => op.id == item.campo) ?? null : null}
                  onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'campo', value)}
                />
              </Box>
              <Box component='div' sx={{px: 0.5, width: 210}}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  options={Operators}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Operador' />}
                  value={item.operador}
                  onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'operador', value)}
                />
              </Box>
              <Box component='div' sx={{px: 0.5, width: 200}}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  options={TipoValores}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Tipo de Valor' />}
                  value={item.tipoValor}
                  onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'tipoValor', value)}
                />
              </Box>
              {item.campo != null && item.campo != 0 && item.tipoValor == 'Específico' && (
                <Box component='div' sx={{px: 0.5, width: 350}}>
                  {GetComponenteValor(item, index)}
                </Box>
              )}
              {item.tipoValor == 'Campo da Tela' && FieldsExtra.length > 0 && (
                <Box component='div' sx={{px: 0.5, width: 350}}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={FieldsExtra}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Campo da Tela' />}
                    value={item.valor != null && item.valor != '' ? FieldsExtra.find((op: any) => op.id == item.valor) ?? null : null}
                    onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'valor', value)}
                  />
                </Box>
              )}
            </Box>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='inherit' onClick={() => handleClose(true)}>
          Fechar
        </Button>
        {/*
        <Button variant='contained' color='primary' onClick={() => handleClose(true)}>
          Aplicar
        </Button>
        */}
      </DialogActions>
    </Dialog>
  )
}

export default FieldRuleFilter
