import {DialogContent, DialogTitle, DialogActions, Box} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import ReportIcon from '@mui/icons-material/Report'
import StyledDialog from '../styled/StyledDialog'
import StyledButton from '../styled/StyledButton'
import {toast} from 'react-toastify'

interface IModalDisplayError {
  code: string
  message: string
  details: string
  callBack(): void
}

const ModalDisplayError = ({code, message, details, callBack}: IModalDisplayError) => {
  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack()
    }
  }

  return (
    <StyledDialog open fullWidth maxWidth='sm'>
      <DialogTitle>Ops! Ocorreu um erro de código: {code}</DialogTitle>
      <DialogContent dividers={true} sx={{minHeight: '200px', alignContent: 'center', textAlign: 'center'}}>
        <Box component='div' sx={{p: 1, fontSize: '40px'}}>
          <ReportIcon color='error' fontSize='inherit' />
        </Box>
        <Box component='div' sx={{p: 1}}>
          {message}
        </Box>
        <Box
          component='div'
          className='text-gray-800 text-hover-primary fw-bold'
          onClick={() => {
            navigator.clipboard.writeText(details)
            toast.success('Copiado para a área de transferência!')
          }}
          sx={{p: 1}}
        >
          {details}
        </Box>
      </DialogContent>
      <DialogActions>
        <StyledButton variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleCallBack()}>
          Fechar
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  )
}

export default ModalDisplayError
