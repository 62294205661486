import {CampoEstruturadoResult} from '../../modules/api/models/Result/CampoEstruturadoResult'
import {CGlobalFilterStructure} from '../classes/CMenuAppHistoryStructure'

export const DownloadLogico = (filename, text) => {
  var a = document.createElement('a')
  a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
  a.setAttribute('download', filename)

  if (document.createEvent) {
    var event = document.createEvent('MouseEvents')
    event.initEvent('click', true, true)
    a.dispatchEvent(event)
  } else {
    a.click()
  }
}

//TODO: Validação da estrutura de filtros pelo front
export const BuildNonSQLFilter = (filters: Array<CGlobalFilterStructure>, fields: Array<CampoEstruturadoResult>) => {
  let result = ''

  for (const filter of filters) {
  }

  return result
}

export const getUrlParams = () => {
  const params: {[key: string]: string} = {}
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  urlParams.forEach((value, key) => {
    params[key] = value
  })

  return params
}
