import Box from '@mui/material/Box'
import {memo} from 'react'
import {Handle, Position} from 'reactflow'
import StyledCard from '../styled/StyledCard'
import StyledCardContent from '../styled/StyledCardContent'
import {Badge, IconButton} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

const CustomNode = ({data}) => {
  return (
    <StyledCard>
      <Handle type='target' position={Position.Top} />
      <StyledCardContent>
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent={data.badgeContent}
          color='primary'
        >
          <Box component='div' className='d-flex' sx={{p: 0.8, alignItems: 'center'}}>
            <Box component='div' sx={{px: 1, mr: 1}}>
              {data.icon}
            </Box>
            {data.label}
            {data.onEdit != null && (
              <Box component='div' sx={{ml: 1}}>
                <IconButton size='small' aria-label='edit' color='inherit' onClick={data.onEdit}>
                  <EditIcon />
                </IconButton>
              </Box>
            )}
            {data.onDelete != null && (
              <Box component='div' sx={{ml: 1}}>
                <IconButton size='small' aria-label='delete' color='error' onClick={data.onDelete}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </Badge>
      </StyledCardContent>
      <Handle type='source' position={Position.Bottom} />
    </StyledCard>
  )
}

export default memo(CustomNode)
