import React from 'react'
import {NumericFormat, NumericFormatProps} from 'react-number-format'

interface CustomProps {
  onChange: (event: {target: {name: string; value: string}}) => void
  name: string
}

export const NumericFormatWithDecimal = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const {onChange, ...other} = props
  const withValueLimit = ({floatValue}) => floatValue <= 9999999.99

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator='.'
      decimalSeparator=','
      valueIsNumericString
      isAllowed={withValueLimit}
    />
  )
})

export const GeocodeFormat = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const {onChange, ...other} = props
  const withValueLimit = ({floatValue}) => floatValue <= 999.999999 && floatValue >= -999.999999

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator='.'
      decimalSeparator=','
      valueIsNumericString
      isAllowed={withValueLimit}
    />
  )
})

export const NumericFormatWithoutDecimal = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const {onChange, ...other} = props
  const withValueLimit = ({floatValue}) => floatValue <= 2147483647

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator='.'
      decimalSeparator=','
      decimalScale={0}
      valueIsNumericString
      isAllowed={withValueLimit}
    />
  )
})
