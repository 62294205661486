import {Configuration, LogLevel, PopupRequest} from '@azure/msal-browser'

//* Essa é a configuração global do MSAL para nossa aplicação, se precisar de algo importe daqui

const urlRedir =
  window.location.hostname.indexOf('localhost') >= 0 ? 'http://' + window.location.hostname + ':' + process.env.REACT_APP_PORT : 'https://' + window.location.hostname

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_MSAL_CLIENTID ?? '',
    authority: 'https://login.microsoftonline.com/common/',
    redirectUri: urlRedir,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            return
          case LogLevel.Info:
            return
          case LogLevel.Verbose:
            return
          case LogLevel.Warning:
            return
        }
      },
    },
  },
}

export const loginRequest: PopupRequest = {
  scopes: ['User.Read'],
}
