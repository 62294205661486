import {useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import DynamicModal from './modals/modalDynamicV2'

const PrivateRead = () => {
  //* PARAMETROS DE ROTA
  const RouteParams = useParams()

  //* VARIAVEIS DE ESTADO
  const [modalDynamic, setModalDynamic] = useState<boolean>(false)
  const [modalDynamicItemID, setModalDynamicItemID] = useState<number>(0)

  const handleInit = () => {
    setModalDynamic(true)
    setModalDynamicItemID(parseInt(RouteParams.idrouteitem ?? '0'))
  }

  const dynamicCallBack = (response: any = null) => {
    setModalDynamic(false)
  }

  useEffect(() => {
    handleInit()
  }, [])

  return (
    <>{modalDynamic && <DynamicModal readonly={true} accessCode={RouteParams.accesscode} id={modalDynamicItemID} callBack={dynamicCallBack} />}</>
  )
}

export default PrivateRead
