import {AxiosRequestConfig, AxiosResponse} from 'axios'
import {AuthenticationResult, InteractionRequiredAuthError, IPublicClientApplication} from '@azure/msal-browser'
import {loginRequest} from '../authentication/config/msalConfig'
import {ApiWrapped as Api} from './Api'
import {getSavedAuthentication} from '../authentication/helpers/StorageHelper'

//* Interface para tipar nossa classe abaixo
interface IApiWrapper {
  msalInstance: IPublicClientApplication
  get(url: string, config?: AxiosRequestConfig<any>): Promise<AxiosResponse<any, any>>
  post(url: string, data: any, config?: AxiosRequestConfig<any>): Promise<AxiosResponse<any, any>>
  put(url: string, data: any, config?: AxiosRequestConfig<any>): Promise<AxiosResponse<any, any>>
  delete(url: string, config?: AxiosRequestConfig<any>): Promise<AxiosResponse<any, any>>
}

//* Classe wrapper implementando a interface acima
export class ApiWrapper implements IApiWrapper {
  //* Instancia do MSAL que utilizaremos para pegar o token, DEVE VIR DO USEMSAL!
  msalInstance: IPublicClientApplication

  //* Construtor com a instancia do MSAL
  /**
   * Api wrapper para o axios que gerencia os tokens da autenticação para requests
   * @param msalInstance Construtor com a instancia do MSAL
   */
  constructor(msalInstance: IPublicClientApplication) {
    this.msalInstance = msalInstance
  }

  //* Função privada que cuidará de adiquirir o token atualizado a cada chamada da API
  private async getAccessToken() {
    let account = this.msalInstance.getAllAccounts()[0]
    if (account == null) {
      return ''
    }

    let obj: any = {
      scopes: loginRequest.scopes,
      account,
    }

    let token: AuthenticationResult | undefined

    await this.msalInstance
      .acquireTokenSilent(obj)
      .then((tokenResponse) => {
        token = tokenResponse
      })
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          token = await this.msalInstance.acquireTokenPopup(obj)
        } else {
          console.log(error)
          alert(error)
        }
      })

    return token?.idToken ?? ''
  }

  //* Função que recebe o config de uma chamada padrão de API e atualiza com o token
  private async updateConfigWithAccessToken(config?: AxiosRequestConfig<any>) {
    if (config == null) {
      config = {}
    }

    let token = ''
    let auth = getSavedAuthentication()

    if (auth == null) {
      token = await this.getAccessToken()
    } else {
      token = auth.api_token
    }

    config.headers = {
      Authorization: `Bearer ${token}`,
    }

    return config
  }

  //* Wrapper padrão do Api.get do Axios
  async get(url: string, config?: AxiosRequestConfig<any>) {
    config = await this.updateConfigWithAccessToken(config)
    return Api.get(url, config)
  }

  //* Wrapper padrão do Api.post do Axios
  async post(url: string, data: any, config?: AxiosRequestConfig<any>) {
    config = await this.updateConfigWithAccessToken(config)
    return Api.post(url, data, config)
  }

  //* Wrapper padrão do Api.put do Axios
  async put(url: string, data: any, config?: AxiosRequestConfig<any>) {
    config = await this.updateConfigWithAccessToken(config)
    return Api.put(url, data, config)
  }

  //* Wrapper padrão do Api.delete do Axios
  async delete(url: string, config?: AxiosRequestConfig<any>) {
    config = await this.updateConfigWithAccessToken(config)
    return Api.delete(url, config)
  }
}
