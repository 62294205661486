import {useState} from 'react'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import {SingleSelectResult} from '../../../../modules/api/models/Result/SingleSelectResult'

function not(a: Array<SingleSelectResult>, b: Array<SingleSelectResult>) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a: Array<SingleSelectResult>, b: Array<SingleSelectResult>) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

interface ITransferList {
  left: Array<SingleSelectResult>
  right: Array<SingleSelectResult>
  setLeft: React.Dispatch<React.SetStateAction<SingleSelectResult[]>>
  setRight: React.Dispatch<React.SetStateAction<SingleSelectResult[]>>
}

export const TransferList = ({left, right, setLeft, setRight}: ITransferList) => {
  const [checked, setChecked] = useState<Array<SingleSelectResult>>([])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value: SingleSelectResult) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = () => {
    setRight(right.concat(left))
    setLeft([])
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const handleAllLeft = () => {
    setLeft(left.concat(right))
    setRight([])
  }

  const customList = (items: Array<SingleSelectResult>) => (
    <Paper sx={{width: 350, height: 400, overflow: 'scroll'}}>
      <List dense component='div' role='list'>
        {items.map((value: SingleSelectResult) => {
          const labelId = `transfer-list-item-${value.id}-label`

          return (
            <ListItemButton key={value.id} role='listitem' onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.label} />
            </ListItemButton>
          )
        })}
      </List>
    </Paper>
  )

  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center'>
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Button sx={{my: 0.5}} variant='outlined' size='small' onClick={handleAllRight} disabled={left.length === 0} aria-label='move all right'>
            ≫
          </Button>
          <Button
            sx={{my: 0.5}}
            variant='outlined'
            size='small'
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label='move selected right'
          >
            &gt;
          </Button>
          <Button
            sx={{my: 0.5}}
            variant='outlined'
            size='small'
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label='move selected left'
          >
            &lt;
          </Button>
          <Button sx={{my: 0.5}} variant='outlined' size='small' onClick={handleAllLeft} disabled={right.length === 0} aria-label='move all left'>
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
  )
}

export default TransferList
