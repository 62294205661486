import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {DynamicKanban} from './components/DynamicKanban'

interface IDynamicParams {
  isDisplayed?: boolean
}

export const TabKanban = ({isDisplayed}: IDynamicParams) => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/:accesscode'
          element={
            <>
              <DynamicKanban isDisplayed={isDisplayed} />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/:accesscode/:sysparam'
          element={
            <>
              <DynamicKanban isDisplayed={isDisplayed} />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
