/**
 * Função padrão que trava o valor entre um possível mínimo e máximo, tambem verifica se o valor é infinito e o reseta caso seja.
 * @param value Valor para travar
 * @param min (Opcional) Valor mínimo para travar o valor informado
 * @param max (Opcional) Valor máximo para travar o valor informado
 * @returns number
 */
export const SafeClamp = (value: number, min?: number, max?: number) => {
  let _val = isNaN(value) ? 0 : value

  if (min != null && value < min) value = min
  if (max != null && value > max) value = max

  return _val
}

export const normalise = (value: number, min: number, max: number) => ((value - min) * 100) / (max - min)
