import React, {useEffect, useState} from 'react'

import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import CancelIcon from '@mui/icons-material/Cancel'
import DownloadIcon from '@mui/icons-material/Download'
import {useThemeMode} from '../../../../../_metronic/partials'
import {globalDarkMuiTheme, globalLightMuiTheme} from '../utility/globalMuiTheme'
import {Theme, ThemeProvider} from '@mui/material'

const _Paper = (props) => {
  return <Paper elevation={0} {...props} />
}

interface IKanbanCardFiles {
  files: Array<any>
  callBack?: any
}

export const KanbanCardFiles = ({files, callBack}: IKanbanCardFiles) => {
  const [Opened, setOpened] = useState<boolean>(false)

  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  const handleOpen = () => {
    setOpened(true)
  }

  const handleClose = () => {
    setOpened(false)
    handleCallBack()
  }

  const handleDownloadClick = (url: string) => {
    let link = document.createElement('a')
    link.href = url
    link.download = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  /* THEME */

  const {mode} = useThemeMode()
  const [muiTheme, setMuiTheme] = useState<Theme>(mode == 'light' ? globalLightMuiTheme : globalDarkMuiTheme)

  const handleUpdateTheme = () => {
    setMuiTheme((mode as any) == 'dark' ? globalDarkMuiTheme : globalLightMuiTheme)
  }

  useEffect(() => {
    handleUpdateTheme()
  }, [mode])

  /* END THEME */

  useEffect(() => {
    handleOpen()
  }, [])

  return (
    <ThemeProvider theme={muiTheme}>
      <Dialog open={Opened}>
        <DialogTitle>Arquivos</DialogTitle>
        <DialogContent dividers={true} sx={{justifyContent: 'center', alignItems: 'center', minWidth: 500}}>
          <FormControl sx={{width: '100%'}} component='fieldset' variant='standard'>
            <TableContainer component={_Paper}>
              <Table sx={{width: '100%'}} size='small' aria-label='SYSMAA table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Nome</TableCell>
                    <TableCell align='center'>Tipo</TableCell>
                    <TableCell align='center'>Baixar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files &&
                    files.map((file: any, index: number) => {
                      return (
                        <TableRow key={`file-${index}`}>
                          <TableCell align='left' component='th' scope='row'>
                            {file.nomeOriginal}
                          </TableCell>
                          <TableCell align='center'>{file.tipo}</TableCell>
                          <TableCell align='center'>
                            <IconButton aria-label='download' onClick={(event) => handleDownloadClick(file.urlDownload)}>
                              <DownloadIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleClose()}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default KanbanCardFiles
