import {useState} from 'react'

import Box from '@mui/material/Box'
import DialogTitle from '@mui/material/DialogTitle'
import StyledDialog from '../styled/StyledDialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import StyledButton from '../styled/StyledButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CircularProgressWithLabel from '../utility/CircularProgressWithLabel'

import CancelIcon from '@mui/icons-material/Cancel'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'

import {toast} from 'react-toastify'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import {normalise} from '../../../../models/wrappers/math'

interface IModalTarefa {
  ids: Array<number>
  callback(data?: any): void
}

export const ModalTarefa = ({ids, callback}: IModalTarefa) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [saving, setSaving] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const [progressIndex, setProgressIndex] = useState<number>(0)
  const [comentario, setComentario] = useState<string>('')

  const handleCallback = (data?: any) => {
    callback(data)
  }

  const handleExecuteTask = async (evento: string) => {
    setSaving(true)
    setProgress(0)
    setProgressIndex(0)

    let index = 0

    for (const item of ids) {
      index++

      setProgress(normalise(index, 0, ids.length))
      setProgressIndex(index)

      let obj = {
        item: {
          IDTarefa: item,
          Evento: evento,
          Observacao: comentario,
        },
      }

      await apiWrapper.post('api/v1/Tarefa/executarTarefa', obj)
    }

    setSaving(false)
    toast.success('Ação executada com sucesso!')
    handleCallback({refresh: true})
  }

  return (
    <StyledDialog open fullWidth maxWidth='sm'>
      <DialogTitle>Execução em massa de Tarefas</DialogTitle>
      <DialogContent dividers sx={{display: 'grid'}}>
        {!saving && (
          <>
            <Box component='div' sx={{p: 1}}>
              <Typography variant='body1' color='textSecondary' component='p' sx={{mt: 0.6}} className='word-wrap text-dark'>
                <strong>Informe uma observação para a ação:</strong>
              </Typography>
            </Box>
            <Box component='div' sx={{p: 1}}>
              <TextField
                sx={{width: '100%'}}
                size='small'
                value={comentario}
                onChange={(event) => setComentario(event.target.value)}
                multiline
                maxRows={6}
                minRows={6}
              />
            </Box>
          </>
        )}
        {saving && (
          <>
            <Box component='div' sx={{p: 1, placeSelf: 'center'}}>
              <CircularProgressWithLabel value={progress} />
            </Box>
            <Box component='div' sx={{p: 1, placeSelf: 'center'}}>
              Executando {progressIndex} de {ids.length}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <StyledButton variant='contained' color='inherit' disabled={saving} startIcon={<CancelIcon />} onClick={(event) => handleCallback()}>
          Fechar
        </StyledButton>
        <StyledButton
          variant='contained'
          color='error'
          disabled={saving}
          startIcon={<ThumbDownIcon />}
          onClick={(event) => handleExecuteTask('REPROVACAO')}
        >
          Reprovar
        </StyledButton>
        <StyledButton
          variant='contained'
          color='success'
          disabled={saving}
          startIcon={<ThumbUpIcon />}
          onClick={(event) => handleExecuteTask('APROVACAO')}
        >
          Aprovar
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  )
}

export default ModalTarefa
