// Cores e classes padrões usadas pelo sistema
export const textColors = [
  {id: 'text-primary', label: 'Azul'},
  {id: 'text-secondary', label: 'Cinza'},
  {id: 'text-success', label: 'Verde'},
  {id: 'text-danger', label: 'Vermelha'},
  {id: 'text-warning', label: 'Amarelo'},
  {id: 'text-info', label: 'Roxo'},
  {id: 'text-light', label: 'Branco'},
  {id: 'text-dark', label: 'Preto'},
  {id: 'text-muted', label: 'Fosco'},
  {id: 'text-white', label: 'Branco'},
]
