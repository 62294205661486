import React, {useEffect, useState} from 'react'

interface TimerProps {
  initialDate: Date
}

const Timer: React.FC<TimerProps> = ({initialDate}) => {
  const [elapsedTime, setElapsedTime] = useState<number>(0)

  const updateElapsedTime = () => {
    const now = new Date()
    const diff = Math.floor((now.getTime() - initialDate.getTime()) / 1000)
    setElapsedTime(diff)
  }

  useEffect(() => {
    updateElapsedTime()

    const intervalId = setInterval(() => {
      updateElapsedTime()
    }, 1000)

    return () => clearInterval(intervalId)
  }, [initialDate])

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    const pad = (value: number): string => (value < 10 ? `0${value}` : `${value}`)

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`
  }

  return <div>{formatTime(elapsedTime)}</div>
}

export default Timer
