import {useRef} from 'react'
import {Bar, Line, Pie, PolarArea, Radar, getElementAtEvent} from 'react-chartjs-2'

interface IChartWrapper {
  type: string
  options: any
  data: any
  onClick?: (dataset: any, options: any, data: any) => void
}

export const ChartWrapper = ({type, options, data, onClick}: IChartWrapper) => {
  const ref = useRef<any>(null)

  const handleOnClick = (event) => {
    let dataset = getElementAtEvent(ref.current, event)

    if (onClick != null) {
      onClick(dataset, options, data)
    }
  }

  switch (type) {
    case 'Bar':
      return <Bar ref={ref} options={options} data={data} onClick={handleOnClick} />
    case 'Pie':
      return <Pie ref={ref} options={options} data={data} onClick={handleOnClick} />
    case 'Line':
      return <Line ref={ref} options={options} data={data} onClick={handleOnClick} />
    case 'Radar':
      return <Radar ref={ref} options={options} data={data} onClick={handleOnClick} />
    case 'PolarArea':
      return <PolarArea ref={ref} options={options} data={data} onClick={handleOnClick} />
    default:
      return <></>
  }
}

export default ChartWrapper
