import {format} from 'date-fns'
import {TranslateLocale} from './translator'

/**
 * Função padrão que formata um objeto de data baseado no local informado usando o date-fns.
 * @param date Objeto date a ser formatado
 * @param locale Local a ser usado na formatação
 * @param includeHours Flag que indica se a hora deve ser incluída na formatação
 * @returns string
 */
export const FormatDate = (date: Date, locale: string, includeHours: boolean = true) => {
  let translatedLocale = TranslateLocale(locale)
  return format(date, `dd/MM/yyyy${includeHours ? ' hh:mm:ss' : ''}`, {locale: translatedLocale})
}
