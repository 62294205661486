import {toast} from 'react-toastify'
import StyledDialog from '../styled/StyledDialog'
import {Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import CancelIcon from '@mui/icons-material/Cancel'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import {DownloadLogico} from '../../../../models/wrappers/helpers'
import {useEffect, useState} from 'react'

interface IModalModeloSQL {
  tipoModelo: string
  callBack: (data?: any) => void
}

const ModalModeloSQL = ({tipoModelo, callBack}: IModalModeloSQL) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [loading, setLoading] = useState<boolean>(false)
  const [modelos, setModelos] = useState<Array<any>>([])
  const [modeloContexto, setModeloContexto] = useState<any>(null)

  const handleInit = () => {
    setLoading(true)
    apiWrapper.get('api/v1/MenuAppResource/listar?item.Tipo=procedure').then((response) => {
      setModelos(response.data.data)
      setModeloContexto(response.data.data.find((item) => item.nome == tipoModelo))
      setLoading(false)
    })
  }

  function BaixarArquivoModelo(modelo: any) {
    //toast.info('Gerando arquivo modelo, aguarde...')
    DownloadLogico('modelo.sql', modelo.resource)
    toast.success('Arquivo modelo gerado com sucesso!')
  }

  useEffect(() => {
    handleInit()
  }, [])

  return (
    <StyledDialog open fullWidth={true} maxWidth='sm'>
      <DialogTitle>Modelo de Procedimento</DialogTitle>
      <DialogContent dividers={true}>
        {loading && <CircularProgress />}
        {!loading && modeloContexto != null && (
          <>
            <Box component='div' sx={{p: 1, alignSelf: 'center'}}>
              <a
                title='Clique aqui para realizar Download do arquivo modelo'
                style={{cursor: 'pointer'}}
                onClick={(event) => BaixarArquivoModelo(modeloContexto)}
              >
                <div className='container'>
                  <div className='row'>
                    <div className='col-1'></div>
                    <div className='col-2 p-1 text-center '>
                      <img alt='Logo' src={toAbsoluteUrl('/media/app/sql64x64.png')} className='h-45px w-45px' />
                    </div>
                    <div className='col-8 p-1'>
                      <span>Para baixar o arquivo modelo </span>{' '}
                      <span>
                        <strong className='link-primary'>Clique Aqui</strong> e após, execute o procedimento no banco de dados da aplicação (
                        Compativel com SQL SERVER ).
                      </span>
                    </div>
                    <div className='col-1'></div>
                  </div>
                </div>
              </a>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => callBack()}>
          Fechar
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default ModalModeloSQL
