import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material'
import StyledDialog from '../styled/StyledDialog'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'

interface IModalExclusao {
  open: boolean
  title: string
  message: string
  callBack(excluir: boolean): void
}

const ModalExclusao = ({open, title, message, callBack}: IModalExclusao) => {
  return (
    <StyledDialog open={open} fullWidth={true} maxWidth='sm'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => callBack(false)}>
          Cancelar
        </Button>
        <Button variant='contained' startIcon={<DeleteIcon />} color='error' onClick={() => callBack(true)}>
          Excluir
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default ModalExclusao
