import {Suspense, useEffect, createContext} from 'react'
import useState from 'react-usestateref'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'

import {MsalProvider} from '@azure/msal-react'
import {msalConfig} from './modules/authentication/config/msalConfig'
import {AuthenticationInit} from './modules/authentication/Authentication'
import {LicenseInfo} from '@mui/x-license-pro'
import {PublicClientApplication} from '@azure/msal-browser'
import {ToastContainer} from 'react-toastify'
import {ThemeModeProvider} from '../_metronic/partials'
import {getPresence, setPresence} from './modules/authentication/helpers/StorageHelper'
import Presence from './modules/authentication/components/Presence'
import ModalDisplayError from './pages/_dynamic/components/modals/modalDisplayError'
import {getLastErrorInStorage, deleteLastErrorInStorage} from './modules/api/Api'

LicenseInfo.setLicenseKey('c0a9f790d4115811ae624a25ded22066Tz02MjU3NCxFPTE3MTExMTU2MTc4OTYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

export const GlobalContext = createContext({})
export const msalInstance = new PublicClientApplication(msalConfig)
msalInstance.initialize()
msalInstance.handleRedirectPromise()

const App = () => {
  const [modalDisplayError, setModalDisplayError] = useState<boolean>(false)
  const [modalDisplayErrorCode, setModalDisplayErrorCode] = useState<string>('')
  const [modalDisplayErrorMessage, setModalDisplayErrorMessage] = useState<string>('')
  const [modalDisplayErrorDetails, setModalDisplayErrorDetails] = useState<string>('')

  const [presenceInterval, setPresenceInterval, presenceIntervalRef] = useState<NodeJS.Timer | undefined>()
  const [modalPresence, setModalPresence, modalPresenceRef] = useState<boolean>(false)
  const presenceTime = parseInt(process.env.REACT_APP_AUTH_PRESENCE_TIME as string)

  const presenceCallback = () => {
    //* FECHAMOS O MODAL, ATUALIZAMOS A DATA DE ULTIMO REQUEST PARA A DATA EXATA ATUAL E SETAMOS O STORAGE PARA NÃO EXPIRADO
    setModalPresence(false)

    let obj = getPresence()
    if (obj == null) {
      return
    }

    obj.lastRequestTime = new Date().toISOString()
    obj.expired = false
    setPresence(obj)
  }

  const EvaluatePresence = () => {
    let obj = getPresence()

    //* VERIFICAMOS SE O MSAL POSSUI ALGUMA CONTA, OU SEJA, ESTA DE FATO LOGADO
    if (msalInstance.getAllAccounts().length == 0 || obj == null) {
      return
    }

    //* VERIFICAMOS SE NÃO ESTÁ MAIS EXPIRADO POREM O MODAL AINDA ESTA ABERTO (Em casos onde foi reautenticado em outra aba)
    if (!obj.expired && modalPresenceRef.current) {
      setModalPresence(false)
      return
    }

    let DateParsed = new Date(obj.lastRequestTime)
    let DateNow = new Date()

    //* DIMINUIMOS O TEMPO SETADO NO .ENV DO DATENOW PARA CALCULAR AS DATAS
    DateNow.setHours(DateNow.getHours() - presenceTime)

    //* CASO PASSE NOS CHECKS DE CALCULO E O MODAL AINDA NÃO ESTEJA ABERTO, ABRA O MODAL E MARQUE O STORAGE COMO EXPIRADO
    if (!isNaN(DateParsed.getTime()) && DateParsed < DateNow && !modalPresenceRef.current) {
      obj.expired = true
      setPresence(obj)
      setModalPresence(true)
    }
  }

  const DisplayRequestErrors = () => {
    if (modalDisplayError) {
      return
    }

    let lastError = getLastErrorInStorage()
    if (lastError != null) {
      setModalDisplayError(true)
      setModalDisplayErrorCode(lastError.code)
      setModalDisplayErrorMessage(lastError.message)
      setModalDisplayErrorDetails(lastError.details)
    }
  }

  const handleModalDisplayErrorCallback = () => {
    setModalDisplayError(false)
    setModalDisplayErrorCode('')
    setModalDisplayErrorMessage('')
    setModalDisplayErrorDetails('')
    deleteLastErrorInStorage()
  }

  useEffect(() => {
    //* CRIAMOS OS INTERVALS
    const intervalId_1 = setInterval(() => {
      EvaluatePresence()
    }, 1000)

    const intervalId_2 = setInterval(() => {
      DisplayRequestErrors()
    }, 500)

    //* FUNÇÃO CLEANUP DO USEEFFECT
    return () => {
      clearInterval(intervalId_1)
      clearInterval(intervalId_2)
    }
  }, [])

  const global: any = {
    ReactState: {
      MenuInner: {
        setMenus: () => {},
      },
      Toolbar2: {
        setTitle: () => {},
        setSubtitle: () => {},
        setControls: () => {},
      },
    },
  }

  //* Retornamos o componente master da aplicação react-app
  return (
    <>
      {msalInstance != null /*&& msalInstanceInitialized*/ && (
        <MsalProvider instance={msalInstance}>
          <GlobalContext.Provider value={global}>
            <ThemeModeProvider>
              <Suspense fallback={<LayoutSplashScreen />}>
                <I18nProvider>
                  <LayoutProvider>
                    <AuthenticationInit>
                      <Outlet />
                      <MasterInit />
                    </AuthenticationInit>
                  </LayoutProvider>

                  {modalPresence && <Presence callback={presenceCallback} />}
                  {modalDisplayError && (
                    <ModalDisplayError
                      code={modalDisplayErrorCode}
                      message={modalDisplayErrorMessage}
                      details={modalDisplayErrorDetails}
                      callBack={handleModalDisplayErrorCallback}
                    />
                  )}

                  <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                </I18nProvider>
              </Suspense>
            </ThemeModeProvider>
          </GlobalContext.Provider>
        </MsalProvider>
      )}
    </>
  )
}

export {App}
