import axios from 'axios'
import {AccountInfo, AuthenticationResult, IPublicClientApplication, InteractionRequiredAuthError, SilentRequest} from '@azure/msal-browser'
import {loginRequest} from '../authentication/config/msalConfig'
import {msalInstance} from '../../App'
import {getPresence, getSavedAuthentication, setPresence} from '../authentication/helpers/StorageHelper'

export const Api = axios.create({baseURL: process.env.REACT_APP_BST_API_URL})
export const ApiWrapped = axios.create({baseURL: process.env.REACT_APP_BST_API_URL})

export async function GetTokenFromMSAL(account: AccountInfo, instance: IPublicClientApplication) {
  if (account == null) {
    return ''
  }

  let obj: SilentRequest = {
    scopes: loginRequest.scopes,
    account,
  }

  try {
    const auth: AuthenticationResult = await instance.acquireTokenSilent(obj)
    return auth.idToken
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      instance.acquireTokenRedirect(obj)
    }

    return ''
  }
}

export const GetAccessToken = async () => {
  let auth = getSavedAuthentication()

  if (auth != null) {
    return auth.api_token
  } else {
    let token: AuthenticationResult | undefined

    let account = msalInstance.getAllAccounts()[0]
    if (account == null) {
      return undefined
    }

    let obj: any = {
      scopes: loginRequest.scopes,
      account,
    }

    await msalInstance
      .acquireTokenSilent(obj)
      .then((tokenResponse) => {
        token = tokenResponse
      })
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          token = await msalInstance.acquireTokenPopup(obj)
        } else {
          console.log(error)
          alert(error)
        }
      })

    return token?.idToken
  }
}

export function setLastErrorInStorage(code: string, message: string, details: string) {
  let obj = {code, message, details}
  localStorage.setItem('lastError', JSON.stringify(obj))
}

export function getLastErrorInStorage() {
  let storageItem = localStorage.getItem('lastError')
  if (storageItem != null) {
    return JSON.parse(storageItem)
  }

  return undefined
}

export function deleteLastErrorInStorage() {
  localStorage.removeItem('lastError')
}

Api.interceptors.request.use(async function (config) {
  let obj = getPresence()
  if (obj != null && !obj.expired) {
    obj.lastRequestTime = new Date().toISOString()
    setPresence(obj)
  }

  /*
  let token = await GetAccessToken()
  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`
  }
  */

  return config
})

ApiWrapped.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    /*
    const originalConfig = error.config
    if (error.response) {
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        return ApiWrapped.request(originalConfig)
      }
    }
    */

    if (error.response) {
      // O servidor retornou um status de erro (por exemplo, 4xx ou 5xx)
      const {status, data} = error.response
      setLastErrorInStorage(`${status}`, data.mensagem, data.detalhes)
    } else if (error.request) {
      // A requisição foi feita, mas não houve resposta do servidor
      setLastErrorInStorage('500', 'Sem resposta do servidor', '')
    } else {
      // Algo aconteceu na configuração da requisição que causou um erro
      setLastErrorInStorage('500', 'Erro na configuração da requisição', '')
    }

    // Verificar se ainda precisamos do retorno em alguns casos
    return Promise.reject(error)
  }
)
