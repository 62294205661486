import {useParams} from 'react-router-dom'
import StyledFormControl from './styled/StyledFormControl'
import useState from 'react-usestateref'
import {useEffect} from 'react'
import {Typography} from '@mui/material'
import DynamicModal from './modals/modalDynamicV2'
import {Api} from '../../../modules/api/Api'
import {useThemeMode} from '../../../../_metronic/partials'
import StyledCard from './styled/StyledCard'
import StyledCardContent from './styled/StyledCardContent'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../modules/api/ApiWrapper'

export const PublicAccess = () => {
  //* PARAMETROS DE ROTA
  const RouteParams = useParams()

  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  //* VARIAVEIS DE ESTADO DE TEMA
  const {mode, menuMode} = useThemeMode()

  //* VARIAVEIS DE ESTADO
  const [isValidAccess, setIsValidAccess, isValidAccessRef] = useState(false)
  const [modalDynamic, setModalDynamic, modalDynamicRef] = useState(false)
  const [loading, setLoading, loadingRef] = useState(false)
  const [finished, setFinished, finishedRef] = useState(false)

  const handleInit = () => {
    setLoading(true)

    apiWrapper.get(`api/v1/Dynamic/verificarAcessoAnonimo?item.AccessCode=${RouteParams.accesscode}`).then((response) => {
      if (response.data.data === true) {
        setIsValidAccess(true)
        setModalDynamic(true)
      }

      setLoading(false)
    })
  }

  const dynamicCallBack = (response: any = null) => {
    setModalDynamic(false)
    setFinished(true)
  }

  useEffect(() => {
    handleInit()
  }, [])

  return (
    <>
      <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          <div
            style={{
              background: 'linear-gradient(to right, rgba(3, 21, 62, 0.95) 0%, rgba(32, 103, 210, 0.95) 100%)',
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: -1,
            }}
          ></div>
          <div className='w-lg-500px rounded shadow-sm p-lg-5 mx-auto text-center'>
            <StyledCard>
              <StyledCardContent>
                {!isValidAccess && !loading && (
                  <Typography variant='h4' sx={mode == 'dark' ? {color: 'white'} : {color: 'black'}} className='p-3'>
                    Acesso público negado.
                  </Typography>
                )}
                {finished && (
                  <Typography variant='h4' sx={mode == 'dark' ? {color: 'white'} : {color: 'black'}} className='p-3'>
                    Operação realizada com sucesso!
                  </Typography>
                )}
                {!loading && (
                  <StyledFormControl sx={{width: '100%', height: '100%', alignItems: 'center'}} component='fieldset' variant='standard'>
                    {modalDynamic && RouteParams.accesscode != null && (
                      <DynamicModal acessoExterno={true} accessCode={RouteParams.accesscode} id={0} callBack={dynamicCallBack} />
                    )}
                  </StyledFormControl>
                )}
              </StyledCardContent>
            </StyledCard>
          </div>
        </div>
      </div>
    </>
  )
}

export default PublicAccess
