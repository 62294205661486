import {useContext, useEffect} from 'react'
import {GlobalContext} from '../../../../App'
import DynamicChartsV2 from '../tabs/components/DynamicChartsV2'

const Dashboard = () => {
  const globalContext: any = useContext(GlobalContext)

  function BuildHeader() {
    globalContext.ReactState.Toolbar2.setTitle('Dashboard')
    globalContext.ReactState.Toolbar2.setSubtitle('Visão geral')
    globalContext.ReactState.Toolbar2.setControls([])
  }

  useEffect(() => {
    BuildHeader()
  }, [])

  return <DynamicChartsV2 isDisplayed={true} isPrimary={true} accessCode='SYSDASHBOARD' />
}

export default Dashboard
