import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useMsal} from '@azure/msal-react'
import {loginRequest} from '../config/msalConfig'
import StyledCard from '../../../pages/_dynamic/components/styled/StyledCard'
import StyledCardContent from '../../../pages/_dynamic/components/styled/StyledCardContent'
import {Box, Button, CircularProgress, IconButton, InputAdornment, Link, Stack, Typography} from '@mui/material'
import StyledButton from '../../../pages/_dynamic/components/styled/StyledButton'
import {useGlobalAuthentication} from '../Authentication'
import {ThemeModeType, useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {useEffect, useState} from 'react'
import {StyledTextField} from '../../../pages/_dynamic/components/styled/StyledTextField'
import {toast} from 'react-toastify'

import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import Separator from '../../../pages/_dynamic/components/utility/separator/separator'
import {ApiWrapper} from '../../api/ApiWrapper'
import TwoFactorModal from '../../../pages/_dynamic/components/modals/modal2Factor'

export function Login() {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()
  const {requestingUser, setCredentials} = useGlobalAuthentication()

  const [loadingMs, setLoadingMs] = useState<boolean>(true)
  const [msalEnabled, setMsalEnabled] = useState<boolean>(false)

  const [email, setEmail] = useState<string>()
  const [senha, setSenha] = useState<string>('')
  const [showEncrypted, setShowEncrypted] = useState<boolean>(false)

  const [modal2Factor, setModal2Factor] = useState<boolean>(false)

  const handleModal2FactorCallback = (data?: any) => {
    setModal2Factor(false)
  }

  const RedirectToMSAL = () => {
    try {
      setLoadingMs(true);
      msal.instance.loginRedirect(loginRequest);    
    } catch {} 
    finally{
      setTimeout(() => {
      setLoadingMs(false);
      }, 2000);
    }    
  }

  const login = () => {
    if (email == '' || senha == '') {
      toast.error('É necessário informar o email e senha!')
      return
    }

    if (email){
      localStorage.setItem("last_user_credential", email.toString());
      setCredentials({Username: email, Password: senha});
    }
  }

  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode)
    updateMode(_mode)
  }

  const VerificarMSAL = () => {
    setLoadingMs(true)
    apiWrapper.get('api/v1/MenuAppIntegration/verificarMSAL').then((response) => {
      setMsalEnabled(response.data.data)
      setLoadingMs(false)
    }).catch((error: any) => {
      setLoadingMs(false)
    })
    .finally(() => {
      setLoadingMs(false)
    });    
  }

  useEffect(() => {
    VerificarMSAL()
    switchMode(mode)
    const lastEmail = localStorage.getItem("last_user_credential") || "";
    setEmail(lastEmail);
  }, [])

  return (
    <StyledCard>
      <StyledCardContent sx={{textAlign: 'center'}}>
        <Box component='div' className='p-2 fs-1 fw-bold'>
          Acesso ao Sistema
        </Box>
        <Box component='div' className='p-5' sx={{textAlign: '-webkit-center'}}>
          {!requestingUser && (
            <>
              <Box component='div' sx={{p: 1}}>
                <StyledTextField
                  fullWidth
                  size='small'
                  sx={{width: '350px'}}
                  label='Email'
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <StyledTextField
                  fullWidth
                  size='small'
                  type={showEncrypted ? 'text' : 'password'}
                  sx={{width: '350px'}}
                  label='Senha'
                  value={senha}
                  onChange={(event) => setSenha(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={(event) => setShowEncrypted((prev) => !prev)} onMouseDown={(e) => e.preventDefault()} edge='end'>
                          {showEncrypted ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <StyledButton variant='contained' color='primary' size='large' sx={{width: '350px'}} onClick={() => login()}>
                  Entrar
                </StyledButton>
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Typography component='span'>
                  Esqueceu sua senha?{' '}
                  <span role='button' onClick={() => setModal2Factor(true)} className='fw-bold link-primary'>
                    Clique aqui
                  </span>
                </Typography>
              </Box>
              {loadingMs ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  {msalEnabled && (
                    <>
                      <Box component='div' sx={{width: '75%'}}>
                        <Separator text='ou' style={mode == 'dark' ? {borderBottom: '1px solid white'} : {borderBottom: '1px solid black'}} />
                      </Box>
                      <Box component='div' sx={{p: 1}}>
                        <StyledButton
                          startIcon={<img alt='Logo' src={toAbsoluteUrl('/media/svg/brand-logos/microsoft-5.svg')} className='h-15px me-3' />}
                          variant='contained'
                          color='inherit'
                          size='large'
                          sx={{width: '350px'}}
                            onClick={() => RedirectToMSAL()}
                        >
                          Entrar com Microsoft
                        </StyledButton>
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {requestingUser && (
            <StyledButton startIcon={<CircularProgress size={20} />} variant='outlined' size='large' sx={{width: '350px'}} disabled>
              Carregando Usuário...
            </StyledButton>
          )}
        </Box>
        <Box component='div' className='d-flex justify-content-center'>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Box component='div' className='d-flex flex-row'>
              <Button
                variant='text'
                color={mode == 'light' ? 'primary' : 'inherit'}
                startIcon={<KTIcon iconName='night-day' className={mode == 'light' ? 'fs-2 pb-1 text-primary' : 'fs-2 pb-1'} />}
                onClick={(event) => switchMode('light')}
              >
                Light
              </Button>
            </Box>
            <Box component='div' className='d-flex flex-row'>
              <Button
                variant='text'
                color={mode == 'dark' ? 'primary' : 'inherit'}
                startIcon={<KTIcon iconName='moon' className={mode == 'dark' ? 'fs-2 pb-1 text-primary' : 'fs-2 pb-1'} />}
                onClick={(event) => switchMode('dark')}
              >
                Dark
              </Button>
            </Box>
          </Stack>
        </Box>

        {modal2Factor && <TwoFactorModal callback={handleModal2FactorCallback} />}
      </StyledCardContent>
    </StyledCard>
  )
}
