import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {CRuleActions} from '../../../../models/classes/CRuleActions'

interface IFieldRuleActionsRow {
  event: string
  description: string
  enableGeneralActions?: boolean
  Fields: Array<any>
  Acoes: Array<string>
  Atribuicoes: Array<any>
  Grupos: Array<any>
  Usuarios: Array<any>
  Templates: Array<any>
  Contas: Array<any>
  Integracoes: Array<any>
  actions: Array<CRuleActions>
  GetComponenteValor: (item: CRuleActions, index: number) => JSX.Element
  handleChangeOrder: (index: number, move: 'up' | 'down') => void
  handleAddAction: (event: string) => void
  handleRemoveAction: (index: number) => void
  handleOnChangeAutoComplete: (index: number, key: string, value: any) => void
  handleOnChangeEvent: (index: number, key: string, event: any) => void
  handleOnChangeMultipleSelect: (index: number, key: string, event: any) => void
  IsReadOnlyField: (idMenuAppField: number) => boolean
  setModalModeloSQL: React.Dispatch<React.SetStateAction<boolean>>
}

export const FieldRuleActionsRow = ({
  event,
  description,
  enableGeneralActions,
  Fields,
  Acoes,
  Atribuicoes,
  Grupos,
  Usuarios,
  Templates,
  Contas,
  Integracoes,
  actions,
  GetComponenteValor,
  handleChangeOrder,
  handleAddAction,
  handleRemoveAction,
  handleOnChangeAutoComplete,
  handleOnChangeEvent,
  handleOnChangeMultipleSelect,
  IsReadOnlyField,
  setModalModeloSQL,
}: IFieldRuleActionsRow) => {
  return (
    <>
      <Typography variant='body1' color='textSecondary' component='p' sx={{mt: 0.6}} className='word-wrap text-dark'>
        <strong>{description}</strong>
      </Typography>
      <Button variant='text' size='small' sx={{px: 1}} onClick={() => handleAddAction(event)}>
        + Adicionar ações
      </Button>
      {actions.filter((x) => x.event == event).length == 0 && (
        <Typography variant='body2' color='textSecondary' sx={{pl: 1}} component='p' className='word-wrap text-dark'>
          Não existem ações cadastradas
        </Typography>
      )}
      {actions.map((item, index) => {
        if (item.event != event) {
          return
        }

        /*
         * Recebemos o array COMPLETO de ações, o que quer dizer que aqui teremos não somente as ações que nos interessam no contexto do evento mas como outras ações de outros eventos.
         * Sendo assim precisamos recuperar a "ordem" das ações pelo index de um array filtrado pelo evento em contexto.
         * Isso é necessário pra saber quem é o útilmo e quem é o primeiro da lista para bloquear os botões de mover pra acima e abaixo quando necessário.
         */

        let contextActions = actions.filter((x) => x.event == event)
        let contextItem = contextActions.find((x) => x.guid == item.guid) as CRuleActions
        let contextItemIndex = contextActions.indexOf(contextItem)

        return (
          <Box key={`item-${index}`} component='div' className='d-flex' sx={{p: 1, width: '100%'}}>
            <Box component='div' sx={{minWidth: 110}}>
              <IconButton
                disabled={contextItemIndex == 0 || contextActions[contextItemIndex - 1].event != event}
                title='Mover acima'
                aria-label='move-up'
                color='inherit'
                onClick={() => handleChangeOrder(index, 'up')}
              >
                <KeyboardArrowUpIcon />
              </IconButton>
              <IconButton
                disabled={contextItemIndex == contextActions.length - 1 || contextActions[contextItemIndex + 1].event != event}
                title='Mover abaixo'
                aria-label='move-down'
                color='inherit'
                onClick={() => handleChangeOrder(index, 'down')}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
              <IconButton aria-label='delete' color='error' onClick={() => handleRemoveAction(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
            <Box component='div' sx={{px: 0.5, minWidth: 250}}>
              <Autocomplete
                fullWidth
                disablePortal
                options={Acoes}
                size='small'
                renderInput={(params) => <TextField {...params} label='Ação' />}
                value={item.action}
                onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'action', value)}
              />
            </Box>
            {item.action == 'Atualizar Campo' && (
              <>
                <Box component='div' sx={{px: 0.5, minWidth: 225}}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={Fields}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Campo' />}
                    value={item.campo != null && item.campo != 0 ? Fields.find((op: any) => op.id == item.campo) ?? null : null}
                    onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'campo', value)}
                  />
                </Box>
                {item.campo != null && item.campo != 0 && !IsReadOnlyField(item.campo) && (
                  <Box component='div' sx={{px: 0.5, width: '100%'}}>
                    {GetComponenteValor(item, index)}
                  </Box>
                )}
                {item.campo != null && item.campo != 0 && IsReadOnlyField(item.campo) && (
                  <Box component='div' sx={{px: 0.5, mt: 1.25, width: '100%'}}>
                    <Typography variant='body2' color='textSecondary' sx={{pl: 1}} component='p' className='word-wrap text-muted'>
                      Não é possível atualizar valor deste campo pois o mesmo é um campo "Somente Leitura"
                    </Typography>
                  </Box>
                )}
              </>
            )}
            {item.action == 'Enviar um E-mail' && (
              <>
                <Box component='div' sx={{px: 0.5, minWidth: 150}}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={Atribuicoes}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Enviar para um' />}
                    value={
                      item.atributo01 != null && item.atributo01 != '' ? Atribuicoes.filter((op: any) => op.id == item.atributo01)[0] ?? null : null
                    }
                    onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'atributo01', value)}
                  />
                </Box>
                <Box component='div' sx={{px: 0.5, minWidth: 225}} hidden={item.atributo01 != 'ROLE'}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={Grupos}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Grupo' />}
                    value={item.atributo02 != null && item.atributo02 != '' ? Grupos.filter((op: any) => op.id == item.atributo02)[0] ?? null : null}
                    onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'atributo02', value)}
                  />
                </Box>
                <Box component='div' sx={{px: 0.5, minWidth: 225}} hidden={item.atributo01 != 'USER'}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={Usuarios}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Usuário' />}
                    value={
                      item.atributo02 != null && item.atributo02 != '' ? Usuarios.filter((op: any) => op.id == item.atributo02)[0] ?? null : null
                    }
                    onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'atributo02', value)}
                  />
                </Box>
                {item.atributo02 != null && item.atributo02 != '' && (
                  <>
                    <Box component='div' sx={{px: 0.5, minWidth: 225}}>
                      <Autocomplete
                        fullWidth
                        disablePortal
                        options={Templates}
                        size='small'
                        renderInput={(params) => <TextField {...params} label='Template' />}
                        value={
                          item.atributo03 != null && item.atributo03 != '' ? Templates.filter((op: any) => op.id == item.atributo03)[0] ?? null : null
                        }
                        onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'atributo03', value)}
                      />
                    </Box>
                    <Box component='div' sx={{px: 0.5, width: '100%'}}>
                      <Autocomplete
                        fullWidth
                        disablePortal
                        options={Contas}
                        size='small'
                        renderInput={(params) => <TextField {...params} label='Conta de Envio' />}
                        value={
                          item.atributo04 != null && item.atributo04 != '' ? Contas.filter((op: any) => op.id == item.atributo04)[0] ?? null : null
                        }
                        onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'atributo04', value)}
                      />
                    </Box>
                  </>
                )}
              </>
            )}
            {item.action == 'Executar Procedimento' && (
              <>
                <Box component='div' sx={{px: 0.5, width: '100%'}}>
                  <TextField
                    fullWidth
                    size='small'
                    label='Nome do procedimento'
                    value={item.valor}
                    onChange={(event) => handleOnChangeEvent(index, 'valor', event)}
                  />
                </Box>
                <Box component='div' sx={{px: 0.5, minWidth: 45}}>
                  <IconButton aria-label='question' color='inherit' onClick={() => setModalModeloSQL(true)}>
                    <HelpIcon />
                  </IconButton>
                </Box>
              </>
            )}
            {item.action == 'Inserir Mensagem no Chat' && (
              <>
                <Box component='div' sx={{px: 0.5, width: '100%'}}>
                  <TextField
                    fullWidth
                    size='small'
                    label='Mensagem'
                    value={item.valor}
                    onChange={(event) => handleOnChangeEvent(index, 'valor', event)}
                  />
                </Box>
              </>
            )}
            {item.action == 'Enviar Mensagem Whatsapp' && (
              <>
                <Box component='div' sx={{px: 0.5, minWidth: 225}}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={Fields}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Campo de resgate da pessoa' />}
                    value={item.atributo03 != null && item.atributo03 != '' ? Fields.find((op: any) => op.id == item.atributo03) ?? null : null}
                    onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'atributo03', value)}
                  />
                </Box>
                <Box component='div' sx={{px: 0.5, minWidth: 300}}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={Integracoes.filter((x) => x.idtipoIntegration == 7)}
                    size='small'
                    getOptionLabel={(x) => x.nome}
                    renderInput={(params) => <TextField {...params} label='Integração' />}
                    value={
                      item.atributo01 != null && item.atributo01 != '' ? Integracoes.filter((op: any) => op.id == item.atributo01)[0] ?? null : null
                    }
                    onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'atributo01', value)}
                  />
                </Box>
                <Box component='div' sx={{px: 0.5, width: '100%'}}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={Templates}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Template' />}
                    value={
                      item.atributo02 != null && item.atributo02 != '' ? Templates.filter((op: any) => op.id == item.atributo02)[0] ?? null : null
                    }
                    onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'atributo02', value)}
                  />
                </Box>
              </>
            )}
            {enableGeneralActions && item.action == 'Ocultar Campos' && (
              <>
                <Box component='div' sx={{px: 0.5, width: '100%', overflow: 'auto'}}>
                  <FormControl fullWidth size='small'>
                    <InputLabel id='multiple-field-select'>Campos a ocultar</InputLabel>
                    <Select
                      labelId='multiple-field-select'
                      multiple
                      size='small'
                      value={JSON.parse(item.atributo01 != '' ? item.atributo01 : '[]') as Array<string>}
                      onChange={(event) => handleOnChangeMultipleSelect(index, 'atributo01', event)}
                      input={<OutlinedInput label='Campos a ocultar' />}
                      renderValue={(selected) => {
                        let selectedFields = Fields.filter((x) => selected.find((y) => y == x.id) != null)
                        let selectedFieldsLabels: Array<string> = []

                        for (const item of selectedFields) {
                          selectedFieldsLabels.push(item.label)
                        }

                        return selectedFieldsLabels.join(', ')
                      }}
                    >
                      {Fields.map((field: any, index: number) => (
                        <MenuItem key={`field-${index}`} value={field.id.toString()}>
                          <Checkbox
                            checked={(JSON.parse(item.atributo01 != '' ? item.atributo01 : '[]') as Array<string>).indexOf(field.id.toString()) > -1}
                          />
                          <ListItemText primary={field.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </>
            )}
            {enableGeneralActions && item.action == 'Bloquear Campos' && (
              <>
                <Box component='div' sx={{px: 0.5, width: '100%', overflow: 'auto'}}>
                  <FormControl fullWidth size='small'>
                    <InputLabel id='multiple-field-select'>Campos a bloquear</InputLabel>
                    <Select
                      labelId='multiple-field-select'
                      multiple
                      size='small'
                      value={JSON.parse(item.atributo01 != '' ? item.atributo01 : '[]') as Array<string>}
                      onChange={(event) => handleOnChangeMultipleSelect(index, 'atributo01', event)}
                      input={<OutlinedInput label='Campos a bloquear' />}
                      renderValue={(selected) => {
                        let selectedFields = Fields.filter((x) => selected.find((y) => y == x.id) != null)
                        let selectedFieldsLabels: Array<string> = []

                        for (const item of selectedFields) {
                          selectedFieldsLabels.push(item.label)
                        }

                        return selectedFieldsLabels.join(', ')
                      }}
                    >
                      {Fields.map((field: any, index: number) => (
                        <MenuItem key={`field-${index}`} value={field.id.toString()}>
                          <Checkbox
                            checked={(JSON.parse(item.atributo01 != '' ? item.atributo01 : '[]') as Array<string>).indexOf(field.id.toString()) > -1}
                          />
                          <ListItemText primary={field.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </>
            )}
          </Box>
        )
      })}
    </>
  )
}

export default FieldRuleActionsRow
