import React, {useEffect, useState} from 'react'
import {styled} from '@mui/material/styles'

import {GridCallbackDetails, GridCellParams, GridFilterModel, GridSortItem, GridSortModel, MuiEvent, ptBR} from '@mui/x-data-grid-pro'
import Box from '@mui/material/Box'

import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

import {IconButton, LinearProgress} from '@mui/material'
import {NoRowsOverlay} from '../utility/NoRowsOverlay'
import {StyledDataGrid} from './StyledDataGrid'
import {IGlobalFilterStructure} from '../../../../models/interfaces/IMenuAppHistoryStructure'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'

const StyledFileGrid = styled(StyledDataGrid)(({theme}) => ({
  '& .MuiDataGrid-cell': {
    cursor: 'pointer',
  },
}))

interface IFileGridProps {
  idMenuApp: number
  filters: IGlobalFilterStructure[]
}

export const FileGrid = ({idMenuApp, filters}: IFileGridProps) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [selectedMenuApp, setSelectedMenuApp] = useState<number>(idMenuApp)
  const [loading, setLoading] = useState<boolean>(false)

  const [columns, setColumns] = useState<Array<any>>([])
  const [rows, setRows] = useState<Array<any>>([])
  const [totalRows, setTotalRows] = useState<number>(0)

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  })

  const [order, setOrder] = useState<Array<GridSortItem>>([])
  const [filter, setFilter] = useState<Array<any>>([])

  const handleInit = (
    menuapp: number,
    resetColumns: boolean,
    newOrder: Array<GridSortItem> | undefined = undefined,
    newFilter: Array<any> | undefined = undefined
  ) => {
    setLoading(true)
    setSelectedMenuApp(menuapp)

    if (resetColumns) {
      setRows([])
      setTotalRows(0)
      setColumns(BuildColumns(menuapp == -1 ? 'menuapp' : 'file'))
      setOrder([])
      setFilter([])
      setPaginationModel((prev) => {
        prev.page = 0
        return {...prev}
      })
    }

    let _order = newOrder ?? order
    let _filter = newFilter ?? filter

    let obj: any = {
      RegistroPorPagina: paginationModel.pageSize,
      PaginaAtual: paginationModel.page,
      OrdenarPor: _order.length > 0 ? `${_order[0].field} ${_order[0].sort}` : '',
      item: {
        IDMenuApp: menuapp,
        Filtro: _filter,
        FiltroElemento: filters,
      },
    }

    apiWrapper.post('api/v1/Element/listarArquivos', obj, {}).then((response) => {
      setLoading(false)
      setRows(response.data.data)
      setTotalRows(response.data.total)
    })
  }

  const handleOnCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
    if (selectedMenuApp == -1) {
      handleInit(params.row.ID, true)
    } else {
      window.open(params.row.URL, '_blank')
    }
  }

  const handleOnOrderChange = (orderModel: GridSortModel) => {
    setOrder(orderModel)
    handleInit(selectedMenuApp, false, orderModel)
  }

  const handleOnFilterChange = (filterModel: GridFilterModel) => {
    let filters: any = []

    for (const item of filterModel.items) {
      let values: any[] = []

      if (typeof item.value === 'string') {
        values[0] = item.value
      } else if (item.value != null) {
        let num = 0

        item.value.forEach((item) => {
          values[num] = item
          num++
        })
      }

      filters.push({
        columnField: item.field,
        operatorValue: item.operator != null ? item.operator : 'INVALID',
        value: values,
      })
    }

    setFilter(filters)
    handleInit(selectedMenuApp, false, undefined, filters)
  }

  function BuildColumns(mode: string) {
    let columns: Array<any> = []

    if (mode == 'menuapp') {
      columns = [
        {
          field: 'ID',
          type: 'number',
          headerName: '',
          headerAlign: 'left',
          align: 'left',
          width: 25,
          hideable: false,
          filterable: false,
          sortable: false,
          pinnable: false,
          disableColumnMenu: true,
          renderCell: (params: any) => (
            <IconButton size='small' disabled={true}>
              <FolderOpenIcon fontSize='small' />
            </IconButton>
          ),
        },
        {
          field: 'Titulo',
          type: 'string',
          headerName: 'App',
          headerAlign: 'left',
          align: 'left',
          width: 250,
          hideable: false,
          filterable: true,
          sortable: true,
          pinnable: false,
        },
        {
          field: 'Arquivos',
          type: 'number',
          headerName: 'Arquivos',
          headerAlign: 'center',
          align: 'center',
          width: 70,
          hideable: false,
          filterable: false,
          sortable: true,
          pinnable: false,
        },
        {
          field: 'UltimaModificacao',
          type: 'date',
          headerName: 'Última Modificação',
          headerAlign: 'center',
          align: 'center',
          width: 160,
          hideable: false,
          filterable: false,
          sortable: true,
          pinnable: false,
          valueGetter: ({value}) => {
            if (value == null) {
              return 'N/A'
            } else {
              return new Date(value)
            }
          },
        },
        {
          field: 'ModificacaoPor',
          type: 'string',
          headerName: 'Modificado Por',
          headerAlign: 'right',
          align: 'right',
          width: 225,
          hideable: false,
          filterable: false,
          sortable: true,
          pinnable: false,
        },
      ]
    } else if (mode == 'file') {
      columns = [
        {
          field: 'URL',
          type: 'string',
          headerName: '',
          headerAlign: 'left',
          align: 'left',
          width: 25,
          hideable: false,
          filterable: false,
          sortable: false,
          pinnable: false,
          disableColumnMenu: true,
          renderHeader: (params: any) => {
            if (idMenuApp != -1) {
              return <></>
            }

            return (
              <IconButton title='Voltar' size='small' onClick={() => handleInit(-1, true)}>
                <ArrowBackIcon fontSize='small' />
              </IconButton>
            )
          },
          renderCell: (params: any) => (
            <IconButton title='Baixar' size='small' disabled={true}>
              <InsertDriveFileIcon fontSize='small' />
            </IconButton>
          ),
        },
        {
          field: 'NomeOriginal',
          type: 'string',
          headerName: 'Nome',
          headerAlign: 'left',
          align: 'left',
          width: 300,
          hideable: false,
          filterable: true,
          sortable: true,
          pinnable: false,
        },
        {
          field: 'Tamanho',
          type: 'number',
          headerName: 'Tamanho',
          headerAlign: 'center',
          align: 'center',
          width: 90,
          hideable: false,
          filterable: false,
          sortable: true,
          pinnable: false,
          valueGetter: ({value}) => {
            if (value == null) {
              return 'N/A'
            } else {
              return (value / 1024 / 1024).toFixed(2) + ' MB'
            }
          },
        },
        {
          field: 'DataCriacao',
          type: 'date',
          headerName: 'Data de Criação',
          headerAlign: 'center',
          align: 'center',
          width: 150,
          hideable: false,
          filterable: false,
          sortable: true,
          pinnable: false,
          valueGetter: ({value}) => {
            if (value == null) {
              return 'N/A'
            } else {
              return new Date(value)
            }
          },
        },
        {
          field: 'Autor',
          type: 'string',
          headerName: 'Autor',
          headerAlign: 'right',
          align: 'right',
          width: 175,
          hideable: false,
          filterable: false,
          sortable: true,
          pinnable: false,
        },
      ]
    }

    return columns
  }

  useEffect(() => {
    handleInit(idMenuApp, true)
  }, [])

  useEffect(() => {
    handleInit(selectedMenuApp, false)
  }, [paginationModel])

  return (
    <Box component='div' sx={{display: 'flex', height: '100%', width: '100%'}}>
      <Box component='div' sx={{flexGrow: 1}}>
        <StyledFileGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          components={{
            NoRowsOverlay: NoRowsOverlay,
            NoResultsOverlay: NoRowsOverlay,
            LoadingOverlay: LinearProgress,
          }}
          disableRowSelectionOnClick
          pagination
          paginationMode='server'
          sortingMode='server'
          filterMode='server'
          loading={loading}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rows={rows && rows.length > 0 ? rows : []}
          columns={columns}
          rowCount={totalRows}
          rowHeight={38}
          columnHeaderHeight={38}
          getRowId={(row) => row.ID}
          onCellClick={handleOnCellClick}
          onSortModelChange={(newOrder) => handleOnOrderChange(newOrder)}
          onFilterModelChange={(newFilter) => handleOnFilterChange(newFilter)}
        />
      </Box>
    </Box>
  )
}

export default FileGrid
