import {IKanbanCardConfig, IMenuAppJsonField} from '../interfaces/IMenuAppJsonField'

export class CKanbanCardConfig implements IKanbanCardConfig {
  area1field: number
  area2field: number
  area3field: number
  area4field: number
  area5field: number
  ids: Array<number>

  HasMappedFields(): boolean {
    if (this.area1field != 0 || this.area2field != 0 || this.area3field != 0 || this.area4field != 0 || this.area5field != 0) return true

    return false
  }

  constructor() {
    this.area1field = 0
    this.area2field = 0
    this.area3field = 0
    this.area4field = 0
    this.area5field = 0
    this.ids = []
  }
}

export class CMenuAppJsonFields implements IMenuAppJsonField {
  kanban: CKanbanCardConfig

  constructor() {
    this.kanban = new CKanbanCardConfig()
  }
}
