import {useEffect, useState} from 'react'
import {CRuleActions} from '../../../../models/classes/CRuleActions'
import {Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material'
import {DateTimePicker, DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import ptBRLocale from 'date-fns/locale/pt-BR'
import moment from 'moment'
import {NumericFormatWithDecimal} from '../utility/numericFormatDecimal'
import ModalModeloSQL from '../modals/modalModeloSQL'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import FieldRuleActionsRow from './fieldRuleActionsRow'
import uuid from 'react-uuid'
import {handleApplyClampedValueToArrayedState} from '../../../../models/wrappers/handlers'

interface IFieldRuleTaskActions {
  tipo: string
  accessCode: string
  actions: Array<CRuleActions>
  setActions: React.Dispatch<React.SetStateAction<CRuleActions[]>>
  callBack: (response: any) => void
}

const FieldRuleActions = ({tipo, accessCode, actions, setActions, callBack}: IFieldRuleTaskActions) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [Opened, setOpened] = useState<boolean>(false)
  const [Fields, setFields] = useState<Array<any>>([])
  const [SingleSelectOptions, setSingleSelectOptions] = useState<Array<any>>([])
  const [Grupos, setGrupos] = useState<Array<any>>([])
  const [Usuarios, setUsuarios] = useState<Array<any>>([])
  const [Templates, setTemplates] = useState<Array<any>>([])
  const [Contas, setContas] = useState<Array<any>>([])
  const [Integracoes, setIntegracoes] = useState<Array<any>>([])

  const Acoes: Array<string> = [
    'Atualizar Campo',
    'Enviar um E-mail',
    'Executar Procedimento',
    'Inserir Mensagem no Chat',
    'Enviar Mensagem Whatsapp',
    'Ocultar Campos',
    'Bloquear Campos',
  ]
  const Values: any = {
    boolean: ['Verdadeiro', 'Falso', 'Nenhum'],
  }
  const Atribuicoes: Array<any> = [
    {id: 'ROLE', label: 'Grupo'},
    {id: 'USER', label: 'Usuário'},
  ]

  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  const handleInit = () => {
    setOpened(true)

    //* Forçamos um guid inicial para caso as ações ainda não tenham um (todas antes dessa alteração)
    setActions((prev: Array<CRuleActions>) => {
      for (let i = 0; i < prev.length; i++) {
        if (prev[i].guid == null || prev[i].guid == '') {
          prev[i].guid = uuid()
        }
      }

      return [...prev]
    })

    apiWrapper.get('api/v1/System/listarPessoas?item.Query=').then((response) => {
      setUsuarios(response.data.data)
    })

    apiWrapper.get('api/v1/System/listarRoles?item.Query=').then((response) => {
      setGrupos(response.data.data)
    })

    apiWrapper.get('api/v1/MenuAppMailTemplate/listar').then((response) => {
      setTemplates(response.data.data)
    })

    apiWrapper.get('api/v1/MenuAppMailConfig/listar').then((response) => {
      setContas(response.data.data)
    })

    apiWrapper.get('api/v1/MenuAppIntegration/listar').then((response) => {
      setIntegracoes(response.data.data)
    })

    apiWrapper.get(`api/v1/MenuAppFields/listarPorMenuApp?item.AccessCode=${accessCode}`).then((response) => {
      let fields: Array<any> = response.data.data.filter((item: any) => {
        return item.campo.toLowerCase() != 'id'
      })

      let _singleSelectOptions: any = []

      actions.forEach((filter, index) => {
        _singleSelectOptions[index] = []
      })

      setSingleSelectOptions(_singleSelectOptions)

      actions.forEach((taskAction, index) => {
        let actionField = fields.find((field: any) => field.id == taskAction.campo)

        if (actionField == null) {
          return
        }

        if (actionField.tipo == 'singleSelect') {
          handleOnInputChangeAutoComplete(taskAction, index, null, taskAction.valor)
        }
      })

      setFields(fields)
    })
  }

  const handleClose = (applyFilters: boolean) => {
    let obj: any = {}

    if (applyFilters) {
      obj.apply = true
    }

    setOpened(false)
    handleCallBack(obj)
  }

  const handleAddAction = (event: string) => {
    let newAction = new CRuleActions()
    newAction.event = event
    setActions([...actions, newAction])
    setSingleSelectOptions([...SingleSelectOptions, []])
  }

  const handleRemoveAction = (index: number) => {
    setActions((prev: Array<CRuleActions>) => {
      prev.splice(index, 1)
      return [...prev]
    })
    setSingleSelectOptions(SingleSelectOptions.filter((item, i) => i != index))
  }

  const handleOnChangeDate = (index: number, key: string, value: any) => {
    setActions((prev: Array<CRuleActions>) => {
      prev[index][key] = moment(value).format('YYYY-MM-DD').toString()
      return [...prev]
    })
  }

  const handleOnChangeEvent = (index: number, key: string, event: any) => {
    setActions((prev: Array<CRuleActions>) => {
      prev[index][key] = event.target.value
      return [...prev]
    })
  }

  const handleOnChangeDecimalEvent = (index: number, key: string, event: any) => {
    setActions((prev: Array<CRuleActions>) => {
      let valueString = event.target.value

      if (valueString != '') {
        valueString.replace('.', '').replace(',', '.')
      }

      let newValue = parseFloat(valueString)

      if (!isNaN(newValue)) {
        if (newValue > 9999999.99) {
          newValue = 9999999.99
        }

        prev[index][key] = newValue.toString()
      }

      return [...prev]
    })
  }

  const handleOnOpenAutoComplete = (item: CRuleActions, index: number, event: any) => {
    if (event.target.value == '' || event.target.value == null) {
      handleOnInputChangeAutoComplete(item, index, '')
    }
  }

  const handleOnInputChangeAutoComplete = (item: CRuleActions, index: number, value: any, id: any = null) => {
    if (item.campo == null || item.campo == 0) {
      return
    }

    let url: string = ''

    if (id == null) {
      url = `/api/v1/Dynamic/listarSingleSelect?item.AccessCode=${accessCode}&item.IDMenuAppField=${item.campo}&item.Query=${value}&RegistroPorPagina=15`
    } else {
      url = `/api/v1/Dynamic/listarSingleSelect?item.AccessCode=${accessCode}&item.IDMenuAppField=${item.campo}&item.IDs=${id}&RegistroPorPagina=15`
    }

    apiWrapper.get(url).then((response) => {
      setSingleSelectOptions((prev: any) => {
        prev[index] = response.data.data
        return [...prev]
      })

      if (id != null) {
        setActions((prev: Array<CRuleActions>) => {
          prev[index]['valor'] = id.toString()
          return [...prev]
        })
      }
    })
  }

  const handleOnChangeAutoComplete = (index: number, key: string, value: any, boolean: boolean = false) => {
    setActions((prev: Array<CRuleActions>) => {
      if (key == 'campo') {
        prev[index]['valor'] = ''
      }

      if (key == 'action') {
        prev[index]['campo'] = undefined
        prev[index]['valor'] = ''
        prev[index]['atributo01'] = ''
        prev[index]['atributo02'] = ''
        prev[index]['atributo03'] = ''
        prev[index]['atributo04'] = ''
      }

      if (key == 'valor' || key == 'atributo01' || key == 'atributo02' || key == 'atributo03' || key == 'atributo04') {
        if (boolean)
          prev[index][key] = value ?? ''
        else
          prev[index][key] = value?.id?.toString() ?? ''
        return [...prev]
      }

      if (key == 'campo') {
        if (value == null) {
          setSingleSelectOptions((prev: any) => {
            prev[index] = []
            return [...prev]
          })
        }

        prev[index][key] = value?.id ?? 0
        return [...prev]
      }

      prev[index][key] = value
      return [...prev]
    })
  }

  const handleOnChangeMultipleSelect = (index: number, key: string, event: any) => {
    const {
      target: {value},
    } = event

    setActions((prev: Array<CRuleActions>) => {
      prev[index][key] = JSON.stringify(typeof value === 'string' ? value.split(',') : value)
      return [...prev]
    })
  }

  const handleChangeOrder = (index: number, move: 'up' | 'down') => {
    //* Mudamos a ordem FISICA do objeto no array, assim quando o sistema o recuperar já receberá na ordem definida pelo usuário na regra podendo seguir o fluxo normalmente

    setActions((prev: Array<CRuleActions>) => {
      let item = prev[index]
      let inc = move === 'up' ? -1 : 1

      const newIndex = index + inc
      prev.splice(index, 1) //* Retira
      prev.splice(newIndex, 0, item) //* Re-insere

      return [...prev]
    })
  }

  function IsReadOnlyField(idMenuAppField: number) {
    let field = Fields.find((x) => x.id == idMenuAppField)

    return field != null && field.somenteLeitura === true
  }

  function GetComponenteValor(item: CRuleActions, index: number) {
    if (Fields.length == 0) {
      return <></>
    }

    let field: any = Fields.find((field: any) => field.id == item.campo)

    if (field == null) {
      return <></>
    }

    switch (field.tipo) {
      case 'boolean':
        return (
          <Autocomplete
            fullWidth
            disablePortal
            options={Values.boolean}
            size='small'
            renderInput={(params) => <TextField {...params} label='Valor' />}
            value={item.valor}
            onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'valor', value, true)}
          />
        )
      case 'string':
      case 'special':
      case 'textArea':
      case 'geolocation':
      case 'formulaSQL':
        return <TextField fullWidth size='small' label='Valor' value={item.valor} onChange={(event) => handleOnChangeEvent(index, 'valor', event)} />
      case 'number':
        return (
          <TextField
            fullWidth
            size='small'
            label='Valor'
            type='number'
            InputLabelProps={{shrink: true}}
            value={item.valor}
            onChange={(event) => handleOnChangeEvent(index, 'valor', event)}
          />
        )
      case 'decimal':
        return (
          <TextField
            fullWidth
            size='small'
            label='Valor'
            InputLabelProps={{shrink: true}}
            value={item.valor}
            onChange={(event) =>
              handleApplyClampedValueToArrayedState(
                setActions,
                index,
                'valor',
                parseFloat(event.target.value != '' ? event.target.value : '0'),
                -9999999.99,
                9999999.99,
                2
              )
            }
            InputProps={{
              inputComponent: NumericFormatWithDecimal as any,
            }}
          />
        )
      case 'geocode':
        return (
          <TextField
            fullWidth
            size='small'
            label='Valor'
            InputLabelProps={{shrink: true}}
            value={item.valor}
            onChange={(event) =>
              handleApplyClampedValueToArrayedState(
                setActions,
                index,
                'valor',
                parseFloat(event.target.value != '' ? event.target.value : '0'),
                -999.999999,
                999.999999,
                6
              )
            }
            InputProps={{
              inputComponent: NumericFormatWithDecimal as any,
            }}
          />
        )
      case 'date':
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
            <DesktopDatePicker
              label='Valor'
              value={item.valor != '' ? new Date(item.valor as string) : null}
              onChange={(date) => handleOnChangeDate(index, 'valor', date)}
              sx={{width: 300}}
            />
          </LocalizationProvider>
        )
      case 'dateTime':
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
            <DateTimePicker
              label='Valor'
              value={item.valor != '' ? new Date(item.valor as string) : null}
              onChange={(date) => handleOnChangeDate(index, 'valor', date)}
              sx={{width: 300}}
            />
          </LocalizationProvider>
        )
      case 'singleSelect':
        return (
          <Autocomplete
            fullWidth
            disablePortal
            options={SingleSelectOptions[index]}
            size='small'
            renderInput={(params) => <TextField {...params} label='Valor' />}
            value={item.valor != null && item.valor != '' ? SingleSelectOptions[index]?.find((op: any) => op.id == item.valor) ?? null : null}
            onOpen={(event) => handleOnOpenAutoComplete(item, index, event)}
            onChange={(event, value, reason) => handleOnChangeAutoComplete(index, 'valor', value)}
            onInputChange={(event, value) => handleOnInputChangeAutoComplete(item, index, value)}
            filterOptions={(x) => x}
          />
        )
      default:
        return <></>
    }
  }

  function GetTypeTitle(type: string) {
    switch (type) {
      case 'TASK':
        return 'Ações de Tarefa de Aprovação'
      case 'CONFIRMATION':
        return 'Ações de Confirmação'
      case 'GENERAL':
        return 'Ações Gerais'
      default:
        return ''
    }
  }

  /* MODAL MODELO SQL */

  const [modalModeloSQL, setModalModeloSQL] = useState<boolean>(false)

  const modalModeloCallback = (data?: any) => {
    setModalModeloSQL(false)
  }

  useEffect(() => {
    handleInit()
  }, [])

  //TODO: Componentizar os items abaixo, pois a replicação de código ta INSANA 😨

  return (
    <Dialog open={Opened} fullWidth={true} maxWidth='xl'>
      <DialogTitle>{GetTypeTitle(tipo)}</DialogTitle>
      <DialogContent dividers={true} sx={{minHeight: 300}}>
        <Box component='div'>
          {tipo == 'TASK' && (
            <FieldRuleActionsRow
              event='APROVACAO'
              description='SE A TAREFA FOR APROVADA'
              Fields={Fields}
              Acoes={Acoes}
              Atribuicoes={Atribuicoes}
              Grupos={Grupos}
              Usuarios={Usuarios}
              Templates={Templates}
              Contas={Contas}
              Integracoes={Integracoes}
              actions={actions}
              GetComponenteValor={GetComponenteValor}
              handleChangeOrder={handleChangeOrder}
              handleAddAction={handleAddAction}
              handleRemoveAction={handleRemoveAction}
              handleOnChangeAutoComplete={handleOnChangeAutoComplete}
              handleOnChangeEvent={handleOnChangeEvent}
              handleOnChangeMultipleSelect={handleOnChangeMultipleSelect}
              IsReadOnlyField={IsReadOnlyField}
              setModalModeloSQL={setModalModeloSQL}
            />
          )}
          {tipo == 'CONFIRMATION' && (
            <FieldRuleActionsRow
              event='SIM'
              description='SE SIM'
              Fields={Fields}
              Acoes={Acoes}
              Atribuicoes={Atribuicoes}
              Grupos={Grupos}
              Usuarios={Usuarios}
              Templates={Templates}
              Contas={Contas}
              Integracoes={Integracoes}
              actions={actions}
              GetComponenteValor={GetComponenteValor}
              handleChangeOrder={handleChangeOrder}
              handleAddAction={handleAddAction}
              handleRemoveAction={handleRemoveAction}
              handleOnChangeAutoComplete={handleOnChangeAutoComplete}
              handleOnChangeEvent={handleOnChangeEvent}
              handleOnChangeMultipleSelect={handleOnChangeMultipleSelect}
              IsReadOnlyField={IsReadOnlyField}
              setModalModeloSQL={setModalModeloSQL}
            />
          )}
          {tipo == 'GENERAL' && (
            <FieldRuleActionsRow
              event='AOCRIAR'
              description='AO CRIAR REGISTRO'
              enableGeneralActions={true}
              Fields={Fields}
              Acoes={Acoes}
              Atribuicoes={Atribuicoes}
              Grupos={Grupos}
              Usuarios={Usuarios}
              Templates={Templates}
              Contas={Contas}
              Integracoes={Integracoes}
              actions={actions}
              GetComponenteValor={GetComponenteValor}
              handleChangeOrder={handleChangeOrder}
              handleAddAction={handleAddAction}
              handleRemoveAction={handleRemoveAction}
              handleOnChangeAutoComplete={handleOnChangeAutoComplete}
              handleOnChangeEvent={handleOnChangeEvent}
              handleOnChangeMultipleSelect={handleOnChangeMultipleSelect}
              IsReadOnlyField={IsReadOnlyField}
              setModalModeloSQL={setModalModeloSQL}
            />
          )}
        </Box>
        <Box component='div'>
          {tipo == 'TASK' && (
            <FieldRuleActionsRow
              event='REPROVACAO'
              description='SE A TAREFA FOR REPROVADA'
              Fields={Fields}
              Acoes={Acoes}
              Atribuicoes={Atribuicoes}
              Grupos={Grupos}
              Usuarios={Usuarios}
              Templates={Templates}
              Contas={Contas}
              Integracoes={Integracoes}
              actions={actions}
              GetComponenteValor={GetComponenteValor}
              handleChangeOrder={handleChangeOrder}
              handleAddAction={handleAddAction}
              handleRemoveAction={handleRemoveAction}
              handleOnChangeAutoComplete={handleOnChangeAutoComplete}
              handleOnChangeEvent={handleOnChangeEvent}
              handleOnChangeMultipleSelect={handleOnChangeMultipleSelect}
              IsReadOnlyField={IsReadOnlyField}
              setModalModeloSQL={setModalModeloSQL}
            />
          )}
          {tipo == 'CONFIRMATION' && (
            <FieldRuleActionsRow
              event='NAO'
              description='SE NÃO'
              Fields={Fields}
              Acoes={Acoes}
              Atribuicoes={Atribuicoes}
              Grupos={Grupos}
              Usuarios={Usuarios}
              Templates={Templates}
              Contas={Contas}
              Integracoes={Integracoes}
              actions={actions}
              GetComponenteValor={GetComponenteValor}
              handleChangeOrder={handleChangeOrder}
              handleAddAction={handleAddAction}
              handleRemoveAction={handleRemoveAction}
              handleOnChangeAutoComplete={handleOnChangeAutoComplete}
              handleOnChangeEvent={handleOnChangeEvent}
              handleOnChangeMultipleSelect={handleOnChangeMultipleSelect}
              IsReadOnlyField={IsReadOnlyField}
              setModalModeloSQL={setModalModeloSQL}
            />
          )}
          {tipo == 'GENERAL' && (
            <FieldRuleActionsRow
              event='AOEDITAR'
              description='AO EDITAR REGISTRO'
              enableGeneralActions={true}
              Fields={Fields}
              Acoes={Acoes}
              Atribuicoes={Atribuicoes}
              Grupos={Grupos}
              Usuarios={Usuarios}
              Templates={Templates}
              Contas={Contas}
              Integracoes={Integracoes}
              actions={actions}
              GetComponenteValor={GetComponenteValor}
              handleChangeOrder={handleChangeOrder}
              handleAddAction={handleAddAction}
              handleRemoveAction={handleRemoveAction}
              handleOnChangeAutoComplete={handleOnChangeAutoComplete}
              handleOnChangeEvent={handleOnChangeEvent}
              handleOnChangeMultipleSelect={handleOnChangeMultipleSelect}
              IsReadOnlyField={IsReadOnlyField}
              setModalModeloSQL={setModalModeloSQL}
            />
          )}
        </Box>
        <Box component='div'>
          {tipo == 'TASK' && (
            <FieldRuleActionsRow
              event='APROVACAORESSALVA'
              description='SE A TAREFA FOR APROVADA COM RESSALVA'
              Fields={Fields}
              Acoes={Acoes}
              Atribuicoes={Atribuicoes}
              Grupos={Grupos}
              Usuarios={Usuarios}
              Templates={Templates}
              Contas={Contas}
              Integracoes={Integracoes}
              actions={actions}
              GetComponenteValor={GetComponenteValor}
              handleChangeOrder={handleChangeOrder}
              handleAddAction={handleAddAction}
              handleRemoveAction={handleRemoveAction}
              handleOnChangeAutoComplete={handleOnChangeAutoComplete}
              handleOnChangeEvent={handleOnChangeEvent}
              handleOnChangeMultipleSelect={handleOnChangeMultipleSelect}
              IsReadOnlyField={IsReadOnlyField}
              setModalModeloSQL={setModalModeloSQL}
            />
          )}
        </Box>

        {/* MODAL MODELO SQL */}
        {modalModeloSQL && <ModalModeloSQL tipoModelo='Validação' callBack={modalModeloCallback} />}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='inherit' onClick={() => handleClose(true)}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FieldRuleActions
