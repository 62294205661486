import React, {useEffect, useState} from 'react'
import {useThemeMode} from '../../../../../_metronic/partials'
import {Box, Theme, ThemeProvider} from '@mui/material'
import {globalDarkMuiTheme, globalLightMuiTheme} from './globalMuiTheme'

interface ProgressBarProps {
  value: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({value}) => {
  const {mode} = useThemeMode()
  const [muiTheme, setMuiTheme] = useState<Theme>(mode == 'light' ? globalLightMuiTheme : globalDarkMuiTheme)

  const handleUpdateTheme = () => {
    setMuiTheme((mode as any) == 'dark' ? globalDarkMuiTheme : globalLightMuiTheme)
  }

  useEffect(() => {
    handleUpdateTheme()
  }, [mode])

  const getColor = (percentage: number): string => {
    /*
    const red = (255 * (100 - percentage)) / 100
    const green = (255 * percentage) / 100
    return `rgb(${red}, ${green}, 0)`
    */
   return `rgb(0 255 0 / 43%)`
  }

  return (
    <ThemeProvider theme={muiTheme}>
      <Box component='div' sx={{width: '100%', borderRadius: '4px', padding: '4px'}}>
        <Box
          component='div'
          sx={{
            width: `${value}%`,
            backgroundColor: getColor(value),
            borderRadius: '4px',
            color: (mode as any) == 'dark' ? '#fff' : '#000',
            textAlign: 'center',
          }}
        >
          {`${value}%`}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default ProgressBar
