import {AccountInfo, IPublicClientApplication} from '@azure/msal-browser'
import {Api, GetTokenFromMSAL} from '../Api'
import {IUserMSALCredential, IUserDefaultCredential} from '../models/UserModels'

export const UserServiceMSAL = (account: AccountInfo, instance: IPublicClientApplication) => {
  return {
    authenticate: (obj: IUserMSALCredential) => {
      return Api.post('api/v1/User/authenticate', obj)
    },
    validarEstruturaDoUsuario: async () => {
      let token = await GetTokenFromMSAL(account, instance)
      return Api.post(
        'api/v1/User/validarEstruturaDoUsuario',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    },
  }
}

export const UserServiceDefault = () => {
  return {
    authenticate: (obj: IUserDefaultCredential) => {
      return Api.post('api/v1/User/authenticateEmailSenha', obj)
    },
    loadUser: (api_token: string) => {
      return Api.get('api/v1/User/carregarUsuario', {headers: {Authorization: `Bearer ${api_token}`}})
    },
  }
}
