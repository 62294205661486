import React, {FC, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {SearchComponent} from '../../../assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Api} from '../../../../app/modules/api/Api'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../app/modules/api/ApiWrapper'

const Search: FC = () => {
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)

  const [search, setSearch] = useState<string>('')
  const [menuApps, setMenuApps] = useState<Array<any>>([])

  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const processs = (search: SearchComponent) => {
    setTimeout(function () {
      search.complete()
    }, 1000)
  }

  const clear = (search: SearchComponent) => {
    suggestionsElement.current!.classList.remove('d-none')
    resultsElement.current!.classList.add('d-none')
    emptyElement.current!.classList.add('d-none')

    setSearch('')
  }

  const getMenuApps = async () => {
    apiWrapper.get('api/v1/MenuApp/listarPorTitulo?item.Query=' + search + '&item.ReturnObject=true').then((response) => {
      setMenuApps(response.data.data)

      if (response.data.data.length == 0) {
        suggestionsElement.current!.classList.add('d-none')
        resultsElement.current!.classList.add('d-none')
        emptyElement.current!.classList.remove('d-none')
      } else {
        if (search == '') {
          suggestionsElement.current!.classList.remove('d-none')
          resultsElement.current!.classList.add('d-none')
          emptyElement.current!.classList.add('d-none')
          return
        }

        suggestionsElement.current!.classList.add('d-none')
        resultsElement.current!.classList.remove('d-none')
        emptyElement.current!.classList.add('d-none')
      }
    })
  }

  useEffect(() => {
    const searchObject = SearchComponent.createInsance('#kt_header_search')
    searchObject!.on('kt.search.process', processs)
    searchObject!.on('kt.search.clear', clear)

    getMenuApps()
  }, [])

  useEffect(() => {
    getMenuApps()
  }, [search])

  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div className='d-flex align-items-center' data-kt-search-element='toggle' id='kt_header_search_toggle'>
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
          </div>
        </div>

        <div data-kt-search-element='content' className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'>
          <div ref={wrapperElement} data-kt-search-element='wrapper'>
            <form data-kt-search-element='form' className='w-100 position-relative mb-3' autoComplete='off'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />

              <input
                type='text'
                className='form-control form-control-flush ps-10'
                name='search'
                placeholder='Localizar App...'
                data-kt-search-element='input'
                onChange={(e) => setSearch(e.target.value)}
              />

              <span className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1' data-kt-search-element='spinner'>
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>

              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2 svg-icon-lg-1 me-0' />
              </span>
            </form>

            <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
              <div className='scroll-y mh-200px mh-lg-350px'>
                <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
                  Menu Apps
                </h3>
                {menuApps &&
                  menuApps.length > 0 &&
                  menuApps.map((app: any, index: number) => {
                    return (
                      <div key={`menuapp-result-${index}`} className='d-flex align-items-center mb-5'>
                        <div className='symbol symbol-40px me-4'>
                          <span className='symbol-label bg-light'>
                            <KTSVG path='/media/icons/duotune/general/gen017.svg' className='svg-icon-2 svg-icon-primary' />
                          </span>
                        </div>

                        <div className='d-flex flex-column'>
                          <Link
                            to={`/pages/_dynamicTabs/${app.accessCode}`}
                            className='fs-6 text-gray-800 text-hover-primary fw-bold'
                            title={`AccessCode: /${app.accessCode}`}
                          >
                            {app.titulo}
                          </Link>
                          <span className='fs-7 text-muted fw-bold'>{app.descricao}</span>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>

            <div ref={suggestionsElement} className='mb-4' data-kt-search-element='main'>
              <div className='scroll-y mh-200px mh-lg-350px'>
                <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
                  Sugeridos
                </h3>
                {menuApps &&
                  menuApps.length > 0 &&
                  menuApps.map((app: any, index: number) => {
                    return (
                      <div key={`menuapp-result-${index}`} className='d-flex align-items-center mb-5'>
                        <div className='symbol symbol-40px me-4'>
                          <span className='symbol-label bg-light'>
                            <KTSVG path='/media/icons/duotune/general/gen017.svg' className='svg-icon-2 svg-icon-primary' />
                          </span>
                        </div>

                        <div className='d-flex flex-column'>
                          <Link
                            to={`/pages/_dynamicTabs/${app.accessCode}`}
                            className='fs-6 text-gray-800 text-hover-primary fw-bold'
                            title={`AccessCode: /${app.accessCode}`}
                          >
                            {app.titulo}
                          </Link>
                          <span className='fs-7 text-muted fw-bold'>{app.descricao}</span>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>

            <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <KTSVG path='/media/icons/duotune/general/gen017.svg' className='svg-icon-4x opacity-50' />
              </div>

              <div className='pb-15 fw-bold'>
                <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
                <div className='text-muted fs-7'>Please try again with a different query</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Search}
