import {useEffect, useState} from 'react'
import {useMsal} from '@azure/msal-react'
import StyledDialog from '../styled/StyledDialog'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import DialogActions from '@mui/material/DialogActions'
import StyledButton from '../styled/StyledButton'

import CancelIcon from '@mui/icons-material/Cancel'
import SaveIcon from '@mui/icons-material/Save'
import TransferList from '../utility/TransferList'
import {SingleSelectResult} from '../../../../modules/api/models/Result/SingleSelectResult'
import {CMenuAppJsonFields} from '../../../../models/classes/CMenuAppJsonField'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import {toast} from 'react-toastify'

interface IKanbanconfigModal {
  accessCode: string
  idmenuAppField: number
  callback(data?: any): void
}

export const KanbanconfigModal = ({accessCode, idmenuAppField, callback}: IKanbanconfigModal) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [loading, setLoading] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)

  const [menuApp, setMenuApp] = useState<any>(null)
  const [menuAppJsonConfig, setMenuAppJsonConfig] = useState<CMenuAppJsonFields>(new CMenuAppJsonFields())

  const [left, setLeft] = useState<Array<SingleSelectResult>>([])
  const [right, setRight] = useState<Array<SingleSelectResult>>([])

  const handleCallback = (data?: any) => {
    callback(data)
  }

  const handleInit = async () => {
    setLoading(true)

    let menuAppRequest = await apiWrapper.get(`api/v1/MenuApp/carregarPorAccessCode?item.AccessCode=${accessCode}`)
    let _menuApp = menuAppRequest.data.data
    setMenuApp(_menuApp)

    let parsed = new CMenuAppJsonFields()

    if (_menuApp.jsonField != null && _menuApp.jsonField != '') {
      parsed = JSON.parse(_menuApp.jsonField) as CMenuAppJsonFields

      if (parsed.kanban.ids == null) {
        parsed.kanban.ids = []
      }
    }

    setMenuAppJsonConfig(parsed)

    let obj: any = {
      item: {
        AccessCode: accessCode,
        IDMenuAppField: idmenuAppField,
        IgnoreConfig: true,
      },
    }

    let lanesRequest = await apiWrapper.post('api/v1/Dynamic/carregarRaias', obj, {})
    let lanes = lanesRequest.data.data as Array<{ID: number; Label: string}>

    let _left: Array<SingleSelectResult> = []
    let _right: Array<SingleSelectResult> = []

    lanes.map((item, index) => {
      let isRight = parsed.kanban.ids.find((x) => x == item.ID)

      if (isRight != null) {
        _right.push({id: item.ID, label: item.Label})
      } else {
        _left.push({id: item.ID, label: item.Label})
      }
    })

    setLeft(_left)
    setRight(_right)

    setLoading(false)
  }

  const handleClose = async () => {
    setSaving(true)

    menuApp.jsonField = JSON.stringify(menuAppJsonConfig)

    let obj = {
      item: {
        MenuApp: menuApp,
        CriarTabela: false,
      },
    }

    await apiWrapper.put('api/v1/MenuApp/salvar', obj)
    toast.success('Configuração salva com sucesso!')

    handleCallback({apply: true})
  }

  const handleUpdateConfig = () => {
    setMenuAppJsonConfig((prev) => {
      prev.kanban.ids = right.map((item, index) => item.id)
      return {...prev}
    })
  }

  useEffect(() => {
    handleInit()
  }, [])

  useEffect(() => {
    handleUpdateConfig()
  }, [left, right])

  return (
    <StyledDialog open fullWidth maxWidth='md'>
      <DialogTitle>Configuração do Kanban</DialogTitle>
      <DialogContent dividers sx={{display: 'grid'}}>
        {loading && (
          <Box component='div' className='d-flex justify-content-center'>
            <CircularProgress />
          </Box>
        )}
        <FormControl sx={{justifyContent: 'center', alignItems: 'center'}} component='fieldset' variant='standard'>
          <TransferList left={left} right={right} setLeft={setLeft} setRight={setRight} />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <StyledButton variant='contained' color='inherit' disabled={saving} startIcon={<CancelIcon />} onClick={(event) => handleCallback()}>
          Fechar
        </StyledButton>
        <StyledButton variant='contained' color='success' disabled={saving} startIcon={<SaveIcon />} onClick={(event) => handleClose()}>
          {saving ? 'Salvando' : 'Salvar'}
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  )
}

export default KanbanconfigModal
