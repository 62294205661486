import {useMsal} from '@azure/msal-react'
import {useState} from 'react'
import StyledDialog from '../../../pages/_dynamic/components/styled/StyledDialog'
import {Box, CircularProgress, DialogContent, FormControl, Typography} from '@mui/material'
import StyledButton from '../../../pages/_dynamic/components/styled/StyledButton'
import {loginRequest} from '../config/msalConfig'
import {useThemeMode} from '../../../../_metronic/partials'
import {useGlobalAuthentication} from '../Authentication'

interface IPresence {
  callback: () => void
}

export const Presence = ({callback}: IPresence) => {
  const {mode} = useThemeMode()
  const {instance} = useMsal()
  const {logout} = useGlobalAuthentication()

  const [requesting, setRequesting] = useState<boolean>(false)

  const MSALLogin = () => {
    setRequesting(true)
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        callback()
      })
      .catch((error) => {})
      .finally(() => {
        setRequesting(false)
      })
  }

  const MSALLogout = () => {
    instance
      .logoutPopup()
      .then((response) => {
        callback()
        logout()
      })
      .catch((error) => {})
      .finally(() => {
        setRequesting(false)
      })
  }

  return (
    <StyledDialog open={true} fullWidth={true} maxWidth='xs'>
      <DialogContent>
        <FormControl sx={{p: 1, height: '100%', width: '100%', textAlign: 'center'}} component='fieldset' variant='standard'>
          <Typography variant='h4' sx={mode == 'dark' ? {color: 'white'} : {color: 'black'}} className='p-3'>
            Sessão Expirada
          </Typography>
          <Box component='div' className='p-2'>
            {!requesting && (
              <StyledButton variant='outlined' color='inherit' size='large' sx={{width: '300px'}} onClick={() => MSALLogin()}>
                Reautenticar
              </StyledButton>
            )}
            {requesting && (
              <StyledButton startIcon={<CircularProgress size={20} />} variant='outlined' size='large' sx={{width: '300px'}} disabled>
                Aguardando...
              </StyledButton>
            )}
          </Box>
          <Box component='div' className='p-2'>
            {!requesting && (
              <StyledButton variant='outlined' color='inherit' size='large' sx={{width: '300px'}} onClick={() => MSALLogout()}>
                Sair do Sistema
              </StyledButton>
            )}
          </Box>
        </FormControl>
      </DialogContent>
    </StyledDialog>
  )
}

export default Presence
