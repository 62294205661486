export interface IMenuAppDesktopsResult {
  id: number
  nome?: string
  descricao?: string
  json?: string
  ativo: boolean
  idmenuApp?: number
  idmenuAppDesktopsPai?: number
  biDeleted?: boolean
  dtDeleted?: Date
  idpessoaDeleted?: number
  dtCreated?: Date
  idpessoaCreated?: number
  dtEdited?: Date
  idpessoaEdited?: number
}

export class MenuAppDesktopsResult implements IMenuAppDesktopsResult {
  id: number
  nome: string
  descricao?: string
  json?: string
  ativo: boolean
  idmenuApp?: number
  idmenuAppDesktopsPai?: number
  biDeleted?: boolean
  dtDeleted?: Date
  idpessoaDeleted?: number
  dtCreated?: Date
  idpessoaCreated?: number
  dtEdited?: Date
  idpessoaEdited?: number

  constructor() {
    this.id = 0
    this.nome = 'Novo Desktop'
    this.ativo = true
  }
}
