import {Route, Routes, Outlet} from 'react-router-dom'
import {DynamicLog} from './components/DynamicLog'

interface IDynamicParams {
  isDisplayed?: boolean
}

export const TabLog = ({isDisplayed}: IDynamicParams) => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/:accesscode'
          element={
            <>
              <DynamicLog isDisplayed={isDisplayed} />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/:accesscode/:sysparam'
          element={
            <>
              <DynamicLog isDisplayed={isDisplayed} />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
