import {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom'

import {
  GridFilterModel,
  GridSortModel,
  GridRowSelectionModel,
  GridActionsCellItem,
  GridColumnVisibilityModel,
  useGridApiRef,
  GridPinnedColumns,
  GridPaginationModel,
  GridCallbackDetails,
  GridColumnOrderChangeParams,
  MuiEvent,
  useGridApiContext,
  GridRenderEditCellParams,
  GridColDef,
} from '@mui/x-data-grid-pro'
import {GridColumnHeaderParams, GridRenderCellParams, ptBR} from '@mui/x-data-grid'

import DynamicModal from '../../modals/modalDynamicV2'
import SYSMAFModal from '../../modals/modalSYSMAF'
import SYSMAPModal from '../../modals/modalSYSMAP'
import SYSMARModal from '../../modals/modalSYSMAR'

import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Dialog } from '@mui/material';


import { Document, Page } from 'react-pdf';

import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ContentCopy from '@mui/icons-material/ContentCopy'
import SettingsIcon from '@mui/icons-material/Settings'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import PreviewIcon from '@mui/icons-material/Preview'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import MailIcon from '@mui/icons-material/Mail'
import ReplyIcon from '@mui/icons-material/Reply'
import ReplyAllIcon from '@mui/icons-material/ReplyAll'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import RefreshIcon from '@mui/icons-material/Refresh'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import CallIcon from '@mui/icons-material/Call'
import SearchIcon from '@mui/icons-material/Search'
import GridOnIcon from '@mui/icons-material/GridOn'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'

import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import LinearProgress from '@mui/material/LinearProgress'
import {NoRowsOverlay} from '../../utility/NoRowsOverlay'
import {StyledDataGrid} from '../../grid/StyledDataGrid'
import {BoxSVGMessage} from '../../utility/BoxSVGMessage'
import {SYSTEMPLATEModal} from '../../modals/modalSYSTEMPLATE'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {writeFileXLSX, utils} from 'xlsx'
import ImportacaoModal from '../../modals/modalImportacao'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {Autocomplete, Box, InputAdornment, TextField} from '@mui/material'
import {GlobalContext} from '../../../../../App'
import {StyledRadioGroup} from '../../styled/StyledRadioButton'
import StyledButton from '../../styled/StyledButton'
import StyledMenu from '../../styled/StyledMenu'
import StyledDialog from '../../styled/StyledDialog'
import {CMenuAppHistoryStructure} from '../../../../../models/classes/CMenuAppHistoryStructure'
import SYSMAAModal from '../../modals/modalSYSMAA'
import HTMLViewer from '../../modals/modalHtmlViewer'
import HTMLSend from '../../modals/modalHtmlSend'
import useStateRef from 'react-usestateref'
import React from 'react'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../../modules/api/ApiWrapper'
import ModalExclusao from '../../modals/modalExclusao'
import {useIntl} from 'react-intl'
import ModalPerformingAction from '../../modals/modalPerformingAction'
import {StyledTextField} from '../../styled/StyledTextField'
import ModalSYSACT from '../../modals/modalSYSACT'
import SYSCHEDULEModal from '../../modals/modalSYSSCHEDULE'
import {DownloadLogico} from '../../../../../models/wrappers/helpers'
import ProgressBar from '../../utility/ProgressBar'
import {useLang} from '../../../../../../_metronic/i18n/Metronici18n'
import {FormatDate} from '../../../../../models/wrappers/format'
import {WhatsAppRedirectToolTip} from '../../grid/CellToolTip'
import PasswordModal from '../../modals/modalPassword'
import {useGlobalAuthentication} from '../../../../../modules/authentication/Authentication'
import ModalTarefa from '../../modals/modalTarefa'
import ModalPdf from '../../modals/modalPdf'
import { MenuAppActionResult } from '../../../../../modules/api/models/Result/MenuAppActionResult'

interface IDynamicRequestProps {
  useRelationFilters?: boolean
  idMenuAppRelation?: number
  idRelationItem: number
}

interface IDynamicList {
  readonly?: boolean
  isPrimary?: boolean
  isDisplayed?: boolean
  accessCode?: string
  sysParam?: string
  filters?: Array<any>
  IDMenuAppGenericItem?: string
  IDIntegration?: number
  title?: string
  subtitle?: string
  displayHeader?: boolean
  relationItem?: any
  requestProps?: IDynamicRequestProps
  getDataCallBack?: any
}

export const DynamicList = ({
  readonly,
  isPrimary,
  isDisplayed,
  accessCode,
  sysParam,
  filters,
  IDMenuAppGenericItem,
  IDIntegration,
  title,
  subtitle,
  displayHeader,
  relationItem,
  requestProps,
  getDataCallBack,
}: IDynamicList) => {
  const intl = useIntl()
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [actions, setActions] = useState<Array<MenuAppActionResult>>([])

  const {loggedUser} = useGlobalAuthentication()

  const locale = useLang()

  //* CONTEXTO GLOBAL PARA APLICAR TITULO E BOTÕES AO TOOLBAR
  const globalContext: any = useContext(GlobalContext)

  const apiRef = useGridApiRef()
  const navigate = useNavigate()

  //* VARIAVEIS GLOBAIS
  var gridSelection: Array<number> = []

  //* REGIÃO: PARÂMETROS DE ROTA *//

  // Recuperamos os parametos da rota
  const RouteParams = useParams()

  /**
   * Escolhe quais parametros da rota iremos usar no contexto atual
   * @returns object
   */
  const BuildParams = () => {
    if (!isPrimary) {
      return {AccessCode: accessCode, SYSParam: sysParam, IDRouteItem: undefined}
    } else {
      // Possuimos ja duas rotas para o lista dinamica e, por as duas terem pelo menos 2 parametros uma sobrepoe a outra
      // Sendo assim o react route adiciona o que poderia ou não ser o proximo parametro de rota na chave * para evitar que parametros se percam
      // Utilizamos o split para recuperar-lo

      let idrouteitem = RouteParams['*'] as string
      if (idrouteitem != null) {
        let split = idrouteitem.split('/')
        idrouteitem = split[1]
      }

      return {AccessCode: RouteParams.accesscode, SYSParam: RouteParams.sysparam, IDRouteItem: idrouteitem}
    }
  }

  const handleLoadActions = (AccessCode) => {
    apiWrapper.get(`/api/v1/MenuAppAction/listarPorMenuApp?item.AccessCode=${AccessCode}`).then(async (response) => {
      let _actions: Array<MenuAppActionResult> = response.data.data

      setActions(_actions)
    })
  }

  // Construímos os parametros gerais
  const {AccessCode, SYSParam, IDRouteItem} = BuildParams()

  // Variaveis de estado de código de acesso
  const [AccessCodeMaster, setAccessCodeMaster] = useState<string>('')
  const [AccessCodeInherited, setAccessCodeInherited] = useState<string>('')

  
  const [AcaoAdicionar, setAcaoAdicionar] = useState<any>({})

  /**
   * Carregamos o objeto do app e verificamos se ele é herdado de algum app pai, caso seja então carregamos o pai e guardamos o access code na variavel de estado para uso posterior
   * @returns void
   */
  const VerifyAccessCode = async () => {
    // Caso o código de acesso da rota seja nulo então não temos o que carregar, retornamos
    if (AccessCode == null || AccessCode == '') {
      return
    }

    setLoading(true)

    let _accessCodeMaster = AccessCode ?? ''
    let _accessCodeInherited = AccessCode ?? ''

    // Carregamos o objeto do app da rota
    const baseAccessCodeRequest = await apiWrapper.get(`api/v1/MenuApp/carregarPorAccessCode?item.AccessCode=${AccessCode}`)
    const baseMenuApp = baseAccessCodeRequest.data.data

    if (baseMenuApp == null) {
      setAccessCodeMaster('')
      setAccessCodeInherited('')
      return
    }

    // Verificamos a existencia de um app pai para o app da rota CASO o app filho não seja uma página customizada
    if (!baseMenuApp.biCustomPage && baseMenuApp.idmenuAppPai != null) {
      // Carregamos o app pai
      const masterAccessCodeRequest = await apiWrapper.get(`api/v1/MenuApp/carregar?item.ID=${baseMenuApp.idmenuAppPai}`)
      const masterMenuApp = masterAccessCodeRequest.data.data

      // Guardamos o access code do app pai
      _accessCodeMaster = masterMenuApp.accessCode
    }

    setLoading(false)

    // Aplicamos ambos os códigos de acesso nas variáveis de estado
    setAccessCodeMaster(_accessCodeMaster)
    setAccessCodeInherited(_accessCodeInherited)
  }

  //* FIM REGIÃO: PARÂMETROS DE ROTA *//

  //* VARIAVEIS DE ESTADO DO APP
  const [menuApp, setMenuApp] = useState<any>(null)
  const [menuAppHistory, setMenuAppHistory] = useState<any>(null)

  //* VARIAVEIS DE ESTADO DE ACESSO
  const [permissions, setPermissions] = useState<any>(null)
  const [userRoles, setUserRoles] = useState<Array<any>>([])

  //* VARIAVEIS DE ESTADO DO GRID
  const [loading, setLoading] = useState<boolean>(false)
  const [readOnly, setReadOnly] = useState<boolean>(false)
  const [rows, setRows, rowsRef] = useStateRef<Array<any>>([])
  const [columns, setColumns] = useState<Array<any>>([])
  const [rowsCount, setRowsCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({pageSize: 25, page: 0})
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
  const [invisibleColumns, setInvisibleColumns] = useState<GridColumnVisibilityModel>({id: false})
  const [gridFilter, setGridFilter] = useState<GridFilterModel | undefined>()
  const [gridSort, setGridSort] = useState<GridSortModel | undefined>()
  const [controls, setControls] = useState<Array<any>>([])
  const [filtro, setFiltro] = useState<string>('')
  const [hideDelete, setHideDelete] = useState<boolean>(false)

  const [requestFilters, setRequestFilters] = useState<Array<any>>([])
  const [requestOrders, setRequestOrders] = useState<Array<any>>([])

  //* VARIAVEIS DE ESTADO EXTRA
  const popperRefObject = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElCM, setAnchorElCM] = useState<null | HTMLElement>(null)
  const contextMenuOpen = Boolean(anchorElCM)
  const [appMode, setAppMode] = useState<string>('2') // 1 = CORE | 2 = DATA
  const [integration, setIntegration] = useState<any>()
  const [filterPanelOpened, setFilterModelOpened, filterPanelOpenedRef] = useStateRef<boolean>(false)


  //* VARIAVEIS DE ESTADO PDF
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const handleOpenPdfModal = (event: any, url: string) => {
    event.preventDefault();
    console.log('Button clicked, opening modal');
    setLoading(true);
    setPdfUrl(url);
  };

  const handleClosePdfModal = () => {
    console.log('Closing modal');
    setPdfUrl(null);
    setLoading(false);
  };

  const handlePdfLoaded = () => {
    setLoading(false);
  };


  //* COMPONENTES - RENDER CELL

  function CustomTextEditRenderComponent(props: GridRenderCellParams<any>) {
    const {value} = props
    const [showEncrypted, setShowEncrypted] = useState<boolean>(false)

    return (
      <>
        <span>{showEncrypted ? value : <>••••••••••••</>}</span>
        <IconButton
          aria-label='toggle password visibility'
          onClick={(event) => setShowEncrypted((prev) => !prev)}
          onMouseDown={(e) => e.preventDefault()}
          edge='end'
        >
          {showEncrypted ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </>
    )
  }

  const renderTextInputCell: GridColDef['renderCell'] = (params) => {
    return <CustomTextEditRenderComponent {...params} />
  }

  //* COMPONENTES - RENDER EDIT CELL

  function CustomSingleSelectEditComponent(props: GridRenderEditCellParams) {
    const {id, value, field, hasFocus} = props
    const [options, setOptions] = useState<Array<any>>([])
    const apiRef = useGridApiContext()
    const ref = useRef<any>()

    const handleOnInit = () => {
      if (IsSystemAccessCode()) {
        let ids = value != null && value != 'N/A' ? (value as number).toString() : ''
        getOptions('', ids, 15)
      } else {
        let query = value != null ? value : ''
        getOptions(query, '', 15)
      }
        
    }

    const handleOnChange = (value: any) => {
      apiRef.current.setEditCellValue({id, field, value: IsSystemAccessCode() ? value?.id : value?.label ?? null})
    }

    const handleOnOpen = (event: any) => {
      if (event == null || event.target.value == '' || event.target.value == null) {
        handleOnInputChange(null, '')
      }
    }

    const handleOnInputChange = (event: any, query: string) => {
      getOptions(query, '', 15)
    }

    const getOptions = (query: string, ids: string, limit: number) => {
      apiWrapper
        .get(
          `api/v1/Dynamic/listarSingleSelect?item.AccessCode=${AccessCodeInherited}&item.Campo=${field}&item.Query=${query}&item.IDs=${ids}&RegistroPorPagina=${limit}`
        )
        .then((response) => {
          setOptions(response.data.data)
        })
    }

    useEffect(() => {
      if (options.length == 0) {
        handleOnInit()
      }
    }, [])

    useLayoutEffect(() => {
      if (hasFocus && ref.current != null) {
        ref.current.focus()
      }
    }, [hasFocus])

    return (
      <>
        <Autocomplete
          ref={ref}
          disablePortal
          options={options}
          sx={{width: '100%'}}
          size='small'
          renderInput={(params) => <TextField {...params} label='' />}
          value={
            IsSystemAccessCode()
              ? value != null && value != 'N/A'
                ? options.filter((op: any) => op.id == value)[0] ?? null
                : null
              : value != null
              ? options.filter((op: any) => op.label == value)[0] ?? null
              : null
          }
          onChange={(event, value, reason) => handleOnChange(value)}
          onOpen={(event) => handleOnOpen(event)}
          onInputChange={(event, value) => handleOnInputChange(event, value)}
          filterOptions={(x) => x}
        />
      </>
    )
  }

  const renderSingleSelectEditInputCell: GridColDef['renderEditCell'] = (params) => {
    return <CustomSingleSelectEditComponent {...params} />
  }

  function CustomTextEditComponent(props: GridRenderEditCellParams) {
    const {id, value, field, hasFocus, headerName} = props
    const apiRef = useGridApiContext()
    const ref = useRef<any>()

    const [showEncrypted, setShowEncrypted] = useState<boolean>(false)

    const handleOnChange = (value: any) => {
      apiRef.current.setEditCellValue({id, field, value: value ?? null})
    }

    useLayoutEffect(() => {
      if (hasFocus && ref.current != null) {
        ref.current.focus()
      }
    }, [hasFocus])

    return (
      <>
        <TextField
          sx={{width: '100%'}}
          size='small'
          type={showEncrypted ? 'text' : 'password'}
          label={headerName}
          value={value}
          onChange={(event) => handleOnChange(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={(event) => setShowEncrypted((prev) => !prev)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge='end'
                >
                  {showEncrypted ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </>
    )
  }

  const renderTextEditInputCell: GridColDef['renderEditCell'] = (params) => {
    return <CustomTextEditComponent {...params} />
  }

  //* CONSTRUTORES
  const BuildActions = (params: any, goToApp: boolean, _permissions: any, isReadOnly: boolean = false) => {
    if (AccessCodeInherited == 'SYSUSERAUDIT') {
      return []
    }

    if (AccessCodeInherited == 'SYSMAA') {
      return [<GridActionsCellItem icon={<PreviewIcon />} label='Visualizar' onClick={() => chooseModalToOpen('SYSMAA', params.id)} showInMenu />]
    }

    if (AccessCodeInherited == 'ZPLUGMAILBOX') {
      return [
        <GridActionsCellItem icon={<PreviewIcon />} label='Visualizar' onClick={() => HTMLViewerOpen(params.row)} showInMenu />,
        <GridActionsCellItem
          hidden={(_permissions == null || !_permissions.biInsert) && !IsSystemAccessCode()}
          icon={<ReplyIcon />}
          label='Responder'
          onClick={() => HTMLSendOpen(params.id, 'Responder', params.row.De, undefined, `Res: ${params.row.Nome}`)}
          showInMenu
        />,
        <GridActionsCellItem
          hidden={(_permissions == null || !_permissions.biInsert) && !IsSystemAccessCode()}
          icon={<ReplyAllIcon />}
          label='Responder a Todos'
          onClick={() => HTMLSendOpen(params.id, 'Responder a Todos', undefined, `${params.row.De}, ${params.row.Cc}`, `Res: ${params.row.Nome}`)}
          showInMenu
        />,
        <GridActionsCellItem
          hidden={(_permissions == null || !_permissions.biInsert) && !IsSystemAccessCode()}
          icon={<DoubleArrowIcon />}
          label='Encaminhar'
          onClick={() => HTMLSendOpen(params.id, 'Encaminhar', undefined, undefined, `Enc: ${params.row.Nome}`)}
          showInMenu
        />,
        <GridActionsCellItem
          hidden={(_permissions == null || !_permissions.biDelete || hideDelete) && !IsSystemAccessCode()}
          icon={<DeleteIcon />}
          label='Excluir'
          onClick={() => handleOpenExcluir(params.id, AccessCodeMaster)}
          showInMenu
        />,
      ]
    }

    let actions: Array<JSX.Element> = [
      <GridActionsCellItem
        hidden={(_permissions == null || (!isReadOnly && !_permissions.biSelect && !_permissions.biUpdate)) && !IsSystemAccessCode()}
        icon={<EditIcon />}
        label={isReadOnly || (_permissions.biSelect && !_permissions.biUpdate) ? 'Visualizar' : 'Editar'}
        onClick={() => chooseModalToOpen(AccessCodeMaster, params.id)}
        showInMenu
      />,
      <GridActionsCellItem
        hidden={(_permissions == null || !_permissions.biInsert || isReadOnly) && !IsSystemAccessCode()}
        icon={<ContentCopy />}
        label='Duplicar'
        onClick={() => Duplicar(params.id)}
        showInMenu
      />,
      <GridActionsCellItem
        hidden={(_permissions == null || !_permissions.biDelete || isReadOnly || hideDelete) && !IsSystemAccessCode()}
        icon={<DeleteIcon />}
        label='Excluir'
        onClick={() => handleOpenExcluir(params.id, AccessCodeMaster)}
        showInMenu
      />,
      <GridActionsCellItem
        hidden={IsSystemAccessCode()}
        icon={<MailIcon />}
        label='Enviar por Email'
        onClick={() => {
          HTMLSendOpenWithSelectedItemAsContent([params.id])
        }}
        showInMenu
      />,
    ]

    if (goToApp) {
      actions.push(
        <GridActionsCellItem
          icon={<ExitToAppIcon />}
          label='Ir para o App'
          onClick={() => {
            apiWrapper.get('/api/v1/MenuApp/carregar?item.ID=' + params.id).then((response) => {
              let accessCode: string = response.data.data.accessCode

              if (accessCode.toLowerCase().startsWith('sys')) {
                navigate(`/pages/_dynamic/${accessCode}`, {replace: true})
              } else {
                navigate(`/pages/_dynamicTabs/${accessCode}`, {replace: true})
              }
            })
          }}
          showInMenu
        />
      )

      actions.push(
        <GridActionsCellItem
          icon={<ExitToAppIcon />}
          label='Abrir em uma nova aba'
          onClick={() => {
            apiWrapper.get('/api/v1/MenuApp/carregar?item.ID=' + params.id).then((response) => {
              let accessCode: string = response.data.data.accessCode
              window.open(`/pages/_dynamicTabs/${accessCode}`, '_blank')
            })
          }}
          showInMenu
        />
      )
    }

    return actions
  }

  const BuildControls = (selection: boolean = false, force: boolean = false) => {
    if (!isDisplayed && !force) {
      return
    }

    let permitedActions = actions.filter(x => x.tipo == "PadraoModal");


    let controls: Array<any> = []

    if (AccessCodeInherited == 'SYSUSERAUDIT') {
      if (permissions != null && permissions.biSelect) {
        controls.push(
          <StyledButton variant='contained' startIcon={<FileDownloadIcon />} color='inherit' onClick={() => Exportar()}>
            Exportar
          </StyledButton>
        )
      }
    } else if (AccessCodeInherited == 'ZPLUGMAILBOX') {
      if (selection && permissions != null && permissions.biDelete && !hideDelete) {
        controls.push(
          <StyledButton variant='contained' startIcon={<DeleteIcon />} color='error' onClick={() => handleOpenExcluir(0, AccessCodeMaster)}>
            Excluir
          </StyledButton>
        )
      }

      if (permissions != null && permissions.biSelect) {
        controls.push(
          <StyledButton variant='contained' startIcon={<RefreshIcon />} color='inherit' onClick={async () => GetData(true, true)}>
            Atualizar
          </StyledButton>
        )
      }

      if (permissions != null && permissions.biInsert) {
        controls.push(
          <StyledButton variant='contained' startIcon={<AddIcon />} color='info' onClick={() => HTMLSendOpen(0, 'Novo Email')}>
            Novo Email
          </StyledButton>
        )
      }
    } else {
      if (AccessCodeInherited == 'SYSMAP' && HasSuperAdmin()) {
        controls.push(
          <FormControl>
            <StyledRadioGroup value={appMode} onChange={handleOnChangeAppMode} row>
              <FormControlLabel value='1' control={<Radio />} label='Apps Core' />
              <FormControlLabel value='2' control={<Radio />} label='Apps Usuário' />
            </StyledRadioGroup>
          </FormControl>
        )
      }

      //* TODO: Refactor para componente de filtros
      if (AccessCodeInherited == 'SYSINTEGRATION' && HasSuperAdmin()) {
        controls.push(
          <FormControl>
            <StyledRadioGroup value={appMode} onChange={handleOnChangeAppMode} row>
              <FormControlLabel value='1' control={<Radio />} label='Integrações Core' />
              <FormControlLabel value='2' control={<Radio />} label='Integrações Usuário' />
            </StyledRadioGroup>
          </FormControl>
        )
      }

      //* Caso o item de relação tenha a flag biOcultarAcoes então ocultamos as abaixo
      //? Note que nem todas as aÇões entram nessa regra pois algumas não tem há ver com items de relação
      let ocultarAcoes = relationItem != null && relationItem.biOcultarAcoes

      if (!ocultarAcoes && selection && !IsSystemAccessCode()) {
        controls.push(
          <StyledButton
            variant='contained'
            startIcon={<AppRegistrationIcon />}
            color='info'
            onClick={() => HTMLSendOpenWithSelectedItemAsContent(gridSelection)}
          >
            Enviar por email
          </StyledButton>
        )
      }

      if (!ocultarAcoes && !readOnly && selection && ((permissions != null && permissions.biUpdate) || IsSystemAccessCode())) {
        controls.push(
          <StyledButton variant='contained' startIcon={<AppRegistrationIcon />} color='inherit' onClick={() => handleOpenModalDynamic('0', true)}>
            Edição em Massa
          </StyledButton>
        )

        if (AccessCode == 'SYSTASK') {
          controls.push(
            <StyledButton variant='contained' startIcon={<PlaylistAddCheckIcon />} color='inherit' onClick={() => handleOpenModalTarefa()}>
              Aprovar/Reprovar
            </StyledButton>
          )
        }
      }

      if (!ocultarAcoes && !hideDelete && !readOnly && selection && ((permissions != null && permissions.biDelete) || IsSystemAccessCode())) {
        controls.push(
          <StyledButton variant='contained' startIcon={<DeleteIcon />} color='error' onClick={() => handleOpenExcluir(0, AccessCodeMaster)}>
            Excluir
          </StyledButton>
        )
      }

      if (AccessCodeInherited == 'SYSMAF' && permissions != null && permissions.biManage) {
        controls.push(
          <StyledButton variant='contained' color='inherit' onClick={() => remapFields()}>
            Remapear Campos
          </StyledButton>
        )
      }

      // Deixar o adicionar por ultimo
      if (permitedActions.filter(x => x.acao == "Add")) {
        controls.push(
          <StyledButton
            hidden={(permissions == null || !permissions.biInsert) && !IsSystemAccessCode()}
            variant='contained'
            startIcon={<AddIcon />}
            color='info'
            onClick={() => chooseModalToOpen(AccessCodeMaster, '0')}>

            {AcaoAdicionar.nome ?? 'Adicionar'}

          </StyledButton>
        )
      }
    
      // Adicionar anterior
      // if (!ocultarAcoes && !readOnly) {
      //   controls.push(
      //     <StyledButton
      //       hidden={(permissions == null || !permissions.biInsert) && !IsSystemAccessCode()}
      //       variant='contained'
      //       startIcon={<AddIcon />}
      //       color='info'
      //       onClick={() => chooseModalToOpen(AccessCodeMaster, '0')}>

      //       {AcaoAdicionar.nome ?? 'Adicionar'}

      //     </StyledButton>
      //   )
      // }

      if (
        !IsSystemAccessCode() ||
        (AccessCodeInherited == 'SYSMAF' && SYSParam != null) ||
        (permissions != null && permissions.biSelect && !IsSystemAccessCode(true)) ||
        (permissions != null && permissions.biInsert && !IsSystemAccessCode(true))
      ) {
        controls.push(
          <IconButton color='inherit' onClick={handleClickOpenContextMenu}>
            <MoreVertIcon />
          </IconButton>
        )
      }
    }

    if (!isPrimary) {
      if (displayHeader) {
        setControls(controls)
      }

      return
    }

    globalContext.ReactState.Toolbar2.setControls(controls)
  }

  //* FUNÇÕES DE AJUDA
  const HasAdmin = () => {
    if (userRoles.length == 0) {
      return false
    }

    return userRoles.find((x: any) => x.identificador == 'ADMIN') != null
  }

  const HasSuperAdmin = () => {
    if (userRoles.length == 0) {
      return false
    }

    return userRoles.find((x: any) => x.identificador == 'SADMIN') != null
  }

  const IsSystemAccessCode = (actions: boolean = false) => {
    if (actions) {
      return AccessCodeInherited == 'SYSMAP' || AccessCodeInherited == 'SYSMAPVISOES' || AccessCodeInherited == 'SYSMAF'
    }

    return (
      AccessCodeInherited != 'SYSTASK' &&
      (AccessCodeInherited?.toLowerCase().startsWith('sys') || AccessCodeInherited?.toLowerCase().startsWith('zplug'))
    )
  }

  const IsPluginAccessCode = () => {
    return AccessCodeInherited?.toLowerCase().startsWith('zplug')
  }

  const isProtectedItemSelected = (newSelectionModel: GridRowSelectionModel) => {
    for (const itemId of newSelectionModel) {
      let fromData = rows.find((x) => x.ID == itemId)

      if (fromData != null) {
        if (AccessCodeInherited == 'SYSROLE') {
          if (
            fromData.Identificador.toLowerCase() == 'admin' ||
            fromData.Identificador.toLowerCase() == 'sadmin' ||
            fromData.Identificador.toLowerCase() == 'userall'
          ) {
            return true
          }
        }
      }
    }

    return false
  }

  const isEmpty = (obj: any) => {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false
      }
    }

    return JSON.stringify(obj) === JSON.stringify({})
  }

  const GetHideableNames = (fieldName: string) => {
    return true
  }

  const GetIgnorableTypes = (fieldType: string) => {
    switch (fieldType) {
      case 'upload':
        return true
    }

    return false
  }

  const GetFilterableTypes = (fieldType: string) => {
    switch (fieldType) {
      case 'formulaSQL':
        return false
    }

    return true
  }

  const TranslateFieldType = (fieldType: string) => {
    switch (fieldType) {
      case 'decimal':
      case 'geocode':
        return 'number'
      case 'special':
      case 'textArea':
      case 'geolocation':
        return 'string'
      case 'singleSelect':
        if (!IsSystemAccessCode()) {
          return 'string'
        }
        break
    }

    return fieldType
  }

  //* FUNÇÕES GRID
  const StartGrid = async () => {
    let isReadOnly = false

    //* Resetamos o grid por completo caso ele esteja sendo re-renderizado de uma outra rota
    setRows([])
    setColumns([])
    setFiltro('')
    setPaginationModel((prev) => {
      prev.page = 0
      return {...prev}
    })

    if (AccessCodeMaster == '' || AccessCodeInherited == '') {
      return
    }

    //* Verificamos se existe a necessidade de abrir o modal com o ID parametro da rota
    if (IDRouteItem != null && parseInt(IDRouteItem) > 0) {
      chooseModalToOpen(AccessCodeMaster, IDRouteItem)
    }

    setLoading(true)

    document.addEventListener('keydown', (event) => {
      handleOnKeyDown(null, event, null)
    })

    //* REQUEST DAS VARIAVEIS BÁSICAS DE ESTADO
    const appRequest = await apiWrapper.get(`api/v1/MenuApp/carregarPorAccessCode?item.AccessCode=${AccessCodeInherited}`)
    setMenuApp(appRequest.data.data)

    const appHistoryRequest = await apiWrapper.get(`api/v1/MenuAppHistory/carregarPorMenuApp?item.AccessCode=${AccessCodeInherited}`)
    setMenuAppHistory(appHistoryRequest.data.data)

    const permissionsRequest = await apiWrapper.get(`api/v1/MenuAppRole/verificarPermissoesPorCodigoDeAcesso?item.AccessCode=${AccessCodeInherited}`)
    setPermissions(permissionsRequest.data.data)

    const userRolesRequest = await apiWrapper.get('api/v1/Role/listarRolesPessoaLogada')
    setUserRoles(userRolesRequest.data.data)

    const appActions = await apiWrapper.get(`/api/v1/MenuAppAction/listarPorMenuApp?item.AccessCode=${AccessCodeInherited}`)

    let acaoAdicionar = appActions.data.data.find((x) => x.acao == 'Add')
    setAcaoAdicionar(acaoAdicionar)

    let acaoExcluir = appActions.data.data.find((x) => x.acao == 'Delete')
    setHideDelete(acaoExcluir != null && !acaoExcluir.biVisible)

    if (
      readonly ||
      (permissionsRequest.data.data.biSelect && !permissionsRequest.data.data.biUpdate) ||
      appRequest.data.data.sqlType.trim() == 'VIEW'
    ) {
      setReadOnly(true)
      isReadOnly = true
    } else {
      setReadOnly(false)
    }

    if (IDIntegration != null) {
      const integrationRequest = await apiWrapper.get(`api/v1/MenuAppIntegration/Carregar?item.ID=${IDIntegration}`)
      setIntegration(integrationRequest.data.data)
    }

    if (isPrimary) {
      globalContext.ReactState.Toolbar2.setTitle(appRequest.data.data.titulo)
      globalContext.ReactState.Toolbar2.setSubtitle(appRequest.data.data.descricao)
    }

    let historico =
      appHistoryRequest.data.data.historico != null ? (JSON.parse(appHistoryRequest.data.data.historico) as CMenuAppHistoryStructure) : undefined

    if (permissionsRequest.data.data == null || !permissionsRequest.data.data.biSelect) {
      setLoading(false)
      return
    }

    //* CONSTRUÇÃO DAS COLUNAS
    let columnsRequest = await apiWrapper.get(`/api/v1/Dynamic/carregar?item.AccessCode=${AccessCodeInherited}`)

    let colunas: Array<any> = []
    let colunasParaOcultar: string = ''
    let colunasOcultas: GridColumnVisibilityModel

    let left: string[] = []
    let right: string[] = []

    if (
      AccessCodeInherited != 'SYSUSERAUDIT' &&
      permissionsRequest.data.data != null &&
      (permissionsRequest.data.data.biSelect ||
        permissionsRequest.data.data.biDelete ||
        permissionsRequest.data.data.biInsert ||
        permissionsRequest.data.data.biUpdate)
    ) {
      colunas.push({
        field: 'actions',
        width: 25,
        type: 'actions',
        editable: false,
        hideable: false,
        getActions: (params: any) =>
          BuildActions(params, AccessCodeInherited == 'SYSMAP' || AccessCodeInherited == 'SYSMAPVISOES', permissionsRequest.data.data, isReadOnly),
      })
    }

    for (const item of columnsRequest.data.data.fields) {
      if (!item.ativo || GetIgnorableTypes(item.tipo)) continue
      if (relationItem != null && relationItem.menuAppField.id == item.id) continue

      const exibicao = JSON.parse(item.jsonformatacao)
      const usaExibicao = exibicao != null && (exibicao.formatacao != '' || exibicao.exibicao.length > 0)

      let isID = item.campo.toUpperCase() == 'ID'
      let realType = TranslateFieldType(item.tipo)
      let alignment = realType == 'number' ? 'right' : usaExibicao || item.tipo == 'boolean' ? 'center' : 'left'

      let cx: any = {
        field: item.campo,
        type: realType,
        headerName: item.label,
        headerAlign: isID ? 'left' : alignment,
        align: isID ? 'left' : alignment,
        width: isID ? 125 : undefined,
        flex: isID ? undefined : 0.25,
        hideable: !IsSystemAccessCode() && GetHideableNames(item.campo.toUpperCase()),
        filterable: GetFilterableTypes(item.tipo),
        disableReorder: IsSystemAccessCode(),
        pinnable: !IsSystemAccessCode(),
      }

      if (item.criptografar) {
        cx.renderCell = renderTextInputCell
        cx.renderEditCell = renderTextEditInputCell
      } else {
        if (usaExibicao) {
          cx.renderCell = (params: GridRenderCellParams<any>) => {
            let value = <>{params.formattedValue}</>

            if (params.value == null || params.value == 'N/A') {
              return value
            }

            switch (exibicao.formatacao) {
              case 'Telefone':
                return (
                  <Box component='div' sx={{cursor: 'pointer'}} onClick={(event) => handleOpenTelefone(params.value)}>
                    {params.value}
                  </Box>
                )
              case 'CPF':
                let formated_cpf = params.value.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                value = <>{formated_cpf}</>
                break
              case 'CNPJ':
                let formated_cnpj = params.value.replace(/[^\d]/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5')
                value = <>{formated_cnpj}</>
                break
              case 'Moeda (R$)':
                let formatted_brl = parseFloat(params.value).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                value = <>{formatted_brl}</>
                break
              case 'Moeda ($)':
                let formatted_usd = parseFloat(params.value).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })
                value = <>{formatted_usd}</>
                break
              case 'Percentual (%)':
                let formatted_percent = `${parseFloat(params.value).toLocaleString()}%`
                value = <>{formatted_percent}</>
                break
              case 'Progresso (%)':
                return <ProgressBar value={parseFloat(params.value)} />
              case 'Tudo em caixa alta':
                value = <>{params.value.toString().toUpperCase()}</>
                break
              case 'Tudo em caixa baixa':
                value = <>{params.value.toString().toLowerCase()}</>
                break
              case 'Url':
                return (
                    <a href={params.value} target='_blank' onClick={(e) => handleOpenPdfModal(e, params.value)}>
                      {exibicao?.exibeCampoUrl ? exibicao?.exibeCampoUrl.replace("Baixar", "Visualizar") : 'Acessar Url'}
                    </a>
                    )
              case 'Email':
                return <a href={`mailto:${params.value}`}>{params.value}</a>
            }

            let sorted = exibicao.exibicao.sort((a, b) => {
              if (a.ordem < b.ordem) {
                return -1
              }
              if (a.ordem > b.ordem) {
                return 1
              }
              return 0
            })

            for (const rule of sorted) {
              rule.valor = rule.valor.trim()

              if (typeof params.value == 'string') {
                params.value = (params.value as string).trim()
              }

              if (
                (rule.operador == 'Valor nulo' && (params.value == '' || params.value == 'N/A')) ||
                (rule.operador == 'Valor igual a' &&
                  (params.value == rule.valor ||
                    params.formattedValue == rule.valor ||
                    (item.tipo == 'boolean' &&
                      ((rule.valor == 'Verdadeiro' && params.value == true) || (rule.valor == 'Falso' && params.value == false))))) ||
                (rule.operador == 'Valor diferente de' &&
                  (params.value != rule.valor ||
                    (item.tipo == 'boolean' &&
                      ((rule.valor == 'Verdadeiro' && params.value == false) || (rule.valor == 'Falso' && params.value == true))))) ||
                (rule.operador == 'Valor contém' && params.value.includes(rule.valor)) ||
                (rule.operador == 'Valor não contém' && !params.value.includes(rule.valor)) ||
                (rule.operador == 'Valor maior que' && params.value > rule.valor) ||
                (rule.operador == 'Valor menor que' && params.value < rule.valor) ||
                (rule.operador == 'Valor maior ou igual a' && params.value >= rule.valor) ||
                (rule.operador == 'Valor menor ou igual a' && params.value <= rule.valor) ||
                (rule.operador == 'Valor entre' && params.value >= rule.valor && params.value <= rule.extra)
              ) {
                switch (rule.acao) {
                  case 'Exibir badge':
                  case 'Alterar a cor do texto':
                    return <span className={rule.cor}>{value}</span>
                  case 'Exibir ícone':
                    return (
                      <>
                        <i className={rule.icone} aria-hidden='true' title={params.value}></i>
                      </>
                    )
                }
              }
            }

            if (item.tipo == 'boolean') {
              switch (params.value) {
                case true:
                  return <i title={params.formattedValue} className='fa fa-check'></i>
                case false:
                  return <i title={params.formattedValue} className='fa fa-x'></i>
              }
            }

            return <>{value}</>
          }
        } else {
          if (item.tipo == 'decimal') {
            cx.renderCell = (params: GridRenderCellParams<any>) => {
              if (params.value == null) {
                return params.value
              }

              let formated = (params.value as number).toLocaleString(intl.formatMessage({id: 'NUMBER.FORMAT'}))
              return <>{formated}</>
            }
          } else if (item.tipo == 'geocode') {
            cx.renderCell = (params: GridRenderCellParams<any>) => {
              if (params.value == null) {
                return params.value
              }

              let formated = (params.value as number).toFixed(6)
              return <>{formated}</>
            }
          } else if (item.tipo == 'map') {
            cx.renderCell = (params: GridRenderCellParams<any>) => {
              if (params.value == null || typeof params.value !== 'string') {
                return params.value
              }

              let isJson = (params.value as string).startsWith('{') && (params.value as string).endsWith('}')

              let formated = isJson ? JSON.parse(params.value).address : params.value
              return <>{formated}</>
            }
          }
        }
      }

      if ((AccessCodeInherited == 'SYSMAP' || AccessCodeInherited == 'SYSMAPVISOES') && item.campo == 'Icone') {
        cx.renderCell = (params: GridRenderCellParams<any>) => {
          let value = <>{params.value}</>

          if (params.value == null || params.value == '' || params.value == 'N/A') {
            return value
          }

          return (
            <>
              <i className={params.value} aria-hidden='true' title={params.value}></i>
            </>
          )
        }
      }

      if ((AccessCodeInherited == 'SYSMEN' || AccessCodeInherited == 'SYSMENSUB') && item.campo == 'Icone') {
        cx.renderCell = (params: GridRenderCellParams<any>) => {
          let value = <>{params.value}</>

          if (params.value == null || params.value == '' || params.value == 'N/A') {
            return value
          }

          return (
            <>
              <img src={toAbsoluteUrl('/media/icons/duotune' + params.value)} className='h-15px' />
            </>
          )
        }
      }

      if (
        (AccessCodeInherited == 'ZPLUGPESSOATEL' && item.campo == 'Numero') ||
        (item.tipo == 'special' && usaExibicao && exibicao.formatacao == 'Telefone')
      ) {
        cx.renderCell = (params: GridRenderCellParams<any>) => {
          if (params.value == null || params.value == '') return <></>

          return <WhatsAppRedirectToolTip number={params.value} />
        }
      }

      switch (item.fixar) {
        case 'left':
          left.push(item.campo)
          break
        case 'right':
          right.push(item.campo)
          break
      }

      if (item.tipo == 'singleSelect') {
        if (IsSystemAccessCode()) {
          await apiWrapper
            .get(`/api/v1/Dynamic/listarSingleSelect?item.AccessCode=${AccessCodeInherited}&item.Campo=${item.campo}`)
            .then((response) => {
              let options: any[] = []

              for (const obj of response.data.data) {
                options.push({value: obj.id, label: obj.label})
              }

              cx.valueOptions = options
            })

          if (cx.renderCell == null) {
            cx.renderCell = (params: GridRenderCellParams<any>) => {
              return <>{params.formattedValue}</>
            }
          }
        }

        cx.renderEditCell = renderSingleSelectEditInputCell

        cx.valueGetter = ({value}) => {
          if (value == null || isEmpty(value)) {
            return IsSystemAccessCode() ? 'N/A' : null
          } else {
            return value
          }
        }
      } else if (item.tipo == 'date' || item.tipo == 'dateTime') {
        cx.valueGetter = ({value}) => {
          if (value == null || isEmpty(value)) {
            return null
          } else {
            return new Date(value)
          }
        }

        cx.renderCell = (params: GridRenderCellParams<any>) => {
          if (params.value == null) {
            return params.value
          }

          return <>{FormatDate(new Date(params.value), locale, item.tipo == 'dateTime')}</>
        }
      } else if (item.tipo == 'boolean') {
        cx.valueGetter = ({value}) => {
          if (value == null || isEmpty(value)) {
            return false
          } else {
            return value
          }
        }
      } else if (item.tipo == 'number' || item.tipo == 'decimal' || item.tipo == 'geocode') {
        cx.valueGetter = ({value}) => {
          if (value == null || isEmpty(value)) {
            return 'N/A'
          } else {
            return value
          }
        }
      } else {
        cx.valueGetter = ({value}) => {
          if (value == null || isEmpty(value)) {
            return null
          } else {
            return value
          }
        }
      }

      colunas.push(cx)
      colunasParaOcultar += '"' + item.campo + '"' + ':' + (item.exibirGrid ? 'true' : 'false') + ', '
    }

    colunasOcultas = JSON.parse('{ ' + colunasParaOcultar.substring(0, colunasParaOcultar.length - 2) + ' }')

    if (!readOnly && !IsSystemAccessCode() && permissionsRequest.data.data.biManage) {
      colunas.push({
        field: 'addColumn',
        type: 'actions',
        editable: false,
        hideable: false,
        pinable: false,
        width: 75,
        getActions: (params: any) => [],
        renderHeader: (params: GridColumnHeaderParams) => (
          <IconButton aria-label='add' onClick={() => SYSMAFExternal(AccessCodeInherited, 0)}>
            <AddIcon fontSize='small' />
          </IconButton>
        ),
      })
    }

    if (isPrimary && historico != null && historico.grid != null && historico.grid.columns != null && historico.grid.columns.length > 0) {
      let reordered: Array<any> = []

      for (const item of historico.grid.columns) {
        let column = colunas.find((x) => x.field == item)

        if (column != null) {
          reordered.push(column)
        }
      }

      setColumns(reordered.concat(colunas.filter((x) => reordered.find((y) => x.field == y.field) == null)))
    } else {
      setColumns(colunas)
    }

    setInvisibleColumns(colunasOcultas)

    let colunasFixadas: GridPinnedColumns = {
      left: ['__check__', 'actions'].concat(left),
      right: right,
    }

    //TODO: Identificar o porque o apiRef esta vindo nulo em alguns casos de re-render
    if (apiRef.current != null) {
      apiRef.current.setPinnedColumns(colunasFixadas)

      let columns: any = apiRef.current.getAllColumns()
      if (columns[0] != null) {
        columns[0].hideable = false
      }
    }

    let queryString = window.location.search
    let params = new URLSearchParams(queryString)
    let urlFiltro = params.get('f')

    if (urlFiltro != null) {
      // O replace serve para limpar espaços não tratados pela função "btoa"
      // TODO: Verificar se isto esta resolvido na nova função não depreciada de codificação base64
      let obj = JSON.parse(atob(urlFiltro.replaceAll(' ', '+')))

      let urlFilterObject = {
        items: [
          {
            field: obj.columnField,
            operator: obj.operatorValue,
            value: obj.value[0],
          },
        ],
        logicOperator: 'and',
        quickFilterValues: [],
        quickFilterLogicOperator: 'and',
      }

      onFilterChange(urlFilterObject as any, true)
      return
    }

    if (!IsSystemAccessCode() && isPrimary && historico != null && historico.grid != null) {
      if (historico.grid.filter != null) {
        onFilterChange(historico.grid.filter as any, true)
      } else {
        setGridFilter(undefined)
      }
      if (historico.grid.order != null) {
        onOrderChange(historico.grid.order as any, true)
      } else {
        setGridSort(undefined)
      }

      if (historico.grid.filter == null && historico.grid.order == null) {
        setTimeout(() => {
          GetData(true, AccessCodeInherited == 'ZPLUGMAILBOX')
        }, 1)
      }
    } else {
      setGridFilter(undefined)
      setGridSort(undefined)

      setTimeout(() => {
        GetData(true, AccessCodeInherited == 'ZPLUGMAILBOX')
      }, 1)
    }

    //setLoading(false)
  }

  const GetData = async (useLoading?: boolean, fetchZPLUGMAIL?: boolean, _filtro?: string) => {
    if (AccessCodeMaster == '' || AccessCodeInherited == '') {
      return
    }

    if (useLoading) setLoading(true)
    if (fetchZPLUGMAIL) await fetchZPLUGMAILBOX()

    let request = {
      item: {
        AccessCode: AccessCodeInherited,
        SysParam: SYSParam,
        IDMenuAppGenericItem: IDMenuAppGenericItem,
        UseRelationFilter: requestProps?.useRelationFilters,
        IDMenuAppRelation: requestProps?.idMenuAppRelation,
        IDRelationItem: requestProps?.idRelationItem,
        DescricoesAoInvesDeIDs: IsSystemAccessCode() ? false : true,
        AppFactoryType: parseInt(appMode),
        Filters: requestFilters.concat(filters ?? []),
        Orders: requestOrders,
        Busca: _filtro ?? filtro,
      },
      PaginaAtual: paginationModel.page,
      RegistroPorPagina: paginationModel.pageSize,
    }

    apiWrapper
      .post('/api/v1/Dynamic/listarComFiltros', request)
      .then((response) => {
        setRowsCount(response.data.totalRegistros)
        setRows(response.data.data)
      })
      .catch((error) => {
        toast.error(error.response?.data?.mensagem ?? error.message)
      })
      .finally(() => {
        if (useLoading) setLoading(false)

        if (getDataCallBack != null) {
          getDataCallBack()
        }
      })
  }

  const handleOnKeyDown = (params, event, details) => {
    if (event.key == 'F3' && !filterPanelOpened && isPrimary) {
      event.preventDefault()
      setFilterModelOpened(true)
      apiRef.current.showFilterPanel()
    }
  }

  const handleCellDoubleClick = (params, event, details) => {
    if (permissions != null && (permissions.biSelect || permissions.biUpdate)) {
      if (AccessCodeInherited == 'ZPLUGMAILBOX') {
        HTMLViewerOpen(params.row)
      } else {
        chooseModalToOpen(AccessCodeInherited, params.id)
      }
    }
  }

  const handleOnChangeAppMode = (event) => {
    setAppMode(event.target.value)
    setPaginationModel((prev) => {
      prev.page = 0
      return {...prev}
    })
  }

  const handleClickOpenContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCM(event.currentTarget)
  }

  const onMouseEnterRow = (event) => {
    const id = event.currentTarget.dataset.id
    setAnchorEl(event.currentTarget)
  }

  const onMouseLeaveRow = (event) => {
    if (anchorEl == null || (popperRefObject?.current ?? false)) return
    setAnchorEl(null)
  }

  const onFilterChange = (filterModel?: GridFilterModel, skipSave?: boolean) => {
    if (filterModel == null || (menuAppHistory == null && skipSave == null) || loading) {
      return
    }

    setGridFilter(filterModel)

    setMenuAppHistory((prev) => {
      let historico =
        prev.historico != null && prev.historico != '' ? (JSON.parse(prev.historico) as CMenuAppHistoryStructure) : new CMenuAppHistoryStructure()

      if (historico.grid == null) {
        historico.grid = {filter: undefined, order: undefined, columns: []}
      }

      historico.grid.filter = filterModel
      prev.historico = JSON.stringify(historico)

      if (skipSave != true) {
        apiWrapper.put('api/v1/MenuAppHistory/salvar', {item: {AccessCode: AccessCodeInherited, MenuAppHistory: prev}})
      }

      return {...prev}
    })

    let newFilters: Array<any> = []

    for (const item of filterModel.items) {
      if (item.value == null || item.value == '') {
        continue
      }

      let obj: any[] = []
      obj[0] = item.value.toString()

      newFilters.push({
        columnField: item.field,
        operatorValue: item.operator != null ? item.operator : 'INVALID',
        value: obj,
      })
    }

    setRequestFilters(newFilters)

    setPaginationModel((prev) => {
      prev.page = 0
      return {...prev}
    })
  }

  const onOrderChange = (sortModel: GridSortModel, skipSave?: boolean) => {
    if (sortModel == null || (menuAppHistory == null && skipSave == null) || loading) {
      return
    }

    setGridSort(sortModel)

    setMenuAppHistory((prev) => {
      let historico =
        prev.historico != null && prev.historico != '' ? (JSON.parse(prev.historico) as CMenuAppHistoryStructure) : new CMenuAppHistoryStructure()

      if (historico.grid == null) {
        historico.grid = {filter: undefined, order: undefined, columns: []}
      }

      historico.grid.order = sortModel
      prev.historico = JSON.stringify(historico)

      if (skipSave != true) {
        apiWrapper.put('api/v1/MenuAppHistory/salvar', {item: {AccessCode: AccessCodeInherited, MenuAppHistory: prev}})
      }

      return {...prev}
    })

    let newOrders: Array<any> = []

    for (const item of sortModel) {
      newOrders.push(item)
    }

    setRequestOrders(newOrders)

    setPaginationModel((prev) => {
      prev.page = 0
      return {...prev}
    })
  }

  const onVisibilityChange = (newModel: GridColumnVisibilityModel) => {
    if (IsSystemAccessCode()) {
      return
    }

    let hiddenFields: any = []
    let visibleFields: any = []

    for (let key in newModel) {
      if (newModel[key] === false) {
        hiddenFields.push(key)
      }

      if (newModel[key] === true) {
        visibleFields.push(key)
      }
    }

    let obj: any = {
      item: {
        AccessCode: AccessCodeInherited,
        HiddenFields: hiddenFields,
        VisibleFields: visibleFields,
      },
    }

    apiWrapper.post('/api/v1/Dynamic/salvarExibicaoGrid', obj, {}).then((response) => {
      setInvisibleColumns((col) => newModel)
    })
  }

  const onSelectionChange = (newSelectionModel: GridRowSelectionModel) => {
    if (newSelectionModel.length > 0 && !isProtectedItemSelected(newSelectionModel)) {
      BuildControls(true)
    } else {
      BuildControls()
    }

    gridSelection = newSelectionModel as Array<number>
    setSelectionModel(newSelectionModel)
    setModalToDeleteItemsExcluir(newSelectionModel)
    setModalTarefaIDs(newSelectionModel.map((x) => x as number))
  }

  const onColumnHeaderDragEnd = (params: GridColumnOrderChangeParams, event: MuiEvent<{}>, details: GridCallbackDetails) => {
    let order: Array<string> = []

    for (const item of apiRef.current.getAllColumns()) {
      order.push(item.field)
    }

    setMenuAppHistory((prev) => {
      let historico =
        prev.historico != null && prev.historico != '' ? (JSON.parse(prev.historico) as CMenuAppHistoryStructure) : new CMenuAppHistoryStructure()

      if (historico.grid == null) {
        historico.grid = {filter: undefined, order: undefined, columns: []}
      }

      historico.grid.columns = order
      prev.historico = JSON.stringify(historico)

      apiWrapper.put('api/v1/MenuAppHistory/salvar', {item: {AccessCode: AccessCodeInherited, MenuAppHistory: prev}})

      return {...prev}
    })
  }

  const handleClearFilters = () => {
    setFiltro('')
    onFilterChange({items: []})
  }

  //* ROTINAS
  const Exportar = async () => {
    let request = {
      item: {
        AccessCode: AccessCodeInherited,
        SysParam: SYSParam,
        DescricoesAoInvesDeIDs: true,
        Filters: requestFilters,
        Orders: requestOrders,
        Busca: filtro,
      },
      PaginaAtual: 0,
      RegistroPorPagina: 999999,
    }

    let fields = await apiWrapper.get(`api/v1/MenuAppFields/listarPorMenuApp?item.AccessCode=${AccessCodeInherited}`)
    let list = await apiWrapper.post('api/v1/Dynamic/listarComFiltros', request, {})

    let newArray: Array<any> = []

    for (const item of list.data.data) {
      let newEntry: any = {}

      for (const [key, value] of Object.entries(item)) {
        if (key == 'RowNum' || key == 'TotalRecord') {
          continue
        }

        let mappedField = fields.data.data.find((x) => x.campo == key)

        if (mappedField != null) {
          newEntry[mappedField.label] = value
        }
      }

      newArray.push(newEntry)
    }

    const worksheet = utils.json_to_sheet(newArray)
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, 'Dados')
    writeFileXLSX(workbook, `${menuApp.titulo} - Export - ${new Date().toLocaleDateString()}.xlsx`)

    //SALVAR LOG
    apiWrapper.put('api/v1/Dynamic/salvarLogImportacaoExportacao', {
      item: {
        AccessCode: AccessCodeInherited,
        Tipo: 'EXPORTACAO',
        TotalRegistros: list.data.data.length,
      },
    })
  }

  const ExportarPlanilhaConectada = async () => {
    if (rowsCount > 100000) {
      toast.error(
        'A exportação de planilhas conectadas está limitada a 50.000 registros, considere o uso de filtros no cabeçalho do grid para exportar uma quantidade menor de registros.'
      )
      return
    }

    let passwordRequest = await apiWrapper.get(`api/v1/User/verificarSenha?item.IDPessoa=${loggedUser?.id}`)
    if (!passwordRequest.data.data) {
      setModalPassword(true)
      return
    }

    let obj = {
      AccessCode: AccessCodeInherited,
      SysParam: SYSParam,
      Filters: requestFilters.concat(filters ?? []),
      RegistrosPorPagina: 50000,
      PaginaAtual: 0,
    }

    let requestParams = btoa(JSON.stringify(obj))
    let fileText = `WEB\n1\n${process.env.REACT_APP_BST_API_URL}/api/v1/Dynamic/Export?q=${requestParams}`

    DownloadLogico('Planilha de Dados.iqy', fileText)
  }

  const Duplicar = async (id: string) => {
    handleOpenModalPerformingAction('Realizando duplicação de registro, aguarde...')

    let duplicarRequest = await apiWrapper.post('api/v1/Dynamic/duplicarRegistro', {
      item: {AccessCode: AccessCodeMaster, ID: id},
    })

    handleCloseModalPerformingAction()

    if (duplicarRequest.data.mensagem != 'SUCESSO') {
      toast.error(duplicarRequest.data.mensagem)
      return
    }

    toast.success('Registro duplicado com sucesso!')
    GetData(true, false, filtro)

    setModalDynamicItemID(duplicarRequest.data.id)
    setModalSYSMAFItemID(duplicarRequest.data.id)
    setModalSYSMAPItemID(duplicarRequest.data.id)
    setModalSYSMARItemID(duplicarRequest.data.id)
    setModalSYSTEMPLATEItemID(duplicarRequest.data.id)

    setTimeout(() => {
      chooseModalToOpen(AccessCodeMaster, duplicarRequest.data.id)
    }, 100)
  }

  const remapFields = () => {
    apiWrapper
      .get(`api/v1/MenuAppFields/remapearCamposApp?item.AccessCode=${SYSParam}`)
      .then((response) => {
        if (response.data.mensagem == 'SUCESSO') {
          toast.success('Campos validados e remapeados com sucesso!')
          GetData(true)
        } else {
          toast.error(response.data.mensagem)
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.mensagem ?? error.message)
      })
  }

  const fetchZPLUGMAILBOX = () => {
    return apiWrapper.get(
      `api/v1/Email/fetchZPLUGMAILBOX?item.AccessCode=${sysParam}&item.IDIntegration=${IDIntegration}&item.IDMenuAppGenericItem=${IDMenuAppGenericItem}`
    )
  }

  const getEnderecoMailBox = (integrationUsername: string) => {
    let split = integrationUsername.split('@')
    return `${split[0]}+id${IDMenuAppGenericItem}@${split[1]}`
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    toast.success('Copiado para a área de transferência!')
  }

  //* MODALS
  const chooseModalToOpen = (accessCode: string | undefined, id: string) => {
    switch (accessCode) {
      case 'SYSMAF':
        setModalSYSMAF(true)
        setModalSYSMAFItemID(parseInt(id))
        setModalSYSMAFAccessCode(SYSParam)
        break
      case 'SYSMAP':
      case 'SYSMAPVISOES':
        setModalSYSMAP(true)
        setModalSYSMAPItemID(parseInt(id))
        break
      case 'SYSMAR':
        setModalSYSMAR(true)
        setModalSYSMARItemID(parseInt(id))
        break
      case 'SYSMAA':
        setModalSYSMAA(true)
        setModalSYSMAAItemID(parseInt(id))
        break
      case 'SYSTEMPLATE':
        setModalSYSTEMPLATE(true)
        setModalSYSTEMPLATEItemID(parseInt(id))
        break
      case 'SYSACT':
        setModalSYSACT(true)
        setModalSYSACTItemID(parseInt(id))
        break
      case 'SYSSCHEDULE':
        setModalSYSCHEDULE(true)
        setModalSYSSCHEDULEItemID(parseInt(id))
        break
      default:
        setModalDynamic(true)
        setModalDynamicItemID(parseInt(id))
        break
    }
  }

  const chooseModalToClose = () => {
    if (modalDynamic) {
      setModalDynamic(false)
      setModalDynamicItemID(0)
    }

    if (modalSYSMAF) {
      setModalSYSMAF(false)
      setModalSYSMAFItemID(0)
    }

    if (modalSYSMAP) {
      setModalSYSMAP(false)
      setModalSYSMAPItemID(0)
    }

    if (modalSYSMAR) {
      setModalSYSMAR(false)
      setModalSYSMARItemID(0)
    }

    if (modalSYSMAA) {
      setModalSYSMAA(false)
      setModalSYSMAAItemID(0)
    }

    if (modalSYSTEMPLATE) {
      setModalSYSTEMPLATE(false)
      setModalSYSTEMPLATEItemID(0)
    }

    if (modalSYSACT) {
      setModalDynamic(false)
      setModalDynamicItemID(0)
    }

    if (modalSYSSCHEDULE) {
      setModalSYSCHEDULE(false)
      setModalSYSSCHEDULEItemID(0)
    }
  }

  // TAREFA APROVAÇÃO/REPROVAÇÃO

  const [modalTarefa, setModalTarefa] = useState<boolean>(false)
  const [modalTarefaIDs, setModalTarefaIDs] = useState<Array<number>>([])

  const handleOpenModalTarefa = () => {
    setModalTarefa(true)
  }

  const handleCloseModalTarefa = () => {
    setModalTarefa(false)
    setModalTarefaIDs([])
  }

  const handleModalTarefaCallback = (response: any = null) => {
    if (response?.refresh) {
      GetData()
    }

    handleCloseModalTarefa()
  }

  // TELEFONE

  const [modalTelefone, setModalTelefone] = useState<boolean>(false)
  const [modalTelefoneHTMLFragment, setModalTelefoneHTMLFragment] = useState<JSX.Element>(<></>)

  const handleOpenTelefone = (telefone: string) => {
    let formatted = telefone.replaceAll('+', '').replaceAll(' ', '')

    setModalTelefone(true)
    setModalTelefoneHTMLFragment(
      <Box component='div' className='d-flex flex-column'>
        <Box component='div' className='p-1'>
          <a href={`https://api.whatsapp.com/send?phone=${formatted}`} target='_blank'>
            <WhatsAppIcon />
            {telefone}
          </a>
        </Box>
        <Box component='div' className='p-1'>
          <a href={`tel://+${formatted}`} target='_blank'>
            <CallIcon />
            {telefone}
          </a>
        </Box>
      </Box>
    )
  }

  const handleCloseTelefone = () => {
    setModalTelefone(false)
    setModalTelefoneHTMLFragment(<></>)
  }

  // EXCLUSÃO

  const normalise = (value, min, max) => ((value - min) * 100) / (max - min)

  const [exclusaoProgresso, setExclusaoProgresso] = useState<number>(0)

  const [modalAccessCodeExcluir, setModalAccessCodeExcluir] = useState<string | undefined>('')
  const [modalToDeleteItemsExcluir, setModalToDeleteItemsExcluir] = useState<GridRowSelectionModel>([])
  const [modalOpenedExcluir, setModalOpenedExcluir] = useState<boolean>(false)
  const [modalDataIDExcluir, setModalDataIDExcluir] = useState<number>(0)

  const [showExclusaoWait, setShowExclusaoWait] = useState<boolean>(false)

  const handleOpenExcluir = (id: number, accessCode?: string) => {
    setModalDataIDExcluir(id)
    setModalAccessCodeExcluir(accessCode)
    setModalOpenedExcluir(true)
    setExclusaoProgresso(0)
  }

  const handleCloseExcluir = async (excluir: boolean) => {
    setModalOpenedExcluir(false)

    if (!excluir) {
      return
    }

    chooseModalToClose()

    let items: Array<number> = []
    let index: number = 0
    let errorCount: number = 0

    if (modalToDeleteItemsExcluir.length > 0) {
      items = modalToDeleteItemsExcluir.map((item, index) => {
        return item as number
      })
    } else {
      items = [modalDataIDExcluir]
    }

    setShowExclusaoWait(true)

    for (let id of items) {
      let url = ''
      let obj: any = {
        item: {
          ID: id,
        },
      }

      if (modalAccessCodeExcluir == 'SYSMAP' || modalAccessCodeExcluir == 'SYSMAPVISOES') {
        url = 'api/v1/MenuApp/excluir'
      } else if (modalAccessCodeExcluir == 'SYSMAR') {
        url = 'api/v1/MenuAppRelacao/excluir'
      } else {
        obj.item.AccessCode = modalAccessCodeExcluir
        url = 'api/v1/Dynamic/excluir'
      }

      let ret = await apiWrapper.delete(url, {data: obj, headers: {}})
      index++

      setExclusaoProgresso(normalise(index, 0, items.length))

      if (url == 'api/v1/Dynamic/excluir') {
        for (const item of ret.data.data) {
          if (item.item2 != 'SUCESSO') {
            toast.error(`Registro ${item.item1}: ${item.item2}`)
            errorCount++
          }
        }
      } else {
        if (ret.data.mensagem == 'ERRO') {
          toast.error('Não é possível excluir o registro devido ao mesmo estar sendo usado em outras partes do sistema!')
          errorCount++
        }
      }

      if (index == items.length) {
        setShowExclusaoWait(false)
        GetData(true)

        if (errorCount > 0) {
          if (errorCount < items.length) {
            toast.warning('Exclusão realizada parcialmente.')
          }
        } else {
          toast.success('Exclusão realizada com sucesso!')
        }
      }
    }

    chooseModalToClose()
  }

  // DYNAMIC

  const [modalDynamic, setModalDynamic] = useState<boolean>(false)
  const [modalDynamicItemID, setModalDynamicItemID] = useState<number>(0)
  const [modalDynamicItemIDs, setModalDynamicItemIDs] = useState<Array<number>>([])
  const [modalDynamicAcaoEmMassa, setModalDynamicAcaoEmMassa] = useState<boolean>(false)

  const handleOpenModalDynamic = (id: string, acaoEmMassa?: boolean) => {
    setModalDynamic(true)
    setModalDynamicItemID(parseInt(id))
    setModalDynamicItemIDs(gridSelection)
    setModalDynamicAcaoEmMassa(acaoEmMassa ?? false)
  }

  const dynamicCallBack = (response: any = null) => {
    debugger
    if (response != null) {
      if (response.id != null) {
        GetData(true)
      }

      if (response.keepOpen != null && response.keepOpen && response.id != null) {
        setModalDynamicItemID(response.id)

        if (response.customPage) {
          setModalDynamic(false)

          setTimeout(() => {
            setModalDynamic(true)
          }, 1)
        }

        return
      }

      if (response.reopenAsEmailSender) {
        //TODO: Abrir o HTMLSend com os dados do grid como no fluxo padrão de seleção
        //return
      }
    }

    setModalDynamic(false)
    setModalDynamicItemID(0)
    setModalDynamicAcaoEmMassa(false)
  }

  // DYNAMIC READ

  const [modalDynamicRead, setModalDynamicRead] = useState<boolean>(false)
  const [modalDynamicReadAccessCode, setModalDynamicReadAccessCode] = useState<string>('')
  const [modalDynamicReadItemID, setModalDynamicReadItemID] = useState<number>(0)

  const handleOpenDynamicRead = (ac: string, id: number) => {
    setModalDynamicRead(true)
    setModalDynamicReadAccessCode(ac)
    setModalDynamicReadItemID(id)
  }

  const dynamicReadCallBack = (response: any = null) => {
    window['openModalDynamicRead'] = (ac: string, id: number) => {
      handleOpenDynamicRead(ac, id)
    }

    setModalDynamicRead(false)
  }

  // SYSMAF

  const [modalSYSMAF, setModalSYSMAF] = useState<boolean>(false)
  const [modalSYSMAFItemID, setModalSYSMAFItemID] = useState<number>(0)
  const [modalSYSMAFAccessCode, setModalSYSMAFAccessCode] = useState<string | undefined>('')

  const SYSMAFCallBack = (response: any = null) => {
    if (response != null) {
      if (AccessCodeInherited != 'SYSMAF') {
        StartGrid()
      } else {
        GetData(true)
      }

      if (response.keepOpen != null && response.keepOpen && response.id != null) {
        setModalSYSMAFItemID(response.id)
        return
      }
    }

    setModalSYSMAF(false)
    setModalSYSMAFItemID(0)
  }

  const SYSMAFExternal = (accessCode: string | undefined, id: number) => {
    setModalSYSMAF(true)
    setModalSYSMAFItemID(id)
    setModalSYSMAFAccessCode(accessCode)
  }

  // SYSMAP

  const [modalSYSMAP, setModalSYSMAP] = useState<boolean>(false)
  const [modalSYSMAPItemID, setModalSYSMAPItemID] = useState<number>(0)

  const SYSMAPCallBack = (response: any = null) => {
    if (response != null) {
      if (response.id != null) {
        GetData(true)
      }

      if (response.keepOpen != null && response.keepOpen && response.id != null) {
        setModalSYSMAPItemID(response.id)
        return
      }
    }

    setModalSYSMAP(false)
    setModalSYSMAPItemID(0)
  }

  // SYSMAR

  const [modalSYSMAR, setModalSYSMAR] = useState<boolean>(false)
  const [modalSYSMARItemID, setModalSYSMARItemID] = useState<number>(0)

  const SYSMARCallBack = (response: any = null) => {
    if (response != null) {
      if (response.id != null) {
        GetData(true)
      }

      if (response.keepOpen != null && response.keepOpen && response.id != null) {
        setModalSYSMARItemID(response.id)
        return
      }
    }

    setModalSYSMAR(false)
    setModalSYSMARItemID(0)
  }

  // SYSMAA

  const [modalSYSMAA, setModalSYSMAA] = useState<boolean>(false)
  const [modalSYSMAAItemID, setModalSYSMAAItemID] = useState<number>(0)

  const SYSMAACallBack = (response: any = null) => {
    setModalSYSMAA(false)
    setModalSYSMAAItemID(0)
  }

  // SYSTEMPLATE

  const [modalSYSTEMPLATE, setModalSYSTEMPLATE] = useState<boolean>(false)
  const [modalSYSTEMPLATEItemID, setModalSYSTEMPLATEItemID] = useState<number>(0)

  const SYSTEMPLATECallBack = (response: any = null) => {
    if (response != null) {
      if (response.id != null) {
        GetData(true)
      }

      if (response.keepOpen != null && response.keepOpen && response.id != null) {
        setModalSYSTEMPLATEItemID(response.id)
        return
      }
    }

    setModalSYSTEMPLATE(false)
    setModalSYSTEMPLATEItemID(0)
  }

  /* SYSACT */

  const [modalSYSACT, setModalSYSACT] = useState<boolean>(false)
  const [modalSYSACTItemID, setModalSYSACTItemID] = useState<number>(0)

  const handleModalSYSACTCallBack = (response: any) => {
    if (response?.save) {
      GetData(true)
    }

    setModalSYSACT(false)
    setModalSYSACTItemID(0)
  }

  /* SYSSCHEDULE */

  const [modalSYSSCHEDULE, setModalSYSCHEDULE] = useState<boolean>(false)
  const [modalSYSSCHEDULEItemID, setModalSYSSCHEDULEItemID] = useState<number>(0)

  const handleModalSYSSCHEDULECallBack = (response: any) => {
    GetData(true)

    setModalSYSCHEDULE(false)
    setModalSYSSCHEDULEItemID(0)
  }

  // ENVIO HTML

  const [modalHTMLSend, setModalHTMLSend] = useState<boolean>(false)
  const [modalHTMLSendAccessCode, setModalHTMLSendAccessCode] = useState<string>(SYSParam as string)
  const [modalHTMLSendIDIntegration, setModalHTMLSendIDIntegration] = useState<number>(IDIntegration as number)
  const [modalHTMLSendId, setModalHTMLSendId] = useState<number>(0)
  const [modalHTMLSendIdMenuAppGenericItem, setModalHTMLSendIdMenuAppGenericItem] = useState<number>(0)
  const [modalHTMLSendTitle, setModalHTMLSendTitle] = useState<string>('')
  const [modalHTMLSendTo, setModalHTMLSendTo] = useState<string>('')
  const [modalHTMLSendCc, setModalHTMLSendCc] = useState<string>('')
  const [modalHTMLSendSubject, setModalHTMLSendSubject] = useState<string>('')
  const [modalHTMLSendContent, setModalHTMLSendContent] = useState<string>('')

  const HTMLSendCallBack = (response: any = null) => {
    if (response != null) {
      if (response.reload) {
        GetData(true, true)
      }

      if (response.keepOpen) {
        return
      }
    }

    setModalHTMLSend(false)
    setModalHTMLSendId(0)
    setModalHTMLSendTitle('')
    setModalHTMLSendTo('')
    setModalHTMLSendCc('')
    setModalHTMLSendSubject('')
    setModalHTMLSendIdMenuAppGenericItem(0)
  }

  const HTMLSendOpen = (id: number, title: string, to?: string, cc?: string, subject?: string, content?: string) => {
    setModalHTMLSend(true)
    setModalHTMLSendId(id)
    setModalHTMLSendTitle(title)
    setModalHTMLSendTo(to ?? '')
    setModalHTMLSendCc(cc ?? '')
    setModalHTMLSendSubject(subject ?? '')
    setModalHTMLSendContent(content ?? '')
  }

  const HTMLSendOpenWithSelectedItemAsContent = (items: Array<number>) => {
    let content: string = '<table border="1" style="border-collapse: collapse; width: 100%;">'
    content += '<thead>'

    //Recuperamos as colunas ocultas do grid
    let hiddenColumns = Object.keys(invisibleColumns).filter((x) => invisibleColumns[x] == false)

    //Montamos o cabeçalho da tabela
    for (const column of columns) {
      if (hiddenColumns.includes(column.field) || column.field == 'actions' || column.field == 'addColumn') {
        continue
      }

      content += `<th>${column.headerName}</th>`
    }

    content += '</thead>'
    content += '<tbody>'

    //Montamos o corpo da tabela
    for (const item of items) {
      content += '<tr>'

      for (const column of columns) {
        if (hiddenColumns.includes(column.field) || column.field == 'actions' || column.field == 'addColumn') {
          continue
        }

        //Recuperamos o valor do item formatado pelo grid
        let value = apiRef.current.getCellValue(item, column.field)

        //Formatamos o valor em caso de data ou booleano
        if ((column.type == 'date' || column.type == 'dateTime') && value != null && value != '') {
          value = new Date(value).toLocaleDateString()
        } else if (column.type == 'boolean') {
          value = value ? 'Sim' : 'Não'
        }

        content += `<td>${value}</td>`
      }

      content += '</tr>'
    }

    content += '</tbody>'
    content += '</table>'

    setModalHTMLSendAccessCode(AccessCodeInherited)
    setModalHTMLSendIDIntegration(0)
    setModalHTMLSendIdMenuAppGenericItem(items.length == 1 ? items[0] : 0)

    HTMLSendOpen(0, 'Enviar Email', '', '', 'Assunto', content)
  }

  // VISUALIZAÇÃO HTML

  const [modalHTMLViewer, setModalHTMLViewer] = useState<boolean>(false)
  const [modalHTMLViewerContent, setModalHTMLViewerContent] = useState<string>('')

  const HTMLViewerCallBack = (response: any = null) => {
    if (response.action != null) {
      switch (response.action) {
        case 'RES':
          HTMLSendOpen(response.id, 'Responder', response.content.De, undefined, `Res: ${response.content.Nome}`)
          break
        case 'RESALL':
          HTMLSendOpen(response.id, 'Responder a Todos', undefined, `${response.content.De}, ${response.content.Cc}`, `Res: ${response.content.Nome}`)
          break
        case 'ENC':
          HTMLSendOpen(response.id, 'Encaminhar', undefined, undefined, `Enc: ${response.content.Nome}`)
          break
        case 'RELOAD':
          GetData()
          return
      }
    }

    setModalHTMLViewer(false)
    setModalHTMLViewerContent('')
  }

  const HTMLViewerOpen = (content: string) => {
    setModalHTMLViewer(true)
    setModalHTMLViewerContent(content)
  }

  // IMPORTACAO

  const [modalImportacao, setModalImportacao] = useState<boolean>(false)

  const ImportacaoCallBack = (response: any = null) => {
    if (response != null) {
      if (response.refresh) {
        GetData(true)
      }
    }

    setModalImportacao(false)
  }

  // PASSWORD

  const [modalPassword, setModalPassword] = useState<boolean>(false)

  const PasswordCallback = (data: any) => {
    setModalPassword(false)

    if (data.save) {
      ExportarPlanilhaConectada()
    }
  }

  // PERFOMING ACTION

  const [modalPerformingAction, setModalPerformingAction] = useState<boolean>(false)
  const [modalPerformingActionMessage, setModalPerformingActionMessage] = useState<string>('')

  const handleOpenModalPerformingAction = (message: string) => {
    setModalPerformingAction(true)
    setModalPerformingActionMessage(message)
  }

  const handleCloseModalPerformingAction = () => {
    setModalPerformingAction(false)
    setModalPerformingActionMessage('')
  }

  // BUSCA

  let timeoutId: NodeJS.Timeout

  const handleOnChangeFiltro = (filtro: string) => {
    setFiltro(filtro)

    // Cancela o temporizador anterior
    clearTimeout(timeoutId)

    // Inicia um novo temporizador de 3 segundos
    timeoutId = setTimeout(() => {
      if (filtro.length > 0 && filtro.length < 3) {
        return
      }

      // Executa sua lógica após o atraso de 3 segundos

      if (paginationModel.page > 0) {
        setPaginationModel((prev) => {
          prev.page = 0
          return {...prev}
        })
      } else {
        GetData(true, undefined, filtro)
      }
    }, 1000)
  }

  //* USEEFFECTS
  useEffect(() => {
    VerifyAccessCode()

    debugger
    handleLoadActions(AccessCode);
  }, [AccessCode])

  useEffect(() => {
    StartGrid()
  }, [AccessCodeMaster, AccessCodeInherited])

  useEffect(() => {
    if (columns.length == 0) {
      return
    }

    GetData(true, false, filtro)
  }, [paginationModel.page, appMode, requestFilters, requestOrders, filters])

  useEffect(() => {
    BuildControls()
  }, [userRoles, appMode])

  useEffect(() => {
    toast.dismiss()

    if (!isDisplayed) {
      return
    }

    if (!isPrimary) {
      GetData(true)
    }

    BuildControls()
  }, [isDisplayed])

  useEffect(() => {
    window['openModalDynamicRead'] = (ac: string, id: number) => {
      handleOpenDynamicRead(ac, id)
    }
  }, [])

  return (
    <>
      {displayHeader && (
        <Box component='div' className='d-flex flex-stack' sx={{p: 1}}>
          <Box component='div' className='page-title d-flex'>
            <Box component='h1' className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
              {title}
            </Box>
            {accessCode == 'ZPLUGMAILBOX' && IDMenuAppGenericItem != null && integration != null && (
              <>
                <Box component='span' className='h-40px border-gray-200 border-start mx-4'></Box>
                <Box component='ul' className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
                  <Box component='li' className='breadcrumb-item'>
                    Email de comunicação:
                  </Box>
                  <Box component='li' className='breadcrumb-item text-muted'>
                    {getEnderecoMailBox(integration.username)}
                  </Box>
                  <Box component='li' className='breadcrumb-item'>
                    <IconButton onClick={() => copyToClipboard(getEnderecoMailBox(integration.username))}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Box>
                </Box>
              </>
            )}
            {subtitle != null && subtitle != '' && (
              <>
                <Box component='span' className='h-20px border-gray-200 border-start mx-4'></Box>
                <Box component='ul' className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
                  <Box component='li' className='breadcrumb-item text-muted'>
                    {subtitle}
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box component='div' className='d-flex align-items-center py-1'>
            {controls.map((control: any, index: number) => {
              return (
                <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}} key={`ctrl-grid-${index}`}>
                  {control}
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
      {isPrimary && !IsSystemAccessCode() && (
        <Box component='div' style={{position: 'absolute', top: '-72px', right: '1px'}} className='d-flex flex-stack' sx={{pt: 2, pr: 2}}>
          <Box component='div' className='page-title d-flex'></Box>
          <Box component='div' className='d-flex align-items-center py-1'>
            <StyledTextField
              sx={{width: 487}}
              //disabled={loading}
              size='small'
              label='Busca'
              value={filtro}
              onChange={(event) => handleOnChangeFiltro(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          height: !isPrimary ? '100%' : window.location.href.includes('_dynamicTabs') ? 'calc(100vh - 200px)' : 'calc(100vh - 130px)',
          width: '100%',
        }}
      >
        <StyledDataGrid
          sx={{
            display: permissions != null && permissions.biSelect ? '' : 'none',
            minHeight: window.location.href.includes('_dynamicTabs') ? 'calc(100vh - 200px)' : 'calc(100vh - 130px)',
          }}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          apiRef={apiRef}
          components={{
            NoRowsOverlay: () => (
              <>
                <NoRowsOverlay
                  openModal={AccessCode != 'SYSMAA' && permissions != null && permissions.biInsert ? () => handleOpenModalDynamic('0') : undefined}
                />
              </>
            ),
            NoResultsOverlay: NoRowsOverlay,
            LoadingOverlay: LinearProgress,
          }}
          componentsProps={{
            row: {
              onMouseEnter: onMouseEnterRow,
              onMouseLeave: onMouseLeaveRow,
            },
            filterPanel: {
              onKeyDown: (event) => {
                if (event.key == 'Escape' || event.key == 'F3') {
                  event.preventDefault()
                  apiRef.current.hideFilterPanel()
                }
              },
            },
          }}
          editMode={undefined} //'row'
          disableRowSelectionOnClick
          onPreferencePanelClose={(args) => {
            if (args.openedPanelValue == 'filters') {
              setFilterModelOpened(false)
            }
          }}
          onCellDoubleClick={handleCellDoubleClick}
          getRowId={(row: any) => row.ID}
          rows={rows && rows.length > 0 ? rows : []}
          columns={columns}
          checkboxSelection={AccessCodeInherited != 'SYSUSERAUDIT' && AccessCodeInherited != 'SYSMAA'}
          pagination
          rowHeight={38}
          columnHeaderHeight={38}
          columnVisibilityModel={invisibleColumns}
          onColumnVisibilityModelChange={(newModel) => {
            onVisibilityChange(newModel)
          }}
          paginationModel={paginationModel}
          pageSizeOptions={[25]}
          onPaginationModelChange={(newModel) => {
            setPaginationModel(newModel)
            setSelectionModel([])
            BuildControls(false)
          }}
          rowCount={rowsCount}
          paginationMode='server'
          onRowSelectionModelChange={(newSelectionModel) => {
            onSelectionChange(newSelectionModel)
          }}
          filterMode='server'
          filterModel={gridFilter}
          onFilterModelChange={(newFilter, details) => {
            if (details == null) {
              return
            }

            onFilterChange(newFilter, IsSystemAccessCode() || !isPrimary)
          }}
          sortingMode='server'
          sortModel={gridSort}
          onSortModelChange={(newOrder, details) => {
            if (details == null) {
              return
            }

            onOrderChange(newOrder, IsSystemAccessCode())
          }}
          onColumnOrderChange={onColumnHeaderDragEnd}
          rowSelectionModel={selectionModel}
          loading={loading}
        />
        {permissions != null && !IsSystemAccessCode() && !permissions.biSelect && <BoxSVGMessage message='Usuário sem permissão de visualização' />}
      </Box>

      {/* MODAL TAREFA */}
      {modalTarefa && <ModalTarefa ids={modalTarefaIDs} callback={handleModalTarefaCallback} />}

      {/* MODAL TELEFONE */}
      {modalTelefone && (
        <StyledDialog open={modalTelefone}>
          <DialogTitle>Telefone(s)</DialogTitle>
          <DialogContent>{modalTelefoneHTMLFragment}</DialogContent>
          <DialogActions>
            <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleCloseTelefone()}>
              Fechar
            </Button>
          </DialogActions>
        </StyledDialog>
      )}

      {/* MODAL DYNAMIC */}
      {modalDynamic && (
        <DynamicModal
          accessCode={AccessCodeMaster}
          accessCodeMaster={AccessCodeMaster}
          id={modalDynamicItemID}
          ids={modalDynamicItemIDs}
          relationItem={relationItem}
          relationItemID={requestProps?.idRelationItem}
          acaoEmMassa={modalDynamicAcaoEmMassa}
          readonly={readOnly}
          callBack={dynamicCallBack}
          deleteFunc={handleOpenExcluir}
          actions={actions}
          setActions={setActions}
        />
      )}

      {/* MODAL DYNAMIC READ */}
      {modalDynamicRead && (
        <DynamicModal
          readonly={true}
          showClose={true}
          accessCode={modalDynamicReadAccessCode}
          id={modalDynamicReadItemID}
          callBack={dynamicReadCallBack}
        />
      )}

      {/* MODAL SYSMAF */}
      {modalSYSMAF && (
        <SYSMAFModal
          accessCode={modalSYSMAFAccessCode}
          id={modalSYSMAFItemID}
          disableEditType={readOnly}
          callBack={SYSMAFCallBack}
          deleteFunc={handleOpenExcluir}
        />
      )}

      {/* MODAL SYSMAP */}
      {modalSYSMAP && (
        <SYSMAPModal
          id={modalSYSMAPItemID}
          idmenuAppPai={IDMenuAppGenericItem != null ? parseInt(IDMenuAppGenericItem) : undefined}
          callBack={SYSMAPCallBack}
          deleteFunc={handleOpenExcluir}
        />
      )}

      {/* MODAL SYSMAR */}
      {modalSYSMAR && <SYSMARModal accessCode={SYSParam} id={modalSYSMARItemID} callBack={SYSMARCallBack} deleteFunc={handleOpenExcluir} />}

      {/* MODAL SYSMAA */}
      {modalSYSMAA && <SYSMAAModal id={modalSYSMAAItemID} callBack={SYSMAACallBack} />}

      {/* MODAL SYSTEMPLATE */}
      {modalSYSTEMPLATE && <SYSTEMPLATEModal id={modalSYSTEMPLATEItemID} callBack={SYSTEMPLATECallBack} deleteFunc={handleOpenExcluir} />}

      {/* MODAL SYSACT */}
      {modalSYSACT && <ModalSYSACT id={modalSYSACTItemID} accessCode={SYSParam as string} callBack={handleModalSYSACTCallBack} />}

      {/* MODAL SYSSCHEDULE */}
      {modalSYSSCHEDULE && <SYSCHEDULEModal id={modalSYSSCHEDULEItemID} callBack={handleModalSYSSCHEDULECallBack} />}

      {/* MODAL HTMLSEND */}
      {modalHTMLSend && (
        <HTMLSend
          id={modalHTMLSendId}
          accessCode={modalHTMLSendAccessCode}
          idIntegration={modalHTMLSendIDIntegration}
          idMenuAppGenericItem={IDMenuAppGenericItem != null ? IDMenuAppGenericItem : modalHTMLSendIdMenuAppGenericItem.toString()}
          callBack={HTMLSendCallBack}
          title={modalHTMLSendTitle}
          to={modalHTMLSendTo}
          cc={modalHTMLSendCc}
          subject={modalHTMLSendSubject}
          content={modalHTMLSendContent}
          maxWidth='xl'
        />
      )}

      {/* MODAL IMPORTACAO */}
      {modalImportacao && <ImportacaoModal accessCode={AccessCodeMaster} callBack={ImportacaoCallBack} />}

      {/* MODAL PASSWORD */}
      {modalPassword && (
        <PasswordModal
          idPessoa={loggedUser?.id ?? 0}
          content={
            <Box component='div' className='alert alert-warning d-flex align-items-center'>
              <div className='d-flex flex-column'>
                <span>
                  <strong>ATENÇÃO</strong>: É necessário ter uma senha cadastrada no sistema para acessar planilhas conectadas!
                </span>
                <br />
                <span>Defina uma senha que tenha no mínimo 6 caracteres.</span>
              </div>
            </Box>
          }
          callback={PasswordCallback}
        />
      )}

      {/* MODAL HTML */}
      {modalHTMLViewer && <HTMLViewer content={modalHTMLViewerContent} permissions={permissions} callBack={HTMLViewerCallBack} />}

      {/* MODAL EXCLUSÃO */}
      <ModalExclusao open={modalOpenedExcluir} title='Excluir' message='Esta ação não pode ser desfeita, confirma?' callBack={handleCloseExcluir} />

      {/* MODAL EXCLUSÃO PROGRESS */}
      {showExclusaoWait && <ModalPerformingAction message='Realizando exclusão...' progress={exclusaoProgresso} />}

      {/* MODAL PERFORMING ACTION */}
      {modalPerformingAction && <ModalPerformingAction message={modalPerformingActionMessage} />}

      <StyledMenu
        anchorEl={anchorElCM}
        open={contextMenuOpen}
        onClose={() => {
          setAnchorElCM(null)
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            GetData(true)
            setAnchorElCM(null)
          }}
        >
          <ListItemIcon>
            <RefreshIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Recarregar</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClearFilters()
            setAnchorElCM(null)
          }}
        >
          <ListItemIcon>
            <FilterAltOffIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Limpar filtros</ListItemText>
        </MenuItem>
        {!readOnly && permissions != null && permissions.biInsert && !IsSystemAccessCode(true) && !IsPluginAccessCode() && (
          <MenuItem
            onClick={() => {
              setModalImportacao(true)
              setAnchorElCM(null)
            }}
          >
            <ListItemIcon>
              <FileUploadIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Importar</ListItemText>
          </MenuItem>
        )}
        {permissions != null && permissions.biSelect && !IsSystemAccessCode(true) && (
          <>
            <MenuItem
              onClick={() => {
                Exportar()
                setAnchorElCM(null)
              }}
            >
              <ListItemIcon>
                <FileDownloadIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText>Exportar</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                ExportarPlanilhaConectada()
                setAnchorElCM(null)
              }}
            >
              <ListItemIcon>
                <GridOnIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText title='Geração de arquivo de leitura para consulta de dados do App.'>Gerar planilha de leitura</ListItemText>
            </MenuItem>
          </>
        )}
        {isPrimary && (!IsSystemAccessCode() || (AccessCodeInherited == 'SYSMAF' && SYSParam != null)) && (
          <MenuItem
            onClick={() => {
              setAnchorElCM(null)

              let cfgRoute = IsSystemAccessCode() ? '/pages/_dynamicTabs/' + SYSParam : '/pages/_dynamic/SYSMAF/' + AccessCodeInherited

              navigate(cfgRoute, {replace: true})
            }}
          >
            <ListItemIcon>
              {!IsSystemAccessCode() && <SettingsIcon fontSize='small' />}
              {IsSystemAccessCode() && <ArrowBackIcon fontSize='small' />}
            </ListItemIcon>
            <ListItemText>
              {!IsSystemAccessCode() && <>Gerenciar Campos</>}
              {IsSystemAccessCode() && <>Retornar ao App</>}
            </ListItemText>
          </MenuItem>
        )}
      </StyledMenu>
      {pdfUrl && (
        <ModalPdf
          pdfUrl={pdfUrl ?? ""}
          callback={(data) => {
            if (data.closed) {
              handleClosePdfModal();
            }
            if (data.loaded) {
              handlePdfLoaded();
            }
          }}
        />
      )}
    </>
  )
}

export default DynamicList
