import uuid from 'react-uuid'
import {
  IElementStructure,
  IChartStructure,
  ILinkStructure,
  IKanbanHistoryStructure,
  IElementLayoutStructure,
  IMenuAppHistoryStructure,
  IGlobalFilterStructure,
  IFileStructure,
  IImageStructure,
  IDesktopStructure,
  IGridHistoryStructure,
  IChartDatasetStructure,
} from '../interfaces/IMenuAppHistoryStructure'
import {GridFilterModel, GridSortModel} from '@mui/x-data-grid-pro'

export class CGridHistoryStructure implements IGridHistoryStructure {
  filter: GridFilterModel | undefined
  order: GridSortModel | undefined
  columns: Array<string>

  constructor() {
    this.columns = []
  }
}

export class CKanbanHistoryStructure implements IKanbanHistoryStructure {
  ultimaVisaoSelecionada: number
  filtro: Array<IGlobalFilterStructure>
  ids: Array<number>

  constructor() {
    this.ultimaVisaoSelecionada = 0
    this.filtro = []
    this.ids = []
  }
}

export class CElementLayoutStructure implements IElementLayoutStructure {
  x: number
  y: number
  w: number
  h: number
  minW?: number
  minH?: number
  maxW?: number
  maxH?: number
  i: string
  isBounded?: boolean
  isDraggable?: boolean
  isResizable?: boolean
  moved?: boolean
  static?: boolean
  resizeHandles?: string[]

  constructor() {
    this.x = 0
    this.y = 0
    this.w = 0
    this.h = 0
    this.minW = 0
    this.minH = 0
    this.maxW = 0
    this.maxH = 0
    this.i = ''
    this.isBounded = undefined
    this.isDraggable = undefined
    this.isResizable = undefined
    this.moved = undefined
    this.static = undefined
    this.resizeHandles = undefined
  }
}

export class CChartDatasetStructure implements IChartDatasetStructure {
  guid: string
  operacao: string
  campo: number
  cor: string

  constructor() {
    this.guid = uuid()
    this.operacao = ''
    this.campo = 0
    this.cor = ''
  }
}

export class CGlobalFilterStructure implements IGlobalFilterStructure {
  guid: string
  campo: number
  operadorLogico: string
  operador: string
  tipoValor: string
  valor: string

  constructor() {
    this.guid = uuid()
    this.campo = 0
    this.operadorLogico = ''
    this.operador = ''
    this.tipoValor = ''
    this.valor = ''
  }
}

export class CChartStructure implements IChartStructure {
  tipo: string
  ordem: string
  top: string
  dimensao: number
  datasets: Array<IChartDatasetStructure>
  filtro: Array<IGlobalFilterStructure>

  constructor() {
    this.tipo = ''
    this.ordem = ''
    this.top = ''
    this.dimensao = 0
    this.datasets = []
    this.filtro = []
  }
}

export class CLinkStructure implements ILinkStructure {
  nome: string
  tipo: string
  valor: string
  icone: string
  descricao: string

  constructor() {
    this.nome = ''
    this.tipo = 'menuapp'
    this.valor = ''
    this.icone = ''
    this.descricao = ''
  }
}

export class CFileStructure implements IFileStructure {
  menuapp: number
  filtro: Array<IGlobalFilterStructure>

  constructor() {
    this.menuapp = 0
    this.filtro = []
  }
}

export class CImageStructure implements IImageStructure {
  idArquivo: number

  constructor() {
    this.idArquivo = 0
  }
}

export class CElementStructure implements IElementStructure {
  guid: string
  nome: string
  tipo: string
  showTitle: boolean
  icone: string
  layout?: CElementLayoutStructure
  chart: CChartStructure
  link: CLinkStructure
  file: CFileStructure
  image: CImageStructure

  constructor() {
    this.guid = uuid()
    this.nome = ''
    this.tipo = ''
    this.showTitle = true
    this.icone = ''
    this.layout = undefined
    this.chart = new CChartStructure()
    this.link = new CLinkStructure()
    this.file = new CFileStructure()
    this.image = new CImageStructure()
  }
}

export class CDesktopStructure implements IDesktopStructure {
  guid: string
  nome: string
  elements: Array<CElementStructure>

  constructor() {
    this.guid = uuid()
    this.nome = 'Sem título'
    this.elements = []
  }
}

export class CMenuAppHistoryStructure implements IMenuAppHistoryStructure {
  guid: string
  nome: string
  grid: CGridHistoryStructure
  kanban: CKanbanHistoryStructure
  desktops: Array<CDesktopStructure>

  constructor() {
    this.guid = uuid()
    this.nome = ''
    this.grid = {filter: undefined, order: undefined, columns: []}
    this.kanban = {
      ultimaVisaoSelecionada: 0,
      filtro: [],
      ids: [],
    }
    this.desktops = []
  }
}
