import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import DynamicChartsV2 from './components/DynamicChartsV2'

interface IDynamicParams {
  isDisplayed?: boolean
}

export const TabChart = ({isDisplayed}: IDynamicParams) => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/:accesscode'
          element={
            <>
              <DynamicChartsV2 isDisplayed={isDisplayed} isPrimary />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/:accesscode/:sysparam'
          element={
            <>
              <DynamicChartsV2 isDisplayed={isDisplayed} isPrimary />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
