import DialogTitle from '@mui/material/DialogTitle'
import StyledDialog from '../styled/StyledDialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import StyledButton from '../styled/StyledButton'

import CancelIcon from '@mui/icons-material/Cancel'
import SaveIcon from '@mui/icons-material/Save'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import SendIcon from '@mui/icons-material/Send'
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck'
import PendingIcon from '@mui/icons-material/Pending'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

import {useRef, useState} from 'react'
import FormControl from '@mui/material/FormControl'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import {StyledTextField} from '../styled/StyledTextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import {toast} from 'react-toastify'
import Typography from '@mui/material/Typography'

interface ITwoFactorModal {
  callback(data: any): void
}

const TwoFactorModal = ({callback}: ITwoFactorModal) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const inputRefs = useRef<(HTMLInputElement | null)[]>([])

  const [state, setState] = useState<string>('SEND')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [showHiddenPassword, setShowHiddenPassword] = useState<boolean>(false)
  const [showHiddenConfirmPassword, setShowHiddenConfirmPassword] = useState<boolean>(false)

  const [code, setCode] = useState<Array<string>>(['', '', '', '', '', ''])
  const [completeCode, setCompleteCode] = useState<number>(0)

  const title = {
    SEND: 'Informe o email do usuário',
    SENDING: 'Solicitando código...',
    VALIDATE: 'Informe o código enviado ao email informado',
    VALIDATING: 'Validando código informado...',
    SAVE: 'Informe a nova senha',
    SAVING: 'Salvando nova senha informada...',
  }

  const btnLabel = {
    SEND: 'Enviar',
    SENDING: 'Enviando...',
    VALIDATE: 'Validar',
    VALIDATING: 'Validando...',
    SAVE: 'Salvar',
    SAVING: 'Salvando...',
  }

  const btnIcon = {
    SEND: <SendIcon />,
    SENDING: <PendingIcon />,
    VALIDATE: <SafetyCheckIcon />,
    VALIDATING: <PendingIcon />,
    SAVE: <SaveIcon />,
    SAVING: <PendingIcon />,
  }

  const handleCallback = (data?: any) => {
    callback(data)
  }

  const handleClose = () => {
    handleCallback()
  }

  const handleUpdateCode = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) {
      return
    }

    if (index < inputRefs.current.length - 1 && value.length > 0) {
      inputRefs.current[index + 1]?.focus()
    }

    setCode((prev) => {
      prev[index] = value
      return [...prev]
    })
  }

  const handleAdvance = async (currentState: string) => {
    if (currentState == 'SEND') {
      if (email == '') {
        toast.error('É necessário informar um email válido!')
        return
      }

      setState('SENDING')

      await apiWrapper.get(`api/v1/Auth/2FRequest?item.Email=${email}`)
      toast.success('Código enviado com sucesso!')

      setState('VALIDATE')
    } else if (currentState == 'VALIDATE') {
      let allNumbers: boolean = code.every((char) => /^[0-9]$/.test(char))

      if (!allNumbers) {
        toast.error('Todos os caracteres devem ser números!')
        return
      }

      let combinedCode = code.join('')

      if (combinedCode.length != 6) {
        toast.error('É necessário informar todos os 6 dígitos do código!')
        return
      }

      let finalCode = parseInt(combinedCode)
      setCompleteCode(finalCode)

      setState('VALIDATING')

      await apiWrapper.post('api/v1/Auth/2FValidate', {item: {Email: email, TwoFactorCode: finalCode, OnlyValidate: true}}).then((response) => {
        if (response.data.mensagem == 'Código validado com sucesso!') {
          setState('SAVE')
          return
        }

        toast.error('O código informado é inválido!')
        setState('VALIDATE')
      })
    } else if (currentState == 'SAVE') {
      if (password != confirmPassword) {
        toast.error('As senhas não coincídem')
        return
      }

      if (password.length < 6) {
        toast.error('A senha precisa ter no mínimo 6 caracteres')
        return
      }

      setState('SAVING')

      await apiWrapper.post('api/v1/Auth/2FSavePassword', {item: {Email: email, TwoFactorCode: completeCode, Senha: btoa(password)}})

      toast.success('Nova senha cadastrada com sucesso!')
      handleClose()
    }
  }

  return (
    <StyledDialog open fullWidth maxWidth='sm'>
      <DialogTitle>{title[state]}</DialogTitle>
      <DialogContent dividers sx={{display: 'grid'}}>
        {(state == 'SENDING' || state == 'VALIDATING' || state == 'SAVING') && (
          <Box component='div' className='d-flex justify-content-center'>
            <CircularProgress />
          </Box>
        )}
        <FormControl sx={{justifyContent: 'center', alignItems: 'center'}} component='fieldset' variant='standard'>
          {state == 'SEND' && (
            <>
              <Box component='div' sx={{px: 1, py: 3, textAlign: 'center'}}>
                <Typography component='span' variant='h5'>
                  Insira o endereço de email associado a sua conta
                </Typography>
              </Box>
              <Box component='div' sx={{p: 1}}>
                <StyledTextField
                  fullWidth
                  size='small'
                  sx={{width: '350px'}}
                  label='Email do usuário que receberá o código'
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Box>
            </>
          )}
          {state == 'VALIDATE' && (
            <>
              <Box component='div' sx={{px: 1, py: 3, textAlign: 'center'}}>
                <Typography component='span' variant='h5'>
                  Digite o código de verificação enviado para {email}
                </Typography>
              </Box>
              <Box component='div' className='d-flex' sx={{p: 1}}>
                <StyledTextField
                  inputRef={(el) => (inputRefs.current[0] = el)}
                  fullWidth
                  size='small'
                  sx={{width: '51px', px: 1}}
                  value={code[0]}
                  onChange={(event) => handleUpdateCode(0, event.target.value)}
                  inputProps={{maxLength: 1}}
                />
                <StyledTextField
                  inputRef={(el) => (inputRefs.current[1] = el)}
                  fullWidth
                  size='small'
                  sx={{width: '51px', px: 1}}
                  value={code[1]}
                  onChange={(event) => handleUpdateCode(1, event.target.value)}
                  inputProps={{maxLength: 1}}
                />
                <StyledTextField
                  inputRef={(el) => (inputRefs.current[2] = el)}
                  fullWidth
                  size='small'
                  sx={{width: '51px', px: 1}}
                  value={code[2]}
                  onChange={(event) => handleUpdateCode(2, event.target.value)}
                  inputProps={{maxLength: 1}}
                />
                <StyledTextField
                  inputRef={(el) => (inputRefs.current[3] = el)}
                  fullWidth
                  size='small'
                  sx={{width: '51px', px: 1}}
                  value={code[3]}
                  onChange={(event) => handleUpdateCode(3, event.target.value)}
                  inputProps={{maxLength: 1}}
                />
                <StyledTextField
                  inputRef={(el) => (inputRefs.current[4] = el)}
                  fullWidth
                  size='small'
                  sx={{width: '51px', px: 1}}
                  value={code[4]}
                  onChange={(event) => handleUpdateCode(4, event.target.value)}
                  inputProps={{maxLength: 1}}
                />
                <StyledTextField
                  inputRef={(el) => (inputRefs.current[5] = el)}
                  fullWidth
                  size='small'
                  sx={{width: '51px', px: 1}}
                  value={code[5]}
                  onChange={(event) => handleUpdateCode(5, event.target.value)}
                  inputProps={{maxLength: 1}}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <StyledButton
                  variant='contained'
                  color='secondary'
                  startIcon={<RestartAltIcon />}
                  onClick={(event) => {
                    handleAdvance('SEND')
                  }}
                >
                  Reenviar
                </StyledButton>
              </Box>
            </>
          )}
          {state == 'SAVE' && (
            <>
              <Box component='div' sx={{px: 1, py: 3, textAlign: 'center'}}>
                <Typography component='span' variant='h5'>
                  Informe a nova senha
                </Typography>
              </Box>
              <Box component='div' sx={{p: 1}}>
                <StyledTextField
                  fullWidth
                  size='small'
                  type={showHiddenPassword ? 'text' : 'password'}
                  sx={{width: '350px'}}
                  label='Nova senha'
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={(event) => setShowHiddenPassword((prev) => !prev)} onMouseDown={(e) => e.preventDefault()} edge='end'>
                          {showHiddenPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <StyledTextField
                  fullWidth
                  size='small'
                  type={showHiddenConfirmPassword ? 'text' : 'password'}
                  sx={{width: '350px'}}
                  label='Confirmar nova senha'
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={(event) => setShowHiddenConfirmPassword((prev) => !prev)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge='end'
                        >
                          {showHiddenConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <StyledButton
          variant='contained'
          color='inherit'
          disabled={state == 'SENDING' || state == 'VALIDATING' || state == 'SAVING'}
          startIcon={<CancelIcon />}
          onClick={(event) => handleClose()}
        >
          Fechar
        </StyledButton>
        <StyledButton
          variant='contained'
          color='success'
          disabled={state == 'SENDING' || state == 'VALIDATING' || state == 'SAVING'}
          startIcon={btnIcon[state]}
          onClick={(event) => handleAdvance(state)}
        >
          {btnLabel[state]}
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  )
}

export default TwoFactorModal
