import {IntlShape} from 'react-intl'

/**
 * FUNÇÃO RESPONSÁVEL PELO AUTOCOMPLETAR DO COMPONETE CODEMIRROR.
 * PARA EXIBIR O AUTOCOMPLETAR, BASTA DIGITAR UM DOS TERMOS MAPEADOS
 * NO CODIGO ABAIXO OU PRESSIONAR CTRL+ESPAÇO NO TECLADO
 * @param context CAPTURA DETALHES DE VALORES VINDO DO COMPONENTE
 * @returns RETORNA COM AS DEVIDAS AÇÕES
 */
export const handleOnCompletions = (intl: IntlShape, context: any) => {
  let word = context.matchBefore(/\w*/)
  if (word.from == word.to && !context.explicit) return null
  return {
    from: word.from,
    options: [
      {label: '[CTB]', type: 'text', info: intl.formatMessage({id: 'CODEMIRROR.AUTOCOMPLETE.CTB'})},
      {label: '{CTE}', type: 'text', info: 'Utilize para capturar o valor de um campo da tela. Ex: {NOME}'},
      {
        label: '<SQL>',
        type: 'text',
        apply: '"<SQL=SELECT CAMPO FROM TABELA WHERE ID=[CAMPO_TABELA_BANCO]"',
        detail: 'macro',
        info: 'Utilize para informar uma SQL customizado para restagar um valor. Ex: SELECT ID FROM TABELA WHERE NOME=[CAMPO_TABELA_BANCO]',
      },
      {label: '@GETDATE', type: 'text', apply: '"@GETDATE"', detail: 'macro', info: 'Utilize para restagar a data atual'},
      {label: '@USERID', type: 'text', apply: '"@USERID"', detail: 'macro', info: 'Utilize para restagar o usuario Logado'},
    ],
  }
}
