//--<< IMPORTS DE COMPONENTES >>--\\

import {useEffect, useState} from 'react'
import moment from 'moment'
import uuid from 'react-uuid'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'

import MenuAppResourceSelect from '../utility/menuAppResourceSelect'
import {LocalizationProvider, DesktopDatePicker, DateTimePicker} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import ptBRLocale from 'date-fns/locale/pt-BR'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import {fieldOperators} from '../../../../models/objects/operators'
import {badgeColors} from '../../../../models/objects/badgeColors'
import {textColors} from '../../../../models/objects/textColors'
import {handleApplyClampedValueToState, handleApplyValueToState} from '../../../../models/wrappers/handlers'
import {SingleSelectResult} from '../../../../modules/api/models/Result/SingleSelectResult'
import StyledDialog from '../styled/StyledDialog'
import StyledFormControl from '../styled/StyledFormControl'

//--<< END IMPORTS DE COMPONENTES >>--\\

interface IExibicao {
  accessCode?: string
  campo: any
  exibicaoItem?: any
  exibicaoItemIndex: number
  exibicaoItemOperadorLogico?: string
  callBack?: (data?: any) => void
  isMenuAppFilters?: boolean
}

export const ExibicaoModal = ({accessCode, campo, exibicaoItem, exibicaoItemIndex, callBack}: IExibicao) => {
  // Chamamos o MSAL como uma constante consequentemente o re-renderizando e a classe de chamada da API para este componente comsumir
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  // Variaveis de estado
  const [Opened, setOpened] = useState<boolean>(false)
  const [Title, setTitle] = useState<string>('')
  const [Data, setData] = useState<any>(null)
  const [DataIndex, setDataIndex] = useState<number>(-1)
  const [Operators, setOperators] = useState<Array<any>>([])
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<Array<SingleSelectResult>>([])

  /**
   * Verifica se a função de callback é existente e se for chama a mesma passando o retorno para o componente que esta renderizando este.
   * @param data (Opcional) Objeto de retorno para o componente "Pai"
   * @returns void
   */
  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  /**
   * Função de inicialização do componente, geralmente chamada no primeiro useEffect.
   * @returns void
   */
  const handleOpen = async () => {
    // Decidimos o título do modal baseado no objeto de exibição passado para o modal
    setTitle(exibicaoItem != null ? 'Editar Item' : 'Novo Item')

    let item: any = exibicaoItem ?? {
      guid: uuid(),
      operador: '',
      acao: '',
      valor: '',
      extra: '',
      icone: '',
      cor: '',
      ordem: exibicaoItemIndex,
    }

    // Selecionamos os operadores disponíveis para o tipo do campo informado
    setOperators(fieldOperators[campo.tipo])

    // Aplicamos o novo objeto de exibição no estado e o index dele
    setData(item)
    setDataIndex(exibicaoItemIndex)

    // Chamamos a função de input change dos auto completes para eles ja procurarem o objeto que possui o valor aplicado a eles
    handleOnInputChangeAutoComplete('')

    // Aqui setamos a variavel de estado "Opened" para verdadeiro consequentemente abrindo o modal no html
    setOpened(true)
  }

  /**
   * Função de "fechamento" do componente, chamada para salvar os dados ou não e, logo após, enderrar a renderização.
   * @param save Define se os dados devem ser enviados para a API para salvamento
   * @returns void
   */
  const handleClose = (save: boolean) => {
    // Fechamos o modal
    setOpened(false)
    // Enviamos o item novo de exibição para o componente "Pai"
    handleCallBack(save ? {item: Data, index: DataIndex} : null)
  }

  /**
   * Função de abertura do auto complete.
   * @param value Valor para verificar se é necessário chamar a função de onInputChange
   * @returns void
   */
  const handleOnOpenAutoComplete = (value: any) => {
    // Se não tiver nenhum valor então sabemos que devemos chamar a API para popular os dados do auto complete
    if (value == '' || value == null) {
      handleOnInputChangeAutoComplete('')
    }
  }

  /**
   * Função de mudança de input do auto complete, chamado para popular os dados de seleção.
   * @param value Input do usuário para enviar como query de filtragem para a API
   * @param id (Opcional) ID do item caso o mesmo ja exista, assim a API carrega APENAS aquele item ao invés de filtrar todos por um campo de exibição
   * @returns Promise<void>
   */
  const handleOnInputChangeAutoComplete = async (value: string, id?: any) => {
    let url: string = ''

    // Decicimos como montar a URL de request para o retorno dos dados, caso temos o ID usamos o mesmo, senão usamos a query mesmo que seja vazia
    if (id == null) {
      url = `/api/v1/Dynamic/listarSingleSelect?item.AccessCode=${accessCode}&item.IDMenuAppField=${campo.id}&item.Query=${value}&RegistroPorPagina=15`
    } else {
      url = `/api/v1/Dynamic/listarSingleSelect?item.AccessCode=${accessCode}&item.IDMenuAppField=${campo.id}&item.ID=${id}&RegistroPorPagina=15`
    }

    // Requisitamos os dados da API
    const result = await apiWrapper.get(url)

    // Aplicamos os dados a variavel de estado
    setAutoCompleteOptions(result.data.data)

    // Se o id foi informado então já aplicamos ele direto como valor pois ele é a ÚNICA opção que veio da API
    if (id != null) {
      setData((prev: any) => {
        return {...prev, valor: id.toString()}
      })
    }
  }

  /**
   * Função de mudança de vlaor do auto complete, a mesma realiza ações extras dependendo do auto complete que chamou essa função.
   * @param key Chave do objeto de estado para aplicar o valor
   * @param value Valor para aplicar na chave da variável de estado
   * @returns void
   */
  const handleOnChangeAutoComplete = (key: string, value: any) => {
    setData((prev: any) => {
      if (key == 'operador') {
        prev['valor'] = ''
      }

      if (key == 'acao') {
        prev['cor'] = ''
        prev['icone'] = ''
      }

      return {...prev, [key]: value}
    })
  }

  /**
   * Função que escolhe um componente para seleção de valor baseado no tipo informado.
   * @param type Tipo do valor
   * @param label Label para aplicar ao componente
   * @returns Componente escolhido com base nos parâmetros
   */
  function GetComponenteValor(type: string, label: string) {
    switch (type) {
      case 'boolean':
        return (
          <Autocomplete
            disablePortal
            options={['Verdadeiro', 'Falso']}
            sx={{width: 300}}
            size='small'
            renderInput={(params) => <TextField {...params} label={label} />}
            value={Data?.valor}
            onChange={(event, value, reason) => handleOnChangeAutoComplete('valor', value)}
          />
        )
      case 'string':
        return (
          <TextField
            sx={{width: 300}}
            size='small'
            label={label}
            value={Data?.valor}
            onChange={(event) => handleApplyValueToState(setData, 'valor', event.target.value)}
          />
        )
      case 'number':
        return (
          <TextField
            sx={{width: 300}}
            size='small'
            label={label}
            type='number'
            InputLabelProps={{shrink: true}}
            value={Data?.valor}
            onChange={(event) => handleApplyValueToState(setData, 'valor', event.target.value)}
          />
        )
      case 'decimal':
        return (
          <TextField
            sx={{width: 300}}
            size='small'
            label={label}
            type='number'
            InputLabelProps={{shrink: true}}
            inputProps={{
              maxLength: '14',
              step: '0.01',
            }}
            value={Data?.valor}
            onChange={(event) => handleApplyClampedValueToState(setData, 'valor', parseFloat(event.target.value), 0, 9999999.99, 2)}
          />
        )
      case 'date':
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
            <DesktopDatePicker
              label={label}
              value={Data?.valor != '' ? new Date(Data?.valor as string) : null}
              onChange={(date) => handleApplyValueToState(setData, 'valor', moment(date).toISOString())}
              sx={{width: 300}}
            />
          </LocalizationProvider>
        )
      case 'dateTime':
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
            <DateTimePicker
              label={label}
              value={Data?.valor != '' ? new Date(Data?.valor as string) : null}
              onChange={(date) => handleApplyValueToState(setData, 'valor', moment(date).toISOString())}
              sx={{width: 300}}
            />
          </LocalizationProvider>
        )
      case 'singleSelect':
        return (
          <Autocomplete
            disablePortal
            options={autoCompleteOptions}
            sx={{width: 300}}
            size='small'
            renderInput={(params) => <TextField {...params} label={label} />}
            value={Data?.valor != null && Data?.valor != '' ? autoCompleteOptions.filter((op: any) => op.label == Data?.valor)[0] ?? null : null}
            onOpen={(event) => handleOnOpenAutoComplete(event)}
            onChange={(event, value, reason) => handleOnChangeAutoComplete('valor', value != null ? value.label : '')}
            onInputChange={(event, value) => handleOnInputChangeAutoComplete(value)}
            filterOptions={(x) => x}
          />
        )
      default:
        return <></>
    }
  }

  // Use Effect padrão, ele é chamado sempre que o modal é instanciado
  useEffect(() => {
    handleOpen()
  }, [])

  return (
    <StyledDialog open={Opened} fullWidth={true} maxWidth='sm'>
      <DialogTitle>{Title}</DialogTitle>
      <DialogContent dividers={true} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px'}}>
        <StyledFormControl sx={{m: 2}} component='fieldset' variant='standard'>
          {Opened && Data && (
            <>
              <Box component='div' sx={{p: 1}}>
                <TextField
                  sx={{width: 300}}
                  size='small'
                  label='Ordem'
                  type='number'
                  value={Data?.ordem}
                  onChange={(event) => handleApplyValueToState(setData, 'ordem', event.target.value)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  options={Operators}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Operador' />}
                  value={Data?.operador}
                  onChange={(event, value, reason) => handleOnChangeAutoComplete('operador', value)}
                />
              </Box>
              {Data?.operador != '' && Data?.operador != 'Valor nulo' && (
                <Box component='div' sx={{p: 1}}>
                  {GetComponenteValor(campo.tipo, 'Valor')}
                </Box>
              )}
              {Data?.operador == 'Valor entre' && (
                <Box component='div' sx={{p: 1}}>
                  {GetComponenteValor(campo.tipo, 'Máximo')}
                </Box>
              )}
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  options={['Exibir badge', 'Exibir ícone', 'Alterar a cor do texto']}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Ação' />}
                  value={Data?.acao}
                  onChange={(event, value, reason) => handleOnChangeAutoComplete('acao', value)}
                />
              </Box>
              {Data?.acao == 'Exibir ícone' && (
                <Box component='div' sx={{p: 1}}>
                  <MenuAppResourceSelect
                    id='icone-select'
                    label='Ícone'
                    labelId='icone-select-label'
                    type='fontawesome'
                    value={Data?.icone}
                    onChange={(event) => handleApplyValueToState(setData, 'icone', event.target.value)}
                  />
                </Box>
              )}
              {Data?.acao == 'Exibir badge' && (
                <Box component='div' sx={{p: 1}}>
                  <Autocomplete
                    disablePortal
                    options={badgeColors}
                    sx={{width: 300}}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Cor' />}
                    value={Data?.cor != null && Data?.cor != '' ? badgeColors.filter((op: any) => op.id == Data?.cor)[0] ?? null : null}
                    onChange={(event, value, reason) => handleOnChangeAutoComplete('cor', value?.id ?? '')}
                  />
                </Box>
              )}
              {Data?.acao == 'Alterar a cor do texto' && (
                <Box component='div' sx={{p: 1}}>
                  <Autocomplete
                    disablePortal
                    options={textColors}
                    sx={{width: 300}}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Cor' />}
                    value={Data?.cor != null && Data?.cor != '' ? textColors.filter((op: any) => op.id == Data?.cor)[0] ?? null : null}
                    onChange={(event, value, reason) => handleOnChangeAutoComplete('cor', value?.id ?? '')}
                  />
                </Box>
              )}
            </>
          )}
        </StyledFormControl>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleClose(false)}>
          Fechar
        </Button>
        <Button variant='contained' startIcon={<SaveIcon />} color='success' onClick={() => handleClose(true)}>
          Salvar
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default ExibicaoModal
