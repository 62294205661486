/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Login} from './components/Login'
import {toAbsoluteUrl, toAbsoluteResourceUrl} from '../../../_metronic/helpers'

const AuthenticationLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url(${toAbsoluteResourceUrl('/media/background/fundo.jpg')})`,
          backgroundSize: 'cover',
          zIndex: 0,
        }}
      >
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          <div className='login-background-effect'></div>
          <a href='#' className='mb-12'>
            <img alt='Logo' src={toAbsoluteResourceUrl('/media/logos/logo.png')} className='h-80px' />
          </a>
          <div className='w-lg-500px rounded shadow-sm p-lg-5 mx-auto'>
            <Outlet />
          </div>
        </div>
        <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            <a href='#' className='text-muted text-hover-primary px-2'>
              Versão {process.env.REACT_APP_VERSION} - {process.env.REACT_APP_ENV_FRONT}
            </a>
          </div>
        </div>
      </div>
      {/* ORIGINAL CODE */}
      {/*
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/default.svg')} className='h-45px' />
        </a>
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
      </div>
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div>
    </div>
    */}
    </>
  )
}

const AuthenticationPage = () => (
  <Routes>
    <Route element={<AuthenticationLayout />}>
      <Route path='login' element={<Login />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthenticationPage}
