/* eslint-disable react/jsx-no-target-blank */
import {useMsal} from '@azure/msal-react'
import {useGlobalAuthentication} from '../../../../app/modules/authentication/Authentication'
import {KTSVG} from '../../../helpers'

const SidebarFooter = () => {
  const {instance} = useMsal()
  const {logout} = useGlobalAuthentication()

  const handleLogout = () => {
    if (instance.getAllAccounts().length > 0) {
      instance.logoutPopup()
    }

    logout()
  }

  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <a
        target='_blank'
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss-='click'
        title='Sair do Sistema'
        onClick={(event) => handleLogout()}
      >
        <span className='btn-label'>Sair do Sistema</span>
        <span className='svg-icon btn-icon svg-icon-2'>
          <KTSVG path='/media/icons/duotune/arrows/arr077.svg' />
        </span>
      </a>
    </div>
  )
}

export {SidebarFooter}
