import {ICalcFieldRule} from '../interfaces/ICalcFieldRule'

export class CCalcFieldRule implements ICalcFieldRule {
  operador: string
  campo: number

  constructor() {
    this.operador = ''
    this.campo = 0
  }
}
