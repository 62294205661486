import {useEffect, useState} from 'react'

import Box from '@mui/material/Box'

import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'

import {toast} from 'react-toastify'
import {Typography} from '@mui/material'
import StyledCard from '../styled/StyledCard'
import StyledCardContent from '../styled/StyledCardContent'
import StyledCardActions from '../styled/StyledCardActions'
import FieldRuleModalV2 from './fieldRuleModalV2'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import ModalExclusao from '../modals/modalExclusao'
import {NoRowsOverlay} from '../utility/NoRowsOverlay'

interface IFieldRuleProps {
  accessCode: string
  idMenuApp: number
  setRuleCount?: React.Dispatch<React.SetStateAction<number>>
}

const FieldRule = ({accessCode, idMenuApp, setRuleCount}: IFieldRuleProps) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [Data, setData] = useState<any>(null)
  const [ModalOpen, setModalOpen] = useState<boolean>(false)
  const [ModalID, setModalID] = useState<number>(0)
  const [ModalExclusaoOpen, setModalExclusao] = useState<boolean>(false)
  const [ModalExclusaoItemID, setModalExclusaoItemID] = useState<number>(0)

  const handleCallBack = (data: any = null) => {
    setModalID(0)
    setModalOpen(false)
    handleLoad(idMenuApp)
  }

  const handleLoad = (id: number) => {
    apiWrapper.get('api/v1/MenuAppFieldsRule/listarPorMenuApp?item.IDMenuApp=' + id).then((response) => {
      setData(response.data.data)

      if (setRuleCount != null) {
        setRuleCount(response.data.data.length)
      }
    })
  }

  const handleDelete = (id: any) => {
    let obj: any = {
      item: {
        ID: id,
      },
    }

    apiWrapper.delete('api/v1/MenuAppFieldsRule/excluir', {data: obj, headers: {}}).then((response) => {
      if (response.data.mensagem != 'SUCESSO') {
        toast.error('Impossível apagar esta regra pois a mesma está vinculada a outros items do sistema.')
        return
      }

      handleLoad(idMenuApp)
      toast.success('Regra excluída com sucesso!')
    })
  }

  const handleOpenModal = (id: number) => {
    setModalID(id)
    setModalOpen(true)
  }

  const handleOpenModalExclusao = (id: number) => {
    setModalExclusao(true)
    setModalExclusaoItemID(id)
  }

  useEffect(() => {
    let isMounted = true

    handleLoad(idMenuApp)

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      <Box component='div' className='d-flex flex-stack' sx={{p: 1}}>
        <Box component='div' className='page-title d-flex'>
          <Box component='h1' className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
            Regras
          </Box>
        </Box>
        <Box component='div' className='d-flex align-items-center py-1'>
          <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}}>
            <Button variant='contained' startIcon={<AddIcon />} color='info' onClick={() => handleOpenModal(0)}>
              Adicionar
            </Button>
          </Box>
        </Box>
      </Box>
      {Data && Data.length == 0 && <NoRowsOverlay openModal={() => handleOpenModal(0)} />}
      <Box component='div' className='container'>
        <Box component='div' className='row'>
          {Data &&
            Data.length > 0 &&
            Data.map((item: any, index: number) => {
              return (
                <Box component='div' className='col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2' sx={{marginBottom: '20px'}}>
                  <StyledCard>
                    <StyledCardContent>
                      <Typography variant='body1' color='textSecondary' component='p' sx={{mt: 0.6}} className='word-wrap text-dark'>
                        <strong>Regra: </strong>
                        {(item.nome != '' && item.nome) || 'Sem nome'}
                      </Typography>
                      <br />
                      <Typography variant='body1' color='textSecondary' component='p' sx={{mt: 0.6}} className='word-wrap text-dark'>
                        <strong>{item.eventoNome}</strong>
                      </Typography>
                      <Typography variant='body1' color='textSecondary' component='p' className='h-50px word-wrap text-dark py-2'>
                        {item.acaoNome}
                      </Typography>
                    </StyledCardContent>
                    <StyledCardActions sx={{justifyContent: 'flex-end'}}>
                      <Button startIcon={<EditIcon fontSize='small' />} size='small' color='primary' onClick={() => handleOpenModal(item.id)}>
                        Editar
                      </Button>
                      <Button
                        startIcon={<DeleteIcon fontSize='small' />}
                        size='small'
                        color='primary'
                        onClick={() => handleOpenModalExclusao(item.id)}
                      >
                        Excluir
                      </Button>
                    </StyledCardActions>
                  </StyledCard>
                </Box>
              )
            })}
        </Box>
        {ModalOpen && <FieldRuleModalV2 id={ModalID} accessCode={accessCode} idMenuApp={idMenuApp} callBack={handleCallBack} />}
        {ModalExclusaoOpen && (
          <ModalExclusao
            open={true}
            title='Excluir Regra'
            message='Esta ação não pode ser desfeita, confirma?'
            callBack={(excluir: boolean) => {
              if (excluir) {
                handleDelete(ModalExclusaoItemID)
              }

              setModalExclusao(false)
            }}
          />
        )}
      </Box>
    </>
  )
}

export default FieldRule
