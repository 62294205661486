import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import {Typography, styled} from '@mui/material'

interface IWhatsAppRedirectToolTip {
  number: string
}

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => <Tooltip {...props} classes={{popper: className}} />)(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))

export const WhatsAppRedirectToolTip = ({number}: IWhatsAppRedirectToolTip) => {
  const handleWhatsAppClick = () => {
    const url = `https://api.whatsapp.com/send?phone=${number.replaceAll(' ', '').replaceAll('+', '')}`
    window.open(url, '_blank')
  }

  return (
    <HtmlTooltip
      arrow
      title={
        <>
          <IconButton title='Iniciar conversa pelo WhatsApp' onClick={handleWhatsAppClick}>
            <WhatsAppIcon />
          </IconButton>
        </>
      }
    >
      <Typography>{number}</Typography>
    </HtmlTooltip>
  )
}
