import {IAuthPresence} from '../models/AuthRedirector'
import {IStorageAuthentication} from '../models/AuthenticationModel'

const AUTH_LOCAL_STORAGE_KEY = process.env.REACT_APP_AUTHENTICATION_STORAGEKEY
const AUTH_TIMEKEY = process.env.REACT_APP_AUTH_PRESENCE_KEY

const getPresence = (): IAuthPresence | undefined => {
  if (!localStorage || AUTH_TIMEKEY == null) {
    return undefined
  }

  const lsValue: string | null = localStorage.getItem(AUTH_TIMEKEY)
  if (!lsValue) {
    return {
      lastRequestTime: new Date().toISOString(),
      expired: false,
    }
  }

  try {
    const authRedirector: IAuthPresence = JSON.parse(lsValue) as IAuthPresence
    if (authRedirector) {
      return authRedirector
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setPresence = (authRedirector: IAuthPresence) => {
  if (!localStorage || AUTH_TIMEKEY == null) {
    return
  }

  try {
    const lsValue = JSON.stringify(authRedirector)
    localStorage.setItem(AUTH_TIMEKEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const getSavedAuthentication = (): IStorageAuthentication | undefined => {
  if (!localStorage || AUTH_LOCAL_STORAGE_KEY == null) {
    return undefined
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return undefined
  }

  try {
    const auth: IStorageAuthentication = JSON.parse(lsValue) as IStorageAuthentication
    if (auth) {
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setSavedAuthentication = (auth: IStorageAuthentication) => {
  if (!localStorage || AUTH_LOCAL_STORAGE_KEY == null) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeSavedAuthentication = () => {
  if (!localStorage || AUTH_LOCAL_STORAGE_KEY == null) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {getPresence, setPresence, getSavedAuthentication, setSavedAuthentication, removeSavedAuthentication, AUTH_LOCAL_STORAGE_KEY}
