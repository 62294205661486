import {useState, useEffect, useRef, useCallback} from 'react'

import ReactFlow, {Edge, Node, Controls, Background, addEdge, useNodesState, useEdgesState} from 'reactflow'
import 'reactflow/dist/style.css'
import 'reactflow/dist/base.css'

import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import StyledDialog from '../styled/StyledDialog'
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material'
import StyledButton from '../styled/StyledButton'

import ApiIcon from '@mui/icons-material/Api'
import FunctionsIcon from '@mui/icons-material/Functions'

import CodeMirror, {Extension} from '@uiw/react-codemirror'
import {json} from '@codemirror/lang-json'
import {autocompletion} from '@codemirror/autocomplete'
import {basicLight} from '@uiw/codemirror-theme-basic'
import {tokyoNight} from '@uiw/codemirror-theme-tokyo-night'

import CustomNode from '../flowchart/customNode'
import uuid from 'react-uuid'
import {ScheduleResult} from '../../../../modules/api/models/Result/ScheduleResult'
import {WorkflowResult} from '../../../../modules/api/models/Result/WorkflowResult'
import {toast} from 'react-toastify'
import {CWorkflowProp} from '../../../../models/classes/CWorkflowProp'
import ModalForm from './modalForm'
import {useThemeMode} from '../../../../../_metronic/partials'
import {IntlShape, useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

const nodeTypes = {
  custom: CustomNode,
}

interface ISYSCHEDULEModal {
  id: number
  callBack: (response: any) => void
}

const SYSCHEDULEModal = ({id, callBack}: ISYSCHEDULEModal) => {
  const msal = useMsal()
  const intl = useIntl()
  const apiWrapper = new ApiWrapper(msal.instance)

  //* Variáveis de estado do componente
  const [loading, setLoading] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [schedule, setSchedule] = useState<ScheduleResult | undefined>(undefined)
  const [workflow, setWorkflow] = useState<WorkflowResult | undefined>(undefined)
  const [menuAppIntegrations, setMenuAppIntegrations] = useState<Array<any>>([])

  const RequestTypes = ['GET', 'POST', 'PUT', 'DELETE']

  //* callback para o componente pai deste
  const handleCallBack = (response: any = null) => {
    if (callBack != null) {
      callBack(response)
    }
  }

  //* Carregamos os dados e deixamos os componentes pronto para a edição dos mesmos
  const handleOpen = async () => {
    setLoading(true)

    let scheduleRequest = await apiWrapper.get(`api/v1/Schedule/carregar?item.ID=${id}`)
    let _schedule = scheduleRequest.data.data_a as ScheduleResult
    let _workflow = scheduleRequest.data.data_b as WorkflowResult

    setSchedule(_schedule)
    setWorkflow(_workflow)

    if (_workflow.nodes == '[]') {
      setModalAddNode(true)
    }

    let _nodes = JSON.parse(_workflow.nodes)

    for (let _node of _nodes) {
      switch (_node.data.type) {
        case 'PROCEDURE':
          _node.data.icon = <FunctionsIcon />
          break
        case 'API':
          _node.data.icon = <ApiIcon />
          break
      }

      _node.data.onEdit = (event: React.MouseEvent) => {
        handleOpenModalForm(_node.data.type)
      }
      _node.data.onDelete = (event: any) => {
        handleDeleteNode(_node.id)
      }
    }

    setNodes(_nodes)
    setEdges(JSON.parse(_workflow.edges))
    setProps(JSON.parse(_workflow.props))

    //* Carregamos os items extras para as propriedades
    await apiWrapper.get('api/v1/MenuAppIntegration/listar').then((response) => {
      setMenuAppIntegrations(response.data.data)
    })

    setLoading(false)
  }

  //* Salvamento e fechamento do modal
  const handleClose = async (save: boolean) => {
    if (save) {
      if (schedule?.nome == '' || schedule?.cronExpression == '') {
        toast.warning('É necessário preecher todos os campos!')
        return
      }

      for (let prop of props) {
        try {
          if (prop.jsonInput != null && prop.jsonInput != '') {
            JSON.parse(`${prop.jsonInput}`)
          }
          if (prop.jsonOutput != null && prop.jsonOutput != '') {
            JSON.parse(`${prop.jsonOutput}`)
          }
        } catch (error) {
          let node = nodes.find((x) => x.id == prop.nodeId)
          toast.error(`Há uma ação do tipo '${node?.data.label}' que possui um JSON em formato inválido!`)
          return
        }
      }

      setSaving(true)

      if (workflow != null) {
        workflow.nodes = JSON.stringify(nodes)
        workflow.edges = JSON.stringify(edges)
        workflow.props = JSON.stringify(props)

        await apiWrapper.put('api/v1/Workflow/salvar', {item: {Workflow: workflow}}).catch((error) => {
          toast.error(error.response?.data?.mensagem ?? error.message)
        })
      }

      await apiWrapper.put('api/v1/Schedule/salvar', {item: {Schedule: schedule}}).catch((error) => {
        toast.error(error.response?.data?.mensagem ?? error.message)
      })

      setSaving(false)
    }

    handleCallBack()
  }

  //* OnChange para o schedule
  const handleOnChangeSchedule = (key: string, value: any) => {
    setSchedule((prev) => {
      if (prev == null) {
        return
      }

      prev[key] = value

      return {...prev}
    })
  }

  //* OnChange para o workflow
  const handleOnChangeWorkflow = (key: string, value: any) => {
    setWorkflow((prev) => {
      if (prev == null) {
        return
      }

      prev[key] = value

      return {...prev}
    })
  }

  //* OnChange para props
  const handleOnChangeSelectedFlowNodeProp = (key: string, value: any) => {
    if (selectedFlowNodeProp == null) {
      return
    }

    setProps((prev) => {
      let selected = prev.find((x) => x.nodeId == selectedFlowNodeProp.nodeId)
      if (selected != null) {
        selected[key] = value
      }

      return [...prev]
    })
  }

  //* Função que retorna quando os controles devem ser bloquados
  const isEditable = () => {
    return !loading && !saving
  }

  //* Helpers
  function download(filename: string, text: string) {
    var a = document.createElement('a')
    a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
    a.setAttribute('download', filename)

    if (document.createEvent) {
      var event = document.createEvent('MouseEvents')
      event.initEvent('click', true, true)
      a.dispatchEvent(event)
    } else {
      a.click()
    }
  }

  function BaixarArquivoModelo(resourceName: string) {
    toast.info('Gerando arquivo modelo, aguarde...')

    apiWrapper.get(`api/v1/MenuAppResource/carregarModeloPorNome?item.Nome=${resourceName}`).then((response) => {
      download('modelo.sql', response.data.data.resource)
      toast.success('Arquivo modelo gerado com sucesso!')
    })
  }

  const handleOnCompletions = (context: any) => {
    let word = context.matchBefore(/\w*/)
    if (word.from == word.to && !context.explicit) return null
    return {
      from: word.from,
      options: [
        {
          label: '<SQL>',
          type: 'text',
          apply: '"<SQL=SELECT CAMPO FROM TABELA WHERE ID=[CAMPO_TABELA_BANCO]"',
          detail: 'macro',
          info: 'Utilize para informar uma SQL customizado para restagar um valor. Ex: SELECT ID FROM TABELA WHERE NOME=[CAMPO_TABELA_BANCO]',
        },
        {label: '@GETDATE', type: 'text', apply: '"@GETDATE"', detail: 'macro', info: 'Utilize para restagar a data atual'},
        {
          label: '{CJSON}',
          type: 'text',
          apply: '{CAMPOJSONANTERIOR}',
          detail: 'macro',
          info: 'Informe um campo do JSON de retorno da ação anterior a este para resgatar o seu valor',
        },
      ],
    }
  }

  /* REACT FLOW */

  const connectingNodeId = useRef<any>(null)

  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [props, setProps] = useState<Array<CWorkflowProp>>([])

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges])

  const onConnectStart = (event: any, params: any) => {
    connectingNodeId.current = params.nodeId
  }

  const onConnectEnd = (event: any) => {
    let targetIsPane = event.target.classList.contains('react-flow__pane')
    if (targetIsPane) {
      setModalAddNode(true)
    }
  }

  const [selectedFlowNode, setSelectedFlowNode] = useState<Node | undefined>(undefined)
  const [selectedFlowNodeProp, setSelectedFlowNodeProp] = useState<CWorkflowProp | undefined>(undefined)
  const [previousSelectedFlowNode, setPreviousSelectedFlowNode] = useState<Node | undefined>(undefined)
  const [previousSelectedFlowNodeProp, setPreviousSelectedFlowNodeProp] = useState<CWorkflowProp | undefined>(undefined)

  const handleOnNodeClick = (event: React.MouseEvent, node: Node) => {
    setSelectedFlowNode(node)
    setSelectedFlowNodeProp(props.find((x) => x.nodeId == node.id))

    let connectedEdge = edges.find((x) => x.target == node.id)
    if (connectedEdge != null) {
      let connectedNode = nodes.find((x) => x.id == connectedEdge?.source)
      let connectedNodeProp = props.find((x) => x.nodeId == connectedEdge?.source)
      setPreviousSelectedFlowNode(connectedNode)
      setPreviousSelectedFlowNodeProp(connectedNodeProp)
    } else {
      setPreviousSelectedFlowNode(undefined)
      setPreviousSelectedFlowNodeProp(undefined)
    }

    if (node.data.onClick == null) {
      return
    }

    node.data.onClick(event)
  }

  const [modalAddNode, setModalAddNode] = useState<boolean>(false)

  const handleAddNode = (type: string) => {
    //* TODAS AS CIRAÇÕES DE NÓDULOS DEVEM PASSAR POR AQUI PARA QUE SEJAM CRIADOS SEUS DEVIDOS DERIVADOS

    let newID = uuid()

    setNodes((prev) => {
      switch (type) {
        case 'PROCEDURE':
          prev = prev.concat([
            {
              id: newID,
              position: {x: 0, y: 0},
              type: 'custom',
              data: {
                type: 'PROCEDURE',
                label: 'Executar Procedure',
                icon: <FunctionsIcon />,
                onEdit: (event: React.MouseEvent) => {
                  handleOpenModalForm(type)
                },
                onDelete: (event: any) => {
                  handleDeleteNode(newID)
                },
              },
            },
          ])
          break
        case 'API':
          prev = prev.concat([
            {
              id: newID,
              position: {x: 0, y: 0},
              type: 'custom',
              data: {
                type: 'API',
                label: 'Executar uma API',
                icon: <ApiIcon />,
                onEdit: (event: React.MouseEvent) => {
                  handleOpenModalForm(type)
                },
                onDelete: (event: any) => {
                  handleDeleteNode(newID)
                },
              },
            },
          ])
          break
      }

      return [...prev]
    })

    setProps((prev) => {
      return [...prev, new CWorkflowProp(newID)]
    })

    //* Caso o ação esteja sendo criado a parir de um arrasto de edge de outro ação, os conectamos
    if (connectingNodeId.current != null) {
      setEdges((eds) => eds.concat({id: `e${connectingNodeId.current}-${newID}`, source: connectingNodeId.current, target: newID}))
    }

    setModalAddNode(false)
  }

  const handleDeleteNode = (nodeId: string) => {
    setNodes((prev) => {
      return prev.filter((x) => x.id != nodeId)
    })

    setEdges((prev) => {
      return prev.filter((x) => x.target != nodeId && x.source != nodeId)
    })

    setProps((prev) => {
      return prev.filter((x) => x.nodeId != nodeId)
    })

    setSelectedFlowNode(undefined)
    setSelectedFlowNodeProp(undefined)
    setPreviousSelectedFlowNode(undefined)
    setPreviousSelectedFlowNodeProp(undefined)
  }

  /* MODAL FORM */

  const [modalForm, setModalForm] = useState<boolean>(false)
  const [modalFormComponentMode, setModalFormComponentMode] = useState<string>()

  const handleOpenModalForm = (componentMode: string) => {
    setModalForm(true)
    setModalFormComponentMode(componentMode)
  }

  const handleModalFormCallBack = (data?: any) => {
    setModalForm(false)
    setModalFormComponentMode('')
  }

  const {mode} = useThemeMode()
  const [codeEditorTheme, setCodeEditorTheme] = useState<Extension>(basicLight)

  //* Use effect inicial que chama o handleOpen quando o componente renderiza pela primeira vez
  useEffect(() => {
    handleOpen()
  }, [])

  //* Modo de cor para o code editor
  //TODO: Colocar num styled
  useEffect(() => {
    setCodeEditorTheme(mode == 'light' ? basicLight : tokyoNight)
  }, [mode])

  return (
    <StyledDialog open={true} fullWidth maxWidth='lg'>
      <DialogTitle>Agendamento</DialogTitle>
      <DialogContent dividers={true}>
        {loading && (
          <Box component='div' className='d-flex justify-content-center'>
            <CircularProgress />
          </Box>
        )}
        {!loading && schedule != null && workflow != null && (
          <Box component='div' className='d-flex flex-row' sx={{width: '100%', maxHeight: '400px'}}>
            <Box component='div' className='d-flex flex-column border' sx={{width: '35%'}}>
              <Box component='div' sx={{p: 1, width: '100%'}}>
                <TextField
                  fullWidth
                  disabled={!isEditable()}
                  size='small'
                  label='Nome'
                  value={schedule.nome}
                  onChange={(event) => handleOnChangeSchedule('nome', event.target.value)}
                />
              </Box>
              <Box component='div' sx={{p: 1, width: '100%'}}>
                <TextField
                  fullWidth
                  disabled={!isEditable()}
                  size='small'
                  label='Descrição'
                  value={schedule.descricao}
                  onChange={(event) => handleOnChangeSchedule('descricao', event.target.value)}
                  multiline
                  maxRows={5}
                  minRows={5}
                />
              </Box>
              <Box component='div' sx={{p: 1, width: '100%'}}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  disabled={!isEditable()}
                  options={['A cada minuto', 'A cada hora', 'A cada dia']}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Intervalo' />}
                  value={schedule.cronExpression}
                  onChange={(event, value, reason) => handleOnChangeSchedule('cronExpression', value)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <FormGroup sx={{width: '100%'}}>
                  <FormControlLabel
                    control={<Checkbox onChange={(event) => handleOnChangeSchedule('ativo', event.target.checked)} checked={schedule.ativo} />}
                    label='Ativo'
                  />
                </FormGroup>
              </Box>
            </Box>
            <Box component='div' className='border' sx={{width: '65%', height: '400px'}}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onConnectStart={onConnectStart}
                onConnectEnd={onConnectEnd}
                onNodeClick={handleOnNodeClick}
                maxZoom={1.25}
                minZoom={0.75}
                fitView
              >
                <Controls />
                <Background />
              </ReactFlow>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <StyledButton disabled={!isEditable()} variant='contained' color='inherit' onClick={() => handleClose(false)}>
          Fechar
        </StyledButton>
        <StyledButton disabled={!isEditable()} variant='contained' color='success' onClick={() => handleClose(true)}>
          Salvar
        </StyledButton>
      </DialogActions>

      {modalAddNode && (
        <StyledDialog open={true} fullWidth maxWidth='sm'>
          <DialogTitle>Adicionar uma ação</DialogTitle>
          <DialogContent dividers={true}>
            <Box component='div' className='d-flex flex-column' sx={{alignItems: 'center'}}>
              <Box component='div' sx={{p: 0.5, width: '50%'}}>
                <StyledButton fullWidth variant='contained' color='info' onClick={() => handleAddNode('PROCEDURE')}>
                  Executar uma Procedure
                </StyledButton>
              </Box>
              <Box component='div' sx={{p: 0.5, width: '50%'}}>
                <StyledButton fullWidth variant='contained' color='info' onClick={() => handleAddNode('API')}>
                  Executar uma API
                </StyledButton>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <StyledButton disabled={nodes.length == 0} variant='contained' color='inherit' onClick={() => setModalAddNode(false)}>
              Fechar
            </StyledButton>
          </DialogActions>
        </StyledDialog>
      )}

      {/* MODAL FORM */}
      {modalForm && selectedFlowNodeProp != null && (
        <ModalForm
          title={selectedFlowNode?.data?.label}
          fullWidth={true}
          maxWidth='sm'
          element={
            <>
              {previousSelectedFlowNodeProp != null && (
                <>
                  <Box component='div' sx={{p: 1}}>
                    <CodeMirror
                      editable={false}
                      placeholder='JSON de retorno anterior'
                      readOnly={!isEditable()}
                      theme={codeEditorTheme}
                      value={previousSelectedFlowNodeProp.jsonOutput}
                      height='150px'
                      width='100%'
                      extensions={[json()]}
                    />
                  </Box>
                  {previousSelectedFlowNodeProp.jsonOutput.startsWith('[') && previousSelectedFlowNodeProp.jsonOutput.endsWith(']') && (
                    <Box component='div' sx={{p: 1, textAlign: 'center'}} className='text-muted my-1 fs-7'>
                      A ação atual será realizada em loop pois o retorno anterior é uma cadeia de objetos!
                    </Box>
                  )}
                </>
              )}
              {modalFormComponentMode == 'API' && (
                <>
                  <Box component='div' sx={{p: 1}}>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      disabled={!isEditable()}
                      options={menuAppIntegrations.filter((x) => x.idtipoIntegration == 5)}
                      getOptionLabel={(option) => option.nome}
                      size='small'
                      renderInput={(params) => <TextField {...params} label='Integração' />}
                      value={
                        selectedFlowNodeProp.atributo01 != ''
                          ? menuAppIntegrations.find((op: any) => op.id == selectedFlowNodeProp.atributo01) ?? null
                          : null
                      }
                      onChange={(event, value, reason) => handleOnChangeSelectedFlowNodeProp('atributo01', value?.id?.toString() ?? '')}
                    />
                  </Box>
                  <Box component='div' sx={{p: 1}}>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      disabled={!isEditable()}
                      options={RequestTypes}
                      getOptionLabel={(option) => option}
                      size='small'
                      renderInput={(params) => <TextField {...params} label='Verbo' />}
                      value={selectedFlowNodeProp.atributo02}
                      onChange={(event, value, reason) => handleOnChangeSelectedFlowNodeProp('atributo02', value ?? '')}
                    />
                  </Box>
                  <Box component='div' sx={{p: 1}}>
                    <TextField
                      fullWidth
                      disabled={!isEditable()}
                      size='small'
                      label='Endpoint'
                      value={selectedFlowNodeProp.atributo03}
                      onChange={(event) => handleOnChangeSelectedFlowNodeProp('atributo03', event.target.value)}
                    />
                  </Box>
                  {selectedFlowNodeProp.atributo02 != 'GET' && (
                    <Box component='div' sx={{p: 1}}>
                      <CodeMirror
                        placeholder='JSON de envio'
                        readOnly={!isEditable()}
                        theme={codeEditorTheme}
                        value={selectedFlowNodeProp.jsonInput}
                        height='150px'
                        width='100%'
                        extensions={[json(), autocompletion({override: [(context) => handleOnCompletions(context)]})]}
                        onChange={(val, viewUpdate) => handleOnChangeSelectedFlowNodeProp('jsonInput', val)}
                      />
                    </Box>
                  )}
                  <Box component='div' sx={{p: 1}}>
                    <CodeMirror
                      placeholder='JSON de retorno'
                      readOnly={!isEditable()}
                      theme={codeEditorTheme}
                      value={selectedFlowNodeProp.jsonOutput}
                      height='150px'
                      width='100%'
                      extensions={[json(), autocompletion({override: [(context) => handleOnCompletions(context)]})]}
                      onChange={(val, viewUpdate) => handleOnChangeSelectedFlowNodeProp('jsonOutput', val)}
                    />
                  </Box>
                </>
              )}
              {modalFormComponentMode == 'PROCEDURE' && (
                <>
                  <Box component='div' sx={{p: 1, alignSelf: 'center'}}>
                    <a
                      title='Clique aqui para realizar Download do arquivo modelo'
                      style={{cursor: 'pointer'}}
                      onClick={(event) => BaixarArquivoModelo('JSON')}
                    >
                      <div className='container'>
                        <div className='row'>
                          <div className='col-1'></div>
                          <div className='col-2 p-1 text-center '>
                            <img alt='Logo' src={toAbsoluteUrl('/media/app/sql64x64.png')} className='h-45px w-45px' />
                          </div>
                          <div className='col-8 p-1'>
                            <span>Para baixar o arquivo modelo deste app </span>{' '}
                            <span>
                              <strong className='link-primary'>Clique Aqui</strong> e após, execute o procedimento no banco de dados da aplicação (
                              Compativel com SQL SERVER ).
                            </span>
                          </div>
                          <div className='col-1'></div>
                        </div>
                      </div>
                    </a>
                  </Box>
                  <Box component='div' sx={{p: 1, display: 'flex'}}>
                    <TextField
                      fullWidth
                      disabled={!isEditable()}
                      size='small'
                      label='Nome do procedimento'
                      value={selectedFlowNodeProp.atributo01}
                      onChange={(event) => handleOnChangeSelectedFlowNodeProp('atributo01', event.target.value)}
                    />
                  </Box>
                  <Box component='div' sx={{p: 1, textAlign: 'center'}} className='text-muted my-1 fs-6'>
                    Stored procedure deve conter o parâmetro Values.
                  </Box>
                  {/*
                  <Box component='div' sx={{p: 1}}>
                    <CodeMirror
                      placeholder='JSON de entrada'
                      readOnly={!isEditable()}
                      theme={codeEditorTheme}
                      value={selectedFlowNodeProp.jsonInput}
                      height='150px'
                      width='100%'
                      extensions={[json(), autocompletion({override: [(context) => handleOnCompletions(context)]})]}
                      onChange={(val, viewUpdate) => handleOnChangeSelectedFlowNodeProp('jsonInput', val)}
                    />
                  </Box>
                  */}
                  <Box component='div' sx={{p: 1}}>
                    <CodeMirror
                      placeholder='JSON de retorno'
                      readOnly={!isEditable()}
                      theme={codeEditorTheme}
                      value={selectedFlowNodeProp.jsonOutput}
                      height='150px'
                      width='100%'
                      extensions={[json(), autocompletion({override: [(context) => handleOnCompletions(context)]})]}
                      onChange={(val, viewUpdate) => handleOnChangeSelectedFlowNodeProp('jsonOutput', val)}
                    />
                  </Box>
                </>
              )}
            </>
          }
          callBack={handleModalFormCallBack}
        />
      )}
    </StyledDialog>
  )
}

export default SYSCHEDULEModal
