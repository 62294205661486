import uuid from 'react-uuid'
import {IRuleActions} from '../interfaces/IRuleActions'

export class CRuleActions implements IRuleActions {
  guid: string
  event: string
  action: string
  campo?: number
  valor: string
  atributo01: string
  atributo02: string
  atributo03: string
  atributo04: string

  constructor() {
    this.guid = uuid()
    this.event = ''
    this.action = ''
    this.valor = ''
    this.atributo01 = ''
    this.atributo02 = ''
    this.atributo03 = ''
    this.atributo04 = ''
  }
}
