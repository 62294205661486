import {useEffect, useState} from 'react'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import FormControl from '@mui/material/FormControl'

import {toast} from 'react-toastify'
import {useThemeMode} from '../../../../../_metronic/partials'
import {globalDarkMuiTheme, globalLightMuiTheme} from '../utility/globalMuiTheme'
import {Checkbox, FormControlLabel, Theme, ThemeProvider} from '@mui/material'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import {handleApplyValueToState} from '../../../../models/wrappers/handlers'

interface IModalSYSMARProps {
  accessCode: string | undefined
  id: number
  callBack?: any
  deleteFunc?: any
}

const SYSMARModal = ({accessCode, id, callBack, deleteFunc}: IModalSYSMARProps) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [Opened, setOpened] = useState<boolean>(false)
  const [Title, setTitle] = useState<string>('')
  const [Saving, setSaving] = useState<boolean>(false)
  const [Data, setData] = useState<any>(null)
  const [DataID, setDataID] = useState<number>(0)

  const [Relacoes, setRelacoes] = useState<Array<any>>([])

  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  const handleOpen = async (id: number) => {
    setDataID(id)
    setOpened(true)

    if (id == 0) {
      setTitle('Adicionar Relação')
    } else {
      setTitle('Editar Relação')
    }

    apiWrapper.get('api/v1/MenuAppRelacao/carregar?item.ID=' + id).then(async (response) => {
      console.log(response.data.data)

      if (response.data.data.idmenuApp != null) {
        await apiWrapper.get('api/v1/MenuApp/listarRelacoesTabela?item.IDMenuApp=' + response.data.data.idmenuApp).then((response_b) => {
          setRelacoes(response_b.data.data)
        })
      } else {
        await apiWrapper.get('api/v1/MenuApp/listarRelacoesTabela?item.AccessCode=' + accessCode).then((response_b) => {
          setRelacoes(response_b.data.data)
        })
      }

      setData(response.data.data)
    })
  }

  const handleClose = (save: boolean, keepOpen: boolean) => {
    if (!save) {
      setOpened(false)
      setData(null)
      handleCallBack()
      return
    }

    setSaving(true)

    let obj: any = {
      item: {
        AccessCode: accessCode,
        MenuAppRelacao: Data,
      },
    }

    apiWrapper.put('api/v1/MenuAppRelacao/salvar', obj).then((response) => {
      if (!keepOpen) {
        setOpened(false)
        setData(null)
      } else {
        setSaving(false)
        handleOpen(response.data.id)
      }

      let res: any = {
        id: response.data.id,
        keepOpen: keepOpen,
      }

      handleCallBack(res)
      toast.success('Registro salvo com sucesso!')
    })
  }

  const handleOpenExcluir = () => {
    if (deleteFunc != null) {
      deleteFunc(DataID, 'SYSMAR')
      handleClose(false, false)
    }
  }

  const handleOnChangeInput = (key: string, event: any) => {
    setData((prev: any) => {
      prev[key] = event.target.value
      return {...prev}
    })
  }

  const handleOnChangeAutoComplete = (key: string, value: any) => {
    setData((prev: any) => {
      if (key == 'idmenuAppRelacao') {
        prev['idmenuAppField'] = value != null ? value.menuAppFieldID : null
        prev[key] = value != null ? value.menuAppID : null
      }

      return {...prev}
    })
  }

  const isEditable = () => {
    return !Saving
  }

  /* THEME */

  const {mode} = useThemeMode()
  const [muiTheme, setMuiTheme] = useState<Theme>(mode == 'light' ? globalLightMuiTheme : globalDarkMuiTheme)

  const handleUpdateTheme = () => {
    setMuiTheme((mode as any) == 'dark' ? globalDarkMuiTheme : globalLightMuiTheme)
  }

  useEffect(() => {
    handleUpdateTheme()
  }, [mode])

  /* END THEME */

  useEffect(() => {
    handleOpen(id)
  }, [])

  return (
    <ThemeProvider theme={muiTheme}>
      <Dialog fullScreen open={Opened}>
        <DialogTitle>{Title}</DialogTitle>
        <DialogContent dividers={true}>
          <FormControl sx={{m: 1}} component='fieldset' variant='standard'>
            {Opened && Data && (
              <>
                <Box component='div' sx={{p: 1}}>
                  <TextField
                    sx={{width: 300}}
                    disabled={!isEditable()}
                    size='small'
                    label='Nome'
                    value={Data.nome}
                    onChange={(event) => handleOnChangeInput('nome', event)}
                  />
                </Box>
                <Box component='div' sx={{p: 1}}>
                  <TextField
                    sx={{width: 300}}
                    disabled={!isEditable()}
                    size='small'
                    label='Descrição'
                    value={Data.descricao}
                    onChange={(event) => handleOnChangeInput('descricao', event)}
                  />
                </Box>
                <Box component='div' sx={{p: 1}}>
                  <TextField
                    sx={{width: 300}}
                    disabled={!isEditable()}
                    size='small'
                    label='Ordem'
                    type='number'
                    InputLabelProps={{shrink: true}}
                    value={Data.ordem}
                    onChange={(event) => handleOnChangeInput('ordem', event)}
                  />
                </Box>
                <Box component='div' sx={{p: 1}}>
                  <Autocomplete
                    disablePortal
                    disabled={!isEditable()}
                    options={Relacoes}
                    getOptionLabel={(option: any) => option.menuAppTitulo + ' - ' + option.menuAppFieldTitulo}
                    sx={{width: 300}}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Relação' />}
                    value={
                      Data.idmenuAppRelacao != null && Data.idmenuAppRelacao != '' && Data.idmenuAppField != null && Data.idmenuAppField != ''
                        ? Relacoes.filter((op: any) => op.menuAppID == Data.idmenuAppRelacao && op.menuAppFieldID == Data.idmenuAppField)[0] ?? null
                        : null
                    }
                    onChange={(event, value, reason) => handleOnChangeAutoComplete('idmenuAppRelacao', value)}
                  />
                </Box>
                <Box component='div' sx={{pl: 1}}>
                  <FormControlLabel
                    label='Ocultar botões de ação'
                    control={
                      <Checkbox
                        disabled={!isEditable()}
                        checked={Data.biOcultarAcoes}
                        onChange={(event, checked) => handleApplyValueToState(setData, 'biOcultarAcoes', checked)}
                      />
                    }
                  />
                </Box>
                <Box component='div' sx={{pl: 1}}>
                  <FormControlLabel
                    label='Permitir duplicação'
                    control={
                      <Checkbox
                        disabled={!isEditable()}
                        checked={Data.biDuplicarComMenuApp}
                        onChange={(event, checked) => handleApplyValueToState(setData, 'biDuplicarComMenuApp', checked)}
                      />
                    }
                  />
                </Box>
              </>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          {isEditable() && (
            <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleClose(false, false)}>
              Fechar
            </Button>
          )}
          {deleteFunc != null && isEditable() && DataID != 0 && (
            <Button variant='contained' startIcon={<DeleteIcon />} color='error' onClick={() => handleOpenExcluir()}>
              Excluir
            </Button>
          )}
          {isEditable() && (
            <>
              <Button variant='contained' startIcon={<SaveIcon />} color='success' onClick={() => handleClose(true, true)}>
                Salvar
              </Button>
              <Button variant='contained' startIcon={<SaveIcon />} color='success' onClick={() => handleClose(true, false)}>
                Salvar e Fechar
              </Button>
            </>
          )}
          {!isEditable() && (
            <Button variant='contained' startIcon={<SaveIcon />} color='success'>
              Salvando...
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default SYSMARModal
