import {useEffect, useState} from 'react'
import moment from 'moment'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider, DesktopDatePicker, DateTimePicker} from '@mui/x-date-pickers'
import ptBRLocale from 'date-fns/locale/pt-BR'

import {CGlobalFilterStructure} from '../../../../models/classes/CMenuAppHistoryStructure'
import {useThemeMode} from '../../../../../_metronic/partials'
import {Theme, ThemeProvider} from '@mui/material'
import {globalDarkMuiTheme, globalLightMuiTheme} from '../utility/globalMuiTheme'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import useStateRef from 'react-usestateref'
import {toast} from 'react-toastify'

interface ISYSCHFProps {
  accessCode?: string
  filterItem?: CGlobalFilterStructure
  filterItemIndex: number
  filterItemOperadorLogico?: string
  callBack?: any
  isMenuAppFilters?: boolean
}

export const SYSCHFModal = ({accessCode, filterItem, filterItemIndex, filterItemOperadorLogico, callBack, isMenuAppFilters}: ISYSCHFProps) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [Opened, setOpened] = useState<boolean>(false)
  const [Title, setTitle] = useState<string>('')
  const [Data, setData, DataRef] = useStateRef<CGlobalFilterStructure | undefined>(undefined)
  const [DataIndex, setDataIndex] = useState<number>(-1)
  const [Fields, setFields] = useState<Array<any>>([])
  const [Operators, setOperators] = useState<Array<any>>([])
  const [SingleSelectOptions, setSingleSelectOptions] = useState<Array<any>>([])
  const [PessoaOptions, setPessoaOptions] = useState<Array<any>>([])
  const [GrupoOptions, setGrupoOptions] = useState<Array<any>>([])

  const Ops: any = {
    boolean: ['Valor Nulo', 'Igual', 'Diferente'],
    string: ['Valor Nulo', 'Igual', 'Diferente', 'Contém', 'Não contém'],
    singleSelect: ['Valor Nulo', 'Igual', 'Diferente', 'Função', 'Procedimento'],
    number: ['Valor Nulo', 'Igual', 'Diferente', 'Maior que', 'Menor que', 'Maior ou igual a', 'Menor ou igual a', 'Função'],
    decimal: ['Valor Nulo', 'Igual', 'Diferente', 'Maior que', 'Menor que', 'Maior ou igual a', 'Menor ou igual a', 'Função'],
    date: ['Valor Nulo', 'Igual', 'Diferente', 'Maior que', 'Menor que', 'Maior ou igual a', 'Menor ou igual a'],
    dateTime: ['Valor Nulo', 'Igual', 'Diferente', 'Maior que', 'Menor que', 'Maior ou igual a', 'Menor ou igual a'],
  }

  const Funcoes: any = ['Usuário Logado']

  const Values: any = {
    boolean: ['Verdadeiro', 'Falso', 'Nenhum'],
  }

  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  const handleOpen = () => {
    setOpened(true)
    setTitle(filterItem != null ? 'Editar Filtro' : 'Novo Filtro')

    let item: CGlobalFilterStructure = filterItem ?? new CGlobalFilterStructure()

    if (filterItemOperadorLogico != null) {
      item.operadorLogico = filterItemOperadorLogico
    }

    setData(item)
    setDataIndex(filterItemIndex)

    apiWrapper.get('api/v1/MenuAppFields/listarPorMenuApp?item.AccessCode=' + accessCode).then((response) => {
      let fields: Array<any> = response.data.data.filter((item: any) => {
        return item.tipo != 'upload' && item.tipo != 'geocode'
      })

      fields = [
        {id: -1, label: 'Criado Por', tipo: 'CRIACAO'},
        {id: -2, label: 'Em Grupo', tipo: 'GRUPO'},
      ].concat(fields)

      setFields(fields)

      handleOnInputChangeAutoComplete_Pessoa('')
      handleOnInputChangeAutoComplete_Grupo('')

      if (filterItem?.campo != 0) {
        let field: any = fields.find((item: any) => item.id == filterItem?.campo)

        if (field == null) {
          return
        }

        if (field.tipo == 'CRIACAO' || field.tipo == 'GRUPO') {
          return
        }

        setOperators(Ops[field.tipo])

        if (
          field.tipo == 'singleSelect' &&
          DataRef.current?.valor != null &&
          DataRef.current?.valor != '' &&
          DataRef.current?.valor != 'Usuário Logado'
        ) {
          handleOnInputChangeAutoComplete('', JSON.parse(DataRef.current?.valor).id)
        }
      }
    })
  }

  const handleClose = (save: boolean) => {
    if (save && Data?.operador != 'Valor Nulo' && (Data?.valor == null || Data?.valor == '')) {
      toast.error('É necessário informar um valor!')
      return
    }

    setOpened(false)
    handleCallBack(save ? {item: Data, index: DataIndex} : null)
  }

  const handleOnChangeInput = (key: string, event: any) => {
    setData((prev: any) => {
      prev[key] = event.target.value
      return {...prev}
    })
  }

  const handleOnChangeRadio = (key: string, event: any) => {
    setData((prev: any) => {
      return {...prev, [key]: event.target.value}
    })
  }

  const handleOnChangeDate = (key: string, value: any) => {
    setData((prev: any) => {
      return {...prev, [key]: moment(value).format('YYYY-MM-DD').toString()}
    })
  }

  const handleOnOpenAutoComplete = (event: any) => {
    if (event.target.value == '' || event.target.value == null) {
      handleOnInputChangeAutoComplete('')
    }
  }

  const handleOnInputChangeAutoComplete = (value: any, id: any = null) => {
    if (DataRef.current?.campo == null || DataRef.current?.campo == 0 || DataRef.current?.campo < 0) {
      return
    }

    let url: string = ''

    if (id == null || typeof id == 'string') {
      if (typeof id == 'string') {
        value = id
      }

      url = `/api/v1/Dynamic/listarSingleSelect?item.AccessCode=${accessCode}&item.IDMenuAppField=${DataRef.current?.campo}&item.Query=${value}&RegistroPorPagina=15`
    } else {
      url = `/api/v1/Dynamic/listarSingleSelect?item.AccessCode=${accessCode}&item.IDMenuAppField=${DataRef.current?.campo}&item.IDs=${id}&RegistroPorPagina=15`
    }

    apiWrapper.get(url).then((response) => {
      let options = response.data.data
      setSingleSelectOptions(options)

      if (id != null) {
        let option = options.find((x) => x.id == id)
        let optionString = option != null ? JSON.stringify(option) : '{}'

        setData((prev: any) => {
          return {...prev, valor: optionString}
        })
      }
    })
  }

  const handleOnChangeAutoComplete = (key: string, value: any) => {
    setData((prev: any) => {
      if (key == 'campo' || key == 'operador') {
        prev.valor = ''
      }

      if (key == 'valor') {
        let stringValue = typeof value === 'string'
        return {...prev, [key]: stringValue ? value : JSON.stringify(value)}
      }

      if (key == 'campo') {
        if (value != null) {
          let tipo = value.tipo

          if (tipo == 'CRIACAO' || tipo == 'GRUPO') {
            tipo = 'singleSelect'
          }

          setOperators(Ops[tipo])
          prev.operador = Ops[tipo][0]
        } else {
          setOperators([])
          prev.operador = ''
        }

        return {...prev, [key]: value?.id ?? null}
      }

      return {...prev, [key]: value}
    })
  }

  const handleOnOpenAutoComplete_Pessoa = (event: any) => {
    if (event.target.value == '' || event.target.value == null) {
      handleOnInputChangeAutoComplete_Pessoa('')
    }
  }

  const handleOnInputChangeAutoComplete_Pessoa = (value: any) => {
    let url = 'api/v1/System/listarPessoas?item.Query=' + value

    apiWrapper.get(url).then((response) => {
      let options = response.data.data

      if (isMenuAppFilters) {
        options = [{id: '@USERID', label: '@USERID'}].concat(options)
      }

      setPessoaOptions(options)
    })
  }

  const handleOnOpenAutoComplete_Grupo = (event: any) => {
    if (event.target.value == '' || event.target.value == null) {
      handleOnInputChangeAutoComplete_Grupo('')
    }
  }

  const handleOnInputChangeAutoComplete_Grupo = (value: any) => {
    let url = 'api/v1/System/listarRoles?item.Query=' + value

    apiWrapper.get(url).then((response) => {
      let options = response.data.data
      setGrupoOptions(options)
    })
  }

  const getValorJson = (data: any) => {
    let result = data?.valor

    if (result != null && result != '') {
      return result
    }

    return '{}'
  }

  function GetComponenteValor(campo: number) {
    if (Fields.length == 0) {
      return <></>
    }

    let field: any = Fields.find((item: any) => item.id == campo)

    if (field == null) {
      return <></>
    }

    switch (field.tipo) {
      case 'CRIACAO':
        return (
          <Autocomplete
            disablePortal
            options={PessoaOptions}
            sx={{width: 300}}
            size='small'
            renderInput={(params) => <TextField {...params} label='Valor' />}
            value={
              Data?.valor != null && Data?.valor != '' ? PessoaOptions.find((op: any) => op.id == JSON.parse(getValorJson(Data))?.id) ?? null : null
            }
            onOpen={(event) => handleOnOpenAutoComplete_Pessoa(event)}
            onChange={(event, value, reason) => handleOnChangeAutoComplete('valor', value)}
            onInputChange={(event, value) => handleOnInputChangeAutoComplete_Pessoa(value)}
            filterOptions={(x) => x}
          />
        )
      case 'GRUPO':
        return (
          <Autocomplete
            disablePortal
            options={GrupoOptions}
            sx={{width: 300}}
            size='small'
            renderInput={(params) => <TextField {...params} label='Valor' />}
            value={
              Data?.valor != null && Data?.valor != '' ? GrupoOptions.find((op: any) => op.id == JSON.parse(getValorJson(Data))?.id) ?? null : null
            }
            onOpen={(event) => handleOnOpenAutoComplete_Grupo(event)}
            onChange={(event, value, reason) => handleOnChangeAutoComplete('valor', value)}
            onInputChange={(event, value) => handleOnInputChangeAutoComplete_Grupo(value)}
            filterOptions={(x) => x}
          />
        )
      case 'boolean':
        return (
          <Autocomplete
            disablePortal
            options={Values.boolean}
            sx={{width: 300}}
            size='small'
            renderInput={(params) => <TextField {...params} label='Valor' />}
            value={Data?.valor}
            onChange={(event, value, reason) => handleOnChangeAutoComplete('valor', value)}
          />
        )
      case 'string':
        return (
          <TextField sx={{width: 300}} size='small' label='Valor' value={Data?.valor} onChange={(event) => handleOnChangeInput('valor', event)} />
        )
      case 'number':
        return (
          <TextField
            sx={{width: 300}}
            size='small'
            label='Valor'
            type='number'
            InputLabelProps={{shrink: true}}
            value={Data?.valor}
            onChange={(event) => handleOnChangeInput('valor', event)}
          />
        )
      case 'date':
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
            <DesktopDatePicker
              slotProps={{textField: {size: 'small'}}}
              label='Valor'
              value={Data?.valor != '' ? new Date(Data?.valor as string) : null}
              onChange={(date) => handleOnChangeDate('valor', date)}
              sx={{width: 300}}
            />
          </LocalizationProvider>
        )
      case 'dateTime':
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
            <DateTimePicker
              slotProps={{textField: {size: 'small'}}}
              label='Valor'
              value={Data?.valor != '' ? new Date(Data?.valor as string) : null}
              onChange={(date) => handleOnChangeDate('valor', date)}
              sx={{width: 300}}
            />
          </LocalizationProvider>
        )
      case 'singleSelect':
        return (
          <Autocomplete
            disablePortal
            options={SingleSelectOptions}
            sx={{width: 300}}
            size='small'
            renderInput={(params) => <TextField {...params} label='Valor' />}
            value={
              Data?.valor != null && Data?.valor != ''
                ? SingleSelectOptions.find((op: any) => op.id == JSON.parse(getValorJson(Data))?.id) ?? null
                : null
            }
            onOpen={(event) => handleOnOpenAutoComplete(event)}
            onChange={(event, value, reason) => handleOnChangeAutoComplete('valor', value)}
            onInputChange={(event, value) => handleOnInputChangeAutoComplete(value)}
            filterOptions={(x) => x}
          />
        )
      default:
        return <></>
    }
  }

  /* THEME */

  const {mode} = useThemeMode()
  const [muiTheme, setMuiTheme] = useState<Theme>(mode == 'light' ? globalLightMuiTheme : globalDarkMuiTheme)

  const handleUpdateTheme = () => {
    setMuiTheme((mode as any) == 'dark' ? globalDarkMuiTheme : globalLightMuiTheme)
  }

  useEffect(() => {
    handleUpdateTheme()
  }, [mode])

  /* END THEME */

  useEffect(() => {
    handleOpen()
  }, [])

  return (
    <ThemeProvider theme={muiTheme}>
      <Dialog open={Opened} fullWidth={true} maxWidth='sm'>
        <DialogTitle>{Title}</DialogTitle>
        <DialogContent dividers={true} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px'}}>
          <FormControl sx={{m: 2, alignItems: 'center'}} component='fieldset' variant='standard'>
            {Opened && Data && (
              <>
                {DataIndex != -1 && (
                  <Box component='div' sx={{p: 1}}>
                    <FormControl disabled={DataIndex != 1}>
                      <FormLabel id='demo-row-radio-buttons-group-label'>Operador Lógico</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='row-radio-buttons-group'
                        value={Data?.operadorLogico}
                        onChange={(event) => handleOnChangeRadio('operadorLogico', event)}
                      >
                        {DataIndex == 0 && <FormControlLabel value='-' control={<Radio />} label='Nenhum' />}
                        <FormControlLabel value='E' control={<Radio />} label='E' />
                        <FormControlLabel value='Ou' control={<Radio />} label='Ou' />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                )}
                <Box component='div' sx={{p: 1}}>
                  <Autocomplete
                    disablePortal
                    options={Fields}
                    sx={{width: 300}}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Campo' />}
                    value={Data?.campo != null && Data?.campo != 0 ? Fields.filter((op: any) => op.id == Data?.campo)[0] ?? null : null}
                    onChange={(event, value, reason) => handleOnChangeAutoComplete('campo', value)}
                  />
                </Box>
                <Box component='div' sx={{p: 1}}>
                  <Autocomplete
                    disablePortal
                    options={Operators}
                    sx={{width: 300}}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Operador' />}
                    value={Data?.operador}
                    onChange={(event, value, reason) => handleOnChangeAutoComplete('operador', value)}
                  />
                </Box>
                {Data?.campo != null &&
                  Data?.campo != 0 &&
                  Data?.operador != 'Valor Nulo' &&
                  Data?.operador != 'Função' &&
                  Data?.operador != 'Procedimento' && (
                    <Box component='div' sx={{p: 1}}>
                      {GetComponenteValor(Data?.campo)}
                    </Box>
                  )}
                {Data?.campo != null && Data?.campo != 0 && Data?.operador == 'Função' && (
                  <Box component='div' sx={{p: 1}}>
                    <Autocomplete
                      disablePortal
                      options={Funcoes.concat(['Em grupos comuns', 'Em grupos comuns ao usuário logado'])}
                      sx={{width: 300}}
                      size='small'
                      renderInput={(params) => <TextField {...params} label='Função' />}
                      value={Data?.valor}
                      onChange={(event, value, reason) => handleOnChangeAutoComplete('valor', value)}
                    />
                  </Box>
                )}
                {Data?.campo != null && Data?.campo != 0 && Data?.operador == 'Procedimento' && (
                  <>
                    <Box component='div' sx={{p: 1}}>
                      <TextField
                        sx={{width: 300}}
                        size='small'
                        label='Nome do procedimento'
                        value={Data?.valor}
                        onChange={(event) => handleOnChangeInput('valor', event)}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1, textAlign: 'center'}} className='text-muted my-1 fs-6'>
                      Stored procedure deve conter os parametros IDPessoaMaster, IDPessoa e IDMenuApp.
                    </Box>
                  </>
                )}
              </>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleClose(false)}>
            Fechar
          </Button>
          <Button variant='contained' startIcon={<SaveIcon />} color='success' onClick={() => handleClose(true)}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default SYSCHFModal
