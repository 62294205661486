import {useIntl} from 'react-intl'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import {useMsal} from '@azure/msal-react'
import {useEffect, useState} from 'react'

import Box from '@mui/material/Box'
import StyledDialog from '../styled/StyledDialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import StyledButton from '../styled/StyledButton'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import DeleteIcon from '@mui/icons-material/Delete'
import FolderSharedIcon from '@mui/icons-material/FolderShared'
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined'

import {MenuAppDesktopsRoleResult} from '../../../../modules/api/models/Result/MenuAppDesktopsRoleResult'
import ModalExclusao from '../modals/modalExclusao'
import ModalPerformingAction from '../modals/modalPerformingAction'
import {toast} from 'react-toastify'
import BoxSVGMessage from '../utility/BoxSVGMessage'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'

interface IDesktopPermissionModal {
  idMenuAppDesktop: number
  callBack(data: any): void
}

const DesktopPermissionModal = ({idMenuAppDesktop, callBack}: IDesktopPermissionModal) => {
  const intl = useIntl()
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  //* VARIAVEIS DO COMPONENTE
  const [loading, setLoading] = useState<boolean>(false)
  const [desktopRoles, setDesktopRoles] = useState<Array<MenuAppDesktopsRoleResult>>([])
  const [desktopRoleParaExclusao, setDesktopRoleParaExclusao] = useState<MenuAppDesktopsRoleResult | undefined>(undefined)
  const [roles, setRoles] = useState<Array<any>>([])

  //* FUNÇÃO DE CALLBACK QUE É CHAMADO APÓS FECHAR O COMPONENTE
  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  //* FUNÇÃO DE INICIALIZAÇÃO DO COMPONENTE
  const handleOpen = async () => {
    setLoading(true)

    //* Carremos as desktopRoles e em seguida as roles para seleção de grupo

    let desktopRolesRequest = await apiWrapper.get(`api/v1/MenuAppDesktopsRole/listarPorDesktop?item.IDMenuAppDesktop=${idMenuAppDesktop}`)
    let desktopRoles = desktopRolesRequest.data.data as Array<MenuAppDesktopsRoleResult>
    setDesktopRoles(desktopRoles)

    let rolesRequest = await apiWrapper.get('api/v1/Role/listarRoles')
    let roles = rolesRequest.data.data as Array<any>
    setRoles(roles)

    setLoading(false)
  }

  //* FUNÇÃO DE FECHAMENTO DO COMPONENTE
  const handleClose = async () => {
    //* Aqui validamos se alguma permissão foi deixada sem grupo ou possui um grupo que não é mais válido
    for (const desktopRole of desktopRoles) {
      let role = roles.find((x) => x.id == desktopRole.idrole)
      if (role == null) {
        toast.error('Por favor, informe um grupo para cada permissão!')
        return
      }
    }

    handleOpenPerformingAction('Salvando registros, aguarde...')

    //* Salvamos as permissões com as edições feitas
    for (const desktopRole of desktopRoles) {
      await handleSaveDesktopRole(desktopRole)
    }

    handleClosePerformingAction()
    handleCallBack()
  }

  //* FUNÇÃO DE MUDANÇA DE VALORES IN-LINE
  const handleOnChange = (index: number, key: string, value: any) => {
    setDesktopRoles((prev: Array<MenuAppDesktopsRoleResult>) => {
      if (key == 'biUpdate') {
        prev[index].biSelect = false
      } else if (key == 'biSelect') {
        prev[index].biUpdate = false
      }

      prev[index][key] = value
      return [...prev]
    })
  }

  //* FUNÇÃO DE ADIÇÃO DE PERMISSÃO
  const handleAddDesktopRole = () => {
    let newDesktopRole = new MenuAppDesktopsRoleResult(idMenuAppDesktop)

    setDesktopRoles((prev: Array<MenuAppDesktopsRoleResult>) => {
      return [...prev, newDesktopRole]
    })
  }

  //* FUNÇÃO DE SALVAMENTO DE PERMISSÃO
  const handleSaveDesktopRole = (desktopRole: MenuAppDesktopsRoleResult) => {
    let obj = {
      item: {
        MenuAppDesktopsRole: desktopRole,
      },
    }

    return apiWrapper.put('api/v1/MenuAppDesktopsRole/salvar', obj)
  }

  //* FUNÇÃO DE EXCLUSÃO DE PERMISSÃO
  const handleDeleteDesktopRole = (desktopRole: MenuAppDesktopsRoleResult) => {
    let obj = {
      item: {
        ID: desktopRole.id,
      },
    }

    return apiWrapper.delete('api/v1/MenuAppDesktopsRole/excluir', {data: obj, headers: {}})
  }

  //* VARIAVEIS DO MODAL DE EXCLUSÃO
  const [modalExclusao, setModalExclusao] = useState<boolean>(false)

  //* FUNÇÃO DE ABERTURA DO MODAL DE EXCLUSÃO
  const handleOpenExclusao = (desktopRole: MenuAppDesktopsRoleResult) => {
    setModalExclusao(true)
    setDesktopRoleParaExclusao(desktopRole)
  }

  //* FUNÇÃO DE FECHAMENTO E CONFIRMAÇÃO DO MODAL DE EXCLUSÃO
  const handleCloseExclusao = async (excluir: boolean) => {
    setModalExclusao(false)

    if (!excluir || desktopRoleParaExclusao == null) {
      return
    }

    handleOpenPerformingAction('Excluindo registro, aguarde...')

    await handleDeleteDesktopRole(desktopRoleParaExclusao)
    setDesktopRoles((prev: Array<MenuAppDesktopsRoleResult>) => {
      return [...prev.filter((x) => x != desktopRoleParaExclusao)]
    })

    handleClosePerformingAction()
  }

  //* VARIAVEIS DO MODAL DE AGUARDE
  const [modalPerformingAction, setModalPerformingAction] = useState<boolean>(false)
  const [modalPerformingActionMessage, setModalPerformingActionMessage] = useState<string>('')

  //* FUNÇÃO DE ABERTURA DO MODAL DE AGUARDE
  const handleOpenPerformingAction = (message: string) => {
    setModalPerformingAction(true)
    setModalPerformingActionMessage(message)
  }

  //* FUNÇÃO DE FECHAMENTO DO MODAL DE AGUARDE
  const handleClosePerformingAction = () => {
    setModalPerformingAction(false)
    setModalPerformingActionMessage('')
  }

  //* USEEFFECTS
  useEffect(() => {
    handleOpen()
  }, [])

  return (
    <>
      <StyledDialog open={true} fullWidth={true} maxWidth='sm'>
        <DialogTitle>Permissões do Desktop</DialogTitle>
        <DialogContent dividers={true} sx={{minHeight: '300px'}}>
          {loading && (
            <Box component='div' className='d-flex justify-content-center'>
              <CircularProgress />
            </Box>
          )}
          {!loading && desktopRoles.length == 0 && <BoxSVGMessage message='Nenhum item encontrado' />}
          {!loading &&
            desktopRoles.map((item: MenuAppDesktopsRoleResult, index: number) => {
              return (
                <Box key={`desktop-${index}`} component='div' className='d-flex' sx={{py: 1}}>
                  <Box component='div' sx={{pr: 0.5, width: '80%'}}>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={roles}
                      size='small'
                      getOptionLabel={(option: any) => {
                        return option.nome
                      }}
                      renderInput={(params) => <TextField {...params} label='Grupo' />}
                      value={roles.find((op: any) => op.id == item.idrole) ?? null}
                      onChange={(event, value, reason) => handleOnChange(index, 'idrole', value?.id ?? 0)}
                    />
                  </Box>
                  <Box component='div' sx={{pl: 0.5, width: '20%'}}>
                    <Checkbox
                      title='Permitir importação'
                      icon={<FolderSharedOutlinedIcon />}
                      checkedIcon={<FolderSharedIcon />}
                      checked={item.biSelect}
                      onChange={(event, checked) => handleOnChange(index, 'biSelect', event.target.checked)}
                    />
                    <Checkbox
                      title='Exibir automaticamente'
                      icon={<VisibilityOffIcon />}
                      checkedIcon={<VisibilityIcon />}
                      checked={item.biUpdate}
                      onChange={(event, checked) => handleOnChange(index, 'biUpdate', event.target.checked)}
                    />
                    <IconButton aria-label='delete' title='Apagar' color='error' onClick={() => handleOpenExclusao(item)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              )
            })}
        </DialogContent>
        <DialogActions>
          <StyledButton variant='contained' sx={{px: 1}} onClick={() => handleAddDesktopRole()}>
            Adicionar Permissão
          </StyledButton>
          <StyledButton variant='contained' color='inherit' onClick={() => handleClose()}>
            Fechar
          </StyledButton>
        </DialogActions>
      </StyledDialog>

      {/* MODAL EXCLUSÃO */}
      <ModalExclusao open={modalExclusao} title='Excluir' message='Esta ação não pode ser desfeita, confirma?' callBack={handleCloseExclusao} />

      {/* MODAL AGUARDE */}
      {modalPerformingAction && <ModalPerformingAction message={modalPerformingActionMessage} />}
    </>
  )
}

export default DesktopPermissionModal
