export class CWorkflowProp {
  nodeId: string
  jsonInput: string
  jsonOutput: string
  atributo01: string
  atributo02: string
  atributo03: string
  atributo04: string
  atributo05: string
  atributo06: string

  constructor(nodeId: string) {
    this.nodeId = nodeId
    this.jsonInput = ''
    this.jsonOutput = ''
    this.atributo01 = ''
    this.atributo02 = ''
    this.atributo03 = ''
    this.atributo04 = ''
    this.atributo05 = ''
    this.atributo06 = ''
  }
}
