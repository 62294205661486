import React, {useState, useCallback} from 'react'
import {Accept, FileError, FileRejection, useDropzone} from 'react-dropzone'
import {toast} from 'react-toastify'
import Upload from './Upload'
import Item from '../utility/CardItem'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Box, Grid} from '@mui/material'

interface IUploadCollectionProps {
  label?: string
  readonly?: boolean
  customPage?: boolean
  idMenuApp?: number
  azFiles: Array<any>
  accept?: Accept
  noUpload?: boolean
  onDelete: (azFile: any) => void
  onUpload: (newAzFile: any) => void
}

export interface IUploadableFile {
  id: number
  file: File
  errors: FileError[]
  url?: string
}

let currentId = 0

function GetNewID() {
  return ++currentId
}

const MaxSize = 100000 * 1024

const UploadCollection = ({label, readonly, customPage, idMenuApp, azFiles, accept, noUpload, onUpload, onDelete}: IUploadCollectionProps) => {
  const [filesToUpload, setFilesToUpload] = useState<IUploadableFile[]>([])

  const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
    const mappedRej = rejFiles.map((r) => ({...r, id: GetNewID()}))
    const mappedAcc = accFiles.map((file) => ({file, errors: [], id: GetNewID()}))
    setFilesToUpload((curr) => [...curr, ...mappedAcc])

    for (const file of mappedRej) {
      toast.error(`Arquivo "${file.file.name}" inválido.`)
    }
  }, [])

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: accept,
    maxSize: MaxSize, // 5000KB
  })

  const onUploadHook = (newAzFile: any, file: File) => {
    setFilesToUpload((curr) => curr.filter((fw) => fw.file !== file))
    onUpload(noUpload ? file : newAzFile)
  }

  return (
    <React.Fragment>
      <Grid container direction='column' spacing={2}>
        {!readonly && (
          <Grid item>
            <Item>
              <div {...getRootProps({})}>
                <input {...getInputProps()} />
                <Box component='div' className='d-flex bg-light flex-column-fluid'>
                  <Box component='span' className='p-4 mr-2'>
                    <KTSVG path='/media/icons/duotune/files/fil022.svg' className='svg-icon-1' />
                  </Box>
                  <Box component='span' className='d-flex flex-column-fluid pt-5'>
                    {label ?? 'Clique ou arraste e solte arquivos'}
                  </Box>
                </Box>
              </div>
            </Item>
          </Grid>
        )}
        <Grid item container direction='column' spacing={1}>
          {filesToUpload.map((fileWrapper: any, index: number) => (
            <Grid item key={`${fileWrapper.id}`}>
              <Upload
                readonly={readonly}
                customPage={customPage}
                idMenuApp={idMenuApp}
                toUploadFile={fileWrapper.file}
                noUpload={noUpload}
                onDelete={onDelete}
                onUpload={onUploadHook}
              />
            </Grid>
          ))}
          {azFiles.map((azFile: any, index: number) => (
            <Grid item key={`${azFile.nome}`}>
              <Upload
                readonly={readonly}
                customPage={customPage}
                idMenuApp={idMenuApp}
                existentAzFile={azFile}
                onDelete={onDelete}
                onUpload={onUpload}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default UploadCollection
