import {DialogActions, DialogContent, DialogTitle} from '@mui/material'
import StyledDialog from '../styled/StyledDialog'
import StyledButton from '../styled/StyledButton'

interface IModalForm {
  element: any
  title?: string
  fullWidth?: boolean
  maxWidth?: string
  callBack: (data?: any) => void
}

const ModalForm = ({element, title, fullWidth, maxWidth, callBack}: IModalForm) => {
  return (
    <StyledDialog open={true} fullWidth={fullWidth} maxWidth={maxWidth}>
      {title != null && <DialogTitle>{title}</DialogTitle>}
      <DialogContent dividers={true}>{element}</DialogContent>
      <DialogActions>
        <StyledButton variant='contained' color='inherit' onClick={(event) => callBack()}>
          Fechar
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  )
}

export default ModalForm
