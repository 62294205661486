import {Theme, ThemeProvider, styled} from '@mui/material/styles'
import {DataGridPro} from '@mui/x-data-grid-pro'
import {useThemeMode} from '../../../../../_metronic/partials'
import {useEffect, useState} from 'react'
import {globalDarkMuiTheme, globalLightMuiTheme} from '../utility/globalMuiTheme'

const StyledDataGridThemed = styled(DataGridPro)(({theme}) => ({
  border: 0,
  width: '100%',
  color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  fontFamily: [
    'Poppins',
    'Helvetica',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontSize: '13px',
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1e1e2d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'auto',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `0px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-cell': {
    color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  '& .MuiDataGrid-pinnedColumns, .MuiDataGrid-pinnedColumnHeaders ': {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1e1e2d',
  },
  '& .MuiDataGrid, .MuiDataGrid-root, .MuiDataGrid-main': {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1e1e2d',
  },
  '& .MuiTablePagination-displayedRows': {
    paddingTop: '15px',
  },
}))

export const StyledDataGrid = (props) => {
  const {mode} = useThemeMode()
  const [muiTheme, setMuiTheme] = useState<Theme>(mode == 'light' ? globalLightMuiTheme : globalDarkMuiTheme)

  const handleUpdateTheme = () => {
    setMuiTheme((mode as any) == 'dark' ? globalDarkMuiTheme : globalLightMuiTheme)
  }

  useEffect(() => {
    handleUpdateTheme()
  }, [mode])

  return (
    <ThemeProvider theme={muiTheme}>
      <StyledDataGridThemed {...props} />
    </ThemeProvider>
  )
}
