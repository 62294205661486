import {useEffect, useState} from 'react'

import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import CancelIcon from '@mui/icons-material/Cancel'
import FormControl from '@mui/material/FormControl'
import {useThemeMode} from '../../../../../_metronic/partials'
import {globalDarkMuiTheme, globalLightMuiTheme} from '../utility/globalMuiTheme'
import {Theme, ThemeProvider} from '@mui/material'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'

interface IModalSYSMAAProps {
  id: number
  callBack?: any
}

const SYSMAAModal = ({id, callBack}: IModalSYSMAAProps) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [Opened, setOpened] = useState<boolean>(false)
  const [Title, setTitle] = useState<string>('')
  const [Data, setData] = useState<any>(null)

  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  const handleOpen = (id: number) => {
    setOpened(true)
    setTitle('Eventos')

    apiWrapper.get('api/v1/MenuAppAudit/carregar?item.ID=' + id).then((response) => {
      if (response.data.data == null) {
        return
      }

      let events = response.data.data.mensagem.split(' | ')
      setData(events)
    })
  }

  const handleClose = () => {
    setOpened(false)
    setData(null)
    handleCallBack()
  }

  /* THEME */

  const {mode} = useThemeMode()
  const [muiTheme, setMuiTheme] = useState<Theme>(mode == 'light' ? globalLightMuiTheme : globalDarkMuiTheme)

  const handleUpdateTheme = () => {
    setMuiTheme((mode as any) == 'dark' ? globalDarkMuiTheme : globalLightMuiTheme)
  }

  useEffect(() => {
    handleUpdateTheme()
  }, [mode])

  /* END THEME */

  useEffect(() => {
    handleOpen(id)
  }, [])

  return (
    <ThemeProvider theme={muiTheme}>
      <Dialog open={Opened}>
        <DialogTitle>{Title}</DialogTitle>
        <DialogContent dividers={true} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <FormControl sx={{m: 2}} component='fieldset' variant='standard'>
            <TableContainer component={Paper}>
              <Table sx={{minWidth: 650}} size='small' aria-label='SYSMAA table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Evento</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Data &&
                    Data.map((event: any, index: number) => {
                      return (
                        <TableRow key={`event-${index}`}>
                          <TableCell component='th' scope='row'>
                            {event}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleClose()}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default SYSMAAModal
