import useState from 'react-usestateref'
import {StyledTabs} from '../styled/StyledTab'
import {Badge, Tab} from '@mui/material'
import InboxIcon from '@mui/icons-material/Inbox'
import SendIcon from '@mui/icons-material/Send'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import InventoryIcon from '@mui/icons-material/Inventory'

export const EmailSideMenu = (props) => {
  const [tabValue, setTabValue, tabValueRef] = useState('INBOX')

  const handleOnChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    if (props?.callBack != null) {
      props.callBack(newValue)
    }

    if (newValue == 'ADD') {
      return
    }

    setTabValue(newValue)
  }

  return (
    <StyledTabs value={tabValue} onChange={handleOnChangeTab} orientation='vertical' {...props}>
      <Tab
        icon={
          <Badge color='info' badgeContent={props?.naoLidos?.length ?? 0}>
            <InboxIcon />
          </Badge>
        }
        title='Caixa de Entrada'
        value='INBOX'
      />
      <Tab icon={<ScheduleSendIcon />} title='Caixa de Saída' value='SEND' />
      <Tab icon={<SendIcon />} title='Items Enviados' value='SENT' />
      <Tab icon={<InventoryIcon />} title='Rascunhos' value='DRAFT' />
    </StyledTabs>
  )
}

export default EmailSideMenu
