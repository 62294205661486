import {Box, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment} from '@mui/material'
import {useEffect, useState} from 'react'

import CancelIcon from '@mui/icons-material/Cancel'
import SaveIcon from '@mui/icons-material/Save'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'

import StyledButton from '../styled/StyledButton'
import StyledDialog from '../styled/StyledDialog'
import {StyledTextField} from '../styled/StyledTextField'
import {toast} from 'react-toastify'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'

interface IPasswordModal {
  idPessoa: number
  currentPassword?: string
  content?: JSX.Element
  callback(data: any): void
}

export const PasswordModal = ({idPessoa, currentPassword, content, callback}: IPasswordModal) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [loading, setLoading] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [confirmCurrentPassword, setConfirmCurrentPassword] = useState<string>('')
  const [showHiddenPassword, setShowHiddenPassword] = useState<boolean>(false)
  const [showHiddenConfirmPassword, setShowHiddenConfirmPassword] = useState<boolean>(false)
  const [showHiddenCurrentPassword, setShowHiddenCurrentPassword] = useState<boolean>(false)

  const handleCallback = (data: any) => {
    callback(data)
  }

  const handleClose = async (save: boolean) => {
    if (save) {
      if (currentPassword != null && currentPassword != confirmCurrentPassword) {
        toast.error('É necessário informar a senha atual para realizar a alteração')
        return
      }

      if (password != confirmPassword) {
        toast.error('As senhas não coincídem')
        return
      }

      if (password.length < 6) {
        toast.error('A senha precisa ter no mínimo 6 caracteres')
        return
      }

      setSaving(true)

      await apiWrapper.post('api/v1/User/salvarSenha', {item: {IDPessoa: idPessoa, Password: btoa(password)}})

      setSaving(false)
      toast.success((currentPassword == null && 'Senha cadastrada com sucesso!') || 'Senha alterada com sucesso!')
    }

    handleCallback({newPassword: save ? password : undefined, save})
  }

  return (
    <StyledDialog open fullWidth maxWidth='sm'>
      <DialogTitle>Cadastro de senha</DialogTitle>
      <DialogContent dividers sx={{display: 'grid'}}>
        {loading && (
          <Box component='div' className='d-flex justify-content-center'>
            <CircularProgress />
          </Box>
        )}
        <FormControl sx={{justifyContent: 'center', alignItems: 'center'}} component='fieldset' variant='standard'>
          {content}
          {currentPassword && (
            <Box component='div' sx={{p: 1}}>
              <StyledTextField
                fullWidth
                size='small'
                type={showHiddenCurrentPassword ? 'text' : 'password'}
                sx={{width: '350px'}}
                label='Senha atual'
                value={confirmCurrentPassword}
                onChange={(event) => setConfirmCurrentPassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={(event) => setShowHiddenCurrentPassword((prev) => !prev)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge='end'
                      >
                        {showHiddenCurrentPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
          <Box component='div' sx={{p: 1}}>
            <StyledTextField
              fullWidth
              size='small'
              type={showHiddenPassword ? 'text' : 'password'}
              sx={{width: '350px'}}
              label='Nova senha'
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={(event) => setShowHiddenPassword((prev) => !prev)} onMouseDown={(e) => e.preventDefault()} edge='end'>
                      {showHiddenPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box component='div' sx={{p: 1}}>
            <StyledTextField
              fullWidth
              size='small'
              type={showHiddenConfirmPassword ? 'text' : 'password'}
              sx={{width: '350px'}}
              label='Confirmar nova senha'
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={(event) => setShowHiddenConfirmPassword((prev) => !prev)} onMouseDown={(e) => e.preventDefault()} edge='end'>
                      {showHiddenConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <StyledButton variant='contained' color='inherit' disabled={saving} startIcon={<CancelIcon />} onClick={(event) => handleClose(false)}>
          Fechar
        </StyledButton>
        <StyledButton variant='contained' color='success' disabled={saving} startIcon={<SaveIcon />} onClick={(event) => handleClose(true)}>
          {saving ? 'Salvando' : 'Salvar'}
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  )
}

export default PasswordModal
