import {Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import {LayoutSplashScreen} from '../../../_metronic/layout/core'
import {IUserModel} from './models/UserModel'
import {IStorageAuthentication} from './models/AuthenticationModel'
import * as authHelper from './helpers/StorageHelper'
import {useAccount, useMsal} from '@azure/msal-react'
import {IUserDefaultCredential, IUserMSALCredential} from '../api/models/UserModels'
import {UserServiceMSAL, UserServiceDefault} from '../api/services/UserService'
import {setLastErrorInStorage} from '../api/Api'

//* Aqui resgatamos o tipo de autenticação que foi definida no arquivo .env, não utilizado por enquanto ja que só usamos o MSAL por hora
const AUTHENTICATION = process.env.REACT_APP_AUTHENTICATION

interface AuthContextProps {
  auth: IStorageAuthentication | undefined
  credentials: IUserDefaultCredential | undefined
  setCredentials: Dispatch<SetStateAction<IUserDefaultCredential | undefined>>
  saveAuth: (auth: IStorageAuthentication | undefined) => void
  loggedUser: IUserModel | undefined
  setLoggedUser: Dispatch<SetStateAction<IUserModel | undefined>>
  logout: () => void
  requestingUser: boolean
  setRequestingUser: Dispatch<SetStateAction<boolean>>
}

//* Variavel global inicial do contexto de autenticação
const initGlobalAuthenticationContextProps: AuthContextProps = {
  auth: authHelper.getSavedAuthentication(),
  credentials: undefined,
  setCredentials: () => {},
  saveAuth: () => {},
  loggedUser: undefined,
  setLoggedUser: () => {},
  logout: () => {},
  requestingUser: false,
  setRequestingUser: () => {},
}

//* Contexto global utilizado na hierarquia da aplicação
const GlobalAuthenticationContext = createContext<AuthContextProps>(initGlobalAuthenticationContextProps)

//* Apenas uma função getter do contexto caso precisemos em qualquer lugar da aplicação
const useGlobalAuthentication = () => {
  return useContext(GlobalAuthenticationContext)
}

//* Provider da hierarquia da aplicação
const AuthenticationProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<IStorageAuthentication | undefined>(authHelper.getSavedAuthentication())
  const [credentials, setCredentials] = useState<IUserDefaultCredential | undefined>()
  const [loggedUser, setLoggedUser] = useState<IUserModel | undefined>()
  const [requestingUser, setRequestingUser] = useState<boolean>(false)

  const saveAuth = (auth: IStorageAuthentication | undefined) => {
    setAuth(auth)
    if (auth != null) {
      authHelper.setSavedAuthentication(auth)
    } else {
      authHelper.removeSavedAuthentication()
    }
  }

  //* Logout limpando as variaveis do localStorage
  const logout = () => {
    setCredentials(undefined)
    setLoggedUser(undefined)
    saveAuth(undefined)
  }

  return (
    <GlobalAuthenticationContext.Provider
      value={{auth, credentials, setCredentials, saveAuth, loggedUser, setLoggedUser, logout, requestingUser, setRequestingUser}}
    >
      {children}
    </GlobalAuthenticationContext.Provider>
  )
}

//* Neste componente verificamos se o usuário está logado ou não e decidimos o que acontece
const AuthenticationInit: FC<WithChildren> = ({children}) => {
  const {logout, auth, saveAuth, credentials, loggedUser, setLoggedUser, setRequestingUser} = useGlobalAuthentication()
  const [showSplashScreen, setShowSplashScreen] = useState<boolean>(true)

  const {accounts, instance} = useMsal()
  const account = useAccount(accounts[0])

  //* USE EFFECT PARA LOOP AUTH DO MSAL
  useEffect(() => {
    if (account != null && loggedUser == null) {
      let UserService = UserServiceMSAL(account, instance)

      let obj: IUserMSALCredential = {
        ClientID: account.idTokenClaims?.aud as string,
        Email: account.username,
      }

      setRequestingUser(true)
      setShowSplashScreen(true)

      UserService.authenticate(obj)
        .then((response) => {
          //* Aqui setamos o usuario logado o que de fato libera todas as rotas privadas
          setLoggedUser(response.data.data as IUserModel)

          //* Enviamos o request de validação de estrutura pra gerar logs, validar grupos e etc. A partir daqui o usuário ja tem acesso a aplicação
          UserService.validarEstruturaDoUsuario()
        })
        .catch((error) => {
          //* Usuário sem acesso a aplicação
          logout()
          //instance.logoutRedirect({postLogoutRedirectUri: window.location.href})
          // TODO: Escrever o redirect pra alguma pagína de erro de acesso que vai redirecionar o usuario pro logoff da microsoft
        })
        .finally(() => {
          setRequestingUser(false)
          setShowSplashScreen(false)
        })
    } else if (account == null && loggedUser == null) {
      setShowSplashScreen(false)
    }
  }, [account])

  //* USE EFFECT PARA AUTH DEFAULT
  useEffect(() => {
    if (loggedUser != null) {
      return
    }

    if (auth == null && credentials != null) {
      //* Não temos token no local storage porem o usuario informou email e senha
      let UserService = UserServiceDefault()

      setRequestingUser(true)

      UserService.authenticate(credentials)
        .then((response) => {
          saveAuth({api_token: response.data.access_token})
          setLoggedUser(response.data.user as IUserModel)
        })
        .catch((error) => {
          //* Usuário sem acesso a aplicação
          setLastErrorInStorage('Acesso não autorizado', 'Usuário ou senha inválidos', '')
          logout()
        })
        .finally(() => {
          setRequestingUser(false)
          setShowSplashScreen(false)
        })
    } else if (auth != null && credentials == null) {
      //* Temos o token no local storage e não temos email e senha informados

      setRequestingUser(true)

      UserServiceDefault()
        .loadUser(auth.api_token)
        .then((response) => {
          setLoggedUser(response.data.data as IUserModel)
        })
        .catch((error) => {
          //* Usuário sem acesso a aplicação
          logout()
        })
        .finally(() => {
          setRequestingUser(false)
        })
    }
  }, [credentials])

  //* Aqui decidimos retornar oo componente do metronic de splash em casso de usuário não logado ou os componentes renderizados abaixo dele na hierarquia
  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthenticationProvider, AuthenticationInit, useGlobalAuthentication}
