/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useContext, useState} from 'react'
import {KTIcon} from '../../../../helpers'
import {CreateAppModal, Dropdown1} from '../../../../partials'
import {useLayout} from '../../../core'
import {GlobalContext} from '../../../../../app/App'
import {Box} from '@mui/material'

const ToolbarClassic = () => {
  const {config} = useLayout()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop ? 'btn-light' : 'bg-body btn-color-gray-700 btn-active-color-primary'

  const globalContext: any = useContext(GlobalContext)

  const [controls, setControls] = useState<any>([])
  globalContext.ReactState.Toolbar2.setControls = setControls

  return (
    <div className='d-flex align-items-center gap-1 gap-md-1 h-40px'>
      {config.app?.toolbar?.filterButton && (
        <div className='m-0'>
          <a
            href='#'
            className={clsx('btn btn-sm btn-flex fw-bold', daterangepickerButtonClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTIcon iconName='filter' className='fs-6 text-muted me-1' />
            Filter
          </a>
          <Dropdown1 />
        </div>
      )}

      {config.app?.toolbar?.daterangepickerButton && (
        <div
          data-kt-daterangepicker='true'
          data-kt-daterangepicker-opens='left'
          className={clsx('btn btn-sm fw-bold  d-flex align-items-center px-4', daterangepickerButtonClass)}
        >
          <div className='text-gray-600 fw-bold'>Loading date range...</div>
          <KTIcon iconName='calendar-8' className='fs-1 ms-2 me-0' />
        </div>
      )}

      {config.app?.toolbar?.secondaryButton && (
        <a href='#' className='btn btn-sm btn-flex btn-light fw-bold'>
          Filter
        </a>
      )}

      {config.app?.toolbar?.primaryButton && (
        <a href='#' onClick={() => setShowCreateAppModal(true)} className='btn btn-sm fw-bold btn-primary'>
          Create
        </a>
      )}

      {controls.map((control: any, index: number) => {
        return (
          <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}} key={`ctrl-${index}`}>
            {control}
          </Box>
        )
      })}
      <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />
    </div>
  )
}

export {ToolbarClassic}
