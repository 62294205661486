import { GlobalStyles } from '@mui/material';

export const CKEditorZIndexFix = (
  <GlobalStyles
    styles={() => {
      const themeMode = document.documentElement.getAttribute('data-bs-theme');

      return {
        // Fix for CKEditor z-index in dialogs (applies regardless of theme)
        '.ck.ck-balloon-panel': {
          zIndex: '1400 !important', // Ensure CKEditor appears above MUI Dialogs
        },

        ...(themeMode === 'dark' && {
          // Editable area styling (only apply if theme is dark)
          '.ck-content': {
            backgroundColor: '#151521 !important', // Match the website background
            color: '#ffffff !important', // White text color for good contrast
            caretColor: '#ffffff !important', // White caret (text cursor) color
          },

          // Root variable overrides for CKEditor theme (only apply if theme is dark)
          ':root': {
            '--ck-border-radius': '4px', // Overrides the border radius setting in the theme
            '--ck-font-size-base': '14px', // Overrides the default font size in the theme

            /* Helper variables to avoid duplication in the colors */
            '--ck-custom-background': '#151521', // Dark background matching the website
            '--ck-custom-foreground': '#1e1e2b', // Slightly lighter background for contrast
            '--ck-custom-border': '#333', // Darker border for subtle separation
            '--ck-custom-white': '#ffffff', // White color for contrast

            /* Overrides generic colors */
            '--ck-color-base-foreground': 'var(--ck-custom-white)', // Text color
            '--ck-color-focus-border': '#3b82f6', // Focus border color (light blue)
            '--ck-color-text': '#ffffff', // Text color
            '--ck-color-shadow-drop': 'rgba(0, 0, 0, 0.5)', // Drop shadow
            '--ck-color-shadow-inner': 'rgba(0, 0, 0, 0.1)', // Inner shadow

            /* Overrides the default .ck-button class colors */
            '--ck-color-button-default-background': 'var(--ck-custom-foreground)', // Button background
            '--ck-color-button-default-hover-background': '#2c2c38', // Button hover background (darker)
            '--ck-color-button-default-active-background': '#242432', // Button active background (even darker)
            '--ck-color-button-default-active-shadow': '#2a2a36', // Button active shadow
            '--ck-color-button-default-disabled-background': 'var(--ck-custom-foreground)', // Disabled button background

            '--ck-color-button-on-background': 'var(--ck-custom-background)', // Button on background
            '--ck-color-button-on-hover-background': '#2c2c38', // Button on hover background
            '--ck-color-button-on-active-background': '#242432', // Button on active background
            '--ck-color-button-on-active-shadow': '#2a2a36', // Button on active shadow
            '--ck-color-button-on-disabled-background': 'var(--ck-custom-background)', // Button on disabled background

            '--ck-color-button-action-background': '#10b981', // Action button background (teal)
            '--ck-color-button-action-hover-background': '#0f9e76', // Action button hover background
            '--ck-color-button-action-active-background': '#0d8b68', // Action button active background
            '--ck-color-button-action-active-shadow': '#0c7e60', // Action button active shadow
            '--ck-color-button-action-disabled-background': '#10b981', // Action button disabled background
            '--ck-color-button-action-text': 'var(--ck-custom-white)', // Action button text color

            '--ck-color-button-save': '#22c55e', // Save button color (green)
            '--ck-color-button-cancel': '#f97316', // Cancel button color (orange)

            /* Overrides the default .ck-dropdown class colors */
            '--ck-color-dropdown-panel-background': 'var(--ck-custom-background)', // Dropdown background
            '--ck-color-dropdown-panel-border': 'var(--ck-custom-foreground)', // Dropdown border

            /* Overrides the default .ck-dialog class colors */
            '--ck-color-dialog-background': 'var(--ck-custom-background)', // Dialog background
            '--ck-color-dialog-form-header-border': 'var(--ck-custom-border)', // Dialog header border

            /* Overrides the default .ck-splitbutton class colors */
            '--ck-color-split-button-hover-background': 'var(--ck-color-button-default-hover-background)', // Split button hover background
            '--ck-color-split-button-hover-border': 'var(--ck-custom-foreground)', // Split button hover border

            /* Overrides the default .ck-input class colors */
            '--ck-color-input-background': 'var(--ck-custom-background)', // Input background
            '--ck-color-input-border': '#444', // Input border (darker grey for better contrast)
            '--ck-color-input-text': '#ffffff', // Input text color (white for readability)
            '--ck-color-input-disabled-background': '#1e1e2b', // Disabled input background (slightly lighter)
            '--ck-color-input-disabled-border': '#333', // Disabled input border
            '--ck-color-input-disabled-text': '#888', // Disabled input text color (light grey)

            /* Overrides the default .ck-labeled-field-view class colors */
            '--ck-color-labeled-field-label-background': 'var(--ck-custom-background)', // Label background

            /* Overrides the default .ck-list class colors */
            '--ck-color-list-background': 'var(--ck-custom-background)', // List background
            '--ck-color-list-button-hover-background': '#2c2c38', // List button hover background
            '--ck-color-list-button-on-background': '#1e1e2b', // List button on background
            '--ck-color-list-button-on-background-focus': '#242432', // List button on focus background
            '--ck-color-list-button-on-text': 'var(--ck-custom-white)', // List button text color

            /* Overrides the default .ck-balloon-panel class colors */
            '--ck-color-panel-background': 'var(--ck-custom-background)', // Balloon panel background
            '--ck-color-panel-border': 'var(--ck-custom-border)', // Balloon panel border

            /* Overrides the default .ck-toolbar class colors */
            '--ck-color-toolbar-background': 'var(--ck-custom-foreground)', // Toolbar background
            '--ck-color-toolbar-border': 'var(--ck-custom-border)', // Toolbar border

            /* Overrides the default .ck-tooltip class colors */
            '--ck-color-tooltip-background': '#242432', // Tooltip background (darker)
            '--ck-color-tooltip-text': '#ffffff', // Tooltip text color (white for readability)

            /* Overrides the default colors used by the ckeditor5-image package */
            '--ck-color-image-caption-background': '#1e1e2b', // Image caption background (slightly lighter)
            '--ck-color-image-caption-text': '#ffffff', // Image caption text color (white for readability)

            /* Overrides the default colors used by the ckeditor5-widget package */
            '--ck-color-widget-blurred-border': '#444', // Widget blurred border
            '--ck-color-widget-hover-border': '#3b82f6', // Widget hover border (light blue)
            '--ck-color-widget-editable-focus-background': 'var(--ck-custom-white)', // Editable widget focus background

            /* Overrides the default colors used by the ckeditor5-link package */
            '--ck-color-link-default': '#3b82f6', // Link color (light blue for visibility)
          },
        }),
      };
    }}
  />
);
