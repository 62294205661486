import {useEffect, useState} from 'react'

import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import FormControl from '@mui/material/FormControl'

import MenuIcon from '@mui/icons-material/Menu'
import BarChartIcon from '@mui/icons-material/BarChart'
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'

import {TabList} from './tabs/TabList'
import {TabKanban} from './tabs/TabKanban'
import {TabChart} from './tabs/TabChart'
import {TabLog} from './tabs/TabLog'

import {StyledTabs} from './styled/StyledTab'

interface ITabOptions {
  defaultOpenedTab: number
  showTabs: boolean
  reset: boolean
}

const TabsOptions = ({defaultOpenedTab, showTabs, reset}: ITabOptions) => {
  const [TabIndex, setTabIndex] = useState<number>(0)

  const [tab0, setTab0] = useState<boolean>(false)
  const [tab1, setTab1] = useState<boolean>(false)
  const [tab2, setTab2] = useState<boolean>(false)
  const [tab3, setTab3] = useState<boolean>(false)

  const handleTabIndexChange = (event: any, value: number) => {
    let tabs = [setTab0, setTab1, setTab2, setTab3]

    setTabIndex(value)

    for (let i: number = 0; i < 4; i++) {
      tabs[i](value == i)
    }
  }

  useEffect(() => {
    handleTabIndexChange(null, defaultOpenedTab)
  }, [])

  useEffect(() => {
    handleTabIndexChange(null, defaultOpenedTab)
  }, [reset])

  return (
    <Box sx={{width: '100%', height: '100%'}}>
      <StyledTabs hidden={showTabs !== true} value={TabIndex} onChange={handleTabIndexChange}>
        <Tab icon={<MenuIcon color='primary' />} iconPosition='start' label='LISTA' value={0} />
        <Tab icon={<BarChartIcon color='primary' />} iconPosition='start' label='KANBAN' value={1} />
        <Tab icon={<StackedLineChartIcon color='primary' />} iconPosition='start' label='GRAFICOS' value={2} />
        <Tab icon={<HistoryEduIcon color='primary' />} iconPosition='start' label='LOGS' value={3} />
      </StyledTabs>
      <FormControl hidden={TabIndex != 0} sx={{height: '91%', width: '100%'}} component='fieldset' variant='standard'>
        <TabList isDisplayed={tab0} />
      </FormControl>
      <FormControl hidden={TabIndex != 1} sx={{height: '91%', width: '100%'}} component='fieldset' variant='standard'>
        <TabKanban isDisplayed={tab1} />
      </FormControl>
      <FormControl hidden={TabIndex != 2} sx={{height: '91%', width: '100%'}} component='fieldset' variant='standard'>
        <TabChart isDisplayed={tab2} />
      </FormControl>
      <FormControl hidden={TabIndex != 3} sx={{height: '91%', width: '100%'}} component='fieldset' variant='standard'>
        <TabLog isDisplayed={tab3} />
      </FormControl>
    </Box>
  )
}

export default TabsOptions
