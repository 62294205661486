import ptBR from 'date-fns/locale/pt-BR'
import enUS from 'date-fns/locale/en-US'

export const TranslateLocale = (locale: string) => {
  switch (locale) {
    case 'pt':
      return ptBR
    case 'en':
      return enUS
    default:
      return ptBR
  }
}
