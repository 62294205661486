export interface IMenuAppDesktopsRoleResult {
  id: number
  idmenuAppDesktops: number
  idrole: number
  biSelect?: boolean
  biUpdate?: boolean
  ativo: boolean
  biDeleted?: boolean
  dtDeleted?: Date
  idpessoaDeleted?: number
  dtCreated?: Date
  idpessoaCreated?: number
  dtEdited?: Date
  idpessoaEdited?: number
}

export class MenuAppDesktopsRoleResult implements IMenuAppDesktopsRoleResult {
  id: number
  idmenuAppDesktops: number
  idrole: number
  biSelect?: boolean
  biUpdate?: boolean
  ativo: boolean
  biDeleted?: boolean
  dtDeleted?: Date
  idpessoaDeleted?: number
  dtCreated?: Date
  idpessoaCreated?: number
  dtEdited?: Date
  idpessoaEdited?: number

  constructor(idMenuAppDesktop: number) {
    this.id = 0
    this.idmenuAppDesktops = idMenuAppDesktop
    this.idrole = 0
    this.ativo = true
    this.biSelect = true
  }
}
