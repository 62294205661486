import {useNavigate, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {Box} from '@mui/material'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../modules/api/ApiWrapper'
import $ from 'jquery'
import 'jquery-mask-plugin'
import {toast} from 'react-toastify'
import {GetAccessToken} from '../../../modules/api/Api'
import DynamicModal from './modals/modalDynamicV2'

declare global {
  interface Window {
    APP_Start(urlAPI: any, token: any, functions: any): void
  }
}

const CustomPageRenderer = () => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)
  const navigate = useNavigate()

  const [customPageFile, setCustomPageFile] = useState<any>(null)

  //* PARAMETROS DE ROTA
  const RouteParams = useParams()

  const handleInit = () => {
    apiWrapper
      .get(`api/v1/Menu/carregarCustomPageMenu?item.AccessCode=${RouteParams.accesscode}`)
      .then((response) => {
        setCustomPageFile(response.data.data)
        $('#contentbind').load(response.data.data.urlDownload, () => {
          window.APP_Start(process.env.REACT_APP_BST_API_URL, GetAccessToken, {
            jQuery: $,
            navigate: navigate,
            openModal: handleOpenModalDynamic,
            successMSG: toast.success,
            errorMSG: toast.error,
            warnMSG: toast.warning,
          })
        })
      })
      .catch((error) => {
        toast.error(error.response?.data?.mensagem ?? error.message)
      })
  }

  // DYNAMIC

  const [modalDynamic, setModalDynamic] = useState<boolean>(false)
  const [modalDynamicAccessCode, setModalDynamicAccessCode] = useState<string>('')
  const [modalDynamicItemID, setModalDynamicItemID] = useState<number>(0)

  const handleOpenModalDynamic = (id: number, accessCode: string) => {
    setModalDynamic(true)
    setModalDynamicItemID(id)
    setModalDynamicAccessCode(accessCode)
  }

  const handleModalDynamicCallback = (response: any = null) => {
    if (response != null) {
      if (response.keepOpen != null && response.keepOpen && response.id != null) {
        setModalDynamicItemID(response.id)
        return
      }

      if (response.reopenAsEmailSender) {
        //TODO: Abrir o HTMLSend com os dados do grid como no fluxo padrão de seleção
        //return
      }
    }

    setModalDynamic(false)
    setModalDynamicItemID(0)
  }

  useEffect(() => {
    handleInit()
  }, [])

  return (
    <>
      <Box component='div' id='contentbind'></Box>
      {/* MODAL DYNAMIC */}
      {modalDynamic && (
        <DynamicModal disableSendEmail={true} accessCode={modalDynamicAccessCode} id={modalDynamicItemID} callBack={handleModalDynamicCallback} />
      )}
    </>
  )
}

export default CustomPageRenderer
