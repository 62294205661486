// Operadores disponíveis por tipo de campo
//TODO: Esses valores deverão ser mudados para identificadores tanto no front quanto no back, considerando tambem a localização do app
export const fieldOperators = {
  boolean: ['Valor nulo', 'Valor igual a', 'Valor diferente de'],
  string: ['Valor nulo', 'Valor igual a', 'Valor diferente de', 'Valor contém', 'Valor não contém'],
  singleSelect: ['Valor nulo', 'Valor igual a', 'Valor diferente de'],
  number: [
    'Valor nulo',
    'Valor entre',
    'Valor igual a',
    'Valor diferente de',
    'Valor maior que',
    'Valor maior ou igual a',
    'Valor menor que',
    'Valor menor ou igual a',
  ],
  decimal: [
    'Valor nulo',
    'Valor entre',
    'Valor igual a',
    'Valor diferente de',
    'Valor maior que',
    'Valor maior ou igual a',
    'Valor menor que',
    'Valor menor ou igual a',
  ],
  geocode: [
    'Valor nulo',
    'Valor entre',
    'Valor igual a',
    'Valor diferente de',
    'Valor maior que',
    'Valor maior ou igual a',
    'Valor menor que',
    'Valor menor ou igual a',
  ],
  date: [
    'Valor nulo',
    'Valor entre',
    'Valor igual a',
    'Valor diferente de',
    'Valor maior que',
    'Valor maior ou igual a',
    'Valor menor que',
    'Valor menor ou igual a',
  ],
  dateTime: [
    'Valor nulo',
    'Valor entre',
    'Valor igual a',
    'Valor diferente de',
    'Valor maior que',
    'Valor maior ou igual a',
    'Valor menor que',
    'Valor menor ou igual a',
  ],
}
