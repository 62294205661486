export interface IMenuAppDesktopsChartResult {
  id: number
  idmenuAppDesktops: number
  json?: string
  ativo: boolean
  biDeleted?: boolean
  dtDeleted?: Date
  idpessoaDeleted?: number
  dtCreated?: Date
  idpessoaCreated?: number
  dtEdited?: Date
  idpessoaEdited?: number
}

export class MenuAppDesktopsChartResult implements IMenuAppDesktopsChartResult {
  id: number
  idmenuAppDesktops: number
  json?: string
  ativo: boolean
  biDeleted?: boolean
  dtDeleted?: Date
  idpessoaDeleted?: number
  dtCreated?: Date
  idpessoaCreated?: number
  dtEdited?: Date
  idpessoaEdited?: number

  constructor(idMenuAppDesktop: number) {
    this.id = 0
    this.idmenuAppDesktops = idMenuAppDesktop
    this.ativo = true
  }
}
