import {Autocomplete, Box, Button, DialogActions, DialogContent, DialogTitle, FormControl, TextField} from '@mui/material'
import {useEffect, useState} from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import SendIcon from '@mui/icons-material/Send'
import {toast} from 'react-toastify'
import UploadCollection from '../upload/UploadCollection'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import StyledDialog from '../styled/StyledDialog'
import {CKEditorZIndexFix} from '../utility/CKEditorZIndexFix'

interface IHTMLSend {
  id?: number
  accessCode: string
  idIntegration: number
  idMenuAppGenericItem: string
  title: string
  to?: string
  cc?: string
  subject?: string
  content?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  callBack(response: any): void
}

const Editor: any = ClassicEditor

export const HTMLSend = ({id, accessCode, idIntegration, idMenuAppGenericItem, title, to, cc, subject, content, maxWidth, callBack}: IHTMLSend) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [open, setOpen] = useState<boolean>(false)
  const [To, setTo] = useState<string>('')
  const [Subject, setSubject] = useState<string>('')
  const [Content, setContent] = useState<string>('')
  const [Saving, setSaving] = useState<boolean>(false)
  const [anexos, setAnexos] = useState<Array<any>>([])
  const [idIntegracao, setIdIntegracao] = useState<number>(idIntegration)
  const [integracoes, setIntegracoes] = useState<Array<any>>([])

  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  const handleOpen = () => {
    setOpen(true)
    setTo(to != null && to != '' ? to : cc != null && cc != '' ? cc : '')
    setSubject(subject ?? '')
    setContent(content ?? '')

    apiWrapper.get('api/v1/MenuAppIntegration/listar').then((response) => {
      let opcoes = response.data.data.filter((x) => x.idtipoIntegration == 2)
      setIntegracoes(opcoes)

      if (idIntegration == 0 && opcoes.length > 0) {
        setIdIntegracao(opcoes[0].id)
      }
    })
  }

  const handleClose = async (action?: string) => {
    if (action == null) {
      handleDeleteAllUploads()
      handleCallBack()
      return
    }

    if (idIntegracao == 0) {
      toast.error('Selecione uma integração para enviar o email!')
      return
    }

    if (To == null || To == '') {
      toast.error('Informe o destinatário!')
      return
    }

    let obj: any = {
      item: {
        ID: id,
        AccessCode: accessCode,
        IDIntegration: idIntegracao,
        IDMenuAppGenericItem: idMenuAppGenericItem,
        Folder: '',
        To: To,
        Subject: Subject,
        Body: Content.includes("table")? 
        '<style>table{border-collapse:collapse;width:100%}td{border:1px solid #ccc;padding:1px 3px}th{border:1px solid #ccc;padding:1px 3px;background:#eee}</style>' + Content
        : Content,
        Arquivos: anexos,
      },
    }

    let SendEmail: boolean = false

    switch (action) {
      case 'SEND':
        obj.item.Folder = 'SEND'
        SendEmail = true
        break
      case 'DRAFT':
        obj.item.Folder = 'DRAFT'
        break
      default:
        handleDeleteAllUploads()
        handleCallBack()
        return
    }

    setSaving(true)

    const sendRequest = await apiWrapper.post('api/v1/Email/salvarZPLUGMAILBOX', obj)

    if (!SendEmail) {
      handleCallBack()
      return
    } else {
      setOpen(false)
      handleCallBack({keepOpen: true})
    }

    obj.item.ID = sendRequest.data.id
    obj.item.Subject = sendRequest.data.nome
    obj.item.Body = sendRequest.data.bodyHtml

    apiWrapper.post('api/v1/Email/enviarEmail', obj).then((response) => {
      setSaving(false)
      handleCallBack(idIntegration != 0 ? {reload: true} : undefined)
      toast.success('Email enviado com sucesso!')
    })
  }

  const handleOnEditorStartup = (editor: any) => {
    editor.editing.view.change((writer) => {
      writer.setStyle('height', '400px', editor.editing.view.document.getRoot())
    })

    editor.setData(content ?? '')
  }

  const handleOnChangeEditor = (newContent: string) => {
    setContent(newContent)
  }

  const handleOnUpload = (newAzFile: any) => {
    setAnexos((prev: any) => {
      return [...prev, newAzFile]
    })

    toast.success('Arquivo anexado com sucesso!')
  }

  const handleOnDeleteUpload = (AzFile: any) => {
    let obj: any = {
      item: {
        Nome: AzFile.nome,
      },
    }

    apiWrapper.delete('pi/v1/Storage/ApagarArquivoAz', {data: obj, headers: {}})

    setAnexos((prev: any) => {
      prev = prev.filter((item: any) => item != AzFile)
      return [...prev]
    })

    toast.success('Arquivo removido dos anexos com sucesso!')
  }

  const handleDeleteAllUploads = () => {
    anexos.forEach((arquivo) => {
      if (arquivo.id != 0) {
        return
      }

      handleOnDeleteUpload(arquivo)
    })
  }

  const isEditable = () => {
    return !Saving
  }

  useEffect(() => {
    if (open) {
      return
    }

    handleOpen()
  }, [])

  return (
    <StyledDialog open={open} fullWidth={true} maxWidth={maxWidth ?? 'md'}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={true}>
        <FormControl component='fieldset' variant='standard' sx={{width: '100%'}}>
          {integracoes.length > 0 && idIntegration == 0 && (
            <Box component='div' sx={{p: 1}}>
              <Autocomplete
                disablePortal
                disabled={!isEditable()}
                options={integracoes}
                getOptionLabel={(option) => option.nome}
                sx={{width: '100%'}}
                size='small'
                renderInput={(params) => <TextField {...params} label='Integração' />}
                value={idIntegracao != null ? integracoes.find((x) => x.id == idIntegracao) : null}
                onChange={(event, value, reason) => setIdIntegracao(value?.id ?? 0)}
              />
            </Box>
          )}
          <Box component='div' sx={{p: 1}}>
            <TextField
              disabled={!isEditable()}
              sx={{width: '100%'}}
              size='small'
              label='Para'
              value={To}
              onChange={(event) => setTo(event.target.value)}
            />
          </Box>
          <Box component='div' sx={{p: 1}}>
            <TextField
              disabled={!isEditable()}
              sx={{width: '100%'}}
              size='small'
              label='Assunto'
              value={Subject}
              onChange={(event) => setSubject(event.target.value)}
            />
          </Box>
          <Box component='div' sx={{p: 1, color: 'black !important'}}>
            {CKEditorZIndexFix}
            <CKEditor
              editor={Editor}
              config={{
                removePlugins: ['MediaEmbed', 'EasyImage', 'ImageUpload', 'Link', 'CKFinder'],
              }}
              onReady={(editor: any) => {
                handleOnEditorStartup(editor)
              }}
              onChange={(event: any, editor: any) => {
                const data = editor.getData()
                handleOnChangeEditor(data)
              }}
              onBlur={(event: any, editor: any) => {}}
              onFocus={(event: any, editor: any) => {}}
            />
          </Box>
          <Box component='div' sx={{p: 1}}>
            <UploadCollection
              azFiles={anexos}
              onDelete={(azFile) => handleOnDeleteUpload(azFile)}
              onUpload={(newAzFile) => handleOnUpload(newAzFile)}
            />
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' disabled={Saving} startIcon={<CancelIcon />} color='inherit' onClick={() => handleClose()}>
          Fechar
        </Button>
        {/*
          <Button variant='contained' disabled={Saving} startIcon={<SendIcon />} color='success' onClick={() => handleClose('DRAFT')}>
            Salvar Como Rascunho
          </Button>
          */}
        <Button variant='contained' disabled={Saving} startIcon={<SendIcon />} color='info' onClick={() => handleClose('SEND')}>
          Enviar
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default HTMLSend
