import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Theme, ThemeProvider, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import {useThemeMode} from '../../../../../_metronic/partials'
import {globalDarkMuiTheme, globalLightMuiTheme} from '../utility/globalMuiTheme'

import CancelIcon from '@mui/icons-material/Cancel'
import ReplyIcon from '@mui/icons-material/Reply'
import ReplyAllIcon from '@mui/icons-material/ReplyAll'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import DownloadIcon from '@mui/icons-material/Download'
import StyledButton from '../styled/StyledButton'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'

interface IHTMLViewer {
  content: any
  permissions?: any
  callBack(response: any): void
}

export const HTMLViewer = ({content, permissions, callBack}: IHTMLViewer) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [anexos, setAnexos] = useState<Array<any>>([])

  const handleCallBack = (data: any) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  const handleOpen = () => {
    apiWrapper.post('api/v1/Email/marcarZPLUGMAILBOXComoLido', {item: {ID: content.ID}}).then((response) => {
      handleCallBack({action: 'RELOAD', content: content})
    })

    apiWrapper.get(`api/v1/Arquivo/carregarAnexosEmail?item.IDZPLUGMailBox=${content.ID}`).then((response) => {
      setAnexos(response.data.data)
    })
  }

  const handleClose = (action?: string) => {
    handleCallBack({action: action, content: content})
  }

  const downloadFile = (url: string, fileName: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
      })
      .catch(console.error)
  }

  // THEME

  const {mode} = useThemeMode()
  const [muiTheme, setMuiTheme] = useState<Theme>(mode == 'light' ? globalLightMuiTheme : globalDarkMuiTheme)

  const handleUpdateTheme = () => {
    setMuiTheme((mode as any) == 'dark' ? globalDarkMuiTheme : globalLightMuiTheme)
  }

  useEffect(() => {
    handleUpdateTheme()
  }, [mode])

  useEffect(() => {
    handleOpen()
  }, [])

  return (
    <ThemeProvider theme={muiTheme}>
      <Dialog open={true} fullScreen={true}>
        <DialogTitle>
          <Box component='div' sx={{display: 'flex', placeContent: 'space-between'}}>
            <Box component='div'>{content.Nome}</Box>
            <Box component='div'>{new Date(content.Date).toLocaleString()}</Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Box component='div' className='d-flex'>
            {anexos.map((item: any, index: number) => {
              return (
                <Box key={`anexo-${index}`} component='div' sx={{mb: 1, p: 1}}>
                  <StyledButton
                    endIcon={<DownloadIcon />}
                    disableElevation
                    variant='outlined'
                    color='inherit'
                    onClick={() => downloadFile(item.urlDownload, item.nomeOriginal)}
                  >
                    {item.nomeOriginal}
                  </StyledButton>
                </Box>
              )
            })}
          </Box>
          <div className='content' dangerouslySetInnerHTML={{__html: content.BodyHtml}}></div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleClose()}>
            Fechar
          </Button>
          <Button
            variant='contained'
            startIcon={<ReplyIcon />}
            color='info'
            hidden={permissions == null || !permissions.biInsert}
            onClick={() => handleClose('RES')}
          >
            Responder
          </Button>
          <Button
            variant='contained'
            startIcon={<ReplyAllIcon />}
            color='info'
            hidden={permissions == null || !permissions.biInsert}
            onClick={() => handleClose('RESALL')}
          >
            Responder a Todos
          </Button>
          <Button
            variant='contained'
            startIcon={<DoubleArrowIcon />}
            color='info'
            hidden={permissions == null || !permissions.biInsert}
            onClick={() => handleClose('ENC')}
          >
            Encaminhar
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default HTMLViewer
