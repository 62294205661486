import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useEffect, useState} from 'react'
import {Api} from '../../../../../app/modules/api/Api'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../../app/modules/api/ApiWrapper'

export function MenuInner() {
  const intl = useIntl()
  const [menus, setMenus] = useState<any>([])

  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  function BuildMenuStruct(menuArray: any) {
    let Menus: any = []

    menuArray.map((menu: any) => {
      let obj = {
        icon: '/media/icons/duotune' + menu.icon,
        to: menu.app != '' ? 'pages/_dynamicTabs/' + menu.app : menu.url != '' ? menu.url : '#',
        title: menu.title,
        subMenus: BuildMenuStruct(menu.subMenus),
      }

      Menus.push(obj)

      return menu
    })

    return Menus
  }

  function BuildMenuElements(menuStruct: any, isFirst: boolean) {
    let MenuElements: any = []

    menuStruct.map((menu: any, index: number) => {
      if (
        menu.to == null ||
        (menu.to == '#' &&
          (menu.subMenus.length == 0 || (menu.subMenus.length == 1 && menu.subMenus[0].to == '#' && menu.subMenus[0].subMenus.length == 0)))
      ) {
        return
      }

      if (menu.subMenus.length > 0) {
        let innerElements = BuildMenuElements(menu.subMenus, false)

        MenuElements.push(
          <MenuInnerWithSub
            key={index}
            to={menu.to}
            icon={menu.icon}
            title={menu.title}
            menuPlacement={isFirst ? 'bottom-start' : 'right-start'}
            menuTrigger="{default:'click', lg: 'hover'}"
          >
            {innerElements.map((innerMenu: any, index: number) => {
              return innerMenu
            })}
          </MenuInnerWithSub>
        )
      } else {
        MenuElements.push(<MenuItem key={index} to={menu.to} icon={menu.icon} title={menu.title} />)
      }

      return menu
    })

    return MenuElements
  }

  useEffect(() => {
    apiWrapper
      .get('/api/v1/Dynamic/carregarMenus')
      .then((response: any) => {
        let Menus = BuildMenuStruct(response.data.data)
        let MenuElements = BuildMenuElements(Menus, true)
        setMenus(MenuElements)
      })
      .catch((error: any) => {})
  }, [])

  return (
    <>
      {menus.map((menu: any, index: number) => {
        return menu
      })}
      {/* ORIGINAL CODE */}
      {/*
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      <MenuItem title='Layout Builder' to='/builder' />
      <MenuInnerWithSub
        title='Crafted'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuInnerWithSub
          title='Pages'
          to='/crafted/pages'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Profile'
            to='/crafted/pages/profile'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
            <MenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuInnerWithSub
            title='Wizards'
            to='/crafted/pages/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
            <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub>
        <MenuInnerWithSub
          title='Accounts'
          to='/crafted/accounts'
          fontIcon='bi-person'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
          <MenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        </MenuInnerWithSub>
        <MenuInnerWithSub
          title='Errors'
          to='/error'
          fontIcon='bi-sticky'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </MenuInnerWithSub>
        <MenuInnerWithSub
          title='Widgets'
          to='/crafted/widgets'
          fontIcon='bi-layers'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <MenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <MenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <MenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <MenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <MenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub>

      <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
        <MenuInnerWithSub
          title='Chat'
          to='/apps/chat'
          icon='message-text-2'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
        <MenuItem icon='abstract-28' to='/apps/user-management/users' title='User management' />
      </MenuInnerWithSub>

      <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub>
      */}
    </>
  )
}
