import {createTheme} from '@mui/material/styles'

export const globalLightMuiTheme = createTheme({
  palette: {
    mode: 'light',
  },
  typography: {
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
    fontSize: 13,
  },
})

export const globalDarkMuiTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
    fontSize: 13,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        colorInherit: {
          background: '#1e1e2d',
        },
        contained: {
          color: '#fff',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: '#151521',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: '#1e1e2d',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          background: '#1e1e2d',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: '#151521',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: '#1e1e2d',
        },
      },
    },
  },
})
