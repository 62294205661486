import {Theme, ThemeProvider, styled} from '@mui/material/styles'
import {useThemeMode} from '../../../../../_metronic/partials'
import {useEffect, useState} from 'react'
import {globalDarkMuiTheme, globalLightMuiTheme} from '../utility/globalMuiTheme'
import {RadioGroup} from '@mui/material'

const StyledRadioGroupThemed = styled(RadioGroup)(({theme}) => ({
  color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
}))

export const StyledRadioGroup = (props) => {
  const {mode} = useThemeMode()
  const [muiTheme, setMuiTheme] = useState<Theme>(mode == 'light' ? globalLightMuiTheme : globalDarkMuiTheme)

  const handleUpdateTheme = () => {
    setMuiTheme((mode as any) == 'dark' ? globalDarkMuiTheme : globalLightMuiTheme)
  }

  useEffect(() => {
    handleUpdateTheme()
  }, [mode])

  return (
    <ThemeProvider theme={muiTheme}>
      <StyledRadioGroupThemed {...props} />
    </ThemeProvider>
  )
}
