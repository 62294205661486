import React, {useEffect, useState} from 'react'
import moment from 'moment'

import ReactFlow, {Edge, Node} from 'reactflow'
import 'reactflow/dist/style.css'
import 'reactflow/dist/base.css'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import Badge from '@mui/material/Badge'

import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Cancel'
import SaveIcon from '@mui/icons-material/Save'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import BoltIcon from '@mui/icons-material/Bolt'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import HelpIcon from '@mui/icons-material/Help'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import AttractionsIcon from '@mui/icons-material/Attractions'
import MessageIcon from '@mui/icons-material/Message'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TreeView from '@mui/lab/TreeView'
import TreeItem from '@mui/lab/TreeItem'

import {toast} from 'react-toastify'
import ptBRLocale from 'date-fns/locale/pt-BR'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DateTimePicker, DesktopDatePicker} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {NumericFormatWithDecimal, NumericFormatWithoutDecimal} from '../utility/numericFormatDecimal'
import FieldRuleFilter from './fieldRuleFilter'
import {CGlobalFilterStructure} from '../../../../models/classes/CMenuAppHistoryStructure'
import InputLabel from '@mui/material/InputLabel'
import {
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormGroup,
  Typography,
  CircularProgress,
  IconButton,
  Tabs,
  Tab,
} from '@mui/material'
import {CCalcFieldRule} from '../../../../models/classes/CCalcFieldRule'
import FieldRuleOperation from './fieldRuleOperation'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import StyledCard from '../styled/StyledCard'
import StyledCardContent from '../styled/StyledCardContent'
import StyledCardActions from '../styled/StyledCardActions'
import {CRuleActions} from '../../../../models/classes/CRuleActions'
import FieldRuleActions from './fieldRuleActions'
import ModalModeloSQL from '../modals/modalModeloSQL'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'

import CodeMirror, {Extension} from '@uiw/react-codemirror'
import {json} from '@codemirror/lang-json'
import {autocompletion} from '@codemirror/autocomplete'

import {useThemeMode} from '../../../../../_metronic/partials'
import {basicLight} from '@uiw/codemirror-theme-basic'
import {tokyoNight} from '@uiw/codemirror-theme-tokyo-night'
import {useIntl} from 'react-intl'

import {handleOnCompletions} from './fieldRuleModalV2_functions'
import CustomNode from '../flowchart/customNode'
import ModalForm from '../modals/modalForm'
import {handleApplyClampedValueToState} from '../../../../models/wrappers/handlers'

interface IFieldRuleProps {
  id: number
  accessCode: string
  idMenuApp: number
  StartEvent?: string
  startAction?: string
  hideDescription?: boolean
  hideBackButton?: boolean
  logicalOperator?: string
  callBack: any
}

const nodeTypes = {
  custom: CustomNode,
}

const CADASTRO = -1
const EDICAO = -2

const FieldRuleModalV2 = ({
  id,
  accessCode,
  idMenuApp,
  StartEvent,
  startAction,
  hideDescription,
  hideBackButton,
  logicalOperator,
  callBack,
}: IFieldRuleProps) => {
  const msal = useMsal()
  const intl = useIntl()
  const apiWrapper = new ApiWrapper(msal.instance)

  //VARIAVEIS DO ESTADO GERAL DO COMPONENTE
  const [Opened, setOpened] = useState<boolean>(false)
  const [View, setView] = useState<number | null>(id == 0 ? null : EDICAO)
  const [Saving, setSaving] = useState<boolean>(false)
  const [Eventos, setEventos] = useState<Array<any>>([])
  const [Loading, setLoading] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<number>(0)

  //VARIAVEIS DA LOGICA DO COMPONENTE
  const [FieldRule, setFieldRule] = useState<any>({
    id: id,
    nome: '',
    comentario: '',
    ordem: 0,
    idmenuApp: idMenuApp,
    idmenuAppField: null,
    idmenuAppRuleEventos: null,
    idmenuAppRuleEventosAcoes: null,
    evento: '',
    valor: '',
    acao: '',
    formula: '',
    atributo01: '',
    atributo02: '',
    atributo03: '',
    atributo04: '',
    atributo05: '',
    atributo06: '',
    atributo07: '',
    atributo08: '',
    atributo09: '',
    atributo10: '',
  })

  const [Fields, setFields] = useState<Array<any>>([])
  const [FieldsLoopBack, setFieldsLoopBack] = useState<Array<any>>([])
  const [FieldsWithRelations, setFieldsWithRelations] = useState<Array<any>>([])
  const [TipoValores, setTipoValores] = useState<Array<any>>(['Específico', 'Função', 'Procedimento'])
  const [Funcoes, setFuncoes] = useState<Array<any>>(['Data Atual', 'Usuário Logado'])
  const [Atribuicoes, setAtribuicoes] = useState<Array<any>>([
    {id: 'ROLE', label: 'Grupo'},
    {id: 'USER', label: 'Usuário'},
    {id: 'PROC', label: 'Dinâmico - Executar procedimento'},
  ])
  const [OpcoesBool, setOpcoesBool] = useState<Array<any>>([
    {id: 'true', label: 'Verdadeiro'},
    {id: 'false', label: 'Falso'},
  ])
  const [Operadores, setOperadores] = useState<Array<any>>([])
  const Ops = {
    boolean: ['Não está preenchido', 'Está preenchido', 'Igual', 'Diferente'],
    string: ['Não está preenchido', 'Está preenchido', 'Igual', 'Diferente', 'Contém', 'Não contém'],
    singleSelect: ['Não está preenchido', 'Está preenchido', 'Igual', 'Diferente'],
    number: ['Não está preenchido', 'Está preenchido', 'Igual', 'Diferente', 'Maior que', 'Menor que', 'Maior ou igual a', 'Menor ou igual a'],
    decimal: ['Não está preenchido', 'Está preenchido', 'Igual', 'Diferente', 'Maior que', 'Menor que', 'Maior ou igual a', 'Menor ou igual a'],
    geocode: ['Não está preenchido', 'Está preenchido', 'Igual', 'Diferente', 'Maior que', 'Menor que', 'Maior ou igual a', 'Menor ou igual a'],
    date: ['Não está preenchido', 'Está preenchido', 'Igual', 'Diferente', 'Maior que', 'Menor que', 'Maior ou igual a', 'Menor ou igual a'],
    dateTime: ['Não está preenchido', 'Está preenchido', 'Igual', 'Diferente', 'Maior que', 'Menor que', 'Maior ou igual a', 'Menor ou igual a'],
  }

  const RequestTypes = ['GET', 'POST', 'PUT', 'DELETE']

  const [Templates, setTemplates] = useState<Array<any>>([])
  const [Contas, setContas] = useState<Array<any>>([])
  const [Valores, setValores] = useState<Array<any>>([])
  const [Grupos, setGrupos] = useState<Array<any>>([])
  const [Usuarios, setUsuarios] = useState<Array<any>>([])
  const [menuApps, setMenuApps] = useState<Array<any>>([])
  const [menuAppFields, setMenuAppFields] = useState<Array<any>>([])
  const [menuAppLoopBack, setMenuAppLoopBack] = useState<any>(null)
  const [menuAppIntegrations, setMenuAppIntegrations] = useState<Array<any>>([])
  const [acoes, setAcoes] = useState<Array<string>>([])
  const [camposSelecionados, setCamposSelecionados] = useState<Array<string>>([])
  const [customActions, setCustomActions] = useState<Array<any>>([])

  // TREE VIEW

  const concatCampos = [
    {
      id: -1,
      label: 'Em grupo',
      tipo: 'number',
      guid: `{"id": -1, "label": "Em grupo", "tipo": "number"}`,
    },
  ]

  const [expanded, setExpanded] = useState<string[]>([])
  const [selected, setSelected] = useState<string>('')
  const [selectedNode, setSelectedNode] = useState<Array<any>>([])
  const [selectedNodeIds, setSelectedNodeIds] = useState<Array<number>>([])
  const [selectedNodeLabel, setSelectedNodeLabel] = useState<string>('')

  const BuildFieldTreeView = (item: any) => {
    if (item.tipo != 'singleSelect' || item.loopBackFields.length == 0) {
      return <TreeItem key={item.guid.toString()} nodeId={item.guid.toString()} label={item.label} />
    } else {
      return (
        <TreeItem key={item.guid.toString()} nodeId={item.guid.toString()} label={item.label}>
          {item.loopBackFields.map((item: any, index: number) => {
            return BuildFieldTreeView(item)
          })}
        </TreeItem>
      )
    }
  }

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds)
    setFieldRule((prev) => {
      prev.atributo01 = JSON.stringify(nodeIds)
      return {...prev}
    })
  }

  const handleSelect = (event: React.SyntheticEvent, nodeId: string, key: string) => {
    setSelected(nodeId)
    setSelectedNode(JSON.parse(`[${nodeId}]`))
    setFieldRule((prev) => {
      prev[key] = nodeId
      return {...prev}
    })
  }

  useEffect(() => {
    if (selectedNode.length == 0) {
      return
    }

    let label = ''
    let ids: Array<number> = []

    for (const node of selectedNode) {
      let concat = `${node.label}`

      if (label != '') {
        label = `${label} > ${concat}`
      } else {
        label = concat
      }

      ids.push(node.id)
    }

    setOperadores(Ops[selectedNode[selectedNode.length - 1]?.tipo] ?? Ops['boolean'])

    setSelectedNodeIds(ids)
    setSelectedNodeLabel(label)

    setFieldRule((prev) => {
      prev.valor = ''
      prev.atributo03 = label
      prev.atributo04 = JSON.stringify(ids)

      /*
      if (prev.atributo05 == 'ACAO') {
        prev.atributo06 = 'Igual'
        prev.atributo07 = 'Específico'
      }
      */

      return {...prev}
    })
  }, [selectedNode])

  /* MODAL FORM */

  const [modalForm, setModalForm] = useState<boolean>(false)
  const [modalFormComponentMode, setModalFormComponentMode] = useState<string>()

  const handleOpenModalForm = (componentMode: string) => {
    setModalForm(true)
    setModalFormComponentMode(componentMode)
  }

  const handleModalFormCallBack = (data?: any) => {
    setModalForm(false)
    setModalFormComponentMode('')
  }

  // END TREE VIEW

  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  const handleValidate = () => {
    // TODO: Validar situações ao invés de campos

    if (FieldRule.nome == '') {
      toast.error('É necessário informar um nome para esta regra!')
      return false
    }

    switch (FieldRule.acao) {
    }

    return true
  }

  const handleOpen = async (id: number) => {
    setOpened(true)
    setLoading(true)

    let eventos: Array<any> = []

    //CARREGAMOS OS EVENTOS E ACOES POR BLOCO DO BACK ANTES DE FAZER QUALQUER OUTRA COISA
    await apiWrapper.get('api/v1/MenuAppRuleEventos/listarRuleEventos').then((response) => {
      eventos = response.data.data
      setEventos(eventos)
    })

    let fieldsRequest = await apiWrapper.get('api/v1/MenuAppFields/listarPorMenuApp?item.IDMenuApp=' + idMenuApp)
    let fieldsTemp = fieldsRequest.data.data.filter((x: any) => x.tipo != 'upload')
    setFields(fieldsTemp)

    await apiWrapper
      .get(`api/v1/MenuAppFields/listarPorMenuAppComRelacoes?item.IDMenuApp=${idMenuApp}&item.CamposDeSistema=true`)
      .then((response) => {
        setFieldsWithRelations(response.data.data.concat(concatCampos))
      })

    apiWrapper.get('api/v1/MenuAppIntegration/listar').then((response) => {
      setMenuAppIntegrations(response.data.data)
    })

    apiWrapper.get('api/v1/MenuAppMailTemplate/listar').then((response) => {
      setTemplates(response.data.data)
    })

    apiWrapper.get('api/v1/MenuAppMailConfig/listar').then((response) => {
      setContas(response.data.data)
    })

    apiWrapper.get('api/v1/System/listarPessoas?item.Query=').then((response) => {
      setUsuarios(response.data.data)
    })

    apiWrapper.get('api/v1/System/listarRoles?item.Query=').then((response) => {
      setGrupos(response.data.data)
    })

    apiWrapper.get(`api/v1/MenuAppAction/listarPorMenuApp?item.AccessCode=${accessCode}`).then((response) => {
      setCustomActions(response.data.data.filter((x) => x.tipo == 'Custom'))
    })

    if (id != 0) {
      await apiWrapper.get('api/v1/MenuAppFieldsRule/carregar?item.ID=' + id).then((response) => {
        const rule = response.data.data
        const value = rule.valor

        let evento = eventos[0].eventos.find((x) => x.id == rule.idmenuAppRuleEventos)
        let eventoAcoes = eventos.find((x) => x.idMenuAppRuleEventoPai == rule.idmenuAppRuleEventos)
        let acao = eventoAcoes.acoes.find((x) => x.id == rule.idmenuAppRuleEventosAcoes)

        handleAddActions(evento.eventoNome)
        handleAddActions(acao.acaoNome)

        setFieldRule(rule)

        let fieldContexto = fieldsTemp.find((x) => x.id == rule.idmenuAppField)
        if (fieldContexto != null) {
          handleOnChange('idmenuAppField', undefined, fieldContexto, undefined, undefined, true)
        }

        if (
          rule.acao == 'Buscar Valor' ||
          rule.acao == 'Aplicar Formula' ||
          (rule.acao == 'Executar ação de estrutura' && rule.atributo05 == 'FILTRAR' && rule.formula != '')
        ) {
          setFilters(JSON.parse(rule.formula))
        } else if (rule.acao == 'Calcular Campos') {
          setOperations(JSON.parse(rule.formula))
        } else if (rule.acao == 'Bloquear Campos') {
          if (rule.atributo02 != '') {
            setCamposSelecionados(JSON.parse(rule.atributo02))
          }
        } else if (rule.acao == 'Ocultar Campos') {
          if (rule.evento == 'Ao abrir formulario' && rule.atributo01 != '') {
            setCamposSelecionados(JSON.parse(rule.atributo01))
          }
          if (rule.evento == 'Campo possui valor' && rule.atributo02 != '') {
            setCamposSelecionados(JSON.parse(rule.atributo02))
          }
        } else if (rule.acao == 'Exibir Campos') {
          if (rule.atributo02 != '') {
            setCamposSelecionados(JSON.parse(rule.atributo02))
          }
        } else if (rule.acao == 'Criar Tarefa') {
          setAcoesDeRegra(JSON.parse(rule.atributo10))
        } else if (rule.acao == 'Exibir confirmacao') {
          if (rule.atributo09 != '') {
            setFilters(JSON.parse(rule.atributo09))
          }

          setAcoesDeRegra(JSON.parse(rule.atributo10))
        } else if (rule.acao == 'Executar acoes') {
          if (rule.atributo01 != '') {
            setFilters(JSON.parse(rule.atributo01))
          }

          if (rule.atributo02 != '') {
            setAcoesDeRegra(JSON.parse(rule.atributo02))
          }
        } else if (rule.acao == 'Ocultar custom action') {
          if (rule.atributo02 != '') {
            setCamposSelecionados(JSON.parse(rule.atributo02))
          }
        }

        if (rule.acao == 'Executar ação de estrutura' || rule.acao == 'Aplicar Filtro Avançado') {
          if (rule.atributo01 != '') {
            setExpanded(JSON.parse(rule.atributo01))
          }
          if (rule.atributo02 != '') {
            setSelected(rule.atributo02)
            setSelectedNode(JSON.parse(`[${rule.atributo02}]`))
          }
          if (rule.atributo03 != '') {
            setSelectedNodeLabel(rule.atributo03)
          }
          if (rule.atributo04 != '') {
            setSelectedNodeIds(JSON.parse(rule.atributo04))
          }
          if (rule.atributo05 == 'FILTRAR' && rule.atributo06 != '') {
            let loopBackField = fieldsTemp.find((x) => x.id == rule.atributo06)

            if (loopBackField != null) {
              handleLoadFieldsLoopBack(loopBackField.idmenuAppLoopBack)
              handleLoadMenuAppLoopBack(loopBackField.idmenuAppLoopBack)
            }
          }
          if (rule.atributo05 == 'ACAO' && rule.atributo08 != '') {
            setCamposSelecionados(JSON.parse(rule.atributo08))
          }
        }

        setTimeout(() => {
          setFieldRule((prev) => {
            prev.valor = value
            return {...prev}
          })

          let idmenuAppField = rule.idmenuAppField

          if ((rule.acao == 'Executar ação de estrutura' || rule.acao == 'Aplicar Filtro Avançado') && rule.atributo04 != '') {
            let fields = JSON.parse(rule.atributo04) as Array<number>
            idmenuAppField = fields[fields.length - 1]
          }

          if (Valores.length == 0 && idmenuAppField > 0) {
            handleOnInputChangeAutoComplete('', null, idmenuAppField, rule.acao == 'Aplicar Filtro Avançado')
          }
        }, 100)
      })

      handleOnInputChangeAutoCompleteMenuApps('')
    } else {
      if (StartEvent != null) {
        let evento = eventos[0].eventos.find((x) => x.evento == StartEvent)
        let eventoAcoes = eventos.find((x) => x.idMenuAppRuleEventoPai == evento.id)

        handleChangeView(evento.id, 0, evento.evento, '')
        handleAddActions(evento.eventoNome)

        if (startAction != null) {
          let acao = eventoAcoes.acoes.find((x) => x.acao == startAction)

          handleChangeView(evento.id, acao.id, acao.evento ?? '', acao.acao)
          handleAddActions(acao.acaoNome)
        }
      }
    }

    setLoading(false)
  }

  const handleClose = (save: boolean) => {
    if (!save) {
      setOpened(false)
      handleCallBack({})
      return
    }

    if (!handleValidate()) {
      return
    }

    if (
      FieldRule.acao == 'Buscar Valor' ||
      FieldRule.acao == 'Aplicar Formula' ||
      (FieldRule.acao == 'Executar ação de estrutura' && FieldRule.atributo05 == 'FILTRAR')
    ) {
      FieldRule.formula = JSON.stringify(filters)
      /*
            if (filters.length > 0) {
              FieldRule.formula = JSON.stringify(filters)
            } else {
              toast.error('É necessário aplicar pelo menos um filtro a esta regra!')
              return
            }*/
    } else if (FieldRule.acao == 'Calcular Campos') {
      if (operations.length > 1) {
        FieldRule.formula = JSON.stringify(operations)
      } else {
        toast.error('É necessário aplicar pelo menos uma operação completa a esta regra!')
        return
      }
    } else if (FieldRule.acao == 'Criar Tarefa') {
      FieldRule.atributo10 = JSON.stringify(acoesDeRegra)
    } else if (FieldRule.acao == 'Exibir confirmacao') {
      FieldRule.atributo09 = JSON.stringify(filters)
      FieldRule.atributo10 = JSON.stringify(acoesDeRegra)
    } else if (FieldRule.acao == 'Executar acoes') {
      FieldRule.atributo01 = JSON.stringify(filters)
      FieldRule.atributo02 = JSON.stringify(acoesDeRegra)
    }

    setSaving(true)

    let obj: any = {
      item: {
        MenuAppFieldsRule: FieldRule,
      },
    }

    apiWrapper.put('api/v1/MenuAppFieldsRule/salvar', obj).then((response) => {
      setSaving(false)
      setOpened(false)
      handleCallBack({})
      toast.success('Registro salvo com sucesso!')
    })
  }

  const handleChangeView = (idEvento: number, idAcao: number, evento: string, acao: string, resetFilters: boolean = false) => {
    //AQUI DEFINIMOS O QUE ESTA APARECENDO NO MODAL, SE É A PARTE DE EDIÇÃO DE REGRA OU OS BLOCOS DE EVENTOS-ACOES

    //Resetamos o FieldRule para limpar qualquer valor que tenha sido preenchido por outra previa seleção de regra ou evento
    setFieldRule({
      ...FieldRule,
      idmenuAppRuleEventos: idEvento > 0 ? idEvento : null,
      idmenuAppRuleEventosAcoes: idAcao > 0 ? idAcao : null,
      evento: evento,
      valor: '',
      acao: acao,
      formula: '',
      atributo01: '',
      atributo02: '',
      atributo03: '',
      atributo04: '',
      atributo05: '',
      atributo06: '',
      atributo07: '',
      atributo08: '',
      atributo09: '',
      atributo10: logicalOperator != null ? logicalOperator : '',
    })

    //Caso a ação seja diferente de string vazia então sabemos que esta na hora de aplicar a ação, assim trocando a view para edição onde é renderizada os componentes de cada regra
    //Caso contrario se for cadastro limpamos as ações do campo azul e retornamos a view inicial, senão apenas colocamos a view para renderizar o bloco de eventos-acoes com aquele ID
    if (acao != '') {
      setView(EDICAO)
    } else {
      if (idEvento == CADASTRO) {
        setView(null)
        setAcoes([])
        setFilters([])
        setAcoesDeRegra([])
        setCamposSelecionados([])
      } else {
        setView(idEvento)
      }
    }
  }

  const handleOnChange = (field: string, event?: any, value?: any, convertToString?: boolean, isString?: boolean, isOpeningEvent?: boolean) => {
    setFieldRule((prev: any) => {
      if (field == 'ordem') {
        prev[field] = event.target.value != '' ? parseInt(event.target.value) : 0
        return {...prev}
      }

      if (field == 'idmenuAppField') {
        if (!isOpeningEvent) {
          prev.valor = ''
        }

        if (prev['acao'] == 'Bloquear Campos' && (prev['idmenuAppField'] == null || prev['idmenuAppField'] == 0)) {
          prev['atributo01'] = 'ALL'
          prev['atributo03'] = ''
        }

        if (value != null) {
          setOperadores(Ops[value.tipo] ?? Ops['boolean'])

          if (value.tipo == 'singleSelect') {
            apiWrapper.get(`api/v1/MenuAppFields/listarAccessCodePorLoopback?item.ID=${value.id}`).then((response) => {
              setFilterAccessCode(response.data.data)
            })
          }
        } else {
          setOperadores([])
        }

        if (!isOpeningEvent) {
          setFilters([])
        }
      }

      if (prev.acao == 'Exibir confirmacao' && field == 'atributo02') {
        prev['formula'] = ''
      }

      if (prev.acao == 'Criar Tarefa') {
        if (field == 'atributo05') {
          prev['atributo01'] = ''
          prev['atributo02'] = ''
        }
        if (field == 'atributo06') {
          prev['atributo03'] = ''
          prev['atributo04'] = ''
        }
      }

      if (prev.acao == 'Executar ação de estrutura') {
        if (field == 'atributo05') {
          prev.atributo06 = ''
          prev.atributo07 = ''
          prev.valor = ''
        }

        if (prev.atributo05 == 'FILTRAR') {
          if (field == 'atributo06') {
            if (value != null) {
              handleLoadFieldsLoopBack(value.idmenuAppLoopBack)
              handleLoadMenuAppLoopBack(value.idmenuAppLoopBack)
            } else {
              setFieldsLoopBack([])
              setMenuAppLoopBack(null)
            }

            prev.atributo07 = ''
          }
        }
      }

      if (prev.acao == 'Aplicar Filtro Avançado') {
        if (field == 'atributo06') {
          prev.atributo07 = 'Específico'
          prev.valor = ''
        }

        if (field == 'atributo07') {
          prev.valor = ''
        }
      }

      if (prev.acao == 'Buscar Valor') {
        if (field == 'atributo04') {
          //TODO?
        }
      }

      if (isString != null && isString) {
        prev[field] = value
      } else {
        if (value != null) {
          prev[field] = convertToString ? value.id.toString() : value.id

          if (prev.acao == 'Aplicar Filtro Avançado' && field == 'valor') {
            prev[field] = JSON.stringify(value)
          }
        } else {
          prev[field] = ''
        }
      }

      if (event != null) {
        prev[field] = event.target.value
      }

      return {...prev}
    })
  }

  const handleOnChangeString = (field: string, value: string) => {
    setFieldRule((prev: any) => {
      if (
        field == 'atributo01' ||
        field == 'atributo02' ||
        field == 'atributo03' ||
        field == 'atributo04' ||
        field == 'atributo05' ||
        field == 'atributo06' ||
        field == 'atributo07' ||
        field == 'atributo08'
      ) {
        prev['valor'] = ''
      }

      prev[field] = value

      return {...prev}
    })
  }

  const handleOnOpenAutoComplete = (event: any, idmenuAppField: number) => {
    if (event.target.value == '' || event.target.value == null) {
      handleOnInputChangeAutoComplete('', null, idmenuAppField)
    }
  }

  const handleOnInputChangeAutoComplete = (value: any, id: any = null, idmenuAppField: number, advancedFilter: boolean = false) => {
    let url = ''

    if (id == null) {
      url = `/api/v1/Dynamic/listarSingleSelect?item.IDMenuAppField=${idmenuAppField}&item.Query=${value}&RegistroPorPagina=999`
    } else {
      url = `/api/v1/Dynamic/listarSingleSelect?item.IDMenuAppField=${idmenuAppField}&item.ID=${id}&RegistroPorPagina=999`
    }

    apiWrapper.get(url).then((response) => {
      let options = response.data.data
      setValores(options)

      if (id != null) {
        if (advancedFilter) {
          let option = options.find((x) => x.id == id)
          let optionString = option != null ? JSON.stringify(option) : '{}'

          setFieldRule((prev: any) => {
            return {...prev, valor: optionString}
          })

          return
        }

        setFieldRule((prev: any) => {
          prev.valor = id.toString()
          return {...prev}
        })
      }
    })
  }

  const handleOnChangeDate = (field: string, value: any) => {
    setFieldRule((prev: any) => {
      prev[field] = moment(value).format('YYYY-MM-DDTHH:mm:ss').toString()
      return {...prev}
    })
  }

  const handleOnChangeMultipleSelect = (field: string, event: any) => {
    const {
      target: {value},
    } = event
    setCamposSelecionados(typeof value === 'string' ? value.split(',') : value)
    setFieldRule((prev: any) => {
      prev[field] = JSON.stringify(typeof value === 'string' ? value.split(',') : value)
      return {...prev}
    })
  }

  const handleAddActions = (action: string) => {
    setAcoes((prev: Array<string>) => {
      return [...prev, action]
    })
  }

  function buildRuleIndexString(quantity: number) {
    //AQUI MONTAMOS ESSAS DESGRAÇA DE '>' PRA POR ANTES DO EVENTO DA REGRA NO PAINEL DE DETALHES DA REGRA

    let result = ''

    for (let i = 0; i < quantity; i++) {
      if (i == quantity - 1) {
        result += '>'
      } else {
        result += '-'
      }
    }

    return result
  }

  function download(filename: string, text: string) {
    var a = document.createElement('a')
    a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
    a.setAttribute('download', filename)

    if (document.createEvent) {
      var event = document.createEvent('MouseEvents')
      event.initEvent('click', true, true)
      a.dispatchEvent(event)
    } else {
      a.click()
    }
  }

  function BaixarArquivoModelo(resourceName: string) {
    toast.info('Gerando arquivo modelo, aguarde...')

    apiWrapper.get(`api/v1/MenuAppResource/carregarModeloPorNome?item.Nome=${resourceName}`).then((response) => {
      download('modelo.sql', response.data.data.resource)
      toast.success('Arquivo modelo gerado com sucesso!')
    })
  }

  /* MENU APPS | FIELDS */

  const handleLoadFields = (accessCode: string) => {
    apiWrapper.get(`api/v1/MenuAppFields/listarPorMenuApp?item.AccessCode=${accessCode}`).then((response) => {
      setMenuAppFields(response.data.data.filter((x: any) => x.tipo != 'upload'))
    })
  }

  const handleLoadFieldsLoopBack = (idMenuApp: number) => {
    apiWrapper.get(`api/v1/MenuAppFields/listarPorMenuApp?item.IDMenuApp=${idMenuApp}`).then((response) => {
      setFieldsLoopBack(response.data.data.filter((x: any) => x.tipo != 'upload'))
    })
  }

  const handleLoadMenuAppLoopBack = (idMenuApp: number) => {
    apiWrapper.get(`api/v1/MenuApp/carregar?item.ID=${idMenuApp}`).then((response) => {
      setMenuAppLoopBack(response.data.data)
    })
  }

  const handleOnOpenAutoCompleteMenuApps = (event: any) => {
    if (event.target.value == '' || event.target.value == null) {
      handleOnInputChangeAutoCompleteMenuApps('')
    }
  }

  const handleOnInputChangeAutoCompleteMenuApps = (value: any, id: any = null) => {
    apiWrapper
      .get(
        `api/v1/MenuApp/listarPorTitulo?RegistroPorPagina=999&item.Query=${value}&item.ReturnObject=true${id != null ? '&item.IDMenuApp=' + id : ''}`
      )
      .then((response) => {
        setMenuApps(response.data.data)

        if (value == '' && FieldRule.atributo01 != '') {
          let app: any = response.data.data.find((x: any) => x.id == FieldRule.atributo01)
          if (app != null) {
            handleLoadFields(app.accessCode)
          }
        }
      })
  }

  const handleOnChangeAutoCompleteMenuApps = (field: string, value: any) => {
    setFieldRule((prev: any) => {
      if (field == 'atributo01') {
        if (value != null) {
          handleLoadFields(value.accessCode)
        } else {
          setMenuAppFields([])
          prev['atributo02'] = ''
        }
      }

      setFilters([])

      prev[field] = value?.id != null ? value.id.toString() : ''
      return {...prev}
    })
  }

  /* END MENU APPS | FIELDS */
  const getValorJson = (data: any) => {
    let result = data?.valor

    if (result != null && result != '') {
      return result
    }

    return '{}'
  }

  const getMenuAppFieldType = () => {
    let field = Fields.find((x) => x.id == FieldRule.idmenuAppField)

    if (field != null) {
      return field.tipo as string
    }

    return undefined
  }

  const getValorControl = (type: string, idmenuAppField?: number, width?: any, advancedFilter: boolean = false) => {
    let control: any = null

    if (idmenuAppField == null) {
      idmenuAppField = FieldRule.idmenuAppField
    }

    if (width == null) {
      width = 300
    }

    switch (type) {
      case 'number':
        control = (
          <TextField
            sx={{width: width}}
            disabled={!isEditable()}
            size='small'
            label='Valor'
            type='number'
            InputLabelProps={{shrink: true}}
            value={FieldRule.valor}
            onChange={(event) => handleOnChange('valor', event)}
            InputProps={{
              inputComponent: NumericFormatWithoutDecimal as any,
            }}
          />
        )
        break
      case 'decimal':
        control = (
          <TextField
            sx={{width: width}}
            disabled={!isEditable()}
            size='small'
            label='Valor'
            InputLabelProps={{shrink: true}}
            value={FieldRule.valor}
            onChange={(event) =>
              handleApplyClampedValueToState(
                setFieldRule,
                'valor',
                parseFloat(event.target.value != '' ? event.target.value : '0'),
                -9999999.99,
                9999999.99,
                2
              )
            }
            InputProps={{
              inputComponent: NumericFormatWithDecimal as any,
            }}
          />
        )
        break
      case 'geocode':
        control = (
          <TextField
            sx={{width: width}}
            disabled={!isEditable()}
            size='small'
            label='Valor'
            InputLabelProps={{shrink: true}}
            value={FieldRule.valor}
            onChange={(event) =>
              handleApplyClampedValueToState(
                setFieldRule,
                'valor',
                parseFloat(event.target.value != '' ? event.target.value : '0'),
                -999.999999,
                999.999999,
                6
              )
            }
            InputProps={{
              inputComponent: NumericFormatWithDecimal as any,
            }}
          />
        )
        break
      case 'string':
        control = (
          <TextField
            sx={{width: width}}
            disabled={!isEditable()}
            size='small'
            label='Valor'
            value={FieldRule.valor}
            onChange={(event) => handleOnChange('valor', event)}
          />
        )
        break
      case 'dateTime':
        control = (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
            <DateTimePicker
              slotProps={{textField: {size: 'small'}}}
              label='Valor'
              value={FieldRule.valor != '' ? new Date(FieldRule.valor) : null}
              onChange={(date) => handleOnChangeDate('valor', date)}
              sx={{width: width}}
            />
          </LocalizationProvider>
        )
        break
      case 'date':
        control = (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
            <DesktopDatePicker
              slotProps={{textField: {size: 'small'}}}
              label='Valor'
              value={FieldRule.valor != '' ? new Date(FieldRule.valor) : null}
              onChange={(date) => handleOnChangeDate('valor', date)}
              sx={{width: width}}
            />
          </LocalizationProvider>
        )
        break
      case 'boolean':
        control = (
          <Autocomplete
            disablePortal
            disabled={!isEditable()}
            options={OpcoesBool}
            sx={{width: width}}
            size='small'
            renderInput={(params) => <TextField {...params} label='Valor' />}
            value={FieldRule.valor != null && FieldRule.valor != '' ? OpcoesBool.find((op: any) => op.id == FieldRule.valor) ?? null : null}
            onChange={(event, value, reason) => handleOnChange('valor', undefined, value)}
          />
        )
        break
      case 'singleSelect':
        if (advancedFilter) {
          control = (
            <Autocomplete
              disablePortal
              disabled={!isEditable()}
              options={Valores}
              sx={{width: width}}
              size='small'
              renderInput={(params) => <TextField {...params} label='Valor' />}
              value={
                FieldRule.valor != null && FieldRule.valor != ''
                  ? Valores.find((op: any) => op.id == JSON.parse(getValorJson(FieldRule))?.id) ?? null
                  : null
              }
              onOpen={(event) => handleOnOpenAutoComplete(event, idmenuAppField as number)}
              onChange={(event, value, reason) => handleOnChangeString('valor', value != null ? JSON.stringify(value) : '')}
              onInputChange={(event, value) => handleOnInputChangeAutoComplete(value, null, idmenuAppField as number, true)}
              filterOptions={(x) => x}
            />
          )
        } else {
          control = (
            <Autocomplete
              disablePortal
              disabled={!isEditable()}
              options={Valores}
              sx={{width: width}}
              size='small'
              renderInput={(params) => <TextField {...params} label='Valor' />}
              value={FieldRule.valor != null && FieldRule.valor != '' ? Valores.find((op: any) => op.id == FieldRule.valor) ?? null : null}
              onOpen={(event) => handleOnOpenAutoComplete(event, idmenuAppField as number)}
              onChange={(event, value, reason) => handleOnChange('valor', undefined, value, true)}
              onInputChange={(event, value) => handleOnInputChangeAutoComplete(value, null, idmenuAppField as number)}
              filterOptions={(x) => x}
            />
          )
        }

        break
    }

    if (control == null) {
      return <></>
    }

    return (
      <Box component='div' sx={{p: 1}}>
        {control}
      </Box>
    )
  }

  const isEditable = () => {
    return !Saving
  }

  /* MODAL MODELO SQL */

  const [modalModeloSQL, setModalModeloSQL] = useState<boolean>(false)
  const [modalModeloSQLTipo, setModalModeloSQLTipo] = useState<string>('')

  const handleOpenModalModeloSQL = (tipo: string) => {
    setModalModeloSQL(true)
    setModalModeloSQLTipo(tipo)
  }

  const modalModeloCallback = (data?: any) => {
    setModalModeloSQL(false)
  }

  /* MODAL AÇÕES */

  const [acoesDeRegra, setAcoesDeRegra] = useState<Array<CRuleActions>>([])
  const [modalAcoesDeRegra, setModalAcoesDeRegra] = useState<boolean>(false)

  const handleOpenModalAcoesDeRegra = () => {
    setModalAcoesDeRegra(true)
  }

  const handleCallBackModalAcoesDeRegra = (response: any = null) => {
    if (response?.apply) {
    }

    setModalAcoesDeRegra(false)
  }

  /* MODAL FILTERS */

  const [filters, setFilters] = useState<Array<CGlobalFilterStructure>>([])
  const [modalFilter, setModalFilter] = useState<boolean>(false)

  const [filterAccessCode, setFilterAccessCode] = useState<string>('')

  const filterCallBack = (response: any = null) => {
    if (response?.apply) {
    }

    setModalFilter(false)
  }

  const handleOpenModalFilters = () => {
    setModalFilter(true)
  }

  /* OPERATIONS */

  const [operations, setOperations] = useState<Array<CCalcFieldRule>>([])
  const [modalOperations, setModalOperations] = useState<boolean>(false)

  const operationCallBack = (response: any = null) => {
    if (response?.apply) {
    }

    setModalOperations(false)
  }

  const handleOpenModalOperations = () => {
    setModalOperations(true)
  }

  /* CODE EDITOR */

  const {mode} = useThemeMode()
  const [codeEditorTheme, setCodeEditorTheme] = useState<Extension>(basicLight)

  const handleOnChangeCodeEditor = (field: string, value: string) => {
    setFieldRule((prev) => {
      prev[field] = value
      return {...prev}
    })
  }

  /* NODES */

  const [flowNodes, setFlowNodes] = useState<Array<Node>>([])
  const [flowEdges, setFlowEdges] = useState<Array<Edge>>([])

  const [selectedFlowNode, setSelectedFlowNode] = useState<Node | undefined>(undefined)

  const handleOnNodeClick = (event: React.MouseEvent, node: Node) => {
    setSelectedFlowNode(node)

    if (node.data.onClick == null) {
      return
    }

    node.data.onClick(event)
  }

  useEffect(() => {
    setFlowNodes((prev) => {
      return [...prev]
    })
  }, [filters])

  useEffect(() => {
    handleOpen(id)
  }, [])

  useEffect(() => {
    setCodeEditorTheme(mode == 'light' ? basicLight : tokyoNight)
  }, [mode])

  return (
    <Dialog fullWidth maxWidth='md' open={Opened}>
      <DialogTitle>Configuração de Regra</DialogTitle>
      <DialogContent dividers={true} sx={{display: 'grid'}}>
        {Loading && (
          <Box component='div' className='d-flex justify-content-center'>
            <CircularProgress />
          </Box>
        )}
        {!Loading && !hideDescription && (
          <Box component='div' className='alert alert-primary d-flex align-items-center'>
            <i className='ki-duotone ki-shield-tick fs-2hx text-success me-4'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <div className='d-flex flex-column'>
              <h4 className='mb-1 text-dark'>Detalhes da Regra</h4>
              {acoes.length == 0 && <span>Aguardando escolha da regra...</span>}
              {acoes.map((item, index) => {
                return (
                  <span key={`acao-${index}`}>
                    {buildRuleIndexString(index + 1)} {item}
                  </span>
                )
              })}
            </div>
          </Box>
        )}
        {!Loading && (
          <Tabs value={selectedTab} onChange={(event, value) => setSelectedTab(value)}>
            <Tab label='Dados Gerais' value={0} />
            <Tab label='Configuração' value={1} />
          </Tabs>
        )}
        <br />
        {!Loading &&
          selectedTab == 1 &&
          Eventos.map((eventoBlock: any, EBIndex: number) => {
            //AQUI PERCORREMOS OS BLOCOS DE EVENTO E RENDERIZAMOS UM A UM COM SEUS EVENTOS E AÇÕES
            //Isso consiste numa coleção de FormControls que aparecem ou não dependendo da variavel de estado VIEW
            //Essa variavel carrega o ID do bloco eventos-acoes que foi selecionado ou nulo caso estejamos na pagina inicial do modal (onde são carregados os eventos-acoes "gerais")

            let formControl = (
              <FormControl
                hidden={View != eventoBlock.idMenuAppRuleEventoPai}
                sx={{p: 1, height: '100%', width: '100%'}}
                component='fieldset'
                variant='standard'
                key={`evento-block-${EBIndex}`}
              >
                <Box component='div' className='container'>
                  <Box component='div' className='row'>
                    {eventoBlock.eventos.map((evento: any, EIndex: number) => {
                      //RENDERIZANDO EVENTOS

                      if (evento.ativo == null || evento.ativo === false) {
                        return
                      }

                      return (
                        <Box
                          key={`evento-block-evento-${EIndex}`}
                          component='div'
                          className='col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-6'
                          sx={{marginBottom: '20px'}}
                        >
                          <StyledCard>
                            <StyledCardContent sx={{height: '127px'}}>
                              <Box component='div' className='row d-flex'>
                                <Box component='div' className='col-md-1'>
                                  <PlaylistAddIcon color='primary' sx={{fontSize: 30}} />
                                </Box>
                                <Box component='div' className='col-md-11'>
                                  <Typography variant='body1' color='textSecondary' component='p' sx={{mt: 0.6}} className='word-wrap text-dark'>
                                    <strong>{evento.eventoNome}</strong>
                                  </Typography>
                                </Box>
                              </Box>
                              <Box component='div' className='row'>
                                <Typography variant='body1' color='textSecondary' component='p' className='word-wrap text-dark py-2'>
                                  {evento.descricao}
                                </Typography>
                              </Box>
                            </StyledCardContent>
                            <StyledCardActions sx={{justifyContent: 'flex-end'}}>
                              <Button
                                startIcon={<AddIcon />}
                                color='primary'
                                onClick={() => {
                                  handleChangeView(evento.id, 0, evento.evento, '')
                                  handleAddActions(evento.eventoNome)
                                }}
                              >
                                Selecionar
                              </Button>
                            </StyledCardActions>
                          </StyledCard>
                        </Box>
                      )
                    })}
                    {eventoBlock.acoes.map((acao: any, AIndex: number) => {
                      //RENDERIZANDO AÇÕES

                      if (acao.ativo == null || acao.ativo === false) {
                        return
                      }

                      return (
                        <Box
                          key={`evento-block-acao-${AIndex}`}
                          component='div'
                          className='col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-6'
                          sx={{marginBottom: '20px'}}
                        >
                          <StyledCard>
                            <StyledCardContent sx={{height: '127px'}}>
                              <Box component='div' className='row d-flex'>
                                <Box component='div' className='col-md-1'>
                                  <PlaylistAddIcon color='primary' sx={{fontSize: 30}} />
                                </Box>
                                <Box component='div' className='col-md-11'>
                                  <Typography variant='body1' color='textSecondary' component='p' sx={{mt: 0.6}} className='word-wrap text-dark'>
                                    <strong>{acao.acaoNome}</strong>
                                  </Typography>
                                </Box>
                              </Box>
                              <Box component='div' className='row'>
                                <Typography variant='body1' color='textSecondary' component='p' className='word-wrap text-dark py-2'>
                                  {acao.descricao}
                                </Typography>
                              </Box>
                            </StyledCardContent>
                            <StyledCardActions sx={{justifyContent: 'flex-end'}}>
                              <Button
                                startIcon={<AddIcon />}
                                color='primary'
                                onClick={() => {
                                  handleChangeView(eventoBlock.idMenuAppRuleEventoPai ?? 0, acao.id, acao.evento ?? '', acao.acao)
                                  handleAddActions(acao.acaoNome)
                                }}
                              >
                                Selecionar
                              </Button>
                            </StyledCardActions>
                          </StyledCard>
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
              </FormControl>
            )

            return formControl
          })}
        <FormControl
          hidden={Loading || selectedTab != 0}
          sx={{justifyContent: 'center', alignItems: 'center'}}
          component='fieldset'
          variant='standard'
        >
          <Box component='div' sx={{p: 1, width: '100%'}}>
            <TextField
              fullWidth
              disabled={!isEditable()}
              size='small'
              label='Nome'
              value={FieldRule.nome}
              onChange={(event) => handleOnChange('nome', event)}
            />
          </Box>
          <Box component='div' sx={{p: 1, width: '100%'}}>
            <TextField
              fullWidth
              disabled={!isEditable()}
              type='number'
              InputLabelProps={{shrink: true}}
              size='small'
              label='Ordem'
              value={FieldRule.ordem}
              onChange={(event) => {
                handleOnChange('ordem', event)
              }}
            />
          </Box>
          <Box component='div' sx={{p: 1, width: '100%'}}>
            <TextField
              fullWidth
              disabled={!isEditable()}
              size='small'
              label='Descrição'
              value={FieldRule.comentario}
              onChange={(event) => handleOnChange('comentario', event)}
              multiline
              maxRows={8}
              minRows={8}
            />
          </Box>
        </FormControl>
        <FormControl
          hidden={View != EDICAO || selectedTab != 1}
          sx={{justifyContent: 'center', alignItems: 'center'}}
          component='fieldset'
          variant='standard'
        >
          {FieldRule.acao == 'Aplicar Filtro Avançado' && (
            <>
              <Box component='div' className='d-flex flex-row' sx={{width: '100%', maxHeight: '400px'}}>
                <Box component='div' sx={{width: '50%'}}>
                  <Box component='div' sx={{px: 2, py: 1}}>
                    <h5>Campo de resgate de valor</h5>
                  </Box>
                  <Box component='div' sx={{maxHeight: '300px'}}>
                    <Box component='div' sx={{p: 1, maxHeight: '300px', overflowY: 'scroll'}}>
                      <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        expanded={expanded}
                        selected={selected}
                        onNodeToggle={handleToggle}
                        onNodeSelect={(event, nodeId) => handleSelect(event, nodeId, 'atributo02')}
                        //multiSelect
                      >
                        {FieldsWithRelations.length > 0 && (
                          <>
                            {FieldsWithRelations.map((item: any, index: number) => {
                              return BuildFieldTreeView(item)
                            })}
                          </>
                        )}
                      </TreeView>
                    </Box>
                  </Box>
                </Box>
                <Box component='div' className='d-flex flex-column' sx={{width: '50%', overflowY: 'auto'}}>
                  {selectedNode.length > 0 && Operadores.length > 0 && (
                    <>
                      <Box component='div' sx={{p: 1}}>
                        <FormControl disabled={logicalOperator == '-' || logicalOperator != ''}>
                          <FormLabel id='demo-row-radio-buttons-group-label'>Operador Lógico</FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            name='row-radio-buttons-group'
                            value={FieldRule.atributo10}
                            onChange={(event) => handleOnChange('atributo10', event)}
                          >
                            {logicalOperator == '-' && <FormControlLabel value='-' control={<Radio />} label='Nenhum' />}
                            <FormControlLabel value='E' control={<Radio />} label='E' />
                            <FormControlLabel value='Ou' control={<Radio />} label='Ou' />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {selected == concatCampos[0].guid && (
                        <>
                          <Box component='div' sx={{p: 1}}>
                            <FormControl sx={{width: '99%'}}>
                              <FormLabel>Trazer registros caso o usuário logado pertença ao grupo:</FormLabel>
                            </FormControl>
                          </Box>
                          <Box component='div' sx={{p: 1}}>
                            <Autocomplete
                              disablePortal
                              disabled={!isEditable()}
                              options={Grupos}
                              sx={{width: '99%'}}
                              size='small'
                              renderInput={(params) => <TextField {...params} label='Grupo' />}
                              value={
                                FieldRule.valor != null && FieldRule.valor != ''
                                  ? Grupos.find((op: any) => op.id == JSON.parse(getValorJson(FieldRule))?.id) ?? null
                                  : null
                              }
                              onChange={(event, value, reason) => handleOnChangeString('valor', value != null ? JSON.stringify(value) : '')}
                            />
                          </Box>
                          <Box component='div' sx={{p: 1, textAlign: 'center'}} className='text-muted my-1 fs-6'>
                            ATENÇÃO: Grupos de sistema como "Super Administrador", "Administrador" e "Todos os Usuários" não serão considerados nesta
                            regra.
                          </Box>
                        </>
                      )}
                      {selected != concatCampos[0].guid && (
                        <>
                          <Box component='div' sx={{p: 1}}>
                            <FormControl sx={{width: '99%'}}>
                              <FormLabel>Trazer registros onde o campo "{selectedNodeLabel}":</FormLabel>
                            </FormControl>
                          </Box>
                          <Box component='div' sx={{p: 1}}>
                            <Autocomplete
                              fullWidth
                              disablePortal
                              options={Operadores}
                              sx={{width: '99%'}}
                              size='small'
                              renderInput={(params) => <TextField {...params} label='Operador' />}
                              value={FieldRule.atributo06}
                              onChange={(event, value, reason) => handleOnChange('atributo06', undefined, value, false, true)}
                            />
                          </Box>
                          {FieldRule.atributo06 == 'Igual' && (
                            <Box component='div' sx={{p: 1}}>
                              <Autocomplete
                                disablePortal
                                disabled={!isEditable()}
                                options={TipoValores}
                                sx={{width: '99%'}}
                                size='small'
                                renderInput={(params) => <TextField {...params} label='Tipo de Valor' />}
                                value={FieldRule.atributo07}
                                onChange={(event, value, reason) => handleOnChangeString('atributo07', value)}
                              />
                            </Box>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {selected != concatCampos[0].guid && (
                    <>
                      {selectedNode.length > 0 &&
                        FieldRule.atributo06 != '' &&
                        FieldRule.atributo06 != 'Não está preenchido' &&
                        FieldRule.atributo06 != 'Está preenchido' &&
                        FieldRule.atributo07 == 'Específico' && (
                          <>{getValorControl(selectedNode[selectedNode.length - 1].tipo, selectedNode[selectedNode.length - 1].id, '99%', true)}</>
                        )}
                      {selectedNode.length > 0 && FieldRule.atributo07 == 'Função' && (
                        <Box component='div' sx={{p: 1}}>
                          <Autocomplete
                            disablePortal
                            disabled={!isEditable()}
                            options={Funcoes.concat(['Em grupos comuns', 'Em grupos comuns ao usuário logado'])}
                            sx={{width: '99%'}}
                            size='small'
                            renderInput={(params) => <TextField {...params} label='Função' />}
                            value={FieldRule.valor}
                            onChange={(event, value, reason) => handleOnChangeString('valor', value)}
                          />
                        </Box>
                      )}
                      {FieldRule.atributo07 == 'Procedimento' && (
                        <>
                          <Box component='div' sx={{p: 1}}>
                            <TextField
                              sx={{width: '99%'}}
                              disabled={!isEditable()}
                              size='small'
                              label='Nome do procedimento'
                              value={FieldRule.valor}
                              onChange={(event) => handleOnChangeString('valor', event.target.value)}
                            />
                          </Box>
                          <Box component='div' sx={{p: 1, textAlign: 'center'}} className='text-muted my-1 fs-6'>
                            Stored procedure deve conter os parametros IDPessoaMaster, IDPessoa e IDMenuApp.
                          </Box>
                        </>
                      )}
                      {FieldRule.atributo07 == 'Função' &&
                        (FieldRule.valor == 'Em grupos comuns' || FieldRule.valor == 'Em grupos comuns ao usuário logado') && (
                          <Box component='div' sx={{p: 1, textAlign: 'center'}} className='text-muted my-1 fs-6'>
                            ATENÇÃO: Grupos de sistema como "Super Administrador", "Administrador" e "Todos os Usuários" não serão considerados nesta
                            regra.
                          </Box>
                        )}
                    </>
                  )}
                </Box>
              </Box>
            </>
          )}
          {FieldRule.acao == 'Buscar Valor' && (
            <>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Fields}
                  sx={{width: 330}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Campo que sofre a alteração' />}
                  value={FieldRule.idmenuAppField != null ? Fields.filter((op: any) => op.id == FieldRule.idmenuAppField)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('idmenuAppField', undefined, value)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Fields}
                  sx={{width: 330}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Campo a ser preenchido' />}
                  value={FieldRule.atributo03 != null ? Fields.filter((op: any) => op.id == FieldRule.atributo03)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo03', undefined, value, true)}
                />
              </Box>
              <Box component='div' sx={{px: 2, py: 1}}>
                <FormControl sx={{width: '99%'}}>
                  <RadioGroup value={FieldRule.atributo04} onChange={(event) => handleOnChange('atributo04', event)}>
                    <FormControlLabel value='APP' control={<Radio />} label='App' />
                    <FormControlLabel value='PROC' control={<Radio />} label='Procedimento' />
                  </RadioGroup>
                </FormControl>
              </Box>
              {FieldRule.atributo04 == 'PROC' && (
                <>
                  <Box component='div' sx={{p: 1, alignSelf: 'center'}}>
                    <a
                      title='Clique aqui para realizar Download do arquivo modelo'
                      style={{cursor: 'pointer'}}
                      onClick={(event) => BaixarArquivoModelo('Resgate')}
                    >
                      <div className='container'>
                        <div className='row'>
                          <div className='col-1'></div>
                          <div className='col-2 p-1 text-center '>
                            <img alt='Logo' src={toAbsoluteUrl('/media/app/sql64x64.png')} className='h-45px w-45px' />
                          </div>
                          <div className='col-8 p-1'>
                            <span>Para baixar o arquivo modelo deste app </span>{' '}
                            <span>
                              <strong className='link-primary'>Clique Aqui</strong> e após, execute o procedimento no banco de dados da aplicação (
                              Compativel com SQL SERVER ).
                            </span>
                          </div>
                          <div className='col-1'></div>
                        </div>
                      </div>
                    </a>
                  </Box>
                  <Box component='div' sx={{p: 1, display: 'flex', width: '380px'}}>
                    <TextField
                      sx={{width: 380}}
                      disabled={!isEditable()}
                      size='small'
                      label='Nome do procedimento'
                      value={FieldRule.valor}
                      onChange={(event) => handleOnChange('valor', event)}
                    />
                  </Box>
                  <Box component='div' sx={{p: 1, textAlign: 'center'}} className='text-muted my-1 fs-6'>
                    Stored procedure deve conter os parametros IDPessoaMaster, IDPessoa, IDMenuApp, IDMenuAppGenericValue e Values.
                  </Box>
                </>
              )}
              {FieldRule.atributo04 == 'APP' && (
                <>
                  <Box component='div' sx={{p: 1}}>
                    <Autocomplete
                      disablePortal
                      disabled={!isEditable()}
                      options={menuApps}
                      sx={{width: 330}}
                      size='small'
                      renderInput={(params) => <TextField {...params} label='App do campo de resgate' />}
                      getOptionLabel={(option) => option.titulo}
                      value={
                        FieldRule.atributo01 != null && FieldRule.atributo01 != ''
                          ? menuApps.filter((op: any) => op.id == FieldRule.atributo01)[0] ?? null
                          : null
                      }
                      onInputChange={(event, value) => handleOnInputChangeAutoCompleteMenuApps('')}
                      onOpen={(event) => handleOnOpenAutoCompleteMenuApps(event)}
                      onChange={(event, value, reason) => handleOnChangeAutoCompleteMenuApps('atributo01', value)}
                    />
                  </Box>
                  <Box component='div' sx={{p: 1}}>
                    <Autocomplete
                      disablePortal
                      options={menuAppFields}
                      sx={{width: 330}}
                      size='small'
                      disabled={!isEditable()}
                      renderInput={(params) => <TextField {...params} label='Campo para resgatar o valor' />}
                      value={
                        FieldRule.atributo02 != null && FieldRule.atributo02 != ''
                          ? menuAppFields.find((op: any) => op.id == FieldRule.atributo02) ?? null
                          : null
                      }
                      onChange={(event, value, reason) => handleOnChangeAutoCompleteMenuApps('atributo02', value)}
                    />
                  </Box>
                  {FieldRule.atributo01 != null &&
                    FieldRule.atributo01 != '' &&
                    menuApps.find((op: any) => op.id == FieldRule.atributo01) != null && (
                      <>
                        <Box component='div' sx={{p: 1}}>
                          <Badge
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            badgeContent={filters.length}
                            color='primary'
                          >
                            <Button variant='contained' startIcon={<FilterAltIcon />} color='inherit' onClick={() => handleOpenModalFilters()}>
                              Filtros
                            </Button>
                          </Badge>
                        </Box>
                        {/* MODAL FILTER */}
                        {modalFilter && (
                          <FieldRuleFilter
                            accessCode={menuApps.find((op: any) => op.id == FieldRule.atributo01).accessCode}
                            accessCodeExtra={accessCode}
                            filters={filters}
                            setFilters={setFilters}
                            callBack={filterCallBack}
                          />
                        )}
                      </>
                    )}
                </>
              )}
            </>
          )}
          {FieldRule.acao == 'Calcular Campos' && (
            <>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Fields}
                  sx={{width: 330}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Campo' />}
                  value={FieldRule.idmenuAppField != null ? Fields.filter((op: any) => op.id == FieldRule.idmenuAppField)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('idmenuAppField', undefined, value)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Fields.filter((x: any) => x.tipo == 'number' || x.tipo == 'decimal')}
                  sx={{width: 330}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Campo que recebe o resultado' />}
                  value={FieldRule.atributo01 != null ? Fields.filter((op: any) => op.id == FieldRule.atributo01)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo01', undefined, value, true)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  badgeContent={operations.length}
                  color='primary'
                >
                  <Button variant='contained' startIcon={<FilterAltIcon />} color='inherit' onClick={() => handleOpenModalOperations()}>
                    Operações
                  </Button>
                </Badge>
              </Box>
              {/* MODAL OPERATIONS */}
              {modalOperations && (
                <FieldRuleOperation accessCode={accessCode} operations={operations} setOperations={setOperations} callBack={operationCallBack} />
              )}
            </>
          )}
          {FieldRule.acao == 'Enviar Email - Para endereço eletrônico' && (
            <>
              <Box component='div' sx={{p: 1}}>
                <TextField
                  sx={{width: 300}}
                  disabled={!isEditable()}
                  size='small'
                  label='Endereço eletrônico'
                  value={FieldRule.formula}
                  onChange={(event) => handleOnChange('formula', event)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Templates}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Template' />}
                  value={FieldRule.atributo01 != null ? Templates.filter((op: any) => op.id == FieldRule.atributo01)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo01', undefined, value, true)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Contas}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Conta de Envio' />}
                  value={FieldRule.atributo02 != null ? Contas.filter((op: any) => op.id == FieldRule.atributo02)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo02', undefined, value, true)}
                />
              </Box>
            </>
          )}
          {FieldRule.acao == 'Enviar Email - Para pessoa do formulário' && (
            <>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Fields}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Campo Pessoa' />}
                  value={FieldRule.formula != null ? Fields.filter((op: any) => op.id == FieldRule.formula)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('formula', undefined, value, true)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Templates}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Template' />}
                  value={FieldRule.atributo01 != null ? Templates.filter((op: any) => op.id == FieldRule.atributo01)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo01', undefined, value, true)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Contas}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Conta de Envio' />}
                  value={FieldRule.atributo02 != null ? Contas.filter((op: any) => op.id == FieldRule.atributo02)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo02', undefined, value, true)}
                />
              </Box>
            </>
          )}
          {FieldRule.acao == 'Criar Tarefa' && (
            <>
              <Box component='div' sx={{px: 1}}>
                <FormControl sx={{width: '100%'}}>
                  <RadioGroup row value={FieldRule.atributo06} onChange={(event) => handleOnChange('atributo06', event)}>
                    <FormControlLabel value='SIMPLES' control={<Radio />} label='Tarefa simples' />
                    <FormControlLabel value='APROVACAO' control={<Radio />} label='Tarefa de aprovação' />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Fields}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Campo que contem o valor' />}
                  value={FieldRule.idmenuAppField != null ? Fields.filter((op: any) => op.id == FieldRule.idmenuAppField)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('idmenuAppField', undefined, value)}
                />
              </Box>
              {getMenuAppFieldType() != null && <>{getValorControl(getMenuAppFieldType() as string)}</>}
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Atribuicoes}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Atribuir a um' />}
                  value={FieldRule.atributo05 != null ? Atribuicoes.filter((op: any) => op.id == FieldRule.atributo05)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo05', undefined, value, true)}
                />
              </Box>
              <Box component='div' sx={{p: 1}} hidden={FieldRule.atributo05 != 'ROLE'}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Grupos}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Grupo' />}
                  value={FieldRule.atributo01 != null ? Grupos.filter((op: any) => op.id == FieldRule.atributo01)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo01', undefined, value, true)}
                />
              </Box>
              <Box component='div' sx={{p: 1}} hidden={FieldRule.atributo05 != 'USER'}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Usuarios}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Usuário' />}
                  value={FieldRule.atributo02 != null ? Usuarios.filter((op: any) => op.id == FieldRule.atributo02)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo02', undefined, value, true)}
                />
              </Box>
              <Box component='div' sx={{p: 1, display: 'flex'}} hidden={FieldRule.atributo05 != 'PROC'}>
                <TextField
                  sx={{width: 265}}
                  disabled={!isEditable()}
                  size='small'
                  label='Nome do procedimento'
                  value={FieldRule.formula}
                  onChange={(event) => handleOnChange('formula', event)}
                />
                <Box component='div'>
                  <IconButton aria-label='question' color='inherit' onClick={() => handleOpenModalModeloSQL('Atribuição Tarefa')}>
                    <HelpIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Templates}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Template de email' />}
                  value={FieldRule.atributo03 != null ? Templates.filter((op: any) => op.id == FieldRule.atributo03)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo03', undefined, value, true)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Contas}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Conta que envia o email de abertura' />}
                  value={FieldRule.atributo04 != null ? Contas.filter((op: any) => op.id == FieldRule.atributo04)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo04', undefined, value, true)}
                />
              </Box>
              {FieldRule.atributo06 == 'APROVACAO' && (
                <>
                  <Box component='div' sx={{p: 1}}>
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      badgeContent={acoesDeRegra.length}
                      color='primary'
                    >
                      <Button variant='contained' startIcon={<BoltIcon />} color='inherit' onClick={() => handleOpenModalAcoesDeRegra()}>
                        Ações
                      </Button>
                    </Badge>
                  </Box>

                  {/* MODAL AÇÕES TAREFA */}
                  {modalAcoesDeRegra && (
                    <FieldRuleActions
                      tipo='TASK'
                      accessCode={accessCode}
                      actions={acoesDeRegra}
                      setActions={setAcoesDeRegra}
                      callBack={handleCallBackModalAcoesDeRegra}
                    />
                  )}
                </>
              )}
            </>
          )}
          {FieldRule.acao == 'Finalizar Tarefa' && (
            <>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Fields}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Campo' />}
                  value={FieldRule.idmenuAppField != null ? Fields.filter((op: any) => op.id == FieldRule.idmenuAppField)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('idmenuAppField', undefined, value)}
                />
              </Box>
              {getMenuAppFieldType() != null && <>{getValorControl(getMenuAppFieldType() as string)}</>}
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Templates}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Template' />}
                  value={FieldRule.atributo03 != null ? Templates.filter((op: any) => op.id == FieldRule.atributo01)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo01', undefined, value, true)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Contas}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Conta de Envio' />}
                  value={FieldRule.atributo04 != null ? Contas.filter((op: any) => op.id == FieldRule.atributo02)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo02', undefined, value, true)}
                />
              </Box>
              {/* Como definir qual tarefa finalizar??? */}
            </>
          )}
          {FieldRule.acao == 'Exibir Campos' && (
            <>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Fields}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Campo' />}
                  value={FieldRule.idmenuAppField != null ? Fields.find((op: any) => op.id == FieldRule.idmenuAppField) ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('idmenuAppField', undefined, value)}
                />
              </Box>
              {FieldRule.idmenuAppField != null && (
                <Box component='div' sx={{p: 1}}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={Operadores}
                    sx={{width: 300}}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Operador' />}
                    value={FieldRule.atributo01}
                    onChange={(event, value, reason) => handleOnChange('atributo01', undefined, value, false, true)}
                  />
                </Box>
              )}
              {FieldRule.atributo01 != '' &&
                FieldRule.atributo01 != 'Não está preenchido' &&
                FieldRule.atributo01 != 'Está preenchido' &&
                getMenuAppFieldType() != null && <>{getValorControl(getMenuAppFieldType() as string)}</>}
              {FieldRule.idmenuAppField != null && (
                <>
                  <Box component='div' sx={{p: 1}}>
                    <FormControl sx={{width: 300}} size='small'>
                      <InputLabel id='multiple-field-select'>Campos a exibir</InputLabel>
                      <Select
                        labelId='multiple-field-select'
                        multiple
                        size='small'
                        value={camposSelecionados}
                        onChange={(event) => handleOnChangeMultipleSelect('atributo02', event)}
                        input={<OutlinedInput label='Campos a exibir' />}
                        renderValue={(selected) => {
                          let selectedFields = Fields.filter((x) => selected.find((y) => y == x.id) != null)
                          let selectedFieldsLabels: Array<string> = []

                          for (const item of selectedFields) {
                            selectedFieldsLabels.push(item.label)
                          }

                          return selectedFieldsLabels.join(', ')
                        }}
                      >
                        {Fields.map((item: any, index: number) => (
                          <MenuItem key={`field-${index}`} value={item.id.toString()}>
                            <Checkbox checked={camposSelecionados.indexOf(item.id.toString()) > -1} />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </>
              )}
            </>
          )}
          {FieldRule.acao == 'Ocultar custom action' && (
            <>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Fields}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Campo' />}
                  value={FieldRule.idmenuAppField != null ? Fields.find((op: any) => op.id == FieldRule.idmenuAppField) ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('idmenuAppField', undefined, value)}
                />
              </Box>
              {FieldRule.idmenuAppField != null && (
                <Box component='div' sx={{p: 1}}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={Operadores}
                    sx={{width: 300}}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Operador' />}
                    value={FieldRule.atributo01}
                    onChange={(event, value, reason) => handleOnChange('atributo01', undefined, value, false, true)}
                  />
                </Box>
              )}
              {FieldRule.atributo01 != '' &&
                FieldRule.atributo01 != 'Não está preenchido' &&
                FieldRule.atributo01 != 'Está preenchido' &&
                getMenuAppFieldType() != null && <>{getValorControl(getMenuAppFieldType() as string)}</>}
              {FieldRule.idmenuAppField != null && (
                <>
                  <Box component='div' sx={{p: 1}}>
                    <FormControl sx={{width: 300}} size='small'>
                      <InputLabel id='multiple-field-select'>Ações a ocultar</InputLabel>
                      <Select
                        labelId='multiple-field-select'
                        multiple
                        size='small'
                        value={camposSelecionados}
                        onChange={(event) => handleOnChangeMultipleSelect('atributo02', event)}
                        input={<OutlinedInput label='Ações a ocultar' />}
                        renderValue={(selected) => {
                          let selectedFields = customActions.filter((x) => selected.find((y) => y == x.id) != null)
                          let selectedFieldsLabels: Array<string> = []

                          for (const item of selectedFields) {
                            selectedFieldsLabels.push(item.nome)
                          }

                          return selectedFieldsLabels.join(', ')
                        }}
                      >
                        {customActions.map((item: any, index: number) => (
                          <MenuItem key={`field-${index}`} value={item.id.toString()}>
                            <Checkbox checked={camposSelecionados.indexOf(item.id.toString()) > -1} />
                            <ListItemText primary={item.nome} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </>
              )}
            </>
          )}
          {FieldRule.acao == 'Executar ação de estrutura' && FieldRule.evento == 'Campo mudar de valor' && (
            <>
              <Box component='div' className='d-flex flex-row' sx={{width: '100%', maxHeight: '400px'}}>
                <Box component='div' sx={{width: '50%'}}>
                  <Box component='div' sx={{p: 1}}>
                    <Autocomplete
                      disablePortal
                      disabled={!isEditable()}
                      options={Fields}
                      sx={{width: '99%'}}
                      size='small'
                      renderInput={(params) => <TextField {...params} label='Campo que dispara a regra' />}
                      value={FieldRule.idmenuAppField != null ? Fields.find((op: any) => op.id == FieldRule.idmenuAppField) ?? null : null}
                      onChange={(event, value, reason) => handleOnChange('idmenuAppField', undefined, value)}
                    />
                  </Box>
                  <Box component='div' sx={{px: 2, py: 1}}>
                    <h5>Campo de resgate de valor</h5>
                  </Box>
                  <Box component='div' sx={{maxHeight: '300px'}}>
                    <Box component='div' sx={{p: 1, maxHeight: '300px', overflowY: 'scroll'}}>
                      <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        expanded={expanded}
                        selected={selected}
                        onNodeToggle={handleToggle}
                        onNodeSelect={(event, nodeId) => handleSelect(event, nodeId, 'atributo02')}
                        //multiSelect
                      >
                        {FieldsWithRelations.length > 0 && (
                          <>
                            {FieldsWithRelations.map((item: any, index: number) => {
                              return BuildFieldTreeView(item)
                            })}
                          </>
                        )}
                      </TreeView>
                    </Box>
                  </Box>
                </Box>
                <Box component='div' className='d-flex flex-column' sx={{width: '50%', overflowY: 'auto'}}>
                  {FieldRule.idmenuAppField > 0 && (
                    <>
                      <Box component='div' sx={{px: 2, py: 1}}>
                        <FormControl sx={{width: '99%'}}>
                          <RadioGroup row value={FieldRule.atributo05} onChange={(event) => handleOnChange('atributo05', event)}>
                            <FormControlLabel value='ACAO' control={<Radio />} label='Executar Ação' />
                            <FormControlLabel value='APLICAR' control={<Radio />} label='Aplicar Valor' />
                            <FormControlLabel value='FILTRAR' disabled control={<Radio />} label='Aplicar Filtro' />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {FieldRule.atributo05 == 'ACAO' && (
                        <>
                          {selectedNode.length > 0 && Operadores.length > 0 && (
                            <>
                              <Box component='div' sx={{p: 1}}>
                                <FormControl sx={{width: '99%'}}>
                                  <FormLabel>Quando o campo "{selectedNodeLabel}":</FormLabel>
                                </FormControl>
                              </Box>
                              <Box component='div' sx={{p: 1}}>
                                <Autocomplete
                                  fullWidth
                                  disablePortal
                                  options={Operadores}
                                  sx={{width: '99%'}}
                                  size='small'
                                  renderInput={(params) => <TextField {...params} label='Operador' />}
                                  value={FieldRule.atributo06}
                                  onChange={(event, value, reason) => handleOnChange('atributo06', undefined, value, false, true)}
                                />
                              </Box>
                            </>
                          )}
                          {selectedNode.length > 0 &&
                            FieldRule.atributo06 != '' &&
                            FieldRule.atributo06 != 'Não está preenchido' &&
                            FieldRule.atributo06 != 'Está preenchido' && (
                              <>{getValorControl(selectedNode[selectedNode.length - 1].tipo, selectedNode[selectedNode.length - 1].id, '99%')}</>
                            )}
                          {Operadores.length > 0 &&
                            (FieldRule.valor != '' || FieldRule.atributo06 == 'Não está preenchido' || FieldRule.atributo06 == 'Está preenchido') && (
                              <Box component='div' sx={{px: 2, py: 1}}>
                                <FormControl sx={{width: '99%'}}>
                                  <FormLabel>Executar ação em campos</FormLabel>
                                  <RadioGroup row value={FieldRule.atributo07} onChange={(event) => handleOnChange('atributo07', event)}>
                                    <FormControlLabel value='OCULTAR' control={<Radio />} label='Ocultar / Exibir' />
                                    <FormControlLabel value='BLOQUEAR' control={<Radio />} label='Bloquear / Desbloquear' />
                                    <FormControlLabel value='LIMPAR' control={<Radio />} label='Limpar' />
                                  </RadioGroup>
                                </FormControl>
                              </Box>
                            )}
                          {(FieldRule.valor != '' || FieldRule.atributo06 == 'Não está preenchido' || FieldRule.atributo06 == 'Está preenchido') &&
                            FieldRule.atributo07 != '' && (
                              <>
                                <Box component='div' sx={{p: 1}}>
                                  <FormControl sx={{width: '99%'}} size='small'>
                                    <InputLabel id='multiple-field-select'>Campos</InputLabel>
                                    <Select
                                      labelId='multiple-field-select'
                                      multiple
                                      size='small'
                                      value={camposSelecionados}
                                      onChange={(event) => handleOnChangeMultipleSelect('atributo08', event)}
                                      input={<OutlinedInput label='Campos' />}
                                      renderValue={(selected) => {
                                        let selectedFields = Fields.filter((x) => selected.find((y) => y == x.id) != null)
                                        let selectedFieldsLabels: Array<string> = []

                                        for (const item of selectedFields) {
                                          selectedFieldsLabels.push(item.label)
                                        }

                                        return selectedFieldsLabels.join(', ')
                                      }}
                                    >
                                      {Fields.map((item: any, index: number) => (
                                        <MenuItem key={`field-${index}`} value={item.id.toString()}>
                                          <Checkbox checked={camposSelecionados.indexOf(item.id.toString()) > -1} />
                                          <ListItemText primary={item.label} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </>
                            )}
                        </>
                      )}
                      {FieldRule.atributo05 == 'APLICAR' && (
                        <>
                          <Box component='div' sx={{p: 1}}>
                            <FormControl sx={{width: '99%'}}>
                              <FormLabel>Aplicar valor resgatado em "{selectedNodeLabel}" no:</FormLabel>
                            </FormControl>
                          </Box>
                          <Box component='div' sx={{p: 1}}>
                            <Autocomplete
                              disablePortal
                              disabled={!isEditable()}
                              options={Fields}
                              sx={{width: '99%'}}
                              size='small'
                              renderInput={(params) => <TextField {...params} label='Campo que receberá o valor resgatado' />}
                              value={FieldRule.atributo06 != null ? Fields.find((op: any) => op.id == FieldRule.atributo06) ?? null : null}
                              onChange={(event, value, reason) => handleOnChange('atributo06', undefined, value, true)}
                            />
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </>
          )}
          {FieldRule.acao == 'Executar ação de estrutura' && FieldRule.evento == 'Ao abrir formulario' && (
            <>
              <Box component='div' className='d-flex flex-row' sx={{width: '100%', maxHeight: '400px'}}>
                <Box component='div' sx={{width: '50%'}}>
                  <Box component='div' sx={{px: 2, py: 1}}>
                    <h5>Campo de resgate de valor</h5>
                  </Box>
                  <Box component='div' sx={{maxHeight: '300px'}}>
                    <Box component='div' sx={{p: 1, maxHeight: '300px', overflowY: 'scroll'}}>
                      <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        expanded={expanded}
                        selected={selected}
                        onNodeToggle={handleToggle}
                        onNodeSelect={(event, nodeId) => handleSelect(event, nodeId, 'atributo02')}
                        //multiSelect
                      >
                        {FieldsWithRelations.length > 0 && (
                          <>
                            {FieldsWithRelations.map((item: any, index: number) => {
                              return BuildFieldTreeView(item)
                            })}
                          </>
                        )}
                      </TreeView>
                    </Box>
                  </Box>
                </Box>
                <Box component='div' className='d-flex flex-column' sx={{width: '50%', overflowY: 'auto'}}>
                  <Box component='div' sx={{px: 2, py: 1}}>
                    <FormControl sx={{width: '99%'}}>
                      <RadioGroup row value={FieldRule.atributo05} onChange={(event) => handleOnChange('atributo05', event)}>
                        <FormControlLabel value='ACAO' control={<Radio />} label='Executar Ação' />
                        <FormControlLabel value='APLICAR' control={<Radio />} label='Aplicar Valor' />
                        <FormControlLabel value='FILTRAR' control={<Radio />} label='Aplicar Filtro' />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {FieldRule.atributo05 == 'ACAO' && (
                    <>
                      {selectedNode.length > 0 && Operadores.length > 0 && (
                        <>
                          <Box component='div' sx={{p: 1}}>
                            <FormControl sx={{width: '99%'}}>
                              <FormLabel>Quando o campo "{selectedNodeLabel}":</FormLabel>
                            </FormControl>
                          </Box>
                          <Box component='div' sx={{p: 1}}>
                            <Autocomplete
                              fullWidth
                              disablePortal
                              options={Operadores}
                              sx={{width: '99%'}}
                              size='small'
                              renderInput={(params) => <TextField {...params} label='Operador' />}
                              value={FieldRule.atributo06}
                              onChange={(event, value, reason) => handleOnChange('atributo06', undefined, value, false, true)}
                            />
                          </Box>
                        </>
                      )}
                      {selectedNode.length > 0 &&
                        FieldRule.atributo06 != '' &&
                        FieldRule.atributo06 != 'Não está preenchido' &&
                        FieldRule.atributo06 != 'Está preenchido' && (
                          <>{getValorControl(selectedNode[selectedNode.length - 1].tipo, selectedNode[selectedNode.length - 1].id, '99%')}</>
                        )}
                      {Operadores.length > 0 &&
                        (FieldRule.valor != '' || FieldRule.atributo06 == 'Não está preenchido' || FieldRule.atributo06 == 'Está preenchido') && (
                          <Box component='div' sx={{px: 2, py: 1}}>
                            <FormControl sx={{width: '99%'}}>
                              <FormLabel>Executar ação em campos</FormLabel>
                              <RadioGroup row value={FieldRule.atributo07} onChange={(event) => handleOnChange('atributo07', event)}>
                                <FormControlLabel value='OCULTAR' control={<Radio />} label='Ocultar / Exibir' />
                                <FormControlLabel value='BLOQUEAR' control={<Radio />} label='Bloquear / Desbloquear' />
                                <FormControlLabel value='LIMPAR' control={<Radio />} label='Limpar' />
                              </RadioGroup>
                            </FormControl>
                          </Box>
                        )}
                      {(FieldRule.valor != '' || FieldRule.atributo06 == 'Não está preenchido' || FieldRule.atributo06 == 'Está preenchido') &&
                        FieldRule.atributo07 != '' && (
                          <>
                            <Box component='div' sx={{p: 1}}>
                              <FormControl sx={{width: '99%'}} size='small'>
                                <InputLabel id='multiple-field-select'>Campos</InputLabel>
                                <Select
                                  labelId='multiple-field-select'
                                  multiple
                                  size='small'
                                  value={camposSelecionados}
                                  onChange={(event) => handleOnChangeMultipleSelect('atributo08', event)}
                                  input={<OutlinedInput label='Campos' />}
                                  renderValue={(selected) => {
                                    let selectedFields = Fields.filter((x) => selected.find((y) => y == x.id) != null)
                                    let selectedFieldsLabels: Array<string> = []

                                    for (const item of selectedFields) {
                                      selectedFieldsLabels.push(item.label)
                                    }

                                    return selectedFieldsLabels.join(', ')
                                  }}
                                >
                                  {Fields.map((item: any, index: number) => (
                                    <MenuItem key={`field-${index}`} value={item.id.toString()}>
                                      <Checkbox checked={camposSelecionados.indexOf(item.id.toString()) > -1} />
                                      <ListItemText primary={item.label} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </>
                        )}
                    </>
                  )}
                  {FieldRule.atributo05 == 'APLICAR' && (
                    <>
                      <Box component='div' sx={{p: 1}}>
                        <FormControl sx={{width: '99%'}}>
                          <FormLabel>Aplicar valor resgatado em "{selectedNodeLabel}" no:</FormLabel>
                        </FormControl>
                      </Box>
                      <Box component='div' sx={{p: 1}}>
                        <Autocomplete
                          disablePortal
                          disabled={!isEditable()}
                          options={Fields}
                          sx={{width: '99%'}}
                          size='small'
                          renderInput={(params) => <TextField {...params} label='Campo que recebe o valor' />}
                          value={FieldRule.atributo06 != null ? Fields.find((op: any) => op.id == FieldRule.atributo06) ?? null : null}
                          onChange={(event, value, reason) => handleOnChange('atributo06', undefined, value, true)}
                        />
                      </Box>
                    </>
                  )}
                  {FieldRule.atributo05 == 'FILTRAR' && (
                    <>
                      <Box component='div' sx={{p: 1}}>
                        <FormControl sx={{width: '99%'}}>
                          <FormLabel>Resgatar o valor em "{selectedNodeLabel}" e ultilizar no filtro do:</FormLabel>
                        </FormControl>
                      </Box>
                      <Box component='div' sx={{p: 1}}>
                        <Autocomplete
                          disablePortal
                          disabled={!isEditable()}
                          options={Fields.filter((x) => x.tipo == 'singleSelect')}
                          sx={{width: '99%'}}
                          size='small'
                          renderInput={(params) => <TextField {...params} label='Campo de seleção' />}
                          value={FieldRule.atributo06 != null ? Fields.find((op: any) => op.id == FieldRule.atributo06) ?? null : null}
                          onChange={(event, value, reason) => handleOnChange('atributo06', undefined, value, true)}
                        />
                      </Box>
                      <Box component='div' sx={{p: 1}}>
                        <Autocomplete
                          disablePortal
                          disabled={!isEditable()}
                          options={FieldsLoopBack}
                          sx={{width: '99%'}}
                          size='small'
                          renderInput={(params) => <TextField {...params} label='Campo para aplicar o filtro' />}
                          value={FieldRule.atributo07 != null ? FieldsLoopBack.find((op: any) => op.id == FieldRule.atributo07) ?? null : null}
                          onChange={(event, value, reason) => handleOnChange('atributo07', undefined, value, true)}
                        />
                      </Box>
                      {menuAppLoopBack != null && (
                        <>
                          <Box component='div' sx={{p: 1}}>
                            <Badge
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              badgeContent={filters.length}
                              color='primary'
                            >
                              <Button variant='contained' startIcon={<FilterAltIcon />} color='inherit' onClick={() => handleOpenModalFilters()}>
                                Filtros
                              </Button>
                            </Badge>
                          </Box>
                          {/* MODAL FILTER */}
                          {modalFilter && (
                            <FieldRuleFilter
                              accessCode={menuAppLoopBack.accessCode}
                              accessCodeExtra={accessCode}
                              filters={filters}
                              setFilters={setFilters}
                              callBack={filterCallBack}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </>
          )}
          {FieldRule.acao == 'Ocultar Campos' && FieldRule.evento == 'Campo possui valor' && (
            <>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Fields}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Campo' />}
                  value={FieldRule.idmenuAppField != null ? Fields.find((op: any) => op.id == FieldRule.idmenuAppField) ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('idmenuAppField', undefined, value)}
                />
              </Box>
              {FieldRule.idmenuAppField != null && (
                <Box component='div' sx={{p: 1}}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={Operadores}
                    sx={{width: 300}}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Operador' />}
                    value={FieldRule.atributo01}
                    onChange={(event, value, reason) => handleOnChange('atributo01', undefined, value, false, true)}
                  />
                </Box>
              )}
              {FieldRule.atributo01 != '' &&
                FieldRule.atributo01 != 'Não está preenchido' &&
                FieldRule.atributo01 != 'Está preenchido' &&
                getMenuAppFieldType() != null && <>{getValorControl(getMenuAppFieldType() as string)}</>}
              {FieldRule.idmenuAppField != null && (
                <>
                  <Box component='div' sx={{p: 1}}>
                    <FormControl sx={{width: 300}} size='small'>
                      <InputLabel id='multiple-field-select'>Campos a ocultar</InputLabel>
                      <Select
                        labelId='multiple-field-select'
                        multiple
                        size='small'
                        value={camposSelecionados}
                        onChange={(event) => handleOnChangeMultipleSelect('atributo02', event)}
                        input={<OutlinedInput label='Campos a ocultar' />}
                        renderValue={(selected) => {
                          let selectedFields = Fields.filter((x) => selected.find((y) => y == x.id) != null)
                          let selectedFieldsLabels: Array<string> = []

                          for (const item of selectedFields) {
                            selectedFieldsLabels.push(item.label)
                          }

                          return selectedFieldsLabels.join(', ')
                        }}
                      >
                        {Fields.map((item: any, index: number) => (
                          <MenuItem key={`field-${index}`} value={item.id.toString()}>
                            <Checkbox checked={camposSelecionados.indexOf(item.id.toString()) > -1} />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </>
              )}
            </>
          )}
          {FieldRule.acao == 'Ocultar Campos' && FieldRule.evento == 'Ao abrir formulario' && (
            <>
              <Box component='div' sx={{p: 1}}>
                <FormControl sx={{width: 300}} size='small'>
                  <InputLabel id='multiple-field-select'>Campos a ocultar</InputLabel>
                  <Select
                    labelId='multiple-field-select'
                    multiple
                    size='small'
                    value={camposSelecionados}
                    onChange={(event) => handleOnChangeMultipleSelect('atributo01', event)}
                    input={<OutlinedInput label='Campos a ocultar' />}
                    renderValue={(selected) => {
                      let selectedFields = Fields.filter((x) => selected.find((y) => y == x.id) != null)
                      let selectedFieldsLabels: Array<string> = []

                      for (const item of selectedFields) {
                        selectedFieldsLabels.push(item.label)
                      }

                      return selectedFieldsLabels.join(', ')
                    }}
                  >
                    {Fields.map((item: any, index: number) => (
                      <MenuItem key={`field-${index}`} value={item.id.toString()}>
                        <Checkbox checked={camposSelecionados.indexOf(item.id.toString()) > -1} />
                        <ListItemText primary={item.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box component='div' sx={{p: 1}}>
                <FormGroup sx={{width: 300}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => handleOnChange('atributo02', null, event.target.checked ? 'true' : 'false', false, true)}
                        checked={FieldRule.atributo02 != '' && FieldRule.atributo02 == 'true'}
                      />
                    }
                    label='Ao Criar Registro'
                  />
                </FormGroup>
              </Box>
              <Box component='div' sx={{p: 1}}>
                <FormGroup sx={{width: 300}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => handleOnChange('atributo03', null, event.target.checked ? 'true' : 'false', false, true)}
                        checked={FieldRule.atributo03 != '' && FieldRule.atributo03 == 'true'}
                      />
                    }
                    label='Ao Editar Registro'
                  />
                </FormGroup>
              </Box>
            </>
          )}
          {FieldRule.acao == 'Bloquear Campos' && FieldRule.evento != 'Ao abrir formulario' && (
            <>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Fields}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Campo' />}
                  value={FieldRule.idmenuAppField != null ? Fields.find((op: any) => op.id == FieldRule.idmenuAppField) ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('idmenuAppField', undefined, value)}
                />
              </Box>
              {FieldRule.idmenuAppField != null && (
                <Box component='div' sx={{p: 1}}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={Operadores}
                    sx={{width: 300}}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Operador' />}
                    value={FieldRule.atributo03}
                    onChange={(event, value, reason) => handleOnChange('atributo03', undefined, value, false, true)}
                  />
                </Box>
              )}
              {FieldRule.atributo03 != '' &&
                FieldRule.atributo03 != 'Não está preenchido' &&
                FieldRule.atributo03 != 'Está preenchido' &&
                getMenuAppFieldType() != null && <>{getValorControl(getMenuAppFieldType() as string)}</>}
              {FieldRule.idmenuAppField != null && (
                <>
                  <Box component='div' sx={{p: 1}}>
                    <FormControl sx={{width: 300}}>
                      <RadioGroup value={FieldRule.atributo01} onChange={(event) => handleOnChange('atributo01', event)}>
                        <FormControlLabel value='ALL' control={<Radio />} label='Todos os campos e botões de ações' />
                        <FormControlLabel value='SELECTED' control={<Radio />} label='Campos específicos selecionados' />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {FieldRule.atributo01 == 'SELECTED' && (
                    <Box component='div' sx={{p: 1}}>
                      <FormControl sx={{width: 300}} size='small'>
                        <InputLabel id='multiple-field-select'>Campos a bloquear</InputLabel>
                        <Select
                          labelId='multiple-field-select'
                          multiple
                          size='small'
                          value={camposSelecionados}
                          onChange={(event) => handleOnChangeMultipleSelect('atributo02', event)}
                          input={<OutlinedInput label='Campos a bloquear' />}
                          renderValue={(selected) => {
                            let selectedFields = Fields.filter((x) => selected.find((y) => y == x.id) != null)
                            let selectedFieldsLabels: Array<string> = []

                            for (const item of selectedFields) {
                              selectedFieldsLabels.push(item.label)
                            }

                            return selectedFieldsLabels.join(', ')
                          }}
                        >
                          {Fields.map((item: any, index: number) => (
                            <MenuItem key={`field-${index}`} value={item.id.toString()}>
                              <Checkbox checked={camposSelecionados.indexOf(item.id.toString()) > -1} />
                              <ListItemText primary={item.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
          {FieldRule.acao == 'Bloquear Campos' && FieldRule.evento == 'Ao abrir formulario' && (
            <>
              <Box component='div' sx={{p: 1}}>
                <FormControl sx={{width: 300}}>
                  <RadioGroup value={FieldRule.atributo01} onChange={(event) => handleOnChange('atributo01', event)}>
                    <FormControlLabel value='ALL' control={<Radio />} label='Todos os campos e botões de ações' />
                    <FormControlLabel value='SELECTED' control={<Radio />} label='Campos específicos selecionados' />
                  </RadioGroup>
                </FormControl>
              </Box>
              {FieldRule.atributo01 == 'SELECTED' && (
                <Box component='div' sx={{p: 1}}>
                  <FormControl sx={{width: 300}} size='small'>
                    <InputLabel id='multiple-field-select'>Campos a bloquear</InputLabel>
                    <Select
                      labelId='multiple-field-select'
                      multiple
                      size='small'
                      value={camposSelecionados}
                      onChange={(event) => handleOnChangeMultipleSelect('atributo02', event)}
                      input={<OutlinedInput label='Campos a bloquear' />}
                      renderValue={(selected) => {
                        let selectedFields = Fields.filter((x) => selected.find((y) => y == x.id) != null)
                        let selectedFieldsLabels: Array<string> = []

                        for (const item of selectedFields) {
                          selectedFieldsLabels.push(item.label)
                        }

                        return selectedFieldsLabels.join(', ')
                      }}
                    >
                      {Fields.map((item: any, index: number) => (
                        <MenuItem key={`field-${index}`} value={item.id.toString()}>
                          <Checkbox checked={camposSelecionados.indexOf(item.id.toString()) > -1} />
                          <ListItemText primary={item.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
              <Box component='div' sx={{p: 1}}>
                <FormGroup sx={{width: 300}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => handleOnChange('atributo03', null, event.target.checked ? 'true' : 'false', false, true)}
                        checked={FieldRule.atributo03 != '' && FieldRule.atributo03 == 'true'}
                      />
                    }
                    label='Ao Criar Registro'
                  />
                </FormGroup>
              </Box>
              <Box component='div' sx={{p: 1}}>
                <FormGroup sx={{width: 300}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => handleOnChange('atributo04', null, event.target.checked ? 'true' : 'false', false, true)}
                        checked={FieldRule.atributo04 != '' && FieldRule.atributo04 == 'true'}
                      />
                    }
                    label='Ao Editar Registro'
                  />
                </FormGroup>
              </Box>
            </>
          )}
          {FieldRule.acao == 'Preencher Valor' && (
            <>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={Fields}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Campo' />}
                  value={FieldRule.idmenuAppField != null ? Fields.filter((op: any) => op.id == FieldRule.idmenuAppField)[0] ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('idmenuAppField', undefined, value)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={TipoValores}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Tipo de Valor' />}
                  value={FieldRule.atributo01}
                  onChange={(event, value, reason) => handleOnChangeString('atributo01', value)}
                />
              </Box>
              {FieldRule.atributo01 == 'Específico' && (
                <>{getMenuAppFieldType() != null && <>{getValorControl(getMenuAppFieldType() as string)}</>}</>
              )}
              {FieldRule.atributo01 == 'Função' && (
                <Box component='div' sx={{p: 1}}>
                  <Autocomplete
                    disablePortal
                    disabled={!isEditable()}
                    options={Funcoes}
                    sx={{width: 300}}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Função' />}
                    value={FieldRule.valor}
                    onChange={(event, value, reason) => handleOnChangeString('valor', value)}
                  />
                </Box>
              )}
              <Box component='div' sx={{p: 1}}>
                <FormGroup sx={{width: 300}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => handleOnChange('atributo02', null, event.target.checked ? 'true' : 'false', false, true)}
                        checked={FieldRule.atributo02 != '' && FieldRule.atributo02 == 'true'}
                      />
                    }
                    label='Ao Criar Registro'
                  />
                </FormGroup>
              </Box>
              <Box component='div' sx={{p: 1}}>
                <FormGroup sx={{width: 300}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => handleOnChange('atributo03', null, event.target.checked ? 'true' : 'false', false, true)}
                        checked={FieldRule.atributo03 != '' && FieldRule.atributo03 == 'true'}
                      />
                    }
                    label='Ao Editar Registro'
                  />
                </FormGroup>
              </Box>
            </>
          )}
          {FieldRule.acao == 'Executar procedure' && (
            <>
              <Box component='div' sx={{p: 1, alignSelf: 'center'}}>
                <a
                  title='Clique aqui para realizar Download do arquivo modelo'
                  style={{cursor: 'pointer'}}
                  onClick={(event) => BaixarArquivoModelo('Generica')}
                >
                  <div className='container'>
                    <div className='row'>
                      <div className='col-1'></div>
                      <div className='col-2 p-1 text-center '>
                        <img alt='Logo' src={toAbsoluteUrl('/media/app/sql64x64.png')} className='h-45px w-45px' />
                      </div>
                      <div className='col-8 p-1'>
                        <span>Para baixar o arquivo modelo deste app </span>{' '}
                        <span>
                          <strong className='link-primary'>Clique Aqui</strong> e após, execute o procedimento no banco de dados da aplicação (
                          Compativel com SQL SERVER ).
                        </span>
                      </div>
                      <div className='col-1'></div>
                    </div>
                  </div>
                </a>
              </Box>
              <Box component='div' sx={{p: 1, display: 'flex', width: '380px'}}>
                <TextField
                  sx={{width: 380}}
                  disabled={!isEditable()}
                  size='small'
                  label='Nome do procedimento'
                  value={FieldRule.formula}
                  onChange={(event) => handleOnChange('formula', event)}
                />
              </Box>
              <Box component='div' sx={{p: 1, textAlign: 'center'}} className='text-muted my-1 fs-6'>
                Stored procedure deve conter os parametros IDPessoaMaster, IDPessoa, IDMenuApp e IDMenuAppGenericValue.
              </Box>
            </>
          )}
          {FieldRule.acao == 'Executar procedure validacao' && (
            <>
              {FieldRule.evento == 'Campo mudar de valor' && (
                <Box component='div' sx={{p: 1}}>
                  <Autocomplete
                    disablePortal
                    disabled={!isEditable()}
                    options={Fields}
                    sx={{width: 300}}
                    size='small'
                    renderInput={(params) => <TextField {...params} label='Campo' />}
                    value={FieldRule.idmenuAppField != null ? Fields.filter((op: any) => op.id == FieldRule.idmenuAppField)[0] ?? null : null}
                    onChange={(event, value, reason) => handleOnChange('idmenuAppField', undefined, value)}
                  />
                </Box>
              )}
              <Box component='div' sx={{p: 1, alignSelf: 'center'}}>
                <a
                  title='Clique aqui para realizar Download do arquivo modelo'
                  style={{cursor: 'pointer'}}
                  onClick={(event) => BaixarArquivoModelo('Validação')}
                >
                  <div className='container'>
                    <div className='row'>
                      <div className='col-1'></div>
                      <div className='col-2 p-1 text-center '>
                        <img alt='Logo' src={toAbsoluteUrl('/media/app/sql64x64.png')} className='h-45px w-45px' />
                      </div>
                      <div className='col-8 p-1'>
                        <span>Para baixar o arquivo modelo deste app </span>{' '}
                        <span>
                          <strong className='link-primary'>Clique Aqui</strong> e após, execute o procedimento no banco de dados da aplicação (
                          Compativel com SQL SERVER ).
                        </span>
                      </div>
                      <div className='col-1'></div>
                    </div>
                  </div>
                </a>
              </Box>
              <Box component='div' sx={{p: 1, display: 'flex', width: '380px'}}>
                <TextField
                  sx={{width: 380}}
                  disabled={!isEditable()}
                  size='small'
                  label='Nome do procedimento'
                  value={FieldRule.formula}
                  onChange={(event) => handleOnChange('formula', event)}
                />
              </Box>
              <Box component='div' sx={{p: 1, textAlign: 'center'}} className='text-muted my-1 fs-6'>
                Stored procedure deve conter os parametros IDPessoaMaster, IDPessoa, IDMenuApp, IDMenuAppGenericValue e Values.
              </Box>
            </>
          )}
          {FieldRule.acao == 'Notificar Teams' && (
            <>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={menuAppIntegrations.filter((x) => x.idtipoIntegration == 1)}
                  getOptionLabel={(option) => option.nome}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Integração' />}
                  value={FieldRule.atributo01 != null ? menuAppIntegrations.find((op: any) => op.id == FieldRule.atributo01) ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo01', undefined, value, true)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <TextField
                  sx={{width: 300}}
                  disabled={!isEditable()}
                  size='small'
                  label='Titulo'
                  value={FieldRule.atributo02}
                  onChange={(event) => handleOnChange('atributo02', event)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <TextField
                  sx={{width: 300}}
                  disabled={!isEditable()}
                  size='small'
                  label='Mensagem'
                  value={FieldRule.atributo03}
                  onChange={(event) => handleOnChange('atributo03', event)}
                  multiline
                  maxRows={6}
                  minRows={6}
                />
              </Box>
            </>
          )}
          {FieldRule.acao == 'Exibir caixa de emails' && (
            <>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={menuAppIntegrations.filter((x) => x.idtipoIntegration == 2)}
                  getOptionLabel={(option) => option.nome}
                  sx={{width: 300}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Integração' />}
                  value={FieldRule.atributo01 != null ? menuAppIntegrations.find((op: any) => op.id == FieldRule.atributo01) ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo01', undefined, value, true)}
                />
              </Box>
            </>
          )}
          {FieldRule.acao == 'Exibir confirmacao' && (
            <>
              <Box component='div' sx={{p: 1, width: '50%', height: '400px'}}>
                <ReactFlow
                  nodes={[
                    {
                      id: '1',
                      position: {x: 220, y: 0},
                      type: 'custom',
                      data: {
                        label: 'Critérios de execução',
                        icon: <DoneAllIcon />,
                        badgeContent: filters.length,
                        onClick: (event: React.MouseEvent) => {
                          handleOpenModalFilters()
                        },
                      },
                    },
                    {
                      id: '2',
                      position: {x: 191, y: 100},
                      type: 'custom',
                      data: {
                        label: 'Procedimento de pré-validação',
                        badgeContent: FieldRule.atributo02 != '' && FieldRule.atributo02 == 'true' ? 1 : undefined,
                        icon: <AttractionsIcon />,
                        onClick: (event: React.MouseEvent) => {
                          handleOpenModalForm('Exibir confirmacao.2')
                        },
                      },
                    },
                    {
                      id: '3',
                      position: {x: 204, y: 200},
                      type: 'custom',
                      data: {
                        label: 'Mensagem de confirmação',
                        icon: <MessageIcon />,
                        badgeContent: FieldRule.atributo01 != '' ? 1 : undefined,
                        onClick: (event: React.MouseEvent) => {
                          handleOpenModalForm('Exibir confirmacao.3')
                        },
                      },
                    },
                    {
                      id: '4',
                      position: {x: 228, y: 300},
                      type: 'custom',
                      data: {
                        label: 'Execução de ações',
                        icon: <BoltIcon />,
                        badgeContent: acoesDeRegra.length,
                        onClick: (event: React.MouseEvent) => {
                          handleOpenModalAcoesDeRegra()
                        },
                      },
                    },
                  ]}
                  edges={[
                    {id: 'e1-2', source: '1', target: '2', animated: true},
                    {id: 'e2-3', source: '2', target: '3', animated: true},
                    {id: 'e3-4', source: '3', target: '4', animated: true},
                  ]}
                  nodeTypes={nodeTypes}
                  onNodeClick={handleOnNodeClick}
                  panOnDrag={false}
                  maxZoom={0.9}
                  minZoom={0.9}
                  fitView
                />
              </Box>
              {/* MODAL FILTER */}
              {modalFilter && (
                <FieldRuleFilter
                  accessCode={accessCode}
                  accessCodeExtra={accessCode}
                  filters={filters}
                  setFilters={setFilters}
                  callBack={filterCallBack}
                />
              )}
              {/* MODAL AÇÕES TAREFA */}
              {modalAcoesDeRegra && (
                <FieldRuleActions
                  tipo='CONFIRMATION'
                  accessCode={accessCode}
                  actions={acoesDeRegra}
                  setActions={setAcoesDeRegra}
                  callBack={handleCallBackModalAcoesDeRegra}
                />
              )}
            </>
          )}
          {FieldRule.acao == 'Executar acoes' && (
            <>
              <Box component='div' sx={{p: 1, width: '50%', height: '400px'}}>
                <ReactFlow
                  nodes={[
                    {
                      id: '1',
                      position: {x: 220, y: 0},
                      type: 'custom',
                      data: {
                        label: 'Critérios de execução',
                        icon: <DoneAllIcon />,
                        badgeContent: filters.length,
                        onClick: (event: React.MouseEvent) => {
                          handleOpenModalFilters()
                        },
                      },
                    },
                    {
                      id: '2',
                      position: {x: 228, y: 100},
                      type: 'custom',
                      data: {
                        label: 'Execução de ações',
                        icon: <BoltIcon />,
                        badgeContent: acoesDeRegra.length,
                        onClick: (event: React.MouseEvent) => {
                          handleOpenModalAcoesDeRegra()
                        },
                      },
                    },
                  ]}
                  edges={[{id: 'e1-2', source: '1', target: '2', animated: true}]}
                  nodeTypes={nodeTypes}
                  onNodeClick={handleOnNodeClick}
                  panOnDrag={false}
                  maxZoom={0.9}
                  minZoom={0.9}
                  fitView
                />
              </Box>
              {/* MODAL FILTER */}
              {modalFilter && (
                <FieldRuleFilter
                  accessCode={accessCode}
                  accessCodeExtra={accessCode}
                  filters={filters}
                  setFilters={setFilters}
                  callBack={filterCallBack}
                />
              )}
              {/* MODAL AÇÕES GERAIS */}
              {modalAcoesDeRegra && (
                <FieldRuleActions
                  tipo='GENERAL'
                  accessCode={accessCode}
                  actions={acoesDeRegra}
                  setActions={setAcoesDeRegra}
                  callBack={handleCallBackModalAcoesDeRegra}
                />
              )}
            </>
          )}
          {FieldRule.acao == 'Executar API Generica' && (
            <>
              <Box component='div' sx={{p: 1, display: 'flex'}}>
                <TextField
                  sx={{width: 465}}
                  disabled={!isEditable()}
                  size='small'
                  label='Nome do procedimento de validação'
                  value={FieldRule.formula}
                  onChange={(event) => handleOnChange('formula', event)}
                />
                <Box component='div'>
                  <IconButton aria-label='question' color='inherit' onClick={() => handleOpenModalModeloSQL('Validação')}>
                    <HelpIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={menuAppIntegrations.filter((x) => x.idtipoIntegration == 5)}
                  getOptionLabel={(option) => option.nome}
                  sx={{width: 500}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Integração' />}
                  value={FieldRule.atributo01 != null ? menuAppIntegrations.find((op: any) => op.id == FieldRule.atributo01) ?? null : null}
                  onChange={(event, value, reason) => handleOnChange('atributo01', undefined, value, true)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  disabled={!isEditable()}
                  options={RequestTypes}
                  getOptionLabel={(option) => option}
                  sx={{width: 500}}
                  size='small'
                  renderInput={(params) => <TextField {...params} label='Verbo' />}
                  value={FieldRule.atributo02}
                  onChange={(event, value, reason) => handleOnChange('atributo02', undefined, value, undefined, true)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <TextField
                  sx={{width: 500}}
                  disabled={!isEditable()}
                  size='small'
                  label='Endpoint'
                  value={FieldRule.atributo03}
                  onChange={(event) => handleOnChange('atributo03', event)}
                />
              </Box>
              {FieldRule.atributo02 != 'GET' && (
                <Box component='div' sx={{p: 1}}>
                  <CodeMirror
                    placeholder='JSON de envio'
                    readOnly={!isEditable()}
                    theme={codeEditorTheme}
                    value={FieldRule.atributo04}
                    height='150px'
                    width='500px'
                    extensions={[json(), autocompletion({override: [(context) => handleOnCompletions(intl, context)]})]}
                    onChange={(val, viewUpdate) => handleOnChangeCodeEditor('atributo04', val)}
                  />
                </Box>
              )}
              <Box component='div' sx={{p: 1}}>
                <CodeMirror
                  placeholder='JSON de retorno'
                  readOnly={!isEditable()}
                  theme={codeEditorTheme}
                  value={FieldRule.atributo05}
                  height='150px'
                  width='500px'
                  extensions={[json(), autocompletion({override: [(context) => handleOnCompletions(intl, context)]})]}
                  onChange={(val, viewUpdate) => handleOnChangeCodeEditor('atributo05', val)}
                />
              </Box>
            </>
          )}
          {FieldRule.acao == 'ENABLE_ADVANCED_IMPORT' && (
            <>
              <Box component='div' className='alert alert-warning d-flex align-items-center'>
                <div className='d-flex flex-column'>
                  <span>
                    A importação ocorrerá enviando o JSON do objeto da linha da planilha para tratamento por meio de uma procedure do banco de dados,
                    sendo assim é necessário que a procedure possua o parametro @JSON de tipo NVARCHAR(MAX).
                  </span>
                  <br />
                  <span>ATENÇÃO: Todas os valores do objeto passado ao parâmetro JSON são enviados como string.</span>
                </div>
              </Box>
              <Box component='div' sx={{p: 1, display: 'flex'}}>
                <TextField
                  sx={{width: 465}}
                  disabled={!isEditable()}
                  size='small'
                  label='Nome do procedimento do banco de dados'
                  value={FieldRule.formula}
                  onChange={(event) => handleOnChange('formula', event)}
                />
                <Box component='div'>
                  <IconButton aria-label='question' color='inherit' onClick={() => handleOpenModalModeloSQL('Importação avançada')}>
                    <HelpIcon />
                  </IconButton>
                </Box>
              </Box>
            </>
          )}

          {modalModeloSQL && <ModalModeloSQL tipoModelo={modalModeloSQLTipo} callBack={modalModeloCallback} />}
          {modalForm && (
            <ModalForm
              title={selectedFlowNode?.data?.label}
              fullWidth={true}
              maxWidth='sm'
              element={
                <>
                  {modalFormComponentMode == 'Exibir confirmacao.2' && (
                    <Box component='div' sx={{minHeight: '230px'}}>
                      <Box component='div' sx={{p: 1}}>
                        <FormGroup sx={{width: '100%'}}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(event) => handleOnChange('atributo02', null, event.target.checked ? 'true' : 'false', false, true)}
                                checked={FieldRule.atributo02 != '' && FieldRule.atributo02 == 'true'}
                              />
                            }
                            label='Executar um procedimento de pré-validação'
                          />
                        </FormGroup>
                      </Box>
                      {FieldRule.atributo02 == 'true' && (
                        <>
                          <Box component='div' sx={{mb: 2, p: 1, display: 'flex', width: '100%'}}>
                            <TextField
                              fullWidth
                              disabled={!isEditable()}
                              size='small'
                              label='Nome do procedimento'
                              value={FieldRule.formula}
                              onChange={(event) => handleOnChange('formula', event)}
                            />
                            <Box component='div'>
                              <IconButton aria-label='question' color='inherit' onClick={() => handleOpenModalModeloSQL('Validação')}>
                                <HelpIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          <Box component='div' className='alert alert-primary d-flex align-items-center' sx={{m: 1}}>
                            <div className='d-flex flex-column'>
                              <h4 className='mb-1 text-dark'>Atenção</h4>
                              Este procedimento de validação será executado antes da tela de Confirmação. Somente se a pre-validação retornar
                              "SUCESSO", a tela de confirmação será aberta
                            </div>
                          </Box>
                        </>
                      )}
                    </Box>
                  )}
                  {modalFormComponentMode == 'Exibir confirmacao.3' && (
                    <Box component='div' sx={{p: 1, width: '100%'}}>
                      <TextField
                        fullWidth
                        disabled={!isEditable()}
                        size='small'
                        label='Pergunta a ser realizada na confirmação:'
                        value={FieldRule.atributo01}
                        onChange={(event) => handleOnChange('atributo01', event)}
                        multiline
                        maxRows={6}
                        minRows={6}
                      />
                    </Box>
                  )}
                </>
              }
              callBack={handleModalFormCallBack}
            />
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        {isEditable() && (
          <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleClose(false)}>
            Fechar
          </Button>
        )}
        {!Loading && View != null && hideBackButton != true && (
          <Button variant='contained' startIcon={<ArrowBackIcon />} color='inherit' onClick={() => handleChangeView(CADASTRO, 0, '', '', true)}>
            Voltar
          </Button>
        )}
        {View == EDICAO && (
          <Button disabled={Saving} variant='contained' startIcon={<SaveIcon />} color='success' onClick={() => handleClose(true)}>
            {(Saving && 'Savando...') || 'Salvar'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default FieldRuleModalV2
