/* eslint-disable react/jsx-no-target-blank */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon, KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {Api} from '../../../../../app/modules/api/Api'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../../app/modules/api/ApiWrapper'

const SidebarMenuMain = () => {
  const intl = useIntl()

  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [taskCount, setTaskCount] = useState<number>(0)

  // PERMISSÕES
  const [SYSMAPAccess, setSYSMAPAccess] = useState(false)
  const [SYSFILEccess, setSYSFILEccess] = useState(false)
  const [SYSTASKAccess, setSYSTASKAccess] = useState(false)
  const [SYSUSERAUDITAccess, setSYSUSERAUDITAccess] = useState(false)
  const [SYSINTEGRATIONAccess, setSYSINTEGRATIONAccess] = useState(false)
  const [SYSSCHEDULEAccess, setSYSSCHEDULEAccess] = useState(false)

  // ROLES
  const [UserRoles, setUserRoles] = useState<Array<any>>([])

  // VERIFICAMOS AS PERMISSÕES

  const CarregarPermissoes = () => {
    apiWrapper.get('/api/v1/MenuAppRole/verificarPermissoesPorCodigoDeAcesso?item.AccessCode=SYSMAP').then((response) => {
      setSYSMAPAccess(response.data.data.biSelect)
    })

    apiWrapper.get('/api/v1/MenuAppRole/verificarPermissoesPorCodigoDeAcesso?item.AccessCode=SYSTASK').then((response) => {
      setSYSTASKAccess(response.data.data.biSelect)
    })

    apiWrapper.get('/api/v1/MenuAppRole/verificarPermissoesPorCodigoDeAcesso?item.AccessCode=SYSUSERAUDIT').then((response) => {
      setSYSUSERAUDITAccess(response.data.data.biSelect)
    })

    apiWrapper.get('/api/v1/MenuAppRole/verificarPermissoesPorCodigoDeAcesso?item.AccessCode=SYSINTEGRATION').then((response) => {
      setSYSINTEGRATIONAccess(response.data.data.biSelect)
    })

    apiWrapper.get('/api/v1/MenuAppRole/verificarPermissoesPorCodigoDeAcesso?item.AccessCode=SYSFILE').then((response) => {
      setSYSFILEccess(response.data.data.biSelect)
    })

    apiWrapper.get('/api/v1/MenuAppRole/verificarPermissoesPorCodigoDeAcesso?item.AccessCode=SYSSCHEDULE').then((response) => {
      setSYSSCHEDULEAccess(response.data.data.biSelect)
    })

    apiWrapper.get('/api/v1/Role/listarRolesPessoaLogada').then((response) => {
      setUserRoles(response.data.data)
    })
  }

  const handleLoadTasks = () => {
    apiWrapper.get('api/v1/Tarefa/carreagarNumeroTarefasAtivas').then((response) => {
      setTaskCount(response.data.data)
    })
  }

  const HasAdmin = () => {
    if (UserRoles.length == 0) {
      return false
    }

    return UserRoles.find((x: any) => x.identificador == 'ADMIN') != null
  }

  const HasSuperAdmin = () => {
    if (UserRoles.length == 0) {
      return false
    }

    return UserRoles.find((x: any) => x.identificador == 'SADMIN') != null
  }

  useEffect(() => {
    CarregarPermissoes()

    // TODO: REmover
    // const intervalId = setInterval(() => {
    //   handleLoadTasks()
    // }, 5000)

    //return () => clearInterval(intervalId)
  }, [])

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {SYSTASKAccess && (
        <SidebarMenuItem
          to='pages/_dynamicTabs/SYSTASK'
          icon='/media/icons/duotune/general/gen025.svg'
          title='Minhas tarefas'
          fontIcon='bi-layers'
          badge={taskCount > 0 ? (taskCount > 99 ? '99+' : taskCount.toString()) : undefined}
          badgeTooltip='Considerando apenas tarefas abertas'
        />
      )}

      {SYSFILEccess && (
        <SidebarMenuItem to='pages/_dynamic/SYSFILE' icon='/media/icons/duotune/files/fil012.svg' title='Meus Arquivos' fontIcon='bi-layers' />
      )}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Opções</span>
        </div>
      </div>

      <SidebarMenuItemWithSub to='/crafted/accounts' title='Perfil' icon='/media/icons/duotune/communication/com006.svg' fontIcon='bi-person'>
        <SidebarMenuItem to='/crafted/account/overview' title='Meus Dados' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/logs' title='Logs' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {(HasAdmin() || HasSuperAdmin()) && (
        <>
          <SidebarMenuItemWithSub to='/error' title='Segurança' fontIcon='bi-sticky' icon='/media/icons/duotune/technology/teh004.svg'>
            <SidebarMenuItem to='pages/_dynamicTabs/SYSUSER' title='Usuários' hasBullet={true} />
            <SidebarMenuItem to='pages/_dynamicTabs/SYSROLE' title='Grupos' hasBullet={true} />
            {SYSUSERAUDITAccess && <SidebarMenuItem to='pages/_dynamic/SYSUSERAUDIT' title='Log de acesso' hasBullet={true} />}
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub to='/crafted/widgets' title='Configurações' icon='/media/icons/duotune/general/gen025.svg' fontIcon='bi-layers'>
            <SidebarMenuItem to='/pages/_dynamic/SYSMEN' title='Menus' hasBullet={true} />
            <SidebarMenuItem to='/crafted/widgets/lists' title='Idioma' hasBullet={true} />
            {SYSINTEGRATIONAccess && <SidebarMenuItem to='/pages/_dynamic/SYSINTEGRATION' title='Integrações' hasBullet={true} />}
            {SYSSCHEDULEAccess && <SidebarMenuItem to='/pages/_dynamic/SYSSCHEDULE' title='Agendamentos' hasBullet={true} />}
            <SidebarMenuItem to='/pages/_dynamic/SYSTEMPLATE' title='Templates de Email' hasBullet={true} />
          </SidebarMenuItemWithSub>
        </>
      )}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Aplicações</span>
        </div>
      </div>

      {SYSMAPAccess && (
        <SidebarMenuItem to='pages/_dynamic/SYSMAP' icon='/media/icons/duotune/general/gen025.svg' title='Meus Apps' fontIcon='bi-layers' />
      )}

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a target='_blank' className='menu-link' href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}>
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>
            Versão {process.env.REACT_APP_VERSION} - {process.env.REACT_APP_ENV_FRONT} 
          </span>
        </a>
      </div>

      {/*
      <SidebarMenuItem to='/dashboard' icon='element-11' title={intl.formatMessage({id: 'MENU.DASHBOARD'})} fontIcon='bi-app-indicator' />
      <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub to='/crafted/pages' title='Pages' fontIcon='bi-archive' icon='element-plus'>
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/connections' title='Connections' hasBullet={true} />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/crafted/accounts' title='Accounts' icon='profile-circle' fontIcon='bi-person'>
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/crafted/widgets' title='Widgets' icon='element-7' fontIcon='bi-layers'>
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub to='/apps/chat' title='Chat' fontIcon='bi-chat-left' icon='message-text-2'>
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/apps/user-management/users' icon='abstract-28' title='User management' fontIcon='bi-layers' />
      <div className='menu-item'>
        <a target='_blank' className='menu-link' href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}>
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>
      */}
    </>
  )
}

export {SidebarMenuMain}
