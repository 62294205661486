import Box from '@mui/material/Box'
import StyledModal from '../styled/StyledModal'
import CircularProgress from '@mui/material/CircularProgress'
import CircularProgressWithLabel from '../utility/CircularProgressWithLabel'

interface IModalPerformingAction {
  message: string
  progress?: number
}

const ModalPerformingAction = ({message, progress}: IModalPerformingAction) => {
  return (
    <StyledModal open={true}>
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          display: 'column',
          textAlign: 'center',
        }}
      >
        <Box component='div'>
          {progress != null && <CircularProgressWithLabel value={progress} />}
          {progress == null && <CircularProgress size={30} />}
        </Box>
        <Box component='div' sx={{px: 1, mt: 0.5}}>
          {message}
        </Box>
      </Box>
    </StyledModal>
  )
}

export default ModalPerformingAction
