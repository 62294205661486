import {useState, useContext, useEffect} from 'react'
import {useParams} from 'react-router-dom'

import Box from '@mui/material/Box'
import Board from 'react-trello'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import {CircularProgress, IconButton, Modal} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import SettingsIcon from '@mui/icons-material/Settings'

import {toast} from 'react-toastify'
import DynamicModal from '../../modals/modalDynamicV2'
import KanbanFilter from '../../filter/KanbanFilter'
import {CGlobalFilterStructure, CMenuAppHistoryStructure} from '../../../../../models/classes/CMenuAppHistoryStructure'
import Badge from '@mui/material/Badge'
import BoxSVGMessage from '../../utility/BoxSVGMessage'
import LinearProgress from '@mui/material/LinearProgress'
import KanbanCard from '../../utility/kanbanCard'
import {GlobalContext} from '../../../../../App'
import StyledAutocomplete from '../../styled/StyledAutoComplete'
import StyledButton from '../../styled/StyledButton'
import {useThemeMode} from '../../../../../../_metronic/partials'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../../modules/api/ApiWrapper'
import KanbanconfigModal from '../../modals/modalKanbanConfig'

interface IDynamicParams {
  isDisplayed?: boolean
}

const stringAvatar = (name: string) => {
  return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
}

export const DynamicKanban = ({isDisplayed}: IDynamicParams) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const globalContext: any = useContext(GlobalContext)

  //* REGIÃO: PARÂMETROS DE ROTA *//

  // Recuperamos os parametos da rota
  const RouteParams = useParams()

  /**
   * Escolhe quais parametros da rota iremos usar no contexto atual
   * @returns object
   */
  const BuildParams = () => {
    let idrouteitem = RouteParams['*'] as string

    if (idrouteitem != null) {
      let split = idrouteitem.split('/')
      idrouteitem = split[1]
    }

    return {AccessCode: RouteParams.accesscode, SYSParam: RouteParams.sysparam, IDRouteItem: idrouteitem}
  }

  // Construímos os parametros gerais
  const {AccessCode, SYSParam, IDRouteItem} = BuildParams()

  // Variaveis de estado de código de acesso
  const [AccessCodeMaster, setAccessCodeMaster] = useState<string>('')
  const [AccessCodeInherited, setAccessCodeInherited] = useState<string>('')

  // ROLES
  const [UserRoles, setUserRoles] = useState<Array<any>>([])

  /**
   * Carregamos o objeto do app e verificamos se ele é herdado de algum app pai, caso seja então carregamos o pai e guardamos o access code na variavel de estado para uso posterior
   * @returns void
   */
  const VerifyAccessCode = async () => {
    // Caso o código de acesso da rota seja nulo então não temos o que carregar, retornamos
    if (AccessCode == null || AccessCode == '') {
      return
    }

    setLoading(true)

    let _accessCodeMaster = AccessCode ?? ''
    let _accessCodeInherited = AccessCode ?? ''

    // Carregamos o objeto do app da rota
    const baseAccessCodeRequest = await apiWrapper.get(`api/v1/MenuApp/carregarPorAccessCode?item.AccessCode=${AccessCode}`)
    const baseMenuApp = baseAccessCodeRequest.data.data

    if (baseMenuApp == null) {
      setAccessCodeMaster('')
      setAccessCodeInherited('')
      return
    }

    // Verificamos a existencia de um app pai para o app da rota CASO o app filho não seja uma página customizada
    if (!baseMenuApp.biCustomPage && baseMenuApp.idmenuAppPai != null) {
      // Carregamos o app pai
      const masterAccessCodeRequest = await apiWrapper.get(`api/v1/MenuApp/carregar?item.ID=${baseMenuApp.idmenuAppPai}`)
      const masterMenuApp = masterAccessCodeRequest.data.data

      // Guardamos o access code do app pai
      _accessCodeMaster = masterMenuApp.accessCode
    }

    setLoading(false)

    // Aplicamos ambos os códigos de acesso nas variáveis de estado
    setAccessCodeMaster(_accessCodeMaster)
    setAccessCodeInherited(_accessCodeInherited)
  }

  //* FIM REGIÃO: PARÂMETROS DE ROTA *//

  //* PARAMETROS GERAIS
  const {mode} = useThemeMode()

  const [dataViewOptions, setDataViewOptions] = useState<Array<any>>([])
  const [permissions, setPermissions] = useState<any>(null)

  const [menuAppHistory, setMenuAppHistory] = useState<any>(null)
  const [menuAppHistoryStructure, setMenuAppHistoryStructure] = useState<CMenuAppHistoryStructure | undefined>(undefined)

  const [filters, setFilters] = useState<Array<CGlobalFilterStructure>>([])

  const [data, setData] = useState<any>({lanes: []})
  const [loading, setLoading] = useState<boolean>(false)

  const [boardComponents, setBoardComponents] = useState<any>(undefined)
  const [eventBus, setEventBus] = useState<any>(null)

  const [showWait, setShowWait] = useState<boolean>(false)

  const components: any = {
    Card: KanbanCard,
  }

  /* MODAL DYNAMIC */

  const [modalDynamic, setModalDynamic] = useState<boolean>(false)
  const [modalDynamicItemID, setModalDynamicItemID] = useState<number>(0)

  const dynamicCallBack = (response: any = null) => {
    if (response != null) {
      if (response.id != null) {
        handlePopulateKanban(menuAppHistoryStructure?.kanban?.ultimaVisaoSelecionada ?? 0, filters, true)
      }

      if (response.keepOpen != null && response.keepOpen && response.id != null) {
        setModalDynamicItemID(response.id)
        return
      }
    }

    setModalDynamic(false)
    setModalDynamicItemID(0)
  }

  const handleOpenDynamic = (id: number) => {
    setModalDynamic(true)
    setModalDynamicItemID(id)
  }

  /* MODAL FILTERS */

  const [modalFilter, setModalFilter] = useState<boolean>(false)

  const filterCallBack = (response: any = null) => {
    if (response?.apply) {
      handlePopulateKanban(menuAppHistoryStructure?.kanban?.ultimaVisaoSelecionada ?? 0, filters, true)
    }

    setModalFilter(false)
  }

  const handleOpenModalFilters = () => {
    setModalFilter(true)
  }

  /* MODAL KANBAN CONFIG */

  const [modalKanbanConfig, setModalKanbanConfig] = useState<boolean>(false)

  const handleOpenModalKanbanConfig = () => {
    setModalKanbanConfig(true)
  }

  const handleModalKanbanConfigCallback = (response: any = null) => {
    if (response?.apply) {
      handlePopulateKanban(menuAppHistoryStructure?.kanban?.ultimaVisaoSelecionada ?? 0, filters, true)
    }

    setModalKanbanConfig(false)
  }

  /* HANDLERS */

  const handleInit = async () => {
    if (AccessCodeMaster == '') {
      return
    }

    setData({lanes: []})
    setMenuAppHistory(null)
    setMenuAppHistoryStructure(undefined)

    await apiWrapper.get('/api/v1/Role/listarRolesPessoaLogada').then((response) => {
      setUserRoles(response.data.data)
    })

    apiWrapper.get('api/v1/MenuAppFields/listarLoopBackPorMenuApp?item.AccessCode=' + AccessCodeMaster).then((response) => {
      let options: Array<any> = [{id: 0, label: 'Selecione uma visão'}]
      options = options.concat(response.data.data)

      apiWrapper.get('api/v1/MenuAppHistory/carregarPorMenuApp?item.AccessCode=' + AccessCodeMaster).then((response) => {
        let history: CMenuAppHistoryStructure

        if (response.data.data.historico != null && response.data.data.historico != '') {
          history = JSON.parse(response.data.data.historico) as CMenuAppHistoryStructure
        } else {
          history = new CMenuAppHistoryStructure()
        }

        let ultimaVisao = 0
        let ultimaVisaoSalva = options.find((x) => x.id == history.kanban.ultimaVisaoSelecionada)
        let filtros: Array<CGlobalFilterStructure> = []

        if (ultimaVisaoSalva != null) {
          ultimaVisao = ultimaVisaoSalva.id
          filtros = history.kanban.filtro
        } else {
          history.kanban.ultimaVisaoSelecionada = 0
          history.kanban.filtro = []
        }

        setDataViewOptions(options)
        setMenuAppHistory(response.data.data)
        setMenuAppHistoryStructure(history)
        setFilters(filtros)
        handlePopulateKanban(ultimaVisao, filtros, true)
      })
    })
  }

  const handlePopulateKanban = async (idMenuAppField: number, filtros: Array<CGlobalFilterStructure>, useSetLoading: boolean) => {
    if (idMenuAppField == 0) {
      return
    }

    if (useSetLoading) {
      setLoading(true)
    }

    let data: any = {
      lanes: [],
    }

    let obj: any = {
      item: {
        AccessCode: AccessCodeMaster,
        IDMenuAppField: idMenuAppField,
      },
    }

    let lanes = await apiWrapper.post('api/v1/Dynamic/carregarRaias', obj, {})

    if (lanes.data.data.length == 0) {
      setData(data)

      if (useSetLoading) {
        setLoading(false)
      }

      return
    }

    for (const lane of lanes.data.data) {
      let laneObj: any = {
        id: lane.ID.toString(),
        title: lane.Label.toString(),
        label: '',
        cards: [],
        style: {
          backgroundColor: mode == 'light' ? '#f5f8fa' : '#151521',
          color: mode == 'light' ? '#000' : '#fff',
        },
        currentRow: 0,
      }

      obj = {
        item: {
          AccessCode: AccessCodeMaster,
          IDMenuAppField: idMenuAppField,
          Filtros: filtros,
          RelationID: lane.ID,
        },
        RegistroPorPagina: 8,
        PaginaAtual: laneObj.currentRow,
      }

      let cards = await apiWrapper.post('api/v1/Dynamic/carregarCards', obj, {})

      if (cards.data.tipo == 'CUSTOM') {
        setBoardComponents(components)

        cards.data.data.forEach((card: any) => {
          let keys = Object.keys(card)

          for (const item of keys) {
            if (card[item] == null || isEmpty(card[item])) {
              card[item] = 'N/A'
            }
          }

          const exibicaoField1 =
            card.area1FieldFormatacao != null && card.area1FieldFormatacao != '' ? JSON.parse(card.area1FieldFormatacao) : undefined
          const exibicaoField2 =
            card.area2FieldFormatacao != null && card.area2FieldFormatacao != '' ? JSON.parse(card.area2FieldFormatacao) : undefined
          const exibicaoField3 =
            card.area3FieldFormatacao != null && card.area3FieldFormatacao != '' ? JSON.parse(card.area3FieldFormatacao) : undefined
          const exibicaoField4 =
            card.area4FieldFormatacao != null && card.area4FieldFormatacao != '' ? JSON.parse(card.area4FieldFormatacao) : undefined
          const exibicaoField5 =
            card.area5FieldFormatacao != null && card.area5FieldFormatacao != '' ? JSON.parse(card.area5FieldFormatacao) : undefined

          const area1FieldValor: any = card[keys[1]]
          const area2FieldValor: any = card[keys[2]]
          const area3FieldValor: any = card[keys[3]]
          const area4FieldValor: any = card[keys[4]]
          const area5FieldValor: any = card[keys[5]]

          const area1FieldTipo: any = card.area1FieldTipo
          const area2FieldTipo: any = card.area2FieldTipo
          const area3FieldTipo: any = card.area3FieldTipo
          const area4FieldTipo: any = card.area4FieldTipo
          const area5FieldTipo: any = card.area5FieldTipo

          let area1FieldComponente: any = <>{area1FieldValor}</>
          let area2FieldComponente: any = <>{area2FieldValor}</>
          let area3FieldComponente: any = <>{area3FieldValor}</>
          let area4FieldComponente: any = <>{area4FieldValor}</>
          let area5FieldComponente: any = <>{area5FieldValor}</>

          if (
            (area4FieldTipo == 'singleSelect' || area4FieldTipo == 'string' || area4FieldTipo == 'special' || area4FieldTipo == 'textArea') &&
            area4FieldValor != null &&
            area4FieldValor != ''
          ) {
            const iniciais = stringAvatar(area4FieldValor)
            area4FieldComponente = <span className='badge badge-info'>{iniciais}</span>
          }

          let exibicaoFieldArray: Array<any> = [exibicaoField1, exibicaoField2, exibicaoField3, exibicaoField4, exibicaoField5]
          let areaFieldValorArray: Array<any> = [area1FieldValor, area2FieldValor, area3FieldValor, area4FieldValor, area5FieldValor]
          let areaFieldTipoArray: Array<any> = [area1FieldTipo, area2FieldTipo, area3FieldTipo, area4FieldTipo, area5FieldTipo]
          let areaFieldComponenteArray: Array<any> = [
            area1FieldComponente,
            area2FieldComponente,
            area3FieldComponente,
            area4FieldComponente,
            area5FieldComponente,
          ]

          for (let index = 0; index < 5; index++) {
            if (areaFieldValorArray[index] == 'N/A') {
              continue
            }

            switch (areaFieldTipoArray[index]) {
              case 'date':
                areaFieldComponenteArray[index] = <>{new Date(areaFieldValorArray[index]).toLocaleDateString()}</>
                break
              case 'dateTime':
                areaFieldComponenteArray[index] = (
                  <>
                    {new Date(areaFieldValorArray[index]).toLocaleDateString()} {new Date(areaFieldValorArray[index]).toLocaleTimeString()}
                  </>
                )
                break
              case 'boolean':
                switch (areaFieldValorArray[index]) {
                  case true:
                    areaFieldComponenteArray[index] = <i title='Sim' className='fa fa-check'></i>
                    break
                  case false:
                    areaFieldComponenteArray[index] = <i title='Não' className='fa fa-x'></i>
                    break
                }
                break
            }

            if (exibicaoFieldArray[index] != null && (exibicaoFieldArray[index].formatacao != '' || exibicaoFieldArray[index].exibicao.length > 0)) {
              switch (exibicaoFieldArray[index].formatacao) {
                case 'Moeda (R$)':
                  let formatted_brl = parseFloat(areaFieldValorArray[index] ?? 0).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                  areaFieldComponenteArray[index] = <>{formatted_brl}</>
                  break
                case 'Moeda ($)':
                  let formatted_usd = parseFloat(areaFieldValorArray[index] ?? 0).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })
                  areaFieldComponenteArray[index] = <>{formatted_usd}</>
                  break
                case 'Percentual (%)':
                case 'Progresso (%)':
                  let formatted_percent = (parseFloat(areaFieldValorArray[index] ?? 0) / 100).toLocaleString('pt-BR', {
                    style: 'percent',
                  })
                  areaFieldComponenteArray[index] = <>{formatted_percent}</>
                  break
                case 'Tudo em caixa alta':
                  areaFieldComponenteArray[index] = <>{areaFieldValorArray[index].toString().toUpperCase()}</>
                  break
                case 'Tudo em caixa baixa':
                  areaFieldComponenteArray[index] = <>{areaFieldValorArray[index].toString().toLowerCase()}</>
                  break
              }

              let sorted = exibicaoFieldArray[index].exibicao.sort((a, b) => {
                if (a.ordem < b.ordem) {
                  return -1
                }
                if (a.ordem > b.ordem) {
                  return 1
                }
                return 0
              })

              for (const rule of sorted) {
                if (
                  (rule.operador == 'Valor nulo' && (areaFieldValorArray[index] == '' || areaFieldValorArray[index] == 'N/A')) ||
                  (rule.operador == 'Valor igual a' &&
                    (areaFieldValorArray[index] == rule.valor ||
                      (areaFieldTipoArray[index] == 'boolean' &&
                        ((rule.valor == 'Verdadeiro' && areaFieldValorArray[index] == true) ||
                          (rule.valor == 'Falso' && areaFieldValorArray[index] == false))))) ||
                  (rule.operador == 'Valor diferente de' &&
                    (areaFieldValorArray[index] != rule.valor ||
                      (areaFieldTipoArray[index] == 'boolean' &&
                        ((rule.valor == 'Verdadeiro' && areaFieldValorArray[index] == false) ||
                          (rule.valor == 'Falso' && areaFieldValorArray[index] == true))))) ||
                  (rule.operador == 'Valor contém' && areaFieldValorArray[index].includes(rule.valor)) ||
                  (rule.operador == 'Valor não contém' && !areaFieldValorArray[index].includes(rule.valor)) ||
                  (rule.operador == 'Valor maior que' && areaFieldValorArray[index] > rule.valor) ||
                  (rule.operador == 'Valor menor que' && areaFieldValorArray[index] < rule.valor) ||
                  (rule.operador == 'Valor maior ou igual a' && areaFieldValorArray[index] >= rule.valor) ||
                  (rule.operador == 'Valor menor ou igual a' && areaFieldValorArray[index] <= rule.valor) ||
                  (rule.operador == 'Valor entre' && areaFieldValorArray[index] >= rule.valor && areaFieldValorArray[index] <= rule.extra)
                ) {
                  switch (rule.acao) {
                    case 'Exibir badge':
                    case 'Alterar a cor do texto':
                      areaFieldComponenteArray[index] = (
                        <span className={rule.cor}>
                          {areaFieldTipoArray[index] == 'boolean' ? (areaFieldValorArray[index] ? 'Sim' : 'Não') : areaFieldValorArray[index]}
                        </span>
                      )
                      break
                    case 'Exibir ícone':
                      areaFieldComponenteArray[index] = (
                        <>
                          <i
                            className={rule.icone}
                            aria-hidden='true'
                            title={areaFieldTipoArray[index] == 'boolean' ? (areaFieldValorArray[index] ? 'Sim' : 'Não') : areaFieldValorArray[index]}
                          ></i>
                        </>
                      )
                      break
                  }
                }
              }
            }
          }

          let cardProps: any = {
            onClick: handleOpenDynamic,
            cardId: card.ID,
            accessCode: AccessCodeMaster,
            headerLeft: keys[1] != 'area1Field' ? keys[1] : undefined,
            headerLeftValue: areaFieldValorArray[0] !== '' ? areaFieldComponenteArray[0] : undefined,
            headerRight: keys[5] != 'area5Field' ? keys[5] : undefined,
            headerRightValue: areaFieldValorArray[4] !== '' ? areaFieldComponenteArray[4] : undefined,
            title: areaFieldValorArray[1] !== '' ? areaFieldComponenteArray[1] : undefined,
            titleTooltip: areaFieldValorArray[1] !== '' ? areaFieldValorArray[1] : undefined,
            content: areaFieldValorArray[2] !== '' ? areaFieldComponenteArray[2] : undefined,
            contentTooltip: areaFieldValorArray[2] !== '' ? areaFieldValorArray[2] : undefined,
            footer: areaFieldValorArray[3] !== '' ? areaFieldComponenteArray[3] : undefined,
          }

          laneObj.cards.push({
            id: card.ID.toString(),
            ...cardProps,
            draggable: true,
          })
        })
      } else if (cards.data.tipo == 'NOTMAPPED') {
        setBoardComponents(components)

        cards.data.data.forEach((card: any) => {
          let keys = Object.keys(card)

          for (const item of keys) {
            if (isEmpty(card[item])) {
              card[item] = 'N/A'
            }
          }

          let cardProps: any = {
            onClick: handleOpenDynamic,
            cardId: card.ID,
            accessCode: AccessCodeMaster,
            headerLeft: 'Criado Em',
            headerLeftValue: card[keys[1]] != '' && card[keys[1]] != 'N/A' ? new Date(card[keys[1]]).toLocaleDateString() : 'N/A',
            headerRight: undefined,
            headerRightValue: undefined,
            title: card[keys[2]],
            content: undefined,
            footerBadge: GetInitials(card[keys[4]]),
            footerBadgePopup: card[keys[4]],
          }

          laneObj.cards.push({
            id: card.ID.toString(),
            ...cardProps,
            draggable: true,
          })
        })
      } else {
        cards.data.data.forEach((card: any) => {
          laneObj.cards.push({
            id: card.ID.toString(),
            accessCode: AccessCodeMaster,
            title: card.Label.toString(),
            description: '',
            label: '',
            draggable: true,
          })
        })
      }

      laneObj.currentRow = cards.data.data.length

      data.lanes.push(laneObj)
    }

    setData(data)

    if (useSetLoading) {
      setLoading(false)
    }
  }

  const handleOnLaneScrolled = async (requestedPage: any, laneId: any) => {
    let thisLane = data.lanes.filter((lane: any) => parseInt(lane.id) == parseInt(laneId))[0]

    if (thisLane == null) {
      return
    }

    let obj: any = {
      item: {
        AccessCode: AccessCodeMaster,
        IDMenuAppField: menuAppHistoryStructure?.kanban?.ultimaVisaoSelecionada ?? 0,
        Filtros: filters,
        RelationID: laneId,
      },
      RegistroPorPagina: 8,
      PaginaAtual: thisLane.currentRow,
    }

    let cards = await apiWrapper.post('api/v1/Dynamic/carregarCards', obj, {})

    setData((prev: any) => {
      let cardsLane = prev.lanes.filter((lane: any) => parseInt(lane.id) == parseInt(laneId))[0]

      if (thisLane == null) {
        return {...prev}
      }

      if (cards.data.tipo == 'CUSTOM') {
        setBoardComponents(components)

        cards.data.data.forEach((card: any) => {
          let keys = Object.keys(card)

          for (const item of keys) {
            if (card[item] == null || isEmpty(card[item])) {
              card[item] = 'N/A'
            }
          }

          const exibicaoField1 =
            card.area1FieldFormatacao != null && card.area1FieldFormatacao != '' ? JSON.parse(card.area1FieldFormatacao) : undefined
          const exibicaoField2 =
            card.area2FieldFormatacao != null && card.area2FieldFormatacao != '' ? JSON.parse(card.area2FieldFormatacao) : undefined
          const exibicaoField3 =
            card.area3FieldFormatacao != null && card.area3FieldFormatacao != '' ? JSON.parse(card.area3FieldFormatacao) : undefined
          const exibicaoField4 =
            card.area4FieldFormatacao != null && card.area4FieldFormatacao != '' ? JSON.parse(card.area4FieldFormatacao) : undefined
          const exibicaoField5 =
            card.area5FieldFormatacao != null && card.area5FieldFormatacao != '' ? JSON.parse(card.area5FieldFormatacao) : undefined

          const area1FieldValor: any = card[keys[1]]
          const area2FieldValor: any = card[keys[2]]
          const area3FieldValor: any = card[keys[3]]
          const area4FieldValor: any = card[keys[4]]
          const area5FieldValor: any = card[keys[5]]

          const area1FieldTipo: any = card.area1FieldTipo
          const area2FieldTipo: any = card.area2FieldTipo
          const area3FieldTipo: any = card.area3FieldTipo
          const area4FieldTipo: any = card.area4FieldTipo
          const area5FieldTipo: any = card.area5FieldTipo

          let area1FieldComponente: any = <>{area1FieldValor}</>
          let area2FieldComponente: any = <>{area2FieldValor}</>
          let area3FieldComponente: any = <>{area3FieldValor}</>
          let area4FieldComponente: any = <>{area4FieldValor}</>
          let area5FieldComponente: any = <>{area5FieldValor}</>

          let exibicaoFieldArray: Array<any> = [exibicaoField1, exibicaoField2, exibicaoField3, exibicaoField4, exibicaoField5]
          let areaFieldValorArray: Array<any> = [area1FieldValor, area2FieldValor, area3FieldValor, area4FieldValor, area5FieldValor]
          let areaFieldTipoArray: Array<any> = [area1FieldTipo, area2FieldTipo, area3FieldTipo, area4FieldTipo, area5FieldTipo]
          let areaFieldComponenteArray: Array<any> = [
            area1FieldComponente,
            area2FieldComponente,
            area3FieldComponente,
            area4FieldComponente,
            area5FieldComponente,
          ]

          for (let index = 0; index < 5; index++) {
            if (areaFieldValorArray[index] == 'N/A') {
              continue
            }

            switch (areaFieldTipoArray[index]) {
              case 'date':
                areaFieldComponenteArray[index] = <>{new Date(areaFieldValorArray[index]).toLocaleDateString()}</>
                break
              case 'dateTime':
                areaFieldComponenteArray[index] = (
                  <>
                    {new Date(areaFieldValorArray[index]).toLocaleDateString()} {new Date(areaFieldValorArray[index]).toLocaleTimeString()}
                  </>
                )
                break
              case 'boolean':
                switch (areaFieldValorArray[index]) {
                  case true:
                    areaFieldComponenteArray[index] = <i title='Sim' className='fa fa-check'></i>
                    break
                  case false:
                    areaFieldComponenteArray[index] = <i title='Não' className='fa fa-x'></i>
                    break
                }
                break
            }

            if (exibicaoFieldArray[index] != null && (exibicaoFieldArray[index].formatacao != '' || exibicaoFieldArray[index].exibicao.length > 0)) {
              switch (exibicaoFieldArray[index].formatacao) {
                case 'Moeda (R$)':
                  let formatted_brl = parseFloat(areaFieldValorArray[index] ?? 0).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                  areaFieldComponenteArray[index] = <>{formatted_brl}</>
                  break
                case 'Moeda ($)':
                  let formatted_usd = parseFloat(areaFieldValorArray[index] ?? 0).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })
                  areaFieldComponenteArray[index] = <>{formatted_usd}</>
                  break
                case 'Percentual (%)':
                case 'Progresso (%)':
                  let formatted_percent = (parseFloat(areaFieldValorArray[index] ?? 0) / 100).toLocaleString('pt-BR', {
                    style: 'percent',
                  })
                  areaFieldComponenteArray[index] = <>{formatted_percent}</>
                  break
                case 'Tudo em caixa alta':
                  areaFieldComponenteArray[index] = <>{areaFieldValorArray[index].toString().toUpperCase()}</>
                  break
                case 'Tudo em caixa baixa':
                  areaFieldComponenteArray[index] = <>{areaFieldValorArray[index].toString().toLowerCase()}</>
                  break
              }

              let sorted = exibicaoFieldArray[index].exibicao.sort((a, b) => {
                if (a.ordem < b.ordem) {
                  return -1
                }
                if (a.ordem > b.ordem) {
                  return 1
                }
                return 0
              })

              for (const rule of sorted) {
                if (
                  (rule.operador == 'Valor nulo' && (areaFieldValorArray[index] == '' || areaFieldValorArray[index] == 'N/A')) ||
                  (rule.operador == 'Valor igual a' &&
                    (areaFieldValorArray[index] == rule.valor ||
                      (areaFieldTipoArray[index] == 'boolean' &&
                        ((rule.valor == 'Verdadeiro' && areaFieldValorArray[index] == true) ||
                          (rule.valor == 'Falso' && areaFieldValorArray[index] == false))))) ||
                  (rule.operador == 'Valor diferente de' &&
                    (areaFieldValorArray[index] != rule.valor ||
                      (areaFieldTipoArray[index] == 'boolean' &&
                        ((rule.valor == 'Verdadeiro' && areaFieldValorArray[index] == false) ||
                          (rule.valor == 'Falso' && areaFieldValorArray[index] == true))))) ||
                  (rule.operador == 'Valor contém' && areaFieldValorArray[index].includes(rule.valor)) ||
                  (rule.operador == 'Valor não contém' && !areaFieldValorArray[index].includes(rule.valor)) ||
                  (rule.operador == 'Valor maior que' && areaFieldValorArray[index] > rule.valor) ||
                  (rule.operador == 'Valor menor que' && areaFieldValorArray[index] < rule.valor) ||
                  (rule.operador == 'Valor maior ou igual a' && areaFieldValorArray[index] >= rule.valor) ||
                  (rule.operador == 'Valor menor ou igual a' && areaFieldValorArray[index] <= rule.valor) ||
                  (rule.operador == 'Valor entre' && areaFieldValorArray[index] >= rule.valor && areaFieldValorArray[index] <= rule.extra)
                ) {
                  switch (rule.acao) {
                    case 'Exibir badge':
                    case 'Alterar a cor do texto':
                      areaFieldComponenteArray[index] = (
                        <span className={rule.cor}>
                          {areaFieldTipoArray[index] == 'boolean' ? (areaFieldValorArray[index] ? 'Sim' : 'Não') : areaFieldValorArray[index]}
                        </span>
                      )
                      break
                    case 'Exibir ícone':
                      areaFieldComponenteArray[index] = (
                        <>
                          <i
                            className={rule.icone}
                            aria-hidden='true'
                            title={areaFieldTipoArray[index] == 'boolean' ? (areaFieldValorArray[index] ? 'Sim' : 'Não') : areaFieldValorArray[index]}
                          ></i>
                        </>
                      )
                      break
                  }
                }
              }
            }
          }

          let cardProps: any = {
            onClick: handleOpenDynamic,
            cardId: card.ID,
            accessCode: AccessCodeMaster,
            headerLeft: keys[1] != 'area1Field' ? keys[1] : undefined,
            headerLeftValue: areaFieldValorArray[0] !== '' ? areaFieldComponenteArray[0] : undefined,
            headerRight: keys[5] != 'area5Field' ? keys[5] : undefined,
            headerRightValue: areaFieldValorArray[4] !== '' ? areaFieldComponenteArray[4] : undefined,
            title: areaFieldValorArray[1] !== '' ? areaFieldComponenteArray[1] : undefined,
            titleTooltip: areaFieldValorArray[1] !== '' ? areaFieldValorArray[1] : undefined,
            content: areaFieldValorArray[2] !== '' ? areaFieldComponenteArray[2] : undefined,
            contentTooltip: areaFieldValorArray[2] !== '' ? areaFieldValorArray[2] : undefined,
            footer: areaFieldValorArray[3] !== '' ? areaFieldComponenteArray[3] : undefined,
          }

          cardsLane.cards.push({
            id: card.ID.toString(),
            ...cardProps,
            draggable: true,
          })
        })
      } else if (cards.data.tipo == 'NOTMAPPED') {
        setBoardComponents(components)

        cards.data.data.forEach((card: any) => {
          let keys = Object.keys(card)

          for (const item of keys) {
            if (isEmpty(card[item])) {
              card[item] = 'N/A'
            }
          }

          let cardProps: any = {
            onClick: handleOpenDynamic,
            cardId: card.ID,
            headerLeft: 'Criado Em',
            headerLeftValue: card[keys[1]] != '' && card[keys[1]] != 'N/A' ? new Date(card[keys[1]]).toLocaleDateString() : 'N/A',
            headerRight: undefined,
            headerRightValue: undefined,
            title: card[keys[2]],
            content: undefined,
            footerBadge: GetInitials(card[keys[4]]),
            footerBadgePopup: card[keys[4]],
          }

          cardsLane.cards.push({
            id: card.ID.toString(),
            ...cardProps,
            draggable: true,
          })
        })
      } else {
        cards.data.data.forEach((card: any) => {
          cardsLane.cards.push({
            id: card.ID.toString(),
            title: card.Label.toString(),
            description: '',
            label: '',
            draggable: true,
          })
        })
      }

      cardsLane.currentRow = cardsLane.currentRow + cards.data.data.length

      return {...prev}
    })
  }

  const handleDataViewChange = (value: any) => {
    handlePopulateKanban(value.id, filters, true)

    setMenuAppHistoryStructure((prev: CMenuAppHistoryStructure | undefined) => {
      if (prev != null) {
        prev.kanban.ultimaVisaoSelecionada = value.id
        prev.kanban.filtro = []
      }

      setFilters([])

      return prev != null ? {...prev} : undefined
    })
  }

  const handleFiltersChanged = (value: Array<CGlobalFilterStructure>) => {
    //handlePopulateKanban(menuAppHistoryStructure?.kanban?.ultimaVisaoSelecionada ?? 0, value, false)

    setMenuAppHistoryStructure((prev: CMenuAppHistoryStructure | undefined) => {
      if (prev != null) {
        prev.kanban.filtro = value
      }

      return prev != null ? {...prev} : undefined
    })
  }

  const handleSaveMenuAppHistory = () => {
    if (!isDisplayed || menuAppHistory == null || menuAppHistoryStructure == null) {
      return
    }

    let _menuAppHistory = menuAppHistory
    _menuAppHistory.historico = JSON.stringify(menuAppHistoryStructure)

    let obj: any = {
      item: {
        AccessCode: AccessCodeMaster,
        MenuAppHistory: _menuAppHistory,
      },
    }

    apiWrapper.put('api/v1/MenuAppHistory/salvar', obj)
  }

  const handleAdd = () => {
    setModalDynamic(true)
    setModalDynamicItemID(0)
  }

  const handleDragEnd = async (cardId: any, sourceLaneId: any, targetLaneId: any, position: any, cardDetails: any) => {
    if (sourceLaneId == targetLaneId) {
      return false
    }

    let obj: any = {
      item: {
        AccessCode: AccessCodeMaster,
        IDMenuAppField: menuAppHistoryStructure?.kanban?.ultimaVisaoSelecionada ?? 0,
        ID: parseInt(cardId),
        NewID: parseInt(targetLaneId),
      },
    }

    setShowWait(true)

    let ret = await apiWrapper.put('api/v1/Dynamic/atualizarValorRelacao', obj)

    setShowWait(false)

    if (ret.data.mensagem != 'SUCESSO') {
      toast.error(ret.data.mensagem)

      if (eventBus != null) {
        setTimeout(() => {
          eventBus.publish({
            type: 'MOVE_CARD',
            fromLaneId: targetLaneId,
            toLaneId: sourceLaneId,
            cardId: cardId,
            index: position,
          })
        }, 100)
      }

      return false
    } else {
      toast.success('Registro alterado com sucesso!')
    }

    return true
  }

  const handleBeforeCardDelete = (args: any) => {
    if (window.confirm('Tem certeza que deseja excluir este registro?')) {
      args()
    }
  }

  const handleCardDelete = (cardId: any, laneId: any) => {
    let obj: any = {
      item: {
        AccessCode: AccessCodeMaster,
        ID: parseInt(cardId),
      },
    }

    apiWrapper.delete('api/v1/Dynamic/excluir', {data: obj, headers: {}}).then((response) => {
      handlePopulateKanban(menuAppHistoryStructure?.kanban?.ultimaVisaoSelecionada ?? 0, filters, true)
      toast.success('Registro excluído com sucesso!')
    })
  }

  const handleCardClick = (cardId: any, metadata: any, laneId: any) => {
    setModalDynamic(true)
    setModalDynamicItemID(parseInt(cardId))
  }

  const HasAdmin = (userRoles: Array<any>) => {
    if (userRoles.length == 0) {
      return false
    }

    return userRoles.find((x: any) => x.identificador == 'ADMIN') != null
  }

  const HasSuperAdmin = (userRoles: Array<any>) => {
    if (userRoles.length == 0) {
      return false
    }

    return userRoles.find((x: any) => x.identificador == 'SADMIN') != null
  }

  function BuildControls() {
    if (!isDisplayed) {
      return
    }

    let controls: Array<any> = []

    controls.push(
      <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={filters.length}
        color='primary'
      >
        <StyledButton variant='contained' startIcon={<FilterAltIcon />} color='inherit' onClick={() => handleOpenModalFilters()}>
          Filtros
        </StyledButton>
      </Badge>
    )

    controls.push(
      <Button
        hidden={permissions == null || !permissions.biInsert}
        variant='contained'
        startIcon={<AddIcon />}
        color='info'
        onClick={() => handleAdd()}
      >
        Adicionar
      </Button>
    )

    globalContext.ReactState.Toolbar2.setControls(controls)
  }

  function BuildPermissions() {
    apiWrapper.get('/api/v1/MenuAppRole/verificarPermissoesPorCodigoDeAcesso?item.AccessCode=' + AccessCodeMaster).then((response) => {
      setPermissions(response.data.data)
    })
  }

  function isEmpty(obj: any) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false
      }
    }

    return JSON.stringify(obj) === JSON.stringify({})
  }

  function GetInitials(text: string) {
    if (text == null || text == '') {
      return ''
    }

    let names = text.split(' '),
      initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
  }

  useEffect(() => {
    BuildControls()
  }, [permissions])

  useEffect(() => {
    VerifyAccessCode()
  }, [AccessCode])

  useEffect(() => {
    if (isDisplayed) {
      BuildPermissions()
      handleInit()
    }
  }, [AccessCodeInherited, AccessCodeMaster, mode])

  useEffect(() => {
    handleSaveMenuAppHistory()
  }, [menuAppHistoryStructure])

  useEffect(() => {
    BuildControls()
    handleFiltersChanged(filters)
  }, [filters])

  useEffect(() => {
    toast.dismiss()

    if (isDisplayed) {
      handleInit()
    }
  }, [isDisplayed])

  return (
    <Box sx={{height: 'calc(100vh - 200px)', width: '100%'}}>
      {loading && (
        <Box component='div' sx={{alignItems: 'center', justifyContent: 'center'}}>
          <LinearProgress />
        </Box>
      )}
      <Box component='div' style={{position: 'absolute', top: '-72px', right: '1px'}} className='d-flex flex-stack' sx={{pt: 2, pr: 2}}>
        <Box component='div' className='page-title d-flex'></Box>
        <Box component='div' className='d-flex align-items-center py-1'>
          <StyledAutocomplete
            disablePortal
            options={dataViewOptions}
            sx={{width: 300, px: 1}}
            size='small'
            renderInput={(params) => <TextField {...params} label='Visão' />}
            value={
              menuAppHistoryStructure != null
                ? dataViewOptions.filter((option) => option.id == menuAppHistoryStructure.kanban.ultimaVisaoSelecionada)[0] ?? null
                : null
            }
            onChange={(event, value, reason) => handleDataViewChange(value)}
          />
          {(HasAdmin(UserRoles) || HasSuperAdmin(UserRoles)) &&
            menuAppHistoryStructure != null &&
            menuAppHistoryStructure.kanban.ultimaVisaoSelecionada != 0 && (
              <IconButton aria-label='config' title='Filtro' color='inherit' onClick={() => handleOpenModalKanbanConfig()}>
                <SettingsIcon />
              </IconButton>
            )}
        </Box>
      </Box>
      <Box component='div' sx={{height: '100%', width: '100%'}}>
        {!loading && menuAppHistoryStructure != null && menuAppHistoryStructure.kanban.ultimaVisaoSelecionada != 0 && (
          <Board
            style={{backgroundColor: mode == 'light' ? 'white' : '#1e1e2d', width: '100%', height: '90%', overflow: 'auto'}}
            components={boardComponents}
            data={data}
            handleDragEnd={handleDragEnd}
            onBeforeCardDelete={handleBeforeCardDelete}
            onCardDelete={handleCardDelete}
            onCardClick={handleCardClick}
            onLaneScroll={handleOnLaneScrolled}
            eventBusHandle={setEventBus}
          />
        )}
        {(menuAppHistoryStructure == null || menuAppHistoryStructure.kanban.ultimaVisaoSelecionada == 0) && (
          <BoxSVGMessage message='Nenhuma visão selecionada' />
        )}
      </Box>

      {/* MODAL KANBAN CONFIG */}
      {modalKanbanConfig && (
        <KanbanconfigModal
          accessCode={AccessCodeInherited}
          idmenuAppField={menuAppHistoryStructure?.kanban?.ultimaVisaoSelecionada ?? 0}
          callback={handleModalKanbanConfigCallback}
        />
      )}

      {/* MODAL DYNAMIC */}
      {modalDynamic && (
        <DynamicModal accessCode={AccessCodeMaster} accessCodeMaster={AccessCodeMaster} id={modalDynamicItemID} callBack={dynamicCallBack} />
      )}

      {/* MODAL FILTER */}
      {modalFilter && <KanbanFilter accessCode={AccessCodeMaster as string} filters={filters} setFilters={setFilters} callBack={filterCallBack} />}

      {/* MODAL WAIT */}
      {showWait && (
        <Modal open={showWait}>
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              display: 'column',
              textAlign: 'center',
            }}
          >
            <Box component='div'>
              <CircularProgress size={30} />
            </Box>
            <Box component='div' sx={{px: 1, mt: 0.5}}>
              Validando alteração...
            </Box>
          </Box>
        </Modal>
      )}
    </Box>
  )
}
