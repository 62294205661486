//--<< IMPORTS DE COMPONENTES >>--\\

import {useEffect, useState} from 'react'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'

import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'

import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import {toast} from 'react-toastify'
import Checkbox from '@mui/material/Checkbox'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import {handleApplyValueToState} from '../../../../models/wrappers/handlers'
import StyledDialog from '../styled/StyledDialog'
import StyledFormControl from '../styled/StyledFormControl'
import StyledButton from '../styled/StyledButton'
import {CKEditorZIndexFix} from '../utility/CKEditorZIndexFix'
import Autocomplete from '@mui/lab/Autocomplete'

//--<< END IMPORTS DE COMPONENTES >>--\\

interface ISYSTEMPLATEModal {
  id: number
  callBack?: (data?: any) => void
  deleteFunc?: (id: number, accessCode?: string) => void
}

const Editor: any = ClassicEditor

export const SYSTEMPLATEModal = ({id, callBack, deleteFunc}: ISYSTEMPLATEModal) => {
  // Chamamos o MSAL como uma constante consequentemente o re-renderizando e a classe de chamada da API para este componente comsumir
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  // Variaveis de estado
  const [Opened, setOpened] = useState<boolean>(false)
  const [Title, setTitle] = useState<string>('')
  const [Saving, setSaving] = useState<boolean>(false)
  const [Data, setData] = useState<any>(null)
  const [templates, setTemplates] = useState<Array<any>>([])

  /**
   * Verifica se a função de callback é existente e se for chama a mesma passando o retorno para o componente que esta renderizando este.
   * @param data (Opcional) Objeto de retorno para o componente "Pai"
   * @returns void
   */
  const handleCallBack = (data?: any) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  /**
   * Função de inicialização do componente, geralmente chamada no primeiro useEffect.
   * @returns Promise<void>
   */
  const handleOpen = async () => {
    // Decidimos o título do modal baseado no id
    setTitle(id == 0 ? 'Adicionar Template' : 'Editar Template')

    // Carrregamos os dados da API e aplicamos no estado do componente
    const result = await apiWrapper.get('api/v1/MenuAppMailTemplate/carregar?item.ID=' + id)
    setData(result.data.data)

    const tipoTemplateRequest = await apiWrapper.get('api/v1/MenuAppMailTemplate/listarTipos')
    setTemplates(tipoTemplateRequest.data.data)

    // Aqui setamos a variavel de estado "Opened" para verdadeiro consequentemente abrindo o modal no html
    setOpened(true)
  }

  /**
   * Função de "fechamento" do componente, chamada para salvar os dados ou não e, logo após, enderrar a renderização.
   * @param save Define se os dados devem ser enviados para a API para salvamento
   * @param keepOpen Define se no call back informaremos o componente "Pai" que este componente deve reabrir após fechamento
   * @returns Promise<void>
   */
  const handleClose = async (save: boolean, keepOpen: boolean) => {
    // Caso não devamos salvar então apenas encerramos chamando o callback
    if (!save) {
      setOpened(false)
      setData(null)
      handleCallBack()
      return
    }

    // Setamos o estado de salvamento como true para já bloquear controles e ações
    setSaving(true)

    // Montamos o objeto de envio para a API
    let obj: any = {
      item: {
        ID: id,
        MenuAppMailTemplate: Data,
      },
    }

    // Enviamos os dados à API
    const result = await apiWrapper.put('api/v1/MenuAppMailTemplate/salvar', obj)

    // Montamos o objeto de retorno ao componente "Pai"
    let res: any = {
      id: result.data.id,
      keepOpen: keepOpen,
    }

    // Chamamos o callback sinalizando o componente "Pai"
    handleCallBack(res)

    // Informamos o usuário que o salvamente sucedeu
    toast.success('Registro salvo com sucesso!')
  }

  /**
   * Função de start-up para o componente do editor de HTML.
   * @param editor Instância do editor
   * @returns void
   */
  const handleOnEditorStartup = (editor: any) => {
    // Setamos o tamanho do editor em pixels
    //? Ja tentei porcentagem, sem sucesso
    editor.editing.view.change((writer) => {
      writer.setStyle('height', '400px', editor.editing.view.document.getRoot())
    })

    // Aplicamos os dados do template no editor
    editor.setData(Data.conteudo ?? '')
  }

  /**
   * Função que chama a rotina de exclusão passada pelo componente "Pai".
   * @returns void
   */
  const handleOpenExcluir = () => {
    if (deleteFunc != null) {
      // Chamamos a rotina
      deleteFunc(id, 'SYSTEMPLATE')
      // Encerramos o modal
      handleClose(false, false)
    }
  }

  const handleOnChangeAutoComplete = (key: string, value: any) => {
    setData((prev) => {
      prev[key] = value?.id ?? null
      return {...prev}
    })
  }

  /**
   * Função simplficada para verificar se devemos ou não bloquear ações e controles baseado na variavel de salvamento.
   * @returns boolean
   */
  const isEditable = () => {
    return !Saving
  }

  // Use Effect padrão, ele é chamado quando o parâmetro "id" do componente sofre alteração
  useEffect(() => {
    // Inicializamos o componente
    handleOpen()
  }, [id])

  return (
    <StyledDialog open={Opened} fullScreen={true} disableEnforceFocus>
      <DialogTitle>{Title}</DialogTitle>
      <DialogContent dividers={true}>
        <StyledFormControl sx={{width: '100%'}} component='fieldset' variant='standard'>
          {Opened && Data && (
            <>
              <Box component='div' sx={{p: 1}}>
                <TextField
                  disabled={!isEditable()}
                  sx={{width: '100%'}}
                  size='small'
                  label='Nome'
                  value={Data.nome}
                  onChange={(event) => handleApplyValueToState(setData, 'nome', event.target.value)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <TextField
                  disabled={!isEditable()}
                  sx={{width: '100%'}}
                  size='small'
                  label='Título'
                  value={Data.titulo}
                  onChange={(event) => handleApplyValueToState(setData, 'titulo', event.target.value)}
                />
              </Box>
              <Box component='div' sx={{p: 1}}>
                <Autocomplete
                  disablePortal
                  options={templates}
                  sx={{width: '100%'}}
                  size='small'
                  disabled={!isEditable()}
                  renderInput={(params) => <TextField {...params} label='Tipo' />}
                  value={Data.idtipoTemplate != null ? templates.filter((op: any) => op.id == Data.idtipoTemplate)[0] : null}
                  onChange={(event, value, reason) => handleOnChangeAutoComplete('idtipoTemplate', value)}
                />
              </Box>
              <Box component='div' sx={{p: 1, color: 'black !important'}}>
                {CKEditorZIndexFix}
                <CKEditor
                  editor={Editor}
                  config={{
                    removePlugins: ['MediaEmbed', 'EasyImage', 'ImageUpload', 'CKFinder'],
                  }}
                  onReady={handleOnEditorStartup}
                  onChange={(event: any, editor: any) => {
                    handleApplyValueToState(setData, 'conteudo', editor.getData())
                  }}
                />
              </Box>
              <Box component='div' sx={{textAlign: 'center'}} className='text-muted my-1 fs-6'>
                As Tags disponíveis são: {'{ID}, {IDMenuApp}, {URLSistema}, {IDPessoa}, {IDPessoaMaster}, {AccessCode} e [NomeCampo]'}.
              </Box>
              <Box component='div' sx={{p: 1}}>
                <FormControlLabel
                  label='Ativo'
                  control={
                    <Checkbox
                      disabled={!isEditable()}
                      checked={Data.ativo}
                      onChange={(event, checked) => handleApplyValueToState(setData, 'ativo', checked)}
                    />
                  }
                />
              </Box>
            </>
          )}
        </StyledFormControl>
      </DialogContent>
      <DialogActions>
        {isEditable() && (
          <StyledButton variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleClose(false, false)}>
            Fechar
          </StyledButton>
        )}
        {deleteFunc != null && isEditable() && id != 0 && (
          <StyledButton variant='contained' startIcon={<DeleteIcon />} color='error' onClick={() => handleOpenExcluir()}>
            Excluir
          </StyledButton>
        )}
        {isEditable() && (
          <>
            <StyledButton variant='contained' startIcon={<SaveIcon />} color='success' onClick={() => handleClose(true, true)}>
              Salvar
            </StyledButton>
            <StyledButton variant='contained' startIcon={<SaveIcon />} color='success' onClick={() => handleClose(true, false)}>
              Salvar e Fechar
            </StyledButton>
          </>
        )}
        {!isEditable() && (
          <StyledButton variant='contained' startIcon={<SaveIcon />} color='success'>
            Salvando...
          </StyledButton>
        )}
      </DialogActions>
    </StyledDialog>
  )
}

export default SYSTEMPLATEModal
