import {useContext, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'

import {Box} from '@mui/material'
import {GlobalContext} from '../../../../../App'
import DynamicList from './DynamicList'

interface IDynamicParams {
  isDisplayed?: boolean
}

interface ITempProps {
  date: Date
  action: string
  idPessoa: number
}

export const DynamicLog = ({isDisplayed}: IDynamicParams) => {
  const globalContext: any = useContext(GlobalContext)

  const {accesscode, sysparam} = useParams()

  function BuildControls() {
    if (!isDisplayed) {
      return
    }

    globalContext.ReactState.Toolbar2.setControls([])
  }

  useEffect(() => {
    toast.dismiss()
    BuildControls()
  }, [isDisplayed])

  return (
    <Box sx={{height: window.location.href.includes('_dynamicTabs') ? 'calc(100vh - 200px)' : 'calc(100vh - 130px)', width: '100%'}}>
      <DynamicList isDisplayed={isDisplayed} accessCode='SYSMAA' sysParam={accesscode} title='' subtitle='' displayHeader={false} />
    </Box>
  )
}
