import {useEffect, useState} from 'react'
import moment from 'moment'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'

import {Breakpoint, styled} from '@mui/material/styles'
import {writeFileXLSX, utils} from 'xlsx'
import * as XLSX from 'xlsx'
import BoxSVGMessage from '../utility/BoxSVGMessage'
import {toast} from 'react-toastify'

import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import UploadCollection from '../upload/UploadCollection'
import SYSMAFModal from './modalSYSMAF'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import {useIntl} from 'react-intl'
import CircularProgressWithLabel from '../utility/CircularProgressWithLabel'
import StyledDialog from '../styled/StyledDialog'
import {MenuAppFieldsRuleResult} from '../../../../modules/api/models/Result/MenuAppFieldsRuleResult'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import StyledCard from '../styled/StyledCard'
import StyledCardContent from '../styled/StyledCardContent'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import StyledCardActions from '../styled/StyledCardActions'
import {CircularProgress} from '@mui/material'
import useStateRef from 'react-usestateref'

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.mode == 'light' ? '#fff' : '#151521',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

interface IImportacaoProps {
  accessCode: string
  callBack: (response: any) => void
}

export const ImportacaoModal = ({accessCode, callBack}: IImportacaoProps) => {
  // IMPORTA A LINGUAGEM SELECIONADA NO SISTEMA i.e: pt, en etc...
  const lang = useLang()
  const intl = useIntl()

  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [loading, setLoading] = useState<boolean>(false)
  const [Opened, setOpened] = useState<boolean>(false)
  const [Title, setTitle] = useState<string>('Seleção de modo de importação')
  const [View, setView] = useState<string>('SELECAO')
  const [Fields, setFields] = useState<Array<any>>([])
  const [ModalSize, setModalSize] = useState<string>('md')
  const [Validating, setValidating] = useState<boolean>(false)
  const [CurrentValidation, setCurrentValidation] = useState<number>(0)
  const [Importing, setImporting] = useState<boolean>(false)
  const [ImportIndex, setImportIndex] = useState<number>(0)
  const [ImportProgress, setImportProgress] = useState<number>(0)
  const [IsValidImport, setIsValidImport] = useState<boolean>(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const [sheetNames, setSheetNames] = useState<Array<string>>([])
  const [sheetIndex, setSheetIndex] = useState<number>(0)
  const [workBook, setWorkBook] = useState<XLSX.WorkBook | null>(null)
  const [importedData, setImportedData] = useState<Array<any>>([])
  const [importedDataKeys, setImportedDataKeys] = useState<Array<string>>([])
  const [CamposModal, setCamposModal] = useState<Array<any>>([])
  const [mapping, setMapping] = useState<Array<number>>([])
  const [mappingKeys, setMappingKeys] = useState<Array<string>>([])
  const [SYSMAFPermissions, setSYSMAFPermissions] = useState<any>(null)

  // STATUS
  const [shouldInterrupt, setShouldInterrupt, shouldInterruptRef] = useStateRef<boolean>(false)
  const [interrupted, setInterrupted, interruptedRef] = useStateRef<boolean>(false)
  const [lastImportedIndex, setLastImportedIndex, lastImportedIndexRef] = useStateRef<number>(0)
  const [totalFailed, setTotalFailed, totalFailedRef] = useStateRef<number>(0)
  const [totalSuccess, setTotalSuccess, totalSuccessRef] = useStateRef<number>(0)

  const [advancedImportFieldRuleId, setAdvancedImportFieldRuleId] = useState<number>(0)
  const [canEnableAdvancedImport, setCanEnableAdvancedImport] = useState<boolean>(false)
  const [advancedImportEnabled, setAdvancedImportEnabled] = useState<boolean>(false)

  const normalise = (value, min, max) => ((value - min) * 100) / (max - min)

  const loadFields = (includeNewFieldOption: boolean) => {
    apiWrapper.get('api/v1/MenuAppFields/listarPorMenuApp?item.AccessCode=' + accessCode).then((response) => {
      let baseFields = [{id: -1, label: 'Ignorar Coluna', campo: ''}]

      if (includeNewFieldOption) {
        baseFields.push({id: -2, label: 'Adicionar Campo', campo: ''})
      }

      setFields(baseFields.concat(response.data.data.filter((x: any) => x.tipo != 'upload' && x.campo.toLowerCase() != 'id')))
    })
  }

  const loadCamposModal = () => {
    apiWrapper.get(`api/v1/Dynamic/carregarCamposEstruturados?item.AccessCode=${accessCode}&item.ID=0`).then((response) => {
      setCamposModal(response.data.data.filter((x: any) => x.tipo != 'upload'))
    })
  }

  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  const handleOpen = async () => {
    setOpened(true)
    setLoading(true)

    let fieldRuleRequest = await apiWrapper.get(`/api/v1/MenuAppFieldsRule/listarPorMenuApp?item.AccessCode=${accessCode}`)
    let fieldRules = fieldRuleRequest.data.data as Array<MenuAppFieldsRuleResult>
    let advancedImportRule = fieldRules.find((x) => x.acao == 'ENABLE_ADVANCED_IMPORT' && x.formula != '')
    setCanEnableAdvancedImport(advancedImportRule != null)
    setAdvancedImportFieldRuleId(advancedImportRule?.id ?? 0)

    if (advancedImportRule == null) {
      setView('UPLOAD')
      setTitle('Upload de planilha')
    }

    let permissionRequest = await apiWrapper.get('/api/v1/MenuAppRole/verificarPermissoesPorCodigoDeAcesso?item.AccessCode=SYSMAF')
    setSYSMAFPermissions(permissionRequest.data.data)
    loadFields(permissionRequest.data.data.biInsert)
    loadCamposModal()

    setLoading(false)
  }

  const handleClose = async (save: boolean) => {
    if (save) {
      if (importedData.length == 0) {
        toast.error('É necessário ter ao menos um registro para realizar a importação.')
        return
      }

      setImporting(true)
      setShouldInterrupt(false)
      setInterrupted(false)

      let index: number = lastImportedIndexRef.current
      let toImport = importedData
        .map((x, i) => {
          if (i >= index) {
            return x
          }
        })
        .splice(index, importedData.length - index)

      for (const item of toImport) {
        if (shouldInterruptRef.current) {
          setImporting(false)
          setShouldInterrupt(false)
          setInterrupted(true)
          return
        }

        // Caso seja uma importação avançada redirecionamos para outro endpoint senão seguimos o fluxo padrão de salvamento de campos estruturados
        if (advancedImportEnabled) {
          let toJsonObj: Array<any> = []

          for (let i = 0; i < mappingKeys.length; i++) {
            toJsonObj.push({Campo: mappingKeys[i], Valor: item[importedDataKeys[i]]})
          }

          let obj: any = {
            item: {
              AccessCode: accessCode,
              IDMenuAppFieldRule: advancedImportFieldRuleId,
              Json: JSON.stringify(toJsonObj),
            },
          }

          await apiWrapper
            .post('api/v1/Dynamic/executarImportacaoAvancada', obj)
            .then((response) => {
              if (response.data.mensagem != 'SUCCESS') {
                setTotalFailed(totalFailedRef.current + 1)
                return
              }

              setTotalSuccess(totalSuccessRef.current + 1)
            })
            .catch((error) => {
              setTotalFailed(totalFailedRef.current + 1)
            })
        } else {
          for (let index = 0; index < mapping.length; index++) {
            if (mapping[index] < 0) {
              // IGNORAR COLUNA
              continue
            }

            let mappingField = CamposModal.find((x) => x.id == mapping[index])
            let keysFromData = Object.keys(item)
            let valueFromData = item[keysFromData[index]].trim()

            if (mappingField == null || valueFromData == null || valueFromData == '') {
              continue
            }

            switch (mappingField.tipo) {
              case 'decimal':
                mappingField.valor = parseFloat(valueFromData.toString().replaceAll(',', '.')).toFixed(2)
                break
              case 'geocode':
                mappingField.valor = parseFloat(valueFromData.toString().replaceAll(',', '.')).toFixed(6)
                break
              case 'date':
              case 'dateTime':
                let date = moment(valueFromData, intl.formatMessage({id: 'DATE.FORMAT'}), lang)
                mappingField.valor = date.format('YYYY-MM-DDTHH:mm:ss').toString()
                break
              case 'boolean':
                let toLower = valueFromData.toString().toLowerCase()
                switch (toLower) {
                  case '1':
                  case 'sim':
                  case 'true':
                  case 'verdadeiro':
                    mappingField.valor = 'true'
                    break
                  default:
                    mappingField.valor = 'false'
                    break
                }
                break
              default:
                mappingField.valor = valueFromData.toString()
                break
            }
          }

          let obj: any = {
            item: {
              AccessCode: accessCode,
              Campos: CamposModal,
              AcaoEmMassa: false,
              IDs: [0],
            },
          }

          await apiWrapper
            .put('api/v1/Dynamic/salvarCamposModal', obj)
            .then((response) => {
              setTotalSuccess(totalSuccessRef.current + 1)
            })
            .catch((error) => {
              setTotalFailed(totalFailedRef.current + 1)
            })
        }

        index++

        setImportProgress((prev) => normalise(index, 0, importedData.length))
        setImportIndex(index)
        setLastImportedIndex(index)
      }

      setTimeout(() => {
        //SALVAR LOG
        apiWrapper.put('api/v1/Dynamic/salvarLogImportacaoExportacao', {
          item: {
            AccessCode: accessCode,
            Tipo: 'IMPORTACAO',
            TotalRegistros: importedData.length,
          },
        })

        setImporting(false)
        setOpened(false)
        handleCallBack({refresh: true})

        if (totalFailedRef.current > 0) {
          toast.warning('Importação realizada com falhas, verifique os logs do app para mais detalhes.')
        } else {
          toast.success('Importação realizada com sucesso!')
        }
      }, 750)

      return
    }

    setOpened(false)
    handleCallBack()
  }

  const handleAdvance = () => {
    setView((prev) => {
      switch (prev) {
        case 'SELECAO':
          setTitle('Upload de planilha')
          setModalSize('md')
          return 'UPLOAD'
        case 'UPLOAD':
          setTitle('Preview')
          setModalSize('lg')
          setLoading(false)
          return 'CAMPOS'
        case 'CAMPOS':
          setTitle('Finalizar')
          setModalSize('md')
          Validate()
          return 'FINALIZAR'
      }

      return prev
    })
  }

  const handleReturn = () => {
    setView((prev) => {
      switch (prev) {
        case 'UPLOAD':
          setTitle('Seleção de modo de importação')
          setModalSize('md')
          setAdvancedImportEnabled(false)
          return 'SELECAO'
        case 'CAMPOS':
          setTitle('Upload de planilha')
          setModalSize('md')
          setMapping([])
          setWorkBook(null)
          setSheetNames([])
          setImportedData([])
          setImportedDataKeys([])
          return 'UPLOAD'
        case 'FINALIZAR':
          setTitle('Preview')
          setModalSize('lg')
          setErrors([])
          setCurrentValidation(0)
          setImportProgress(0)
          setImportIndex(0)
          setImporting(false)
          setShouldInterrupt(false)
          setInterrupted(false)
          setLastImportedIndex(0)
          setTotalFailed(0)
          setTotalSuccess(0)
          return 'CAMPOS'
      }

      return prev
    })
  }

  const handleValidate = async () => {
    setValidating(true)
    let isValid = true

    // VALIDAMOS SE OS CAMPOS OBRIGATÓRIOS FORAM SELECIONADOS
    let camposObrigatorios = CamposModal.filter((x) => x.obrigatorio)

    for (const item of camposObrigatorios) {
      if (mapping.find((x) => x == item.id) == null) {
        setErrors((prev) => [...prev, `O campo obrigatório "${item.label}" não foi selecionado para importação.`])
        isValid = false
      }
    }

    let dataIndex = 0

    // VALIDAMOS OS DADOS INSERIDOS
    for (const data of importedData) {
      for (let index = 0; index < mapping.length; index++) {
        if (mapping[index] < 0) {
          // IGNORAR COLUNA
          continue
        }

        let mappingField = Fields.find((x) => x.id == mapping[index])
        let keysFromData = Object.keys(data)
        let valueFromData = (data[keysFromData[index]] ?? '').trim()

        // VALIDAMOS SE O CAMPO É OBRIGATORIO E SE O ITEM TEM VALOR PREENCHIDO
        if (mappingField.obrigatorio && valueFromData !== false && (valueFromData == null || valueFromData == '')) {
          setErrors((prev) => [...prev, `Item ${data.id}: Campo obrigatório "${mappingField.label}" sem valor.`])
          isValid = false
        }

        if (valueFromData == null || valueFromData == '') {
          continue
        }

        // VALIDAMOS OS VALORES
        switch (mappingField.tipo) {
          case 'number':
            if (valueFromData.toString().match(/^[0-9]+$/) == null) {
              setErrors((prev) => [...prev, `Item ${data.id}: Campo inteiro "${mappingField.label}" possui valor "${valueFromData}" inválido.`])
              isValid = false
            }

            break
          case 'decimal':
          case 'geocode':
            let valueReplaced = valueFromData.toString().replaceAll(',', '.')
            let valueWithoutSpecials = valueFromData.toString().replaceAll(',', '').replaceAll('.', '')

            if (valueWithoutSpecials.match(/^[0-9]+$/) == null || isNaN(parseFloat(valueReplaced))) {
              setErrors((prev) => [
                ...prev,
                `Item ${data.id}: Campo decimal/geocode "${mappingField.label}" possui valor "${valueFromData}" inválido.`,
              ])
              isValid = false
            }

            break
          case 'date':
          case 'dateTime':
            let date = moment(valueFromData, intl.formatMessage({id: 'DATE.FORMAT'}), lang)

            if (!date.isValid()) {
              setErrors((prev) => [...prev, `Item ${data.id}: Campo de data "${mappingField.label}" possui valor "${valueFromData}" inválido.`])
              isValid = false
            }

            break
          case 'boolean':
            let toLower = valueFromData.toString().toLowerCase()

            if (
              toLower != 'verdadeiro' &&
              toLower != 'falso' &&
              toLower != 'true' &&
              toLower != 'false' &&
              toLower != '1' &&
              toLower != '0' &&
              toLower != 'sim' &&
              toLower != 'não' &&
              toLower != 'nao'
            ) {
              setErrors((prev) => [
                ...prev,
                `Item ${data.id}: Campo Verdadeiro/Falso "${mappingField.label}" possui valor "${valueFromData}" inválido.`,
              ])
              isValid = false
            }

            break
          case 'singleSelect':
            let ret: any = null
            let value = valueFromData.toString()

            if (value.match(/^[0-9]+$/) != null) {
              ret = await apiWrapper.get(
                `api/v1/Dynamic/listarSingleSelect?item.AccessCode=${accessCode}&item.IDMenuAppField=${mappingField.id}&item.IDs=${value}&PaginaAtual=0&RegistroPorPagina=1`
              )
            } else {
              ret = await apiWrapper.get(
                `api/v1/Dynamic/listarSingleSelect?item.AccessCode=${accessCode}&item.IDMenuAppField=${mappingField.id}&item.Query=${value}&PaginaAtual=0&RegistroPorPagina=1`
              )
            }

            if (ret.data.data.length == 0) {
              setErrors((prev) => [...prev, `Item ${data.id}: Campo de seleção "${mappingField.label}" possui valor "${value}" inexistente na base.`])
              isValid = false
            }

            break
          default:
            break
        }
      }

      dataIndex++
      setCurrentValidation(normalise(dataIndex, 0, importedData.length))
    }

    setValidating(false)
    return isValid
  }

  const handleOnInput = async (file: File) => {
    setLoading(true)

    const data = await file.arrayBuffer()
    const workbook = XLSX.read(data, {raw: true})

    setWorkBook(workbook)
    setSheetNames(workbook.SheetNames)

    handleLoadSheet(workbook, 0)
  }

  const handleLoadSheet = (workBook: XLSX.WorkBook, sheetIndex: number) => {
    debugger
    const worksheet = workBook.Sheets[workBook.SheetNames[sheetIndex]]
    const jsonData = utils.sheet_to_json(worksheet, {header: advancedImportEnabled ? 1 : undefined, defval: '', raw: false}) as Array<any>

    if (jsonData.length == 0) {
      toast.error('É necessário que a planilha tenha ao menos um registro.')
      return
    }

    setMapping([])
    setMappingKeys([])

    let keys: Array<string> = []
    let index: number = 0
    let keyIndex: number = 0

    for (let item of jsonData) {
      keyIndex = 0

      if (index == 0) {
        for (const [key, value] of Object.entries(item)) {
          keys.push(key)

          let field = Fields.find((x) => x.campo == key || x.label == key) ?? Fields.find((x) => x.id == -1)
          setMapping((prev) => [...prev, field.id])
          setMappingKeys((prev) => [...prev, value as string])

          keyIndex++
        }
      }

      item.id = index + 1
      index++
    }

    setImportedData(advancedImportEnabled ? jsonData.splice(1, jsonData.length - 1) : jsonData)
    setImportedDataKeys(keys)
  }

  const handleOnChangeMapping = (index: number, value: any) => {
    if (value.id == -2) {
      setModalSYSMAF(true)
      return
    }

    setMapping((prev) => {
      prev[index] = value != null ? value.id : -1
      return [...prev]
    })

    setMappingKeys((prev) => {
      prev[index] = value != null ? value.campo : ''
      return [...prev]
    })
  }

  const handleOnUpload = (file: File) => {
    handleOnInput(file)
  }

  const Validate = async () => {
    let isValid: boolean = advancedImportEnabled ? true : await handleValidate()
    setIsValidImport(isValid)
  }

  function ExportModelo() {
    let req: any = {
      item: {
        AccessCode: accessCode,
        SysParam: '',
        Filters: [],
        Orders: [],
      },
      PaginaAtual: 0,
      RegistroPorPagina: 1,
    }

    apiWrapper
      .get(`api/v1/Dynamic/carregarCamposEstruturados?item.AccessCode=${accessCode}&item.ID=0&item.AcaoEmMassa=false`)
      .then(async (response: any) => {
        let newArray: Array<any> = []
        let newEntry: any = {}

        for (const item of response.data.data) {
          newEntry[item.label] = ''
        }

        newArray.push(newEntry)

        const worksheet = utils.json_to_sheet(newArray)
        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, worksheet, 'Dados')
        writeFileXLSX(workbook, 'Modelo.xlsx')
      })
  }

  /* MODAL SYSMAF */

  const [modalSYSMAF, setModalSYSMAF] = useState<boolean>(false)

  const SYSMAFCallBack = (response: any = null) => {
    if (response != null) {
      loadFields(SYSMAFPermissions.biInsert)
      loadCamposModal()
    }

    setModalSYSMAF(false)
  }

  useEffect(() => {
    if (Opened) {
      return
    }

    handleOpen()
  }, [])

  useEffect(() => {
    if (importedData.length == 0 || View != 'UPLOAD') {
      return
    }

    handleAdvance()
  }, [importedDataKeys])

  return (
    <>
      <StyledDialog open={Opened} fullWidth={true} maxWidth={ModalSize as Breakpoint}>
        <DialogTitle>
          Importação de Dados - {Title}{' '}
          <Box component='div' sx={{float: 'inline-end'}}>
            {interrupted && (
              <span className='badge bg-info text-light m-1'>
                Importados: {totalSuccess + totalFailed} de {importedData.length}
              </span>
            )}
            {Importing && <span className='badge bg-success text-light m-1'>Sucesso: {totalSuccess}</span>}
            {Importing && <span className='badge bg-danger text-light m-1'>Falha: {totalFailed}</span>}
            {advancedImportEnabled && <span className='badge bg-primary text-light m-1'>Importação Avançada</span>}
          </Box>
        </DialogTitle>
        <DialogContent sx={View == 'CAMPOS' ? {maxHeight: '600px'} : {}}>
          {loading && View == 'UPLOAD' && (
            <>
              <Box component='div' sx={{textAlign: 'center'}}>
                <CircularProgress />
              </Box>
              <Box component='div' sx={{textAlign: 'center'}}>
                <span>Carregando dados da planilha, aguarde...</span>
              </Box>
            </>
          )}
          {loading && View == 'SELECAO' && (
            <Box component='div' sx={{textAlign: 'center'}}>
              <CircularProgress />
            </Box>
          )}
          <FormControl hidden={View != 'SELECAO' || loading} sx={{p: 1, height: '100%', width: '100%'}} component='fieldset' variant='standard'>
            <Box component='div' className='container'>
              <Box component='div' className='row'>
                <Box component='div' className='col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-6'>
                  <StyledCard>
                    <StyledCardContent sx={{height: '127px'}}>
                      <Box component='div' className='row d-flex'>
                        <Box component='div' className='col-md-1'>
                          <PlaylistAddIcon color='primary' sx={{fontSize: 30}} />
                        </Box>
                        <Box component='div' className='col-md-11'>
                          <Typography variant='body1' color='textSecondary' component='p' sx={{mt: 0.6}} className='word-wrap text-dark'>
                            <strong>Importação de planilha padrão</strong>
                          </Typography>
                        </Box>
                      </Box>
                      <Box component='div' className='row'>
                        <Typography variant='body1' color='textSecondary' component='p' className='word-wrap text-dark py-2'>
                          Você deve baixar o arquivo modelo deste app antes de realizar a importação. Os dados automaticamente serão inseridos no App
                          sem qualquer tratamento, por isso é importante que o dos dados respeitem o arquivo modelo baixado.
                        </Typography>
                      </Box>
                    </StyledCardContent>
                    <StyledCardActions sx={{justifyContent: 'flex-end'}}>
                      <Button
                        startIcon={<AddIcon />}
                        color='primary'
                        onClick={() => {
                          handleAdvance()
                          setAdvancedImportEnabled(false)
                        }}
                      >
                        Selecionar
                      </Button>
                    </StyledCardActions>
                  </StyledCard>
                </Box>
                <Box component='div' className='col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-6'>
                  <StyledCard>
                    <StyledCardContent sx={{height: '127px'}}>
                      <Box component='div' className='row d-flex'>
                        <Box component='div' className='col-md-1'>
                          <PlaylistAddIcon color='primary' sx={{fontSize: 30}} />
                        </Box>
                        <Box component='div' className='col-md-11'>
                          <Typography variant='body1' color='textSecondary' component='p' sx={{mt: 0.6}} className='word-wrap text-dark'>
                            <strong>Importação de planilha avançada</strong>
                          </Typography>
                        </Box>
                      </Box>
                      <Box component='div' className='row'>
                        <Typography variant='body1' color='textSecondary' component='p' className='word-wrap text-dark py-2'>
                          Você pode importar qualquer planilha sem um formato definido. Apos a importação serão executados procedimentos desenvolvidos
                          pela equipe tecnica para tratamento dos dados importados.
                        </Typography>
                      </Box>
                    </StyledCardContent>
                    <StyledCardActions sx={{justifyContent: 'flex-end'}}>
                      <Button
                        startIcon={<AddIcon />}
                        color='primary'
                        onClick={() => {
                          handleAdvance()
                          setAdvancedImportEnabled(true)
                        }}
                      >
                        Selecionar
                      </Button>
                    </StyledCardActions>
                  </StyledCard>
                </Box>
              </Box>
            </Box>
          </FormControl>
          <FormControl hidden={View != 'UPLOAD' || loading} sx={{p: 1, height: '100%', width: '100%'}} component='fieldset' variant='standard'>
            <Box component='div' className='alert alert-primary d-flex align-items-center'>
              <i className='ki-duotone ki-shield-tick fs-2hx text-success me-4'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <div className='d-flex flex-column'>
                <h4 className='mb-1 text-dark'>Detalhes de importação:</h4>
                <span>Realize por aqui a importação de dados a partir de uma planilha.</span>
                <span>
                  As extensões aceitas são <strong>.xls</strong>, <strong>.xlsx</strong> e <strong>.csv</strong>.
                </span>
                <br />
                <span>
                  <strong>Dica</strong>: Utilize planilhas simplificadas para evitar erros de leitura, use o arquivo modelo como exemplo se
                  necessário.
                </span>
              </div>
            </Box>
            <Box component='div' sx={{p: 1, alignSelf: 'center'}}>
              <a title='Clique aqui para realizar Download do arquivo modelo' style={{cursor: 'pointer'}} onClick={(event) => ExportModelo()}>
                <div className='container'>
                  <div className='row'>
                    <div className='col-1'></div>
                    <div className='col-2 p-1 text-center '>
                      <img alt='Logo' src={toAbsoluteUrl('/media/app/excel64x64.png')} className='h-45px w-45px' />
                    </div>
                    <div className='col-8 p-1'>
                      <span>Para baixar o arquivo modelo deste app </span>{' '}
                      <span>
                        <strong className='link-primary'>Clique Aqui</strong> e após, clique abaixo para enviar.
                      </span>
                    </div>
                    <div className='col-1'></div>
                  </div>
                </div>
              </a>
            </Box>
            <Box component='div' sx={{p: 1, width: 300, alignSelf: 'center'}}>
              <UploadCollection
                azFiles={[]}
                noUpload={true}
                accept={{
                  'application/vnd.ms-excel': ['.csv', '.xlsx', '.xls'],
                }}
                onDelete={(file) => {}}
                onUpload={(file) => handleOnUpload(file)}
              />
            </Box>
          </FormControl>
          <FormControl hidden={View != 'CAMPOS' || loading} sx={{p: 1, height: '100%', width: '100%'}} component='fieldset' variant='standard'>
            {importedData.length > 0 && (
              <>
                <Box component='div' sx={{py: 1}}>
                  <FormControl fullWidth>
                    <InputLabel id='sheet-select-label'>Sheet</InputLabel>
                    <Select
                      labelId='sheet-select-label'
                      id='sheet-select'
                      label='Sheet'
                      size='small'
                      value={sheetIndex}
                      onChange={(event, child) => {
                        setSheetIndex(event.target.value as number)
                        handleLoadSheet(workBook as XLSX.WorkBook, event.target.value as number)
                      }}
                    >
                      {sheetNames.map((item, index) => {
                        return <MenuItem value={index}>{item}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <TableContainer sx={{minHeight: '400px', maxHeight: '500px'}}>
                  <Table stickyHeader size='small' aria-label='imported-data'>
                    <TableHead>
                      {!advancedImportEnabled &&
                        importedDataKeys.map((item, index) => {
                          return (
                            <StyledTableCell key={`ddl-row-${index}-cell-${index}`} component='th' scope='row' sx={{minWidth: '225px', px: 0}}>
                              <Autocomplete
                                fullWidth
                                disablePortal
                                options={Fields}
                                size='small'
                                renderInput={(params) => <TextField {...params} label='Campo' />}
                                value={mapping[index] != null ? Fields.find((x) => x.id == mapping[index]) ?? null : null}
                                onChange={(event, value, reason) => handleOnChangeMapping(index, value)}
                              />
                            </StyledTableCell>
                          )
                        })}
                      {advancedImportEnabled && (
                        <TableRow>
                          {mappingKeys.map((item, index) => {
                            return (
                              <StyledTableCell
                                key={`ddl-row-${index}-cell-${index}`}
                                scope='row'
                                align='center'
                                className='fs-6 fw-bold'
                                sx={{minWidth: '225px', px: 0}}
                              >
                                {item}
                              </StyledTableCell>
                            )
                          })}
                        </TableRow>
                      )}
                    </TableHead>
                    <TableBody>
                      {importedData.map((item, index) => {
                        return (
                          <StyledTableRow key={`item-${index}`}>
                            {importedDataKeys.map((key, i) => {
                              return (
                                <StyledTableCell key={`body-row-${index}-cell-${i}`} sx={{minWidth: '225px'}} title={item[key]} align='center'>
                                  {(item[key] ?? '').toString()}
                                </StyledTableCell>
                              )
                            })}
                          </StyledTableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </FormControl>
          <FormControl hidden={View != 'FINALIZAR' || loading} sx={{p: 1, height: '100%', width: '100%'}} component='fieldset' variant='standard'>
            {importedData.length == 0 && <BoxSVGMessage message='Aguardando dados...' />}
            {importedData.length > 0 && !IsValidImport && !Importing && !Validating && (
              <Box component='div' className='alert alert-danger d-flex align-items-center'>
                <i className='ki-duotone ki-shield-cross fs-2hx text-danger me-4'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
                <div className='d-flex flex-column'>
                  <h4 className='mb-1 text-dark'>Falha na validação!</h4>
                  <span>As seguintes informações devem ser corrigidas:</span>
                  <br />
                  {errors.map((item: string, index: number) => {
                    return <span>{item}</span>
                  })}
                  <br />
                  <span>Favor corrigir o arquivo e após tente novamente.</span>
                </div>
              </Box>
            )}
            {importedData.length > 0 && IsValidImport && !Importing && !Validating && (
              <>
                {interrupted && (
                  <>
                    <Box component='div' className='alert alert-danger d-flex align-items-center'>
                      <i className='ki-duotone ki-shield-cross fs-2hx text-danger me-4'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      <div className='d-flex flex-column'>
                        <h4 className='mb-1 text-dark'>Importação interrompida!</h4>
                        <span>Você ainda pode finalizar a exportação caso deseje, basta clicar em "Continuar".</span>
                        <span>A importação seguirá a partir do ultimo registro importado antes da solicitação da interrupção.</span>
                      </div>
                    </Box>
                    <Box component='div' className='alert alert-warning d-flex h-35px align-items-center'>
                      <div className='d-flex flex-column'>
                        <span>
                          <strong>Atenção:</strong> Retroceder o fluxo reiniciará o progresso da importação!
                        </span>
                      </div>
                    </Box>
                  </>
                )}
                {!interrupted && (
                  <>
                    <Box component='div' className='alert alert-primary d-flex align-items-center'>
                      <i className='ki-duotone ki-shield-tick fs-2hx text-success me-4'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      <div className='d-flex flex-column'>
                        <h4 className='mb-1 text-dark'>Tudo pronto!</h4>
                        <span>Os dados do arquivo foram validados com sucesso.</span>
                        <span>Para iniciar a importação clique em "Importar" e aguarde o progresso total ser concluído.</span>
                      </div>
                    </Box>
                  </>
                )}
              </>
            )}
            {Validating && (
              <>
                <Box component='div' sx={{p: 1, alignSelf: 'center'}}>
                  <CircularProgressWithLabel value={CurrentValidation} />
                </Box>
                <Box component='div' sx={{p: 1, alignSelf: 'center'}}>
                  Validando dados...
                </Box>
              </>
            )}
            {Importing && (
              <>
                <Box component='div' sx={{p: 1, alignSelf: 'center'}}>
                  <CircularProgressWithLabel value={ImportProgress} />
                </Box>
                <Box component='div' sx={{p: 1, alignSelf: 'center'}}>
                  Importando {ImportIndex} de {importedData.length}
                </Box>
              </>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          {Importing && (
            <Button disabled={shouldInterrupt && !interrupted} variant='contained' color='secondary' onClick={() => setShouldInterrupt(true)}>
              {shouldInterrupt && !interrupted ? 'Interrompendo...' : 'Interromper'}
            </Button>
          )}
          <Button disabled={Importing} variant='contained' color='inherit' onClick={() => handleClose(false)} hidden={loading}>
            Cancelar
          </Button>
          <Button
            disabled={Importing}
            variant='contained'
            color='warning'
            hidden={View == 'SELECAO' || (View == 'UPLOAD' && !canEnableAdvancedImport) || loading}
            onClick={() => handleReturn()}
          >
            Voltar
          </Button>
          <Button
            disabled={Importing}
            variant='contained'
            color='primary'
            hidden={View == 'FINALIZAR' || View == 'UPLOAD' || View == 'SELECAO' || loading}
            onClick={() => handleAdvance()}
          >
            Avançar
          </Button>
          <Button
            disabled={!IsValidImport || Importing || Validating}
            variant='contained'
            color='success'
            hidden={View != 'FINALIZAR' || loading}
            onClick={() => handleClose(true)}
          >
            {interrupted ? 'Continuar' : 'Importar'}
          </Button>
        </DialogActions>
      </StyledDialog>

      {/* MODAL SYSMAF */}
      {modalSYSMAF && <SYSMAFModal accessCode={accessCode} id={0} callBack={SYSMAFCallBack} />}
    </>
  )
}

export default ImportacaoModal
