import {useEffect, useState} from 'react'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'

import {toast} from 'react-toastify'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {GridActionsCellItem, GridRenderCellParams, GridRowSelectionModel, ptBR} from '@mui/x-data-grid'
import {StyledDataGrid} from '../grid/StyledDataGrid'
import {NoRowsOverlay} from '../utility/NoRowsOverlay'
import LinearProgress from '@mui/material/LinearProgress'
import {GridColDef, useGridApiRef} from '@mui/x-data-grid-pro'
import ExibicaoModal from './modalExibicao'
import {IconButton, Radio, RadioGroup, Theme, ThemeProvider} from '@mui/material'
import {useThemeMode} from '../../../../../_metronic/partials'
import {globalDarkMuiTheme, globalLightMuiTheme} from '../utility/globalMuiTheme'
import DynamicList from '../tabs/components/DynamicList'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useMsal} from '@azure/msal-react'
import {ApiWrapper} from '../../../../modules/api/ApiWrapper'
import { json } from 'stream/consumers'

interface IModalSYSMAFProps {
  accessCode: string | undefined
  id: number
  disableEditType?: boolean
  callBack?: any
  deleteFunc?: any
}

interface IFormatacao {
  tipo: string
  exibirBadge: boolean
  badgeCor: string
  exibirIcone: boolean
  iconeCor: string
  icone: string
}

class CFormatacao implements IFormatacao {
  exibirBadge: boolean
  badgeCor: string
  exibirIcone: boolean
  iconeCor: string
  icone: string
  tipo: string

  constructor() {
    this.exibirBadge = false
    this.badgeCor = ''
    this.exibirIcone = false
    this.iconeCor = ''
    this.icone = ''
    this.tipo = ''
  }
}

const SYSMAFModal = ({accessCode, id, disableEditType, callBack, deleteFunc}: IModalSYSMAFProps) => {
  const msal = useMsal()
  const apiWrapper = new ApiWrapper(msal.instance)

  const [Opened, setOpened] = useState<boolean>(false)
  const [Title, setTitle] = useState<string>('')
  const [Saving, setSaving] = useState<boolean>(false)
  const [Data, setData] = useState<any>(null)
  const [DataID, setDataID] = useState<number>(0)
  const [TabIndex, setTabIndex] = useState<number>(0)
  const [mappedFields, setMappedFields] = useState<Array<any>>([])
  const [Tipo, setTipo] = useState<any>([
    {id: 'string', label: 'Texto'},
    {id: 'textArea', label: 'Texto Longo'},
    {id: 'geolocation', label: 'Geolocalização'},
    {id: 'map', label: 'Mapa'},
    {id: 'number', label: 'Número'},
    {id: 'decimal', label: 'Decimal'},
    {id: 'geocode', label: 'Geográfico'},
    {id: 'date', label: 'Data'},
    {id: 'dateTime', label: 'Data e Hora'},
    {id: 'boolean', label: 'Verdadeiro/Falso'},
    {id: 'singleSelect', label: 'Seleção'},
    {id: 'formulaSQL', label: 'Fórmula SQL'},
    {id: 'upload', label: 'Upload'},
    {id: 'special', label: 'Especial'},
  ])

  const [Validation, setValidation] = useState<{campo: {isValid: boolean; message: string | undefined}}>({
    campo: {
      isValid: true,
      message: undefined,
    },
  })

  const Formatacao = {
    special: ['Telefone', 'CEP', 'CPF', 'CNPJ', 'JSON'],
    number: ['Moeda (R$)', 'Moeda ($)', 'Percentual (%)', 'Progresso (%)'],
    decimal: ['Moeda (R$)', 'Moeda ($)', 'Percentual (%)', 'Progresso (%)'],
    string: ['Tudo em caixa alta', 'Tudo em caixa baixa', 'Url', 'Email'],
    textArea: ['Tudo em caixa alta', 'Tudo em caixa baixa'],
  }

  const handleCallBack = (data: any = null) => {
    if (callBack != null) {
      callBack(data)
    }
  }

  const handleValidate = () => {
    if (Data.campo == null || Data.campo == '') {
      toast.error('É necessário informar o campo')
      return false
    } else if (Data.label == null || Data.label == '') {
      toast.error('É necessário informar o label')
      return false
    } else if (Data.descricao == null || Data.descricao == '') {
      toast.error('É necessário informar a descrição')
      return false
    } else if (Data.tipo == null || Data.tipo == '') {
      toast.error('É necessário informar o tipo')
      return false
    }

    if (Data.tipo == 'singleSelect') {
      if (Data.idMenuAppLoopBack == null || Data.idMenuAppLoopBack == 0) {
        toast.error('É necessário informar o Menu App relacionado')
        return false
      } else if (Data.campoExibicao == null || Data.campoExibicao == '') {
        toast.error('É necessário informar o campo de exibição')
        return false
      }
    }

    if (!Validation.campo.isValid) {
      toast.error(Validation.campo.message)
      return false
    }

    return true
  }

  const handleOpen = async (accessCode: string | undefined, id: number) => {
    setDataID(id)
    setOpened(true)

    if (id == 0) {
      setTitle('Adicionar Campo Mapeado')
    } else {
      setTitle('Editar Campo Mapeado')
      handleStartPermissaoTab(id)
    }

    const mappedFieldsRequest = await apiWrapper.get(`api/v1/MenuAppFields/listarPorMenuApp?item.AccessCode=${accessCode}`)
    setMappedFields(mappedFieldsRequest.data.data.filter((x) => x.id != DataID))

    apiWrapper.get('api/v1/MenuAppFields/carregar?item.ID=' + id).then((response) => {
      setData(response.data.data)
      console.log(response.data.data)

      if (response.data.data.jsonformatacao != null && response.data.data.jsonformatacao != '') {
        setDataExtra(JSON.parse(response.data.data.jsonformatacao))
      } else {
        setDataExtra({ formatacao: '', exibicao: [], exibeCampoUrl: ''})
      }
    })
  }

  const handleClose = (save: boolean, keepOpen: boolean) => {
    if (!save) {
      setOpened(false)
      setData(null)
      handleCallBack()
      return
    }

    if (!handleValidate()) {
      return
    }

    setSaving(true)

    Data.jsonformatacao = JSON.stringify(DataExtra)

    let obj: any = {
      item: {
        AccessCode: accessCode,
        MenuAppField: Data,
      },
    }

    apiWrapper.put('api/v1/MenuAppFields/salvar', obj).then((response) => {
      if (!keepOpen) {
        setOpened(false)
        setData(null)
      } else {
        setSaving(false)
        setDataID(response.data.id)
        handleOpen(accessCode, response.data.id)
      }

      let res: any = {
        id: response.data.id,
        keepOpen: keepOpen,
      }

      handleCallBack(res)
      toast.success('Registro salvo com sucesso!')
    })
  }

  const handleOpenExcluir = () => {
    if (deleteFunc != null) {
      deleteFunc(DataID, 'SYSMAF')
      handleClose(false, false)
    }
  }

  const handleTabIndexChange = (event: any, newValue: number) => {
    setTabIndex(newValue)
  }

  const handleOnChangeAutoComplete = (key: string, value: any) => {
    setData((prev: any) => {
      if (key == 'tipo') {
        prev['campoSQLAlias'] = ''
        prev['campoExibicao'] = ''
        prev['idMenuAppLoopBack'] = null
        prev['idMenuAppLoopBackOption'] = []
        prev['criptografar'] = false
        prev['biSqlcustom'] = false
        prev['sqlcustom'] = ''
        prev['configuracao'] = {idmenuAppFieldLat: null, idmenuAppFieldLon: null}
      } else if (key == 'campoExibicao') {
        prev[key] = value != null ? value.label : ''
        return {...prev}
      }

      if (key.includes('.')) {
        let keys = key.split('.')
        prev[keys[0]][keys[1]] = value != null ? value.id : null
        return {...prev}
      }

      prev[key] = value != null ? value.id : ''
      return {...prev}
    })

    if (key == 'tipo') {
      setDataExtra((prev: any) => {
        prev['formatacao'] = ''
        return {...prev}
      })
    }

    if (key == 'idMenuAppLoopBack') {
      if (value != null) {
        apiWrapper.get('api/v1/MenuAppFields/listarSimplesPorMenuApp?item.IDMenuApp=' + value.id).then((response) => {
          setData((prev: any) => {
            prev['campoExibicaoOption'] = response.data.data
            prev['campoExibicao'] = ''
            return {...prev}
          })
        })
      } else {
        setData((prev: any) => {
          prev['campoExibicaoOption'] = []
          prev['campoExibicao'] = ''
          return {...prev}
        })
      }
    }
  }

  const handleOnInputChangeAutoComplete = (key: string, value: any) => {
    if (key == 'idMenuAppLoopBack') {
      apiWrapper.get('api/v1/MenuApp/listarPorTitulo?item.Query=' + value).then((response) => {
        setData((prev: any) => {
          prev['idMenuAppLoopBackOption'] = response.data.data
          return {...prev}
        })
      })
    }
  }

  const handleOnOpenAutoComplete = (key: string, event: any) => {
    if (event.target.value == '' || event.target.value == null) {
      handleOnInputChangeAutoComplete(key, '')
    }
  }

  const handleOnChangeInput = (key: string, event: any) => {
    setData((prev: any) => {
      prev[key] = event.target.value
      return {...prev}
    })
  }

  const handleUrlOnChange = (value) => {
    setDataExtra((prev: any) => {
      prev['exibeCampoUrl'] = value;
      return { ...prev }
    })
  };

  const handleOnChangeBoolean = (key: string, event: any) => {
    setData((prev: any) => {
      prev[key] = event.target.checked
      return {...prev}
    })
  }

  const handleValidateField = (event: any, field: string) => {
    switch (field) {
      case 'campo':
        apiWrapper.get(`api/v1/MenuAppFields/verificarExistenciaPorNome?item.AccessCode=${accessCode}&item.Campo=${Data?.campo}`).then((response) => {
          if (response.data.data != null) {
            setValidation({
              ...Validation,
              [field]: {
                isValid: false,
                message: 'Esta campo já se encontra mapeado.',
              },
            })
          } else {
            setValidation({
              ...Validation,
              [field]: {
                isValid: true,
                message: undefined,
              },
            })
          }
        })
        break
    }
  }

  function GetCanHaveExibicao(tipo?: string) {
    switch (tipo) {
      case 'string':
      case 'textArea':
      case 'number':
      case 'decimal':
      case 'boolean':
      case 'date':
      case 'dateTime':
      case 'singleSelect':
        return true
    }

    return false
  }

  function download(filename: string, text: string) {
    var a = document.createElement('a')
    a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
    a.setAttribute('download', filename)

    if (document.createEvent) {
      var event = document.createEvent('MouseEvents')
      event.initEvent('click', true, true)
      a.dispatchEvent(event)
    } else {
      a.click()
    }
  }

  function BaixarArquivoModelo(resourceName: string) {
    toast.info('Gerando arquivo modelo, aguarde...')

    apiWrapper.get(`api/v1/MenuAppResource/carregarModeloPorNome?item.Nome=${resourceName}`).then((response) => {
      download('modelo.sql', response.data.data.resource)
      toast.success('Arquivo modelo gerado com sucesso!')
    })
  }

  // ExtraData handlers: Mudanças de estado do objeto jsonformatacao do menuappfield

  const [DataExtra, setDataExtra] = useState<any>(null)
  const [DataExtraSelectionModel, setDataExtraSelectionModel] = useState<GridRowSelectionModel>([])

  const handleOnChangeAutoCompleteEx = (key: string, value: any) => {
    setDataExtra((prev: any) => {
      prev[key] = value
      return {...prev}
    })
  }

  const handleDeleteExibicoesSelecionadas = () => {
    setDataExtra((prev: any) => {
      let newArray = prev.exibicao.filter((x) => !DataExtraSelectionModel.includes(x.guid))
      prev.exibicao = newArray

      setDataExtraSelectionModel([])

      return {...prev}
    })
  }

  const handleDeleteExibicaoIndividual = (guid: string) => {
    setDataExtra((prev: any) => {
      let newArray = prev.exibicao.filter((x) => x.guid != guid)
      prev.exibicao = newArray

      return {...prev}
    })
  }

  const isEditable = () => {
    return !Saving
  }

  // Modal regras exibição

  const [modalExibicao, setModalExibicao] = useState(false)
  const [modalExibicaoItem, setModalExibicaoItem] = useState<any>(null)
  const [modalExibicaoItemIndex, setModalExibicaoItemIndex] = useState<number>(-1)

  const ExibicaoOpen = (index: number, item?: any) => {
    setModalExibicao(true)
    setModalExibicaoItem(item)
    setModalExibicaoItemIndex(index)
  }

  const ExibicaoCallback = (response: any = null) => {
    if (response != null) {
      setDataExtra((prev: any) => {
        let oldArray = JSON.parse(JSON.stringify(prev.exibicao))
        let item = oldArray.find((x) => x.guid == response.item.guid)

        if (item == null) {
          oldArray.push(response.item)
        } else {
          let index = oldArray.indexOf(item)
          oldArray[index] = response.item
        }

        prev.exibicao = oldArray
        return {...prev}
      })
    }

    setModalExibicao(false)
    setModalExibicaoItem(undefined)
  }

  // GRID Geral

  function TranslateGridTypeColumn(type: string) {
    switch (type) {
      case 'boolean':
        return 'string'
      case 'decimal':
      case 'geocode':
        return 'number'
    }

    return type
  }

  // GRID Exibição

  const apiRef_Filtros = useGridApiRef()

  function BuildActions_Exibicao(params: any) {
    let actions: Array<JSX.Element> = [
      <GridActionsCellItem
        icon={<EditIcon />}
        label='Editar'
        onClick={() => {
          let rows = apiRef_Filtros.current.getSortedRowIds()
          let index = rows.findIndex((x) => x == params.row.guid)
          ExibicaoOpen(index, params.row)
        }}
        showInMenu
      />,
      <GridActionsCellItem icon={<DeleteIcon />} label='Excluir' onClick={() => handleDeleteExibicaoIndividual(params.row.guid)} showInMenu />,
    ]

    return actions
  }

  function BuildGridColumns_Exibicao() {
    let badgeColors = [
      {id: 'badge badge-primary', label: 'Azul'},
      {id: 'badge badge-secondary', label: 'Cinza'},
      {id: 'badge badge-success', label: 'Verde'},
      {id: 'badge badge-danger', label: 'Vermelha'},
      {id: 'badge badge-warning', label: 'Amarelo'},
      {id: 'badge badge-info', label: 'Roxo'},
      {id: 'badge badge-light', label: 'Branco'},
      {id: 'badge badge-dark', label: 'Preto'},
    ]

    let textColors = [
      {id: 'text-primary', label: 'Azul'},
      {id: 'text-secondary', label: 'Cinza'},
      {id: 'text-success', label: 'Verde'},
      {id: 'text-danger', label: 'Vermelha'},
      {id: 'text-warning', label: 'Amarelo'},
      {id: 'text-info', label: 'Roxo'},
      {id: 'text-light', label: 'Branco'},
      {id: 'text-dark', label: 'Preto'},
      {id: 'text-muted', label: 'Fosco'},
      {id: 'text-white', label: 'Branco'},
    ]

    let columns: Array<any> = [
      {
        field: 'actions',
        width: 25,
        type: 'actions',
        editable: false,
        hideable: false,
        getActions: (params: any) => BuildActions_Exibicao(params),
      },
      {
        field: 'ordem',
        type: 'number',
        headerName: 'Ordem',
        headerAlign: 'left',
        width: 150,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
      {
        field: 'operador',
        type: 'string',
        headerName: 'Operador',
        headerAlign: 'left',
        width: 150,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
      {
        field: 'valor',
        type: TranslateGridTypeColumn(Data.tipo),
        headerName: 'Valor',
        headerAlign: 'left',
        width: 150,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
      /*
      {
        field: 'extra',
        type: Data.tipo,
        headerName: 'Valor extra',
        headerAlign: 'left',
        width: 150,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
      */
      {
        field: 'acao',
        type: 'string',
        headerName: 'Ação',
        headerAlign: 'left',
        width: 150,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
      },
      {
        field: 'icone',
        type: 'string',
        headerName: 'Ícone',
        headerAlign: 'left',
        align: 'left',
        width: 100,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
        renderCell: (params: GridRenderCellParams<any>) => {
          if (params.value != null && params.value != '') {
            return (
              <>
                <i className={params.value} aria-hidden='true'></i>
              </>
            )
          }

          return <>{params.value}</>
        },
      },
      {
        field: 'cor',
        type: 'string',
        headerName: 'Cor',
        headerAlign: 'left',
        width: 150,
        hideable: true,
        filterable: false,
        disableReorder: false,
        pinnable: true,
        renderCell: (params: GridRenderCellParams<any>) => {
          let item = badgeColors.find((x) => x.id == params.value) ?? textColors.find((x) => x.id == params.value)

          if (item != null) {
            return <>{item.label}</>
          }

          return <>{params.value}</>
        },
      },
    ]

    return columns
  }

  // GRID Permissões

  const apiRef_Permissoes = useGridApiRef()

  const [roles, setRoles] = useState<Array<any>>([])
  const [menuAppFieldsRoles, setMenuAppFieldsRoles] = useState<Array<any>>([])

  const handleStartPermissaoTab = (id: number) => {
    apiWrapper.get('api/v1/Role/listarRoles').then((response) => {
      setRoles(response.data.data)
    })

    apiWrapper.get(`api/v1/MenuAppFieldsRole/listarPorMenuAppField?item.IDMenuAppField=${id}`).then((response) => {
      setMenuAppFieldsRoles(response.data.data)
    })
  }

  const handleAddPermissao = () => {
    apiWrapper.get(`api/v1/MenuAppFieldsRole/Carregar?item.ID=0&item.IDMenuAppField=${DataID}`).then((response) => {
      setMenuAppFieldsRoles((prev) => {
        return [...prev, response.data.data]
      })
    })
  }

  const handleDeletePermissao = (id: number) => {
    apiWrapper.delete('api/v1/MenuAppFieldsRole/excluir', {data: {item: {ID: id}, headers: {}}}).then((response) => {
      if (response.data.mensagem == 'SUCESSO') {
        setMenuAppFieldsRoles((prev) => {
          return prev.filter((x) => x.id != id)
        })

        toast.success('Registro apagado com sucesso!')
      } else {
        toast.error('Ocorreu um erro ao excluir o registro.')
      }
    })
  }

  const handleOnChangeRole = (id: number, value: any) => {
    setMenuAppFieldsRoles((prev) => {
      let item = prev.find((x) => x.id == id)

      if (item != null) {
        item.idrole = value?.id ?? 0
        apiWrapper.put('api/v1/MenuAppFieldsRole/salvar', {item: {MenuAppFieldsRole: item}})
      }

      return [...prev]
    })
  }

  const handleOnChangePermissao = (id: number, value: string) => {
    setMenuAppFieldsRoles((prev) => {
      let item = prev.find((x) => x.id == id)

      if (item != null) {
        if (value == '0') {
          item.biUpdate = true
        } else {
          item.biUpdate = false
        }

        item.biSelect = true

        apiWrapper.put('api/v1/MenuAppFieldsRole/salvar', {item: {MenuAppFieldsRole: item}})
      }

      return [...prev]
    })
  }

  function BuildGridColumns_Permissoes() {
    let columns: Array<GridColDef> = [
      {
        field: 'id',
        type: 'number',
        headerName: 'Código',
        headerAlign: 'left',
        align: 'right',
        width: 75,
        minWidth: 75,
        maxWidth: 75,
        hideable: false,
        filterable: false,
        disableReorder: false,
        pinnable: false,
        disableColumnMenu: true,
        hideSortIcons: true,
      },
      {
        field: 'idrole',
        type: 'string',
        headerName: 'Grupo',
        headerAlign: 'left',
        align: 'center',
        width: 450,
        minWidth: 450,
        maxWidth: 450,
        hideable: false,
        filterable: false,
        disableReorder: false,
        pinnable: false,
        disableColumnMenu: true,
        hideSortIcons: true,
        renderCell: (params: GridRenderCellParams<any>) => {
          return (
            <Autocomplete
              disablePortal
              options={roles}
              sx={{width: 450}}
              size='small'
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    '& legend': {display: 'none'},
                    '& fieldset': {top: 0},
                  }}
                  size='small'
                  hiddenLabel
                  InputLabelProps={{shrink: false}}
                />
              )}
              getOptionLabel={(option: any) => {
                return option.nome
              }}
              value={params.row.idrole != 0 ? roles.find((op: any) => op.id == params.row.idrole) ?? null : null}
              onChange={(event, value, reason) => handleOnChangeRole(params.row.id, value)}
            />
          )
        },
      },
      {
        field: 'biSelect',
        type: 'string',
        headerName: 'Permissão',
        headerAlign: 'left',
        align: 'left',
        width: 215,
        minWidth: 215,
        maxWidth: 215,
        hideable: false,
        filterable: false,
        disableReorder: false,
        pinnable: false,
        disableColumnMenu: true,
        hideSortIcons: true,
        renderCell: (params: GridRenderCellParams<any>) => {
          return (
            <RadioGroup
              row
              id={`radio-permissao-${params.row.id}`}
              name='radio-buttons-group'
              defaultValue='0'
              value={params.row.biUpdate ? '0' : '1'}
              onChange={(event) => handleOnChangePermissao(params.row.id, event.target.value as string)}
            >
              <FormControlLabel value='0' control={<Radio size='small' />} label='Editar' />
              <FormControlLabel value='1' control={<Radio size='small' />} label='Visualizar' />
            </RadioGroup>
          )
        },
      },
      {
        field: 'biUpdate',
        type: 'string',
        headerName: 'Excluir',
        headerAlign: 'center',
        align: 'center',
        width: 75,
        minWidth: 75,
        maxWidth: 75,
        hideable: false,
        filterable: false,
        disableReorder: false,
        pinnable: false,
        disableColumnMenu: true,
        hideSortIcons: true,
        renderCell: (params: GridRenderCellParams<any>) => {
          return (
            <IconButton aria-label='delete' onClick={(event) => handleDeletePermissao(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          )
        },
      },
    ]

    return columns
  }

  /* THEME */

  const {mode} = useThemeMode()
  const [muiTheme, setMuiTheme] = useState<Theme>(mode == 'light' ? globalLightMuiTheme : globalDarkMuiTheme)

  const handleUpdateTheme = () => {
    setMuiTheme((mode as any) == 'dark' ? globalDarkMuiTheme : globalLightMuiTheme)
  }

  useEffect(() => {
    handleUpdateTheme()
  }, [mode])

  /* END THEME */

  useEffect(() => {
    handleOpen(accessCode, id)
  }, [])

  return (
    <ThemeProvider theme={muiTheme}>
      <Dialog fullScreen open={Opened}>
        <DialogTitle>
          {Title} {Data && <> - {Data.label}</>}
        </DialogTitle>
        <DialogContent dividers={true}>
          <Tabs value={TabIndex} onChange={handleTabIndexChange} aria-label='SYSMAP Tabs'>
            <Tab label='Campos' value={0} />
            <Tab label='Exibição' value={1} hidden={!GetCanHaveExibicao(Data?.tipo)} />
            <Tab label='Permissões' value={2} hidden={Data == null || DataID == 0 || Data.campo.toLowerCase() == 'id'} />
            <Tab label='Logs' value={3} hidden={DataID == 0} />
          </Tabs>
          <FormControl hidden={TabIndex != 0} sx={{m: 1}} component='fieldset' variant='standard'>
            {Opened && Data && (
              <>
                <Box component='div' sx={{p: 1}}>
                  <TextField
                    error={!Validation.campo.isValid}
                    helperText={Validation.campo.message}
                    onBlur={(event) => handleValidateField(event, 'campo')}
                    sx={{width: 500}}
                    disabled={DataID != 0 || !isEditable()}
                    size='small'
                    label='Campo'
                    value={Data.campo}
                    onChange={(event) => {
                      event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, '')
                      handleOnChangeInput('campo', event)
                    }}
                  />
                </Box>
                <Box component='div' sx={{p: 1}}>
                  <TextField
                    sx={{width: 500}}
                    disabled={!isEditable()}
                    size='small'
                    label='Nome'
                    value={Data.label}
                    onChange={(event) => handleOnChangeInput('label', event)}
                  />
                </Box>
                <Box component='div' sx={{p: 1}}>
                  <TextField
                    sx={{width: 500}}
                    disabled={!isEditable()}
                    size='small'
                    label='Descrição'
                    value={Data.descricao}
                    onChange={(event) => handleOnChangeInput('descricao', event)}
                  />
                </Box>
                <Box component='div' sx={{p: 1}}>
                  <TextField
                    sx={{width: 500}}
                    disabled={DataID == 0 || !isEditable()}
                    size='small'
                    label='Ordem'
                    type='number'
                    InputLabelProps={{shrink: true}}
                    value={Data.ordem}
                    onChange={(event) => handleOnChangeInput('ordem', event)}
                  />
                </Box>
                {disableEditType == null ||
                  (disableEditType === false && (
                    <Box component='div' sx={{p: 1}}>
                      <Autocomplete
                        disablePortal
                        options={Tipo}
                        sx={{width: 500}}
                        size='small'
                        disabled={!isEditable()}
                        renderInput={(params) => <TextField {...params} label='Tipo' />}
                        value={Data.tipo != '' ? Tipo.filter((op: any) => op.id == Data.tipo)[0] : null}
                        onChange={(event, value, reason) => handleOnChangeAutoComplete('tipo', value)}
                      />
                    </Box>
                  ))}
                {Data.tipo == 'map' && (
                  <Box component='div' sx={{p: 1, display: 'flex'}}>
                    <Autocomplete
                      disablePortal
                      options={mappedFields.filter((x) => x.tipo == 'geocode')}
                      sx={{width: 250, pr: 0.5}}
                      size='small'
                      disabled={!isEditable()}
                      renderInput={(params) => <TextField {...params} label='Campo que recebe a latitude' />}
                      value={
                        Data.configuracao.idmenuAppFieldLat != null
                          ? mappedFields.find((op: any) => op.id == Data.configuracao.idmenuAppFieldLat)
                          : null
                      }
                      onChange={(event, value, reason) => handleOnChangeAutoComplete('configuracao.idmenuAppFieldLat', value)}
                    />
                    <Autocomplete
                      disablePortal
                      options={mappedFields.filter((x) => x.tipo == 'geocode')}
                      sx={{width: 250, pl: 0.5}}
                      size='small'
                      disabled={!isEditable()}
                      renderInput={(params) => <TextField {...params} label='Campo que recebe a longitude' />}
                      value={
                        Data.configuracao.idmenuAppFieldLon != null
                          ? mappedFields.find((op: any) => op.id == Data.configuracao.idmenuAppFieldLon)
                          : null
                      }
                      onChange={(event, value, reason) => handleOnChangeAutoComplete('configuracao.idmenuAppFieldLon', value)}
                    />
                  </Box>
                )}
                {(Data.tipo == 'special' || Data.tipo == 'decimal' || Data.tipo == 'number' || Data.tipo == 'string') && DataExtra != null && (
                  <Box component='div' sx={{p: 1}}>
                    <Autocomplete
                      disablePortal
                      options={Formatacao[Data.tipo]}
                      sx={{width: 500}}
                      size='small'
                      disabled={!isEditable()}
                      renderInput={(params) => <TextField {...params} label='Formatação' />}
                      value={
                        DataExtra.formatacao != null && DataExtra.formatacao != ''
                          ? Formatacao[Data.tipo].filter((op: any) => op == DataExtra.formatacao)[0] ?? null
                          : null
                      }
                      onChange={(event, value, reason) => handleOnChangeAutoCompleteEx('formatacao', value)}
                    />
                  </Box>
                )}
                {Data.tipo == 'singleSelect' && (
                  <>
                    <Box component='div' sx={{p: 1}}>
                      <Autocomplete
                        disablePortal
                        options={Data.idMenuAppLoopBackOption}
                        sx={{width: 500}}
                        size='small'
                        renderInput={(params) => <TextField {...params} disabled={!isEditable()} label='Selecione o App' />}
                        value={
                          Data.idMenuAppLoopBack != null ? Data.idMenuAppLoopBackOption.filter((op: any) => op.id == Data.idMenuAppLoopBack)[0] : null
                        }
                        onOpen={(event) => handleOnOpenAutoComplete('idMenuAppLoopBack', event)}
                        onChange={(event, value, reason) => handleOnChangeAutoComplete('idMenuAppLoopBack', value)}
                        onInputChange={(event, value) => handleOnInputChangeAutoComplete('idMenuAppLoopBack', value)}
                      />
                    </Box>
                    <Box component='div' sx={{p: 1}}>
                      <Autocomplete
                        disablePortal
                        options={Data.campoExibicaoOption}
                        sx={{width: 500}}
                        size='small'
                        renderInput={(params) => <TextField {...params} disabled={!isEditable()} label='Campo de Exibição' />}
                        value={Data.campoExibicao != '' ? Data.campoExibicaoOption.filter((op: any) => op.label == Data.campoExibicao)[0] : null}
                        onChange={(event, value, reason) => handleOnChangeAutoComplete('campoExibicao', value)}
                      />
                    </Box>
                  </>
                )}
                {Data.tipo == 'formulaSQL' && (
                  <Box component='div' sx={{p: 1}}>
                    <TextField
                      sx={{width: 500}}
                      disabled={!isEditable()}
                      size='small'
                      label='SQL dinâmico'
                      value={Data.campoSQLAlias}
                      onChange={(event) => handleOnChangeInput('campoSQLAlias', event)}
                    />
                  </Box>
                )}
                {DataExtra != null && DataExtra.formatacao == 'Url' && (
                  <Box component='div' sx={{ p: 1 }}>
                    <TextField
                      sx={{ width: 500 }}
                      disabled={!isEditable()}
                      size='small'
                      label='Texto de Exibição'
                      value={DataExtra?.exibeCampoUrl || ""}
                      onChange={(event) => handleUrlOnChange(event.target.value)}
                    />
                  </Box>
                )}
                <Box component='div' sx={{p: 1}}>
                  <TextField
                    sx={{width: 500}}
                    disabled={!isEditable()}
                    size='small'
                    label='Agrupador'
                    value={Data.agrupador}
                    onChange={(event) => handleOnChangeInput('agrupador', event)}
                  />
                </Box>
                <Box component='div' sx={{pl: 1}}>
                  <FormControlLabel
                    label='Ativo'
                    control={
                      <Checkbox disabled={!isEditable()} checked={Data.ativo} onChange={(event, checked) => handleOnChangeBoolean('ativo', event)} />
                    }
                  />
                </Box>
                <Box component='div' sx={{pl: 1}}>
                  <FormControlLabel
                    label='Exibir grid'
                    control={
                      <Checkbox
                        disabled={!isEditable()}
                        checked={Data.exibirGrid}
                        onChange={(event, checked) => handleOnChangeBoolean('exibirGrid', event)}
                      />
                    }
                  />
                </Box>
                {Data.tipo == 'string' && (
                  <Box component='div' sx={{pl: 1}}>
                    <FormControlLabel
                      label='Dado sensível'
                      control={
                        <Checkbox
                          disabled={!isEditable()}
                          checked={Data.criptografar}
                          onChange={(event, checked) => handleOnChangeBoolean('criptografar', event)}
                        />
                      }
                    />
                  </Box>
                )}
                {Data.campo != '' && Data.campo.toLowerCase() != 'id' && (
                  <>
                    <Box component='div' sx={{pl: 1}}>
                      <FormControlLabel
                        label='Obrigatório'
                        control={
                          <Checkbox
                            disabled={!isEditable()}
                            checked={Data.obrigatorio}
                            onChange={(event, checked) => handleOnChangeBoolean('obrigatorio', event)}
                          />
                        }
                      />
                    </Box>
                    <Box component='div' sx={{pl: 1}}>
                      <FormControlLabel
                        label='Somente leitura'
                        control={
                          <Checkbox
                            disabled={!isEditable()}
                            checked={Data.somenteLeitura}
                            onChange={(event, checked) => handleOnChangeBoolean('somenteLeitura', event)}
                          />
                        }
                      />
                    </Box>
                  </>
                )}
                {Data.tipo == 'singleSelect' && (
                  <>
                    <Box component='div' sx={{pl: 1}}>
                      <FormControlLabel
                        label='Carregar dados a partir de um procedimento do bando de dados'
                        control={
                          <Checkbox
                            disabled={!isEditable()}
                            checked={Data.biSqlcustom}
                            onChange={(event, checked) => handleOnChangeBoolean('biSqlcustom', event)}
                          />
                        }
                      />
                    </Box>
                    {Data.biSqlcustom && (
                      <>
                        <Box component='div' sx={{p: 1}}>
                          <a
                            title='Clique aqui para realizar Download do arquivo modelo'
                            style={{cursor: 'pointer'}}
                            onClick={(event) => BaixarArquivoModelo('Listagem SingleSelect')}
                          >
                            <div className='container'>
                              <div className='row'>
                                <div className='col-1 p-1 text-center'>
                                  <img alt='Logo' src={toAbsoluteUrl('/media/app/sql64x64.png')} className='h-45px w-45px' />
                                </div>
                                <div className='col-11 p-1'>
                                  <span>Para baixar o arquivo modelo de procedimento </span>
                                  <br />
                                  <span>
                                    <strong className='link-primary'>Clique Aqui</strong>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </Box>
                        <Box component='div' sx={{p: 1}}>
                          <TextField
                            sx={{width: 500}}
                            disabled={!isEditable()}
                            size='small'
                            label='Nome do procedimento'
                            value={Data.sqlcustom}
                            onChange={(event) => handleOnChangeInput('sqlcustom', event)}
                          />
                        </Box>
                        <Box component='div' sx={{p: 1}} className='text-muted my-1 fs-6'>
                          Stored procedure deve conter os parametros IDPessoaMaster, IDPessoa, IDMenuApp, IDMenuAppGenericValue e Valores.
                        </Box>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </FormControl>
          <FormControl hidden={TabIndex != 1} sx={{m: 1, height: '90%', width: '100%'}} component='fieldset' variant='standard'>
            {Data && DataExtra && (
              <>
                <Box component='div' className='d-flex flex-stack' sx={{p: 1}}>
                  <Box component='div' className='page-title d-flex'>
                    <Box component='h1' className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
                      Regras de Exibição
                    </Box>
                    <Box component='span' className='h-20px border-gray-200 border-start mx-4'></Box>
                    <Box component='ul' className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
                      <Box component='li' className='breadcrumb-item text-muted'>
                        Gerenciamento
                      </Box>
                    </Box>
                  </Box>
                  <Box component='div' className='d-flex align-items-center py-1'>
                    {DataExtraSelectionModel.length > 0 && (
                      <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}}>
                        <Button variant='contained' startIcon={<DeleteIcon />} color='error' onClick={() => handleDeleteExibicoesSelecionadas()}>
                          Excluir
                        </Button>
                      </Box>
                    )}
                    <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}}>
                      <Button variant='contained' startIcon={<AddIcon />} color='info' onClick={() => ExibicaoOpen(DataExtra.exibicao.length)}>
                        Adicionar
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box component='div' sx={{display: 'flex', height: '100%', width: '100%'}}>
                  <StyledDataGrid
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    apiRef={apiRef_Filtros}
                    components={{
                      NoRowsOverlay: NoRowsOverlay,
                      NoResultsOverlay: NoRowsOverlay,
                      LoadingOverlay: LinearProgress,
                    }}
                    checkboxSelection
                    rows={DataExtra.exibicao}
                    columns={BuildGridColumns_Exibicao()}
                    rowHeight={38}
                    columnHeaderHeight={38}
                    getRowId={(row: any) => row.guid}
                    rowSelectionModel={DataExtraSelectionModel}
                    onRowSelectionModelChange={(newSelectionModel) => {
                      setDataExtraSelectionModel(newSelectionModel)
                    }}
                  />
                </Box>
              </>
            )}
          </FormControl>
          <FormControl hidden={TabIndex != 2} sx={{m: 1, height: '90%', width: '100%'}} component='fieldset' variant='standard'>
            {Data && DataID != 0 && (
              <>
                <Box component='div' className='d-flex flex-stack' sx={{p: 1}}>
                  <Box component='div' className='page-title d-flex'>
                    <Box component='h1' className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
                      Permissões
                    </Box>
                    <Box component='span' className='h-20px border-gray-200 border-start mx-4'></Box>
                    <Box component='ul' className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
                      <Box component='li' className='breadcrumb-item text-muted'>
                        Gerenciamento
                      </Box>
                    </Box>
                  </Box>
                  <Box component='div' className='d-flex align-items-center py-1'>
                    <Box component='div' sx={{display: 'flex', pl: 1, pr: 1}}>
                      <Button variant='contained' startIcon={<AddIcon />} color='info' onClick={() => handleAddPermissao()}>
                        Adicionar
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box component='div' sx={{display: 'flex', height: '100%', width: '100%'}}>
                  <StyledDataGrid
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    apiRef={apiRef_Permissoes}
                    components={{
                      NoRowsOverlay: NoRowsOverlay,
                      NoResultsOverlay: NoRowsOverlay,
                      LoadingOverlay: LinearProgress,
                    }}
                    rows={menuAppFieldsRoles}
                    columns={BuildGridColumns_Permissoes()}
                    rowHeight={45}
                    columnHeaderHeight={38}
                    getRowId={(row: any) => row.id}
                    disableRowSelectionOnClick
                  />
                </Box>
              </>
            )}
          </FormControl>
          <FormControl hidden={TabIndex != 3} sx={{m: 1, height: '90%', width: '100%'}} component='fieldset' variant='standard'>
            {Opened && Data != null && DataID != 0 && (
              <DynamicList
                isDisplayed={TabIndex == 3}
                accessCode='SYSMAA'
                sysParam='SYSMAF'
                IDMenuAppGenericItem={DataID.toString()}
                title=''
                subtitle=''
                displayHeader={false}
              />
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          {isEditable() && (
            <Button variant='contained' startIcon={<CancelIcon />} color='inherit' onClick={() => handleClose(false, false)}>
              Fechar
            </Button>
          )}
          {deleteFunc != null && isEditable() && DataID != 0 && (
            <Button variant='contained' startIcon={<DeleteIcon />} color='error' onClick={() => handleOpenExcluir()}>
              Excluir
            </Button>
          )}
          {isEditable() && (
            <>
              <Button variant='contained' startIcon={<SaveIcon />} color='success' onClick={() => handleClose(true, true)}>
                Salvar
              </Button>
              {deleteFunc != null && (
                <Button variant='contained' startIcon={<SaveIcon />} color='success' onClick={() => handleClose(true, false)}>
                  Salvar e Fechar
                </Button>
              )}
            </>
          )}
          {!isEditable() && (
            <Button variant='contained' startIcon={<SaveIcon />} color='success'>
              Salvando...
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* MODAL EXIBICAO */}
      {Data && modalExibicao && (
        <ExibicaoModal
          accessCode={accessCode}
          campo={Data}
          exibicaoItem={modalExibicaoItem}
          exibicaoItemIndex={modalExibicaoItemIndex}
          callBack={ExibicaoCallback}
          isMenuAppFilters={true}
        />
      )}
    </ThemeProvider>
  )
}

export default SYSMAFModal
