import './separator.css'

interface ISeparator {
  text: string
  style?: React.CSSProperties
}

const Separator = ({text, style}: ISeparator) => {
  return (
    <div className='separator-container'>
      <div className='separator' style={style}></div>
      <div>{text}</div>
      <div className='separator' style={style}></div>
    </div>
  )
}

export default Separator
